import { Action, AppState, AppStore } from './types';
import { useEffect, useMemo, useReducer, useRef } from 'react';

const useReducerWithMiddleware = (
  reducer: (state: AppState, action: Action) => AppState,
  initialState: AppState,
  middlewareFns: { (store: AppStore, action: Action): void }[] = [],
  afterwareFns: { (store: AppStore, action: Action | null | undefined): void }[] = []
) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const store = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  const aRef = useRef<Action | null>();

  function dispatchWithMiddleware(action: Action) {
    middlewareFns.forEach((middlewareFn) => middlewareFn(store, action));

    aRef.current = action;

    dispatch(action);
  }

  useEffect(() => {
    if (!aRef.current) return;

    afterwareFns.forEach((afterwareFn) => afterwareFn(store, aRef.current));

    aRef.current = null;
  }, [afterwareFns, store]);

  return [state, dispatchWithMiddleware] as const;
};

export { useReducerWithMiddleware };
