import { Action, AppState } from './types';
import { ActionTypes } from '../enums/action-types.enum';

const initialState: AppState = {
  isAppLoading: true,
  currentAuthUser: null,
  currentFirestoreUser: null,
  sidebar: {
    open: false,
    content: null,
  },
  learnWorlds: { url: '' },
  databaseEntity: null,
  toast: {
    text: '',
    show: false,
    backgroundColor: '',
  },
  showNavbar: true,
  showFooter: true,
};

function reducer(state: AppState, action: Action): AppState {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_CURRENT_AUTH_USER:
      return {
        ...state,
        currentAuthUser: payload.authUser,
      };
    case ActionTypes.SET_CURRENT_FIRESTORE_USER:
      return {
        ...state,
        currentFirestoreUser: payload.firestoreUser,
      };
    case ActionTypes.FINISH_APP_LOADING:
      return {
        ...state,
        isAppLoading: false,
      };
    case ActionTypes.SIDEBAR_OPEN:
      return {
        ...state,
        sidebar: {
          open: true,
          content: action.payload.sidebarContent,
        },
      };
    case ActionTypes.SIDEBAR_CLOSE:
      return {
        ...state,
        sidebar: {
          open: false,
          content: state.sidebar.content,
        },
      };
    case ActionTypes.LOAD_LEARN_WORLDS:
      return {
        ...state,
        learnWorlds: {
          url: payload.learnWorlds.url,
        },
      };
    case ActionTypes.SET_DATABASE_ENTITY:
      return {
        ...state,
        databaseEntity: payload.databaseEntity,
      };
    case ActionTypes.TOAST_OPEN:
      window.scrollTo(0, 0);
      return {
        ...state,
        toast: {
          text: payload.toast.text,
          backgroundColor: payload.toast.backgroundColor,
          show: payload.toast.show,
        },
      };
    case ActionTypes.TOAST_CLOSE:
      return {
        ...state,
        toast: {
          text: state.toast.text,
          backgroundColor: state.toast.backgroundColor,
          show: payload.toast.show,
        },
      };
    case ActionTypes.TOGGLE_SHOW_NAVBAR:
      return {
        ...state,
        showNavbar: payload.showNavbar,
      };
    case ActionTypes.TOGGLE_SHOW_FOOTER:
      return {
        ...state,
        showFooter: payload.showFooter,
      };
    default:
      return state;
  }
}

export { reducer, initialState };
