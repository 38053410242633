import { FieldValue, Timestamp } from 'firebase/firestore';
import { TimeFormat } from '../interfaces/database.interface';

export class Entity {
  id: string;
  idPrefix: string;

  createdAt?: FieldValue;
  modifiedAt?: FieldValue;
  createdByName?: string;
  createdById?: string;

  constructor(
    id: string,
    idPrefix: string,
    createdAt?: FieldValue,
    modifiedAt?: FieldValue,
    createdById?: string,
    createdByName?: string
  ) {
    this.id = id;
    this.idPrefix = idPrefix;
    this.createdAt = createdAt;
    this.modifiedAt = modifiedAt;
    this.createdByName = createdByName;
    this.createdById = createdById;
  }

  equalsTo(other: Entity): boolean {
    return this.id === other.id;
  }

  static secondsToTimeFormat(seconds?: number): TimeFormat | undefined {
    if (!seconds) {
      return undefined;
    }
    const dateObj = new Date(seconds * 1000);
    return { date: dateObj.toLocaleDateString(), time: dateObj.toLocaleTimeString() };
  }

  static fieldValueToString(value?: unknown) {
    const converted = value as Timestamp;
    if (value) {
      const parsed = Entity.secondsToTimeFormat(converted.seconds);
      if (parsed) {
        return parsed.date + ' pelas ' + parsed.time;
      } else {
        const elasticDate = Date.parse(value as string);
        const elasticParsed = Entity.secondsToTimeFormat(elasticDate / 1000);
        return elasticParsed ? elasticParsed.date + ' pelas ' + elasticParsed.time : 'Não existe data definida';
      }
    }
  }

  static generateId(prefix: string): string {
    return prefix + '#' + Math.random().toString(36).substr(2, 7);
  }
}
