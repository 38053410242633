export enum QueryTypes {
  Problems,
  Regions,
  TargetAudience,
  ProfessionalServices,
  Services,
  Ods,
  VolunteeringProfile,
  AcademicBackground,
  Duration,
  Approval,
}
