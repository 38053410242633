import './details-page-text.component.scss';

export default function DetailsPageText({ title, labels }: { title?: string; labels: string[] }) {
  return (
    <>
      {title ? <div className="details-page-text-title">{title}</div> : <></>}
      {labels.map((label, index) => (
        <div key={`label-${index}`} className="details-page-text-label">
          {label}
        </div>
      ))}
    </>
  );
}
