import { auth } from '../firebase-app';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: API_URL + '/email',
});

api.interceptors.request.use(
  async (config) => {
    const userIdToken = await auth.currentUser?.getIdToken();
    config.headers = {
      Authorization: 'Bearer ' + userIdToken,
    };

    return config;
  },
  (error) => Promise.reject(error)
);

const EmailService = {
  inviteUsers: async function (emails: string[]) {
    return api.post('/inviteUsers', emails);
  },
  resetUserPassword: async function (email: string) {
    return api.post('/resetUserPassword', { email });
  },
  resetUserPasswordConfirmation: async function (email: string) {
    return api.post('/resetUserPasswordConfirmation', { email });
  },
  send: async function (subject: string, bodyText: string, recipientsEmail: string[]) {
    return api.post('/generalist', {
      subject: subject,
      text: bodyText,
      emails: recipientsEmail,
    });
  },
};

export default EmailService;
