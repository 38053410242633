import { AiOutlineMenu } from 'react-icons/ai';
import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import BootstrapNavbar, { NavbarProps as BootstrapNavbarProps } from 'react-bootstrap/Navbar';

import NavbarOffcanvas from './navbar-offcanvas/navbar-offcanvas.component';

import blueLogo from '../../../images/blue-logo.png';

import './navbar.styles.scss';

function Navbar() {
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(false);

  const bootstrapNavbarProps: BootstrapNavbarProps = {
    collapseOnSelect: true,
    // sticky: 'top',
    expand: 'lg',
    className: 'padding-x u-navbar',
    onToggle: setIsNavbarCollapsed,
    expanded: isNavbarCollapsed,
  };

  return (
    <BootstrapNavbar {...bootstrapNavbarProps}>
      <BootstrapNavbar.Brand as={Link} to="/" className="u-navbar-brand">
        <img src={blueLogo} className="u-navbar-brand-logo" alt="U.DREAM logo" />
      </BootstrapNavbar.Brand>
      <BootstrapNavbar.Toggle
        aria-controls="offcanvasNavbar-expand-lg"
        className="u-navbar-toggler"
        label="Alternar navegação"
      >
        {!isNavbarCollapsed ? <AiOutlineMenu size="16" /> : <Fragment />}
      </BootstrapNavbar.Toggle>
      <NavbarOffcanvas
        ariaControlsId="offcanvasNavbar-expand-lg"
        showOffcanvas={isNavbarCollapsed}
        setShowOffcanvas={setIsNavbarCollapsed}
      />
    </BootstrapNavbar>
  );
}

export default Navbar;
