import * as Yup from 'yup';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Fragment, useContext, useEffect, useState } from 'react';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { useEffectOnce, useToggle } from 'react-use';
import { useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import Accordion from 'react-bootstrap/Accordion';
import BsButton from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';

// Components
import { AppContext } from '../../state/app-state-context';
import { ArrowLeftIcon, Button } from '../../components';
import { DatabaseService } from '../../services/database.service';
import { ELASTIC_ORGS_PATH, SearchApiOptions } from '../../interfaces/elastic/search-api.interface';
import { Feedback, feedbackOrganizationConverter, feedbackProjectConverter } from '../../model/feedback/feedback';
import { FeedbackType } from '../../enums/feedback-type.enum';
import {
  ODS,
  PROFESSIONAL_CATEGORIES,
  REGIONS,
  SERVICES,
  SOCIAL_PROBLEMS,
  TARGET_AUDIENCE,
  VOLUNTEERING_PROFILE,
} from '../../model/options';
import { Organization, organizationConverter, organizationElasticConverter } from '../../model/main/organization';
import { Project, projectConverter } from '../../model/main/project';
import { arrayToObject, cleanString, objectToArray } from '../../utils/helpers.util';
import { openToastAction } from '../../state/actions';

type ProjectFormValues = {
  organizationId?: string;
  organizationName?: string;
  projectName?: string;
  mission?: string;
  activity?: string;
  volunteeringDescription?: string;
  site?: string;
  email?: string;
  phoneInfo?: string;
  regions?: string[];
  problems?: string[];
  targetAudience?: string[];
  services?: string[];
  professionalServices?: string[];
  acceptsVolunteering?: boolean;
  volunteeringProfile?: string[];
  sustainabilityObjectives?: string[];
  observations?: string;
};

const projectSchema = Yup.object().shape({
  organizationId: Yup.string(),
  organizationName: Yup.string(),
  projectName: Yup.string(),
  mission: Yup.string(),
  activity: Yup.string(),
  volunteeringDescription: Yup.string(),
  site: Yup.string(),
  email: Yup.string(),
  phoneInfo: Yup.string(),
  regions: Yup.array().of(Yup.string()).nullable(),
  problems: Yup.array().of(Yup.string()).nullable(),
  targetAudience: Yup.array().of(Yup.string()).nullable(),
  services: Yup.array().of(Yup.string()).nullable(),
  professionalServices: Yup.array().of(Yup.string()).nullable(),
  acceptsVolunteering: Yup.boolean(),
  volunteeringProfile: Yup.array().of(Yup.string()).nullable(),
  sustainabilityObjectives: Yup.array().of(Yup.string()).nullable(),
  observations: Yup.string(),
});

enum ProjectSubmissionType {
  NewProject,
  NewOrganization,
  FeedbackProject,
  FeedbackOrganization,
}

function ProjectSubmission() {
  const { pathname } = useLocation();
  const [projectSubmissionType, setProjectSubmissionType] = useState<ProjectSubmissionType>();
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [project, setProject] = useState<Project>();
  const [organization, setOrganization] = useState<Organization>();
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<string>();
  const [appIsLoading, setAppIsLoading] = useState(true);
  const [organizationLoading, setIsOrganizationLoading] = useToggle(false);
  const {
    register,
    handleSubmit,
    formState: {
      errors,
      touchedFields: { acceptsVolunteering: acceptsVolunteeringWasTouched },
    },
    control,
    setValue,
    setFocus,
    setError,
  } = useForm<ProjectFormValues>({
    mode: 'all',
    resolver: yupResolver(projectSchema),
  });
  const {
    dispatch,
    state: { databaseEntity },
  } = useContext(AppContext);
  const navigate = useNavigate();

  useEffectOnce(() => {
    const setPurpose = async () => {
      try {
        if (pathname.includes('novo-projeto')) {
          setProjectSubmissionType(ProjectSubmissionType.NewProject);
        } else if (pathname.includes('nova-organizacao')) {
          setProjectSubmissionType(ProjectSubmissionType.NewOrganization);
        } else if (pathname.includes('projeto/feedback')) {
          setProjectSubmissionType(ProjectSubmissionType.FeedbackProject);
          const projectFromContext =
            databaseEntity && databaseEntity instanceof Project ? (databaseEntity as Project) : undefined;

          if (projectFromContext && projectFromContext.orgId) {
            const orgFromDb = await getOrganization(projectFromContext.orgId);
            setOrganizations([orgFromDb]);
          }

          setProject(projectFromContext);
        } else if (pathname.includes('organizacao/feedback')) {
          setProjectSubmissionType(ProjectSubmissionType.FeedbackOrganization);
          const projectFromContext =
            databaseEntity && databaseEntity instanceof Project ? (databaseEntity as Project) : undefined;

          if (projectFromContext && projectFromContext.orgId) {
            const orgFromDb = await getOrganization(projectFromContext.orgId);
            setOrganization(orgFromDb);
          }

          setProject(projectFromContext);
        }
      } catch {
        dispatch(openToastAction('Não foi possível carregar as entidades', 'danger'));
      }
    };

    setPurpose();
  });

  useEffect(() => {
    if (Object.keys(errors)[0] === 'organizationId') {
      setFocus('organizationId');
    }
  }, [setFocus, errors]);

  useEffect(() => {
    if (appIsLoading) {
      if (
        (projectSubmissionType === ProjectSubmissionType.FeedbackOrganization && organization && project) ||
        (projectSubmissionType === ProjectSubmissionType.FeedbackProject && organizations && project)
      ) {
        setFormDefaultValues();
        setAppIsLoading(false);
        return;
      }

      if (
        projectSubmissionType === ProjectSubmissionType.NewOrganization ||
        projectSubmissionType === ProjectSubmissionType.NewProject
      ) {
        setAppIsLoading(false);
      }
    }
  }, [projectSubmissionType, project, organization, organizations]);

  const handleOrganizationSearch = async (query: string) => {
    setIsOrganizationLoading(true);

    const searchApiOptions: SearchApiOptions = {
      engine: ELASTIC_ORGS_PATH,
      query: [query],
      pagination: { size: 1000, current: 1 },
      searchFields: {
        orgname: {},
      },
      sort: { orgname: 'asc' },
    };

    try {
      const { results: orgsFromDb } = await DatabaseService.searchWithElastic(
        searchApiOptions,
        organizationElasticConverter
      );

      setOrganizations(orgsFromDb);
    } catch {
      dispatch(openToastAction('Não foi possível encontrar organizações', 'warning'));
    } finally {
      setIsOrganizationLoading(false);
    }
  };

  const getOrganization = async (orgId: string) => {
    return await DatabaseService.getById<Organization>(Organization.PATH, orgId, organizationConverter);
  };

  const handleSelectOrganization = (options: Option[]) => {
    if (options.length > 0) {
      const selectedOrganization = options[0] as Organization;
      setSelectedOrganizationId(selectedOrganization.id);
      setValue('organizationId', selectedOrganization.id);
    } else {
      setOrganizations([]);
      setSelectedOrganizationId(undefined);
      setValue('organizationId', '');
    }
  };

  const setFormDefaultValues = () => {
    if (project) {
      if (projectSubmissionType === ProjectSubmissionType.FeedbackOrganization && organization) {
        setValue('organizationName', organization.orgName);
      }

      if (projectSubmissionType === ProjectSubmissionType.FeedbackProject) {
        setSelectedOrganizationId(project.orgId);
        setValue('organizationId', project.orgId);
        setValue('organizationName', project.orgName);
        setValue('projectName', project.name);
      }

      setValue('mission', project.mission);
      setValue('activity', project.activity);
      setValue('site', project.site);
      setValue('email', project.email);
      setValue('phoneInfo', project.phoneInfo);
      setValue('observations', project.observations);
      setValue('acceptsVolunteering', project.acceptsVolunteering === 'SIM');

      if (project.volunteeringProfile && project.volunteeringProfile.length > 0) {
        setValue(
          'volunteeringProfile',
          project.volunteeringProfile.map((profile) => profile.trim())
        );
      }

      setValue('volunteeringDescription', project.volunteeringDescription);

      if (project.regions && project.regions.length > 0) {
        setValue(
          'regions',
          project.regions.map((region) => region.trim())
        );
      }

      if (project.targetAudience && project.targetAudience.length > 0) {
        setValue(
          'targetAudience',
          project.targetAudience.map((audience) => audience.trim())
        );
      }

      if (project.services && project.services.length > 0) {
        setValue(
          'services',
          project.services.map((service) => service.trim())
        );
      }

      if (project.sustainabilityObjectives && project.sustainabilityObjectives.length > 0) {
        setValue(
          'sustainabilityObjectives',
          project.sustainabilityObjectives.map((objective) => objective.trim())
        );
      }

      if (project.problems && Object.keys(project.problems).length > 0) {
        setValue('problems', objectToArray(project.problems));
      }

      if (project.professionalServices && Object.keys(project.professionalServices).length > 0) {
        setValue('professionalServices', objectToArray(project.professionalServices));
      }
    }
  };

  const handleNavigationButton = () => {
    if (project) {
      return {
        text: 'VOLTAR',
        link: `/projetos/${
          project && project.name ? project.name.replace(/\s+/g, '') : 'nome-indefinido'
        }/${encodeURIComponent(project.id)}`,
      };
    } else {
      return {
        text: 'VOLTAR À LISTA',
        link: '/projetos',
      };
    }
  };

  const getAccordionItemKeys = (projectKey: string) => {
    if (
      !project ||
      !project[projectKey as keyof Project] ||
      Array.isArray(project[projectKey as keyof Project]) ||
      Object.keys(project[projectKey as keyof Project] ?? {}).length === 0
    ) {
      return [];
    }

    return Object.keys(project[projectKey as keyof Project] ?? {}).map((value) => value.split('.')[0]);
  };

  const isAccordionOpen = (projectKey: string) => {
    if (project && project[projectKey as keyof Project] && project[projectKey as keyof Project] !== null) {
      if (
        typeof project[projectKey as keyof Project] === 'object' &&
        !Array.isArray(project[projectKey as keyof Project])
      ) {
        return Object.keys(project[projectKey as keyof Project] ?? {}).length > 0 ? '0' : '-1';
      } else {
        return ((project[projectKey as keyof Project] as Array<string>) ?? []).length > 0 ? '0' : '-1';
      }
    }

    return '-1';
  };

  const onSubmit: SubmitHandler<ProjectFormValues> = async (data) => {
    if (checkForFormErrors(data)) {
      return;
    }
    const org = buildOrganizationFromForm(data);
    const proj = buildProjectFromForm(data, org);

    try {
      switch (projectSubmissionType) {
        case ProjectSubmissionType.NewOrganization:
          org.mainProjectId = proj.id;
          await DatabaseService.addEntry<Organization>(Organization.PATH, org, organizationConverter);
          await DatabaseService.addEntry<Project>(Project.PATH, proj, projectConverter);
          break;
        case ProjectSubmissionType.NewProject:
          await DatabaseService.addEntry<Project>(Project.PATH, proj, projectConverter);
          break;

        case ProjectSubmissionType.FeedbackOrganization:
          await DatabaseService.addEntry<Feedback<Organization>>(
            Feedback.ORGANIZATION_PATH,
            new Feedback(org, FeedbackType.Organization),
            feedbackOrganizationConverter
          );
          await DatabaseService.addEntry<Feedback<Project>>(
            Feedback.PROJECT_PATH,
            new Feedback(proj, FeedbackType.Project),
            feedbackProjectConverter
          );
          break;
        case ProjectSubmissionType.FeedbackProject:
          await DatabaseService.addEntry<Feedback<Project>>(
            Feedback.PROJECT_PATH,
            new Feedback(proj, FeedbackType.Project),
            feedbackProjectConverter
          );
          break;
      }

      dispatch(openToastAction('Operação efetuada com sucesso', 'success'));
      navigate(
        `/projetos/${proj.name ? proj.name.replace(/\s+/g, '') : 'nome-indefinido'}/${encodeURIComponent(proj.id)}`
      );
    } catch {
      dispatch(openToastAction('Não foi possível realizar a operação', 'danger'));
    }
  };

  const checkForFormErrors = (data: ProjectFormValues) => {
    let numberOfErrors = 0;

    if (
      projectSubmissionType === ProjectSubmissionType.FeedbackOrganization ||
      projectSubmissionType === ProjectSubmissionType.NewOrganization
    ) {
      if (!data.organizationName) {
        setError('organizationName', { message: 'Nome da organização é um campo obrigatório' });
        numberOfErrors++;
      }
    } else if (
      projectSubmissionType === ProjectSubmissionType.FeedbackProject ||
      projectSubmissionType === ProjectSubmissionType.NewProject
    ) {
      if (!data.organizationId) {
        setError('organizationId', { message: 'Nome da organização é um campo obrigatório' });
        numberOfErrors++;
      }

      if (!data.projectName) {
        setError('projectName', { message: 'Nome do projeto é um campo obrigatório' });
        numberOfErrors++;
      }
    }

    return numberOfErrors > 0;
  };

  const buildOrganizationFromForm = (data: ProjectFormValues) => {
    let org: Organization;
    if (projectSubmissionType === ProjectSubmissionType.FeedbackOrganization && organization) {
      org = organization;
      org.orgName = data.organizationName;
    } else {
      org = Organization.newUnapprovedOrganization(data.organizationName!);
    }
    return org;
  };

  const buildProjectFromForm = (data: ProjectFormValues, org: Organization) => {
    let proj: Project;
    if (projectSubmissionType === ProjectSubmissionType.NewOrganization) {
      proj = Project.newUnapprovedProject(data.organizationName!);
      proj.orgId = org.id;
      proj.orgName = org.orgName;
    } else if (projectSubmissionType === ProjectSubmissionType.NewProject) {
      proj = Project.newUnapprovedProject(data.projectName!);
      proj.orgId = data.organizationId;
      proj.orgName = organizations.filter((o) => o.id === data.organizationId)[0].orgName ?? '';
    } else {
      proj = project!;
      if (projectSubmissionType === ProjectSubmissionType.FeedbackOrganization) {
        proj.name = data.organizationName;
        proj.orgId = org.id;
      } else {
        proj.name = data.projectName;
        proj.orgId = data.organizationId;
        proj.orgName = organizations.filter((o) => o.id === data.organizationId)[0].orgName ?? '';
      }
    }

    proj.mission = data.mission;
    proj.activity = data.activity;
    proj.volunteeringDescription = data.volunteeringDescription;
    proj.volunteeringProfile = data.volunteeringProfile ?? [];
    proj.site = data.site;
    proj.email = data.email;
    proj.phoneInfo = data.phoneInfo;
    proj.regions = data.regions ?? [];
    proj.problems = data.problems && Object.keys(data.problems).length > 0 ? arrayToObject(data.problems) : {};
    proj.targetAudience = data.targetAudience ?? [];
    proj.services = data.services ?? [];
    proj.professionalServices =
      data.professionalServices && Object.keys(data.professionalServices).length > 0
        ? arrayToObject(data.professionalServices)
        : {};
    proj.sustainabilityObjectives = data.sustainabilityObjectives ?? [];
    proj.observations = data.observations;

    if (acceptsVolunteeringWasTouched) {
      proj.acceptsVolunteering = data.acceptsVolunteering ? 'SIM' : 'NÂO';
    } else {
      proj.acceptsVolunteering = project ? project.acceptsVolunteering : '';
    }

    return proj;
  };

  return (
    <Fragment>
      {appIsLoading ? (
        <Fragment />
      ) : (
        <Container className="py-5">
          <div className="d-inline-block mb-3">
            <Button
              text={handleNavigationButton().text}
              link={true}
              classes="p-0 m-0 d-flex align-items-center"
              icon={<ArrowLeftIcon classes="me-2" />}
              iconLeft={true}
              handleButtonPressed={() => navigate(handleNavigationButton().link)}
            />
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            {/* Organizations */}
            {(projectSubmissionType === ProjectSubmissionType.FeedbackProject ||
              projectSubmissionType === ProjectSubmissionType.NewProject) && (
              <Form.Group className="mb-3" controlId="formOrganizationId">
                <Form.Label>Organização</Form.Label>
                <Controller
                  control={control}
                  name="organizationId"
                  render={({ field, fieldState }) => (
                    <AsyncTypeahead
                      {...field}
                      onSearch={handleOrganizationSearch}
                      isLoading={organizationLoading}
                      id="formOrganizationId-select"
                      labelKey="orgName"
                      clearButton
                      options={organizations}
                      className={`${fieldState.error ? 'is-invalid' : ''}`}
                      placeholder="Por favor, selecione uma organização"
                      aria-describedby="Por favor, selecione uma organização"
                      caseSensitive={false}
                      emptyLabel="Não foi encontrada nenhuma organização"
                      onChange={handleSelectOrganization}
                      selected={organizations.filter((org) => org.id === selectedOrganizationId)}
                      inputProps={{ className: fieldState.error ? 'is-invalid' : '' }}
                      paginationText="Exibir resultados adicionais..."
                      paginate
                      minLength={2}
                      delay={500}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">{errors.organizationId?.message}</Form.Control.Feedback>
              </Form.Group>
            )}

            {/* Organization name */}
            {(projectSubmissionType === ProjectSubmissionType.FeedbackOrganization ||
              projectSubmissionType === ProjectSubmissionType.NewOrganization) && (
              <Form.Group className="mb-3" controlId="formName">
                <Form.Label>Nome da Organização</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nome da Organização"
                  {...register('organizationName')}
                  isInvalid={!!errors.organizationName}
                />
                <Form.Control.Feedback type="invalid">{errors.organizationName?.message}</Form.Control.Feedback>
              </Form.Group>
            )}

            {/* Project name */}
            {(projectSubmissionType === ProjectSubmissionType.FeedbackProject ||
              projectSubmissionType === ProjectSubmissionType.NewProject) && (
              <Form.Group className="mb-3" controlId="formProjectName">
                <Form.Label>Nome do Projeto</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nome da Projeto"
                  {...register('projectName')}
                  isInvalid={!!errors.projectName}
                />
                <Form.Control.Feedback type="invalid">{errors.projectName?.message}</Form.Control.Feedback>
              </Form.Group>
            )}

            {/* Mission */}
            <Form.Group className="mb-3" controlId="formMission">
              <Form.Label>Missão</Form.Label>
              <Form.Control as="textarea" rows={3} {...register('mission')} />
            </Form.Group>

            {/* Activity */}
            <Form.Group className="mb-3" controlId="formActivity">
              <Form.Label>Atividade</Form.Label>
              <Form.Control as="textarea" rows={3} {...register('activity')} />
            </Form.Group>

            {/* Website */}
            <Form.Group className="mb-3" controlId="formSite">
              <Form.Label>Página web</Form.Label>
              <Form.Control as="textarea" rows={3} {...register('site')} />
            </Form.Group>

            {/* Email */}
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control as="textarea" rows={3} {...register('email')} />
            </Form.Group>

            {/* Phone info */}
            <Form.Group className="mb-3" controlId="formPhoneInfo">
              <Form.Label>Contactos</Form.Label>
              <Form.Control as="textarea" rows={3} {...register('phoneInfo')} />
            </Form.Group>

            {/* Observations */}
            <Form.Group className="mb-3" controlId="formObservations">
              <Form.Label>Observações</Form.Label>
              <Form.Control as="textarea" rows={3} {...register('observations')} />
            </Form.Group>

            {/* Accepts volunteering */}
            <Form.Group className="mb-3" controlId="formAcceptsVolunteering">
              <Form.Check type="switch" label="Aceita voluntários" {...register('acceptsVolunteering')} />
            </Form.Group>

            {/* Volunteering profile */}
            <Accordion className="mb-3" defaultActiveKey={isAccordionOpen('volunteeringProfile')}>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Perfil de voluntariado</Accordion.Header>
                <Accordion.Body>
                  <Table bordered={false}>
                    <tbody>
                      {Object.values(VOLUNTEERING_PROFILE).map((profile, index) => {
                        return (
                          <tr key={`${profile}-${index}`}>
                            <td>{cleanString(profile)}</td>
                            <td>
                              <Form.Group controlId={`formVolunteeringProfile-${profile}-${index}`}>
                                <Form.Check
                                  className="float-end"
                                  aria-label={profile}
                                  {...register('volunteeringProfile')}
                                  value={profile}
                                />
                              </Form.Group>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            {/* Volunteering description */}
            <Form.Group className="mb-3" controlId="formVolunteeringDescription">
              <Form.Label>Atividades do Voluntário</Form.Label>
              <Form.Control as="textarea" rows={3} {...register('volunteeringDescription')} />
            </Form.Group>

            {/* Regions */}
            <Accordion className="mb-3" defaultActiveKey={isAccordionOpen('regions')}>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Regiões</Accordion.Header>
                <Accordion.Body>
                  <Table bordered={false}>
                    <tbody>
                      {Object.values(REGIONS).map((region, index) => {
                        return (
                          <tr key={`${region}-${index}`}>
                            <td>{cleanString(region)}</td>
                            <td>
                              <Form.Group controlId={`formRegions-${region}-${index}`}>
                                <Form.Check
                                  className="float-end"
                                  aria-label={region}
                                  {...register('regions')}
                                  value={region}
                                />
                              </Form.Group>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            {/* Target audience */}
            <Accordion className="mb-3" defaultActiveKey={isAccordionOpen('targetAudience')}>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Público Alvo</Accordion.Header>
                <Accordion.Body>
                  <Table bordered={false}>
                    <tbody>
                      {Object.values(TARGET_AUDIENCE).map((target, index) => {
                        return (
                          <tr key={`${target}-${index}`}>
                            <td>{cleanString(target)}</td>
                            <td>
                              <Form.Group controlId={`formTargetAudience-${target}-${index}`}>
                                <Form.Check
                                  className="float-end"
                                  aria-label={target}
                                  {...register('targetAudience')}
                                  value={target}
                                />
                              </Form.Group>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            {/* Services */}
            <Accordion className="mb-3" defaultActiveKey={isAccordionOpen('services')}>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Serviços</Accordion.Header>
                <Accordion.Body>
                  <Table bordered={false}>
                    <tbody>
                      {Object.values(SERVICES).map((service, index) => {
                        return (
                          <tr key={`${service}-${index}`}>
                            <td>{cleanString(service)}</td>
                            <td>
                              <Form.Group controlId={`formServices-${service}-${index}`}>
                                <Form.Check
                                  className="float-end"
                                  aria-label={service}
                                  {...register('services')}
                                  value={service}
                                />
                              </Form.Group>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            {/* Sustainability objectives */}
            <Accordion className="mb-3" defaultActiveKey={isAccordionOpen('sustainabilityObjectives')}>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Objetivos de desenvolvimento sustentável</Accordion.Header>
                <Accordion.Body>
                  <Table bordered={false}>
                    <tbody>
                      {Object.values(ODS).map((ods, index) => {
                        return (
                          <tr key={`${ods}-${index}`}>
                            <td>{cleanString(ods)}</td>
                            <td>
                              <Form.Group controlId={`formSustainabilityObjectives-${ods}-${index}`}>
                                <Form.Check
                                  className="float-end"
                                  aria-label={ods}
                                  {...register('sustainabilityObjectives')}
                                  value={ods}
                                />
                              </Form.Group>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            {/* Problems */}
            <Accordion className="mb-3" defaultActiveKey={isAccordionOpen('problems')}>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Problemas Sociais</Accordion.Header>
                <Accordion.Body>
                  <Accordion defaultActiveKey={getAccordionItemKeys('problems')} alwaysOpen>
                    {Object.values(SOCIAL_PROBLEMS).map((socialGroup) => {
                      return (
                        <Accordion.Item eventKey={socialGroup.id.toString()} key={socialGroup.group}>
                          <Accordion.Header>{cleanString(socialGroup.group)}</Accordion.Header>
                          <Accordion.Body>
                            <Table bordered={false}>
                              <tbody>
                                {socialGroup.problems.map((problem) => (
                                  <tr key={`${socialGroup.id}#${problem}`}>
                                    <td>{problem}</td>
                                    <td>
                                      <Form.Group controlId={`formProblems-${socialGroup.id}#${problem}`}>
                                        <Form.Check
                                          className="float-end"
                                          aria-label={problem}
                                          value={`${socialGroup.group}#${problem}`}
                                          {...register('problems')}
                                        />
                                      </Form.Group>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })}
                  </Accordion>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            {/* Professional categories */}
            <Accordion className="mb-3" defaultActiveKey={isAccordionOpen('professionalServices')}>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Serviços Profissionais</Accordion.Header>
                <Accordion.Body>
                  <Accordion defaultActiveKey={getAccordionItemKeys('professionalServices')} alwaysOpen>
                    {Object.values(PROFESSIONAL_CATEGORIES).map((category) => {
                      return (
                        <Accordion.Item eventKey={category.id.toString()} key={category.area}>
                          <Accordion.Header>{cleanString(category.area)}</Accordion.Header>
                          <Accordion.Body>
                            <Table bordered={false}>
                              <tbody>
                                {category.professions.map((profession) => (
                                  <tr key={`${category.id}#${profession}`}>
                                    <td>{cleanString(profession)}</td>
                                    <td>
                                      <Form.Group controlId={`formProfessionalServices-${category.id}#${profession}`}>
                                        <Form.Check
                                          className="float-end"
                                          aria-label={profession}
                                          value={`${category.area}#${profession}`}
                                          {...register('professionalServices')}
                                        />
                                      </Form.Group>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })}
                  </Accordion>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <div className="d-flex justify-content-center">
              <BsButton variant="primary" size="lg" type="submit" className="text-white">
                Submeter
              </BsButton>
            </div>
          </Form>
        </Container>
      )}
    </Fragment>
  );
}

export default ProjectSubmission;
