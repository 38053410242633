import { ElasticConverter } from '../../interfaces/elastic/elastic-converter.interface';
import { Entity } from '../Entity';
import { FieldValue, QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { FirestoreConverter } from '../../interfaces/database.interface';
import { SearchApiResponse, SearchBoxMeta } from '../../interfaces/elastic/search-api.interface';
import {
  problemsToSchema,
  professionalServicesToSchema,
  schemaToProblems,
  schemaToProfessionalServices,
} from '../../utils/helpers.util';

export type ProjectDTO = {
  id: string;
  name: string;
  mission: string;
  activity: string;
  volunteeringDescription: string;
  site: string;
  email: string;
  phoneInfo: string;
  rawRegions: string[];
  problems: { [key: string]: string[] };
  rawTargetAudience: string[];
  rawServices: string[];
  professionalServices: { [key: string]: string[] };
  acceptsVolunteering: string;
  rawVolunteeringProfile: string[];
  rawSustainabilityObjectives: string[];
  observations: string;
  approved: boolean;
  orgId: string;
  orgName: string;
  createdAt?: string;
  modifiedAt?: string;
  createdById?: string;
  createdByUser?: string;
};

export class Project extends Entity {
  status?: string;
  contactStatus?: string;
  name?: string;
  mission?: string;
  activity?: string;
  volunteeringDescription?: string;
  site?: string;
  email?: string;
  phoneInfo?: string;
  regions?: string[];
  /*
  key: ProblemGroup (Grupo do Problema)
  value: Array of SocialProblems (Problemas Sociais)
   */
  problems?: { [key: string]: string[] };
  targetAudience?: string[];
  services?: string[];
  /*
  key: ProfessionalArea (Area Profissional)
  value: Array of Profession (Profissão)
   */
  professionalServices?: { [key: string]: string[] };
  acceptsVolunteering?: string;
  volunteeringProfile?: string[];
  sustainabilityObjectives?: string[];
  observations?: string;
  approved: boolean;
  orgId?: string;
  orgName?: string;

  public static COLLECTION_NAME = '/projects';
  public static PATH = '/main' + Project.COLLECTION_NAME;

  public static PREFIX = 'prj';

  constructor(
    id: string,
    name: string,
    mission: string,
    activity: string,
    volunteeringDescription: string,
    site: string,
    email: string,
    phoneInfo: string,
    regions: string[],
    problems: { [p: string]: string[] },
    targetAudience: string[],
    services: string[],
    professionalServices: { [p: string]: string[] },
    acceptsVolunteering: string,
    volunteeringProfile: string[],
    sustainabilityObjectives: string[],
    observations: string,
    approved: boolean,
    orgId: string,
    orgName: string,
    createdAt?: FieldValue,
    modifiedAt?: FieldValue,
    createdById?: string,
    createdByName?: string
  ) {
    super(id, Project.PREFIX, createdAt, modifiedAt, createdById, createdByName);
    this.name = name;
    this.mission = mission;
    this.activity = activity;
    this.volunteeringDescription = volunteeringDescription;
    this.site = site;
    this.email = email;
    this.phoneInfo = phoneInfo;
    this.regions = regions;
    this.problems = problems;
    this.targetAudience = targetAudience;
    this.services = services;
    this.professionalServices = professionalServices;
    this.acceptsVolunteering = acceptsVolunteering;
    this.volunteeringProfile = volunteeringProfile;
    this.sustainabilityObjectives = sustainabilityObjectives;
    this.observations = observations;
    this.approved = approved;
    this.orgId = orgId;
    this.orgName = orgName;
  }

  static new(name: string): Project {
    return new Project('', name, '', '', '', '', '', '', [], {}, [], [], {}, '', [], [], '', false, '', '');
  }

  static newUnapprovedProject(name: string): Project {
    return new Project(
      Project.generateId(this.PREFIX),
      name,
      '',
      '',
      '',
      '',
      '',
      '',
      [],
      {},
      [],
      [],
      {},
      '',
      [],
      [],
      '',
      false,
      '',
      ''
    );
  }

  static projectFromFile(mappedProject: ProjectDTO): Project {
    return new Project(
      mappedProject.id,
      mappedProject.name,
      mappedProject.mission,
      mappedProject.activity,
      mappedProject.volunteeringDescription,
      mappedProject.site,
      mappedProject.email,
      mappedProject.phoneInfo,
      mappedProject.rawRegions.filter((e: string) => e),
      mappedProject.problems,
      mappedProject.rawTargetAudience.filter((e: string) => e),
      mappedProject.rawServices.filter((e: string) => e),
      mappedProject.professionalServices,
      mappedProject.acceptsVolunteering,
      mappedProject.rawVolunteeringProfile.filter((e: string) => e),
      mappedProject.rawSustainabilityObjectives.filter((e: string) => e),
      mappedProject.observations,
      mappedProject.approved,
      mappedProject.orgId,
      mappedProject.orgName
    );
  }
}

export const projectConverter: FirestoreConverter<Project> = {
  toFirestore: (project: Project) => {
    return {
      id: project.id,
      name: project.name === '' ? null : project.name,
      mission: project.mission === '' ? null : project.mission,
      activity: project.activity === '' ? null : project.activity,
      volunteeringDescription: project.volunteeringDescription === '' ? null : project.volunteeringDescription,
      site: project.site === '' ? null : project.site,
      email: project.email === '' ? null : project.email,
      phoneInfo: project.phoneInfo === '' ? null : project.phoneInfo,
      regions: project.regions,
      problems: problemsToSchema(project.problems),
      targetAudience: project.targetAudience,
      services: project.services,
      professionalServices: professionalServicesToSchema(project.professionalServices),
      acceptsVolunteering: project.acceptsVolunteering === '' ? null : project.acceptsVolunteering,
      volunteeringProfile: project.volunteeringProfile,
      sustainabilityObjectives: project.sustainabilityObjectives,
      observations: project.observations === '' ? null : project.observations,
      approved: project.approved,
      orgId: project.orgId,
      orgName: project.orgName,
      createdAt: project.createdAt,
      modifiedAt: project.modifiedAt,
      createdById: project.createdById,
      createdByName: project.createdByName,
    };
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot, options?: SnapshotOptions): Project => {
    const data = options ? snapshot.data(options) : snapshot.data();
    return new Project(
      data.id,
      data.name,
      data.mission,
      data.activity,
      data.volunteeringDescription,
      data.site,
      data.email,
      data.phoneInfo,
      data.regions,
      schemaToProblems(data.problems),
      data.targetAudience,
      data.services,
      schemaToProfessionalServices(data.professionalServices),
      data.acceptsVolunteering,
      data.volunteeringProfile,
      data.sustainabilityObjectives,
      data.observations,
      data.approved,
      data.orgId,
      data.orgName,
      data.createdAt,
      data.modifiedAt,
      data.createdById,
      data.createdByName
    );
  },
};

export const projectElasticConverter: ElasticConverter<Project> = {
  fromElastic(results: unknown[]): Project[] {
    return (results as ElasticProjectInterface[]).map(convertElasticEntityToProject);
  },
};

const convertElasticEntityToProject = (entity: ElasticProjectInterface) =>
  new Project(
    entity.id.raw,
    entity.name.raw,
    entity.mission.raw,
    entity.activity.raw,
    entity.volunteeringdescription.raw,
    entity.site.raw,
    entity.email.raw,
    entity.phoneinfo.raw,
    entity.regions.raw || [],
    JSON.parse(entity.problems.raw) || {},
    entity.targetaudience.raw || [],
    entity.services.raw || [],
    JSON.parse(entity.professionalservices.raw) || {},
    entity.acceptsvolunteering.raw,
    entity.volunteeringprofile.raw || [],
    entity.sustainabilityobjectives.raw || [],
    entity.observations.raw,
    entity.approved.raw === 'true',
    entity.orgid.raw,
    entity.orgname.raw,
    entity.createdat.raw,
    entity.modifiedat.raw,
    entity.createdbyid.raw,
    entity.createdbyname.raw
  );

export interface ElasticProjectInterface {
  id: { raw: string };
  name: { raw: string };
  mission: { raw: string };
  activity: { raw: string };
  volunteeringdescription: { raw: string };
  site: { raw: string };
  email: { raw: string };
  phoneinfo: { raw: string };
  regions: { raw: string[] };
  problems: { raw: string };
  targetaudience: { raw: string[] };
  services: { raw: string[] };
  professionalservices: { raw: string };
  acceptsvolunteering: { raw: string };
  volunteeringprofile: { raw: string[] };
  sustainabilityobjectives: { raw: string[] };
  observations: { raw: string };
  approved: { raw: string };
  orgid: { raw: string };
  orgname: { raw: string };
  createdat: { raw: FieldValue };
  modifiedat: { raw: FieldValue };
  createdbyid: { raw: string };
  createdbyname: { raw: string };
}

export interface SearchBoxProjectInterface {
  id: { raw: string };
  name: { raw: string };
  mission: { raw: string };
  activity: { raw: string };
  volunteeringdescription: { raw: string };
  site: { raw: string };
  email: { raw: string };
  phoneinfo: { raw: string };
  regions: { raw: string[] };
  problems: { raw: string };
  targetaudience: { raw: string[] };
  services: { raw: string[] };
  professionalservices: { raw: string };
  acceptsvolunteering: { raw: string };
  volunteeringprofile: { raw: string[] };
  sustainabilityobjectives: { raw: string[] };
  observations: { raw: string };
  approved: { raw: string };
  orgid: { raw: string };
  orgname: { raw: string };
  createdat: { raw: FieldValue };
  modifiedat: { raw: FieldValue };
  createdbyid: { raw: string };
  createdbyname: { raw: string };
  _meta: SearchBoxMeta;
}
