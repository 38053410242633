/* eslint-disable @typescript-eslint/no-explicit-any */

import { FilteredFormValues } from '../interfaces/filtered-form-values.interface';
import { PROFESSIONAL_CATEGORIES, SOCIAL_PROBLEMS } from '../model/options';
import { QueryFormat } from '../interfaces/database.interface';

export const fetchImage = async (imagePath: string) => {
  let image = '';
  let error = false;

  try {
    const response = await import(`../images/${imagePath}`);
    image = response.default;
  } catch {
    error = true;
  }

  return { image, error };
};

export const getWindowSize = () => {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
};

export const cleanString = (value: string) => value.slice(value.indexOf(' ') + 1);

export const allAreNullOrEmpty = (arr: any[]) => {
  let result = true;

  for (const value of arr) {
    if (value !== null) {
      result = false;
      break;
    }
  }

  return result;
};

export const cutString = (string = '', limit = 0) => {
  return string.length > limit ? string.substring(0, limit) + '...' : string;
};

export const isValidUrl = (urlString: string) => {
  try {
    const url = new URL(urlString);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch {
    return false;
  }
};

export const arrayToObject = (strArray: string[]) => {
  return strArray.reduce(
    (previousValue, currentValue) => {
      const { key, value } = stringToKeyValue(currentValue);

      if (!key || !value) {
        return previousValue;
      }

      if (key in previousValue) {
        previousValue[key] = [...previousValue[key], value];
      } else {
        previousValue[key] = [value];
      }

      return previousValue;
    },
    {} as {
      [key: string]: string[];
    }
  );
};

const stringToKeyValue = (str: string) => {
  const [key, value] = str.split('#');

  return {
    key,
    value,
  };
};

export const assign = (obj: any, keyPath: string[], value: any) => {
  const lastKeyIndex = keyPath.length - 1;
  for (let i = 0; i < lastKeyIndex; ++i) {
    const key = keyPath[i];
    if (!(key in obj)) {
      obj[key] = {};
    }
    obj = obj[key];
  }
  obj[keyPath[lastKeyIndex]] = value;
};

export const objectToArray = (obj: { [key: string]: string[] }) => {
  return Object.keys(obj).reduce(
    (arr, key) => [...arr, ...obj[key].map((value) => key + '#' + value.trim())],
    [] as string[]
  );
};

export const trimStringArray = (arr?: string[]) => {
  return !arr
    ? undefined
    : arr.map((element) => {
        return element.trim();
      });
};

export const problemsToSchema = (problems?: { [key: string]: string[] }): { [key: string]: string[] } => {
  if (!problems) {
    return {};
  }
  const result: { [key: string]: string[] } = {};
  Object.entries(problems).forEach(([key, value]) => {
    SOCIAL_PROBLEMS.forEach((rawProblem) => {
      if (rawProblem.group === key) {
        result[rawProblem.id + ''] = value;
      }
    });
  });
  return result;
};

export const schemaToProblems = (schema?: { [key: string]: string[] }): { [key: string]: string[] } => {
  if (!schema) {
    return {};
  }
  const result: { [key: string]: string[] } = {};
  Object.entries(schema).forEach(([key, value]) => {
    SOCIAL_PROBLEMS.forEach((rawProblem) => {
      if (rawProblem.id === parseInt(key)) {
        result[rawProblem.group] = value;
      }
    });
  });
  return result;
};

export const professionalServicesToSchema = (professionalServices?: {
  [key: string]: string[];
}): {
  [key: string]: string[];
} => {
  if (!professionalServices) {
    return {};
  }
  const result: { [key: string]: string[] } = {};
  Object.entries(professionalServices).forEach(([key, value]) => {
    PROFESSIONAL_CATEGORIES.forEach((rawArea) => {
      if (rawArea.area === key) {
        result[rawArea.id + ''] = value;
      }
    });
  });
  return result;
};

export const schemaToProfessionalServices = (schema?: { [key: string]: string[] }): { [key: string]: string[] } => {
  if (!schema) {
    return {};
  }
  const result: { [key: string]: string[] } = {};
  Object.entries(schema).forEach(([key, value]) => {
    PROFESSIONAL_CATEGORIES.forEach((rawArea) => {
      if (rawArea.id === parseInt(key)) {
        result[rawArea.area] = value;
      }
    });
  });
  return result;
};

type FunctionArrayEqualsGeneric<T> = (a?: T, b?: T) => boolean;

export const arrayEquals: FunctionArrayEqualsGeneric<Array<string | number>> = (a, b) => {
  if (a === b) {
    return true;
  }

  if (a == null || b == null) {
    return false;
  }

  if (a.length !== b.length) {
    return false;
  }

  return a.every((val, index) => val === b[index]);
};

export const titleCase = (str: string) => {
  return str
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
};

export const formatDate = (date: Date, separator = '/') => {
  return [padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join(separator);
};

export const padTo2Digits = (num: number) => {
  return num.toString().padStart(2, '0');
};

export const removeAccentsFromString = (str: string) => {
  return str.normalize('NFD').replace(/\p{Diacritic}/gu, '');
};

export const stringHasAccents = (str: string) => {
  return str !== removeAccentsFromString(str);
};

export const range = (start: number, stop: number) => Array.from({ length: stop - start + 1 }, (_, i) => start + i);

export const goTo = (url: string, target: '_self' | '_blank' | '_parent' | '_top' = '_self') =>
  window.open(url, target);

export const updateSetSelectedFilteredValues = (
  duplicatedSelectedFilteredValues: FilteredFormValues,
  key: string,
  filterToRemove: string
) => {
  type filteredFormValuesObjectKey = keyof typeof duplicatedSelectedFilteredValues;

  if (
    duplicatedSelectedFilteredValues[key as filteredFormValuesObjectKey] &&
    Array.isArray(duplicatedSelectedFilteredValues[key as filteredFormValuesObjectKey])
  ) {
    const arrayWithoutFilter = (
      duplicatedSelectedFilteredValues[key as filteredFormValuesObjectKey] as Array<string>
    ).filter((filter) => filter !== filterToRemove);

    return { ...duplicatedSelectedFilteredValues, [key]: arrayWithoutFilter };
  }
};

export const updateQueryFormatArray = (
  duplicateQueryFormatArray: QueryFormat[],
  key: string,
  filterToRemove: string
) => {
  let indexOfQueryFormat: number;

  if (filterToRemove.includes('#')) {
    indexOfQueryFormat = duplicateQueryFormatArray.findIndex((q) => {
      const [id, value] = filterToRemove.split('#');
      if (q.value instanceof Map && q.value.has(id)) {
        const mapValue = q.value.get(id);

        if (mapValue) {
          return mapValue.some((v) => v === value);
        }
      }

      return false;
    });
  } else {
    indexOfQueryFormat = duplicateQueryFormatArray.findIndex((q) => q.field === key);
  }

  const queryFormat = { ...duplicateQueryFormatArray[indexOfQueryFormat] };

  if (['string', 'substring', 'boolean'].includes(queryFormat.queryType)) {
    duplicateQueryFormatArray.splice(indexOfQueryFormat, 1);
  } else if (queryFormat.queryType === 'arrays-contains-any') {
    if (Array.isArray(queryFormat.value)) {
      if (queryFormat.value.length > 1) {
        queryFormat.value = (queryFormat.value as string[]).filter((q) => q !== filterToRemove);
        duplicateQueryFormatArray[indexOfQueryFormat] = queryFormat;
      } else {
        duplicateQueryFormatArray.splice(indexOfQueryFormat, 1);
      }
    }
  } else {
    const [id, value] = filterToRemove.split('#');
    if (queryFormat.value instanceof Map && queryFormat.value.has(id)) {
      const mapValue = queryFormat.value.get(id);

      if (mapValue) {
        if (mapValue.length > 1) {
          const arrFromMap = Array.from(queryFormat.value as Map<string, string[]>)[0];
          arrFromMap[1] = arrFromMap[1].filter((v) => v !== value);
          const mapStructure = new Map<string, string[]>();
          mapStructure.set(arrFromMap[0], arrFromMap[1]);
          queryFormat.value = mapStructure;
          duplicateQueryFormatArray[indexOfQueryFormat] = queryFormat;
        } else {
          duplicateQueryFormatArray.splice(indexOfQueryFormat, 1);
        }
      }
    }
  }

  return [...duplicateQueryFormatArray];
};
