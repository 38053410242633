import { DocumentData, FieldValue, QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { ElasticConverter } from '../../interfaces/elastic/elastic-converter.interface';
import { Entity } from '../Entity';
import { FirestoreConverter } from '../../interfaces/database.interface';
import { SearchBoxMeta } from '../../interfaces/elastic/search-api.interface';
import { problemsToSchema, schemaToProblems } from '../../utils/helpers.util';

export class OpinionLeader extends Entity {
  public static COLLECTION_NAME = '/leaders';
  public static PATH = '/opinion-leaders' + OpinionLeader.COLLECTION_NAME;
  public static PREFIX = 'opl';
  problems?: { [key: string]: string[] };
  name?: string;
  description?: string;
  academicBackground?: string[];
  linkedin?: string;
  observations?: string;
  twitter?: string;
  contacted?: string;
  contactResponsability?: string;
  facebook?: string;
  sites?: string;
  focusArea?: string;
  approved: boolean;

  constructor(
    id: string,
    problems: { [key: string]: string[] },
    name: string,
    description: string,
    academicBackground: string[],
    linkedin: string,
    observations: string,
    twitter: string,
    contacted: string,
    contactResponsability: string,
    facebook: string,
    sites: string,
    focusArea: string,
    approved: boolean,
    createdAt?: FieldValue,
    modifiedAt?: FieldValue,
    createdById?: string,
    createdByName?: string
  ) {
    super(id, OpinionLeader.PREFIX, createdAt, modifiedAt, createdById, createdByName);
    this.name = name;
    this.description = description;
    this.academicBackground = academicBackground;
    this.linkedin = linkedin;
    this.observations = observations;
    this.twitter = twitter;
    this.facebook = facebook;
    this.sites = sites;
    this.contacted = contacted;
    this.contactResponsability = contactResponsability;
    this.focusArea = focusArea;
    this.problems = problems;
    this.approved = approved;
  }

  static newUnapprovedOrganization(opinionLeaderName: string) {
    return new OpinionLeader(
      OpinionLeader.generateId(this.PREFIX),
      {},
      opinionLeaderName,
      '',
      [],
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      false
    );
  }

  fromFile(mappedOpinionLeader: any): OpinionLeader {
    this.name = mappedOpinionLeader.name;
    this.description = mappedOpinionLeader.description;
    this.academicBackground = mappedOpinionLeader.academicBackground;
    this.linkedin = mappedOpinionLeader.linkedin;
    this.observations = mappedOpinionLeader.observations;
    this.twitter = mappedOpinionLeader.twitter;
    this.facebook = mappedOpinionLeader.facebook;
    this.sites = mappedOpinionLeader.sites;
    this.contacted = mappedOpinionLeader.contacted;
    this.contactResponsability = mappedOpinionLeader.contactResponsability;
    this.focusArea = mappedOpinionLeader.focusArea;
    this.problems = mappedOpinionLeader.problems;
    this.approved = mappedOpinionLeader.approved;
    return this;
  }
}

export const opinionLeaderConverter: FirestoreConverter<OpinionLeader> = {
  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions | undefined): OpinionLeader {
    const data = options ? snapshot.data(options) : snapshot.data();
    return new OpinionLeader(
      data.id,
      schemaToProblems(data.problems),
      data.name,
      data.description,
      data.academicBackground,
      data.linkedin,
      data.observations,
      data.twitter,
      data.contacted,
      data.contactResponsability,
      data.facebook,
      data.sites,
      data.focusArea,
      data.approved,
      data.createdAt,
      data.modifiedAt,
      data.createdById,
      data.createdByName
    );
  },
  toFirestore(opinionLeader: OpinionLeader): DocumentData {
    return {
      id: opinionLeader.id,
      name: opinionLeader.name === '' ? null : opinionLeader.name,
      description: opinionLeader.description === '' ? null : opinionLeader.description,
      academicBackground: opinionLeader.academicBackground,
      linkedin: opinionLeader.linkedin === '' ? null : opinionLeader.linkedin,
      observations: opinionLeader.observations === '' ? null : opinionLeader.observations,
      twitter: opinionLeader.twitter === '' ? null : opinionLeader.twitter,
      facebook: opinionLeader.facebook === '' ? null : opinionLeader.facebook,
      sites: opinionLeader.sites === '' ? null : opinionLeader.sites,
      contacted: opinionLeader.contacted === '' ? null : opinionLeader.contacted,
      contactResponsability: opinionLeader.contactResponsability === '' ? null : opinionLeader.contactResponsability,
      focusArea: opinionLeader.focusArea === '' ? null : opinionLeader.focusArea,
      problems: problemsToSchema(opinionLeader.problems),
      approved: opinionLeader.approved,
      createdAt: opinionLeader.createdAt,
      modifiedAt: opinionLeader.modifiedAt,
      createdById: opinionLeader.createdById,
      createdByName: opinionLeader.createdByName,
    };
  },
};

export const leaderElasticConverter: ElasticConverter<OpinionLeader> = {
  fromElastic(results: unknown[]): OpinionLeader[] {
    return (results as ElasticLeaderInterface[]).map(convertElasticEntityToOpinionLeader);
  },
};

const convertElasticEntityToOpinionLeader = (entity: ElasticLeaderInterface) =>
  new OpinionLeader(
    entity.id.raw,
    JSON.parse(entity.problems.raw),
    entity.name.raw,
    entity.description.raw,
    entity.academicbackground.raw,
    entity.linkedin.raw,
    entity.observations.raw,
    entity.twitter.raw,
    entity.contacted.raw,
    entity.contactresponsability.raw,
    entity.facebook.raw,
    entity.sites.raw,
    entity.focusarea.raw,
    entity.approved.raw === 'true',
    entity.createdat.raw,
    entity.modifiedat.raw,
    entity.createdbyid.raw,
    entity.createdbyname.raw
  );

export interface ElasticLeaderInterface {
  id: { raw: string };
  name: { raw: string };
  description: { raw: string };
  academicbackground: { raw: string[] };
  linkedin: { raw: string };
  observations: { raw: string };
  twitter: { raw: string };
  contacted: { raw: string };
  contactresponsability: { raw: string };
  facebook: { raw: string };
  sites: { raw: string };
  focusarea: { raw: string };
  problems: { raw: string };
  approved: { raw: string };
  createdat: { raw: FieldValue };
  modifiedat: { raw: FieldValue };
  createdbyid: { raw: string };
  createdbyname: { raw: string };
}

export interface SearchBoxLeaderInterface {
  id: { raw: string };
  name: { raw: string };
  description: { raw: string };
  academicbackground: { raw: string[] };
  linkedin: { raw: string };
  observations: { raw: string };
  twitter: { raw: string };
  contacted: { raw: string };
  contactresponsability: { raw: string };
  facebook: { raw: string };
  sites: { raw: string };
  focusarea: { raw: string };
  problems: { raw: string };
  approved: { raw: string };
  createdat: { raw: FieldValue };
  modifiedat: { raw: FieldValue };
  createdbyid: { raw: string };
  createdbyname: { raw: string };
  _meta: SearchBoxMeta;
}
