import { AppContext } from '../../state/app-state-context';
import { DatabaseService } from '../../services/database.service';
import { ELASTIC_ORGS_PATH, SearchApiMeta, SearchApiOptions } from '../../interfaces/elastic/search-api.interface';
import { Entity } from '../../model/Entity';
import { Modal, SubmitOrganizationModalBody } from '../../components';
import { Organization, organizationConverter, organizationElasticConverter } from '../../model/main/organization';
import { SearchBoxProjectInterface } from '../../model/main/project';
import { openToastAction } from '../../state/actions';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/button/button.component';
import ElasticSearchBoxComponent from '../../components/elastic/elastic-search-box.component';
import OrganizationList from '../../components/database/main-document-form/organization-list';
import PaginationComponent from '../../components/pagination/pagination.component';
function MainDocumentEditor() {
  const navigate = useNavigate();
  const { dispatch } = useContext(AppContext);
  const [currentOrgs, setCurrentOrgs] = useState<Organization[]>([]);
  const [showSubmitNewOrganizationModal, setShowSubmitNewOrganizationModal] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [currentMeta, setCurrentMeta] = useState<SearchApiMeta>();
  useEffect(() => {
    fetchOrgs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchOrgs = async (page = 1) => {
    try {
      const options: SearchApiOptions = {
        engine: ELASTIC_ORGS_PATH,
        pagination: { size: 10, current: page },
      };
      const response = await DatabaseService.searchWithElastic(options, organizationElasticConverter);
      setCurrentOrgs(response?.results);
      setCurrentMeta(response?.meta);
    } catch (error) {
      dispatch(openToastAction('Erro na busca. Tente novamente.', 'danger'));
    }
  };
  const refreshOrg = async (orgId: string) => {
    try {
      const org = await DatabaseService.getById(Organization.PATH, orgId, organizationConverter);
      setCurrentOrgs(currentOrgs.map((o) => (o.id === org.id ? org : o)));
    } catch (error) {
      dispatch(openToastAction('Erro na busca. Tente novamente.', 'danger'));
    }
  };
  async function changePage(direction: 'next' | 'previous') {
    if (!currentMeta) {
      return;
    }
    if (direction === 'next') {
      return await fetchOrgs(currentMeta.page.current + 1);
    }
    if (direction === 'previous') {
      return await fetchOrgs(currentMeta.page.current - 1);
    } else {
      await fetchOrgs();
      dispatch(openToastAction('Erro ao carregar página. Tente novamente mais tarde.', 'danger'));
    }
  }
  async function newOrg(organizationName: string) {
    const org = new Organization(Entity.generateId(Organization.PREFIX), organizationName, true);
    try {
      const newOrg = await DatabaseService.addEntry(Organization.PATH, org, organizationConverter);
      setCurrentOrgs([newOrg]);
      dispatch(openToastAction('Organização submetida com sucesso.', 'success'));
    } catch (error) {
      dispatch(openToastAction('Erro ao submeter organização, tente novamente.', 'danger'));
    }
  }
  async function getOrgElastic(result: SearchBoxProjectInterface) {
    if (result.orgid.raw) {
      try {
        const org = await DatabaseService.getById<Organization>(
          Organization.PATH,
          result.orgid.raw,
          organizationConverter
        );
        setCurrentMeta(undefined);
        setCurrentOrgs([org]);
      } catch (e) {
        dispatch(openToastAction('Erro na busca da organização', 'danger'));
      }
    } else {
      dispatch(openToastAction('Este projeto não pertence a nenhuma organização.', 'danger'));
    }
  }
  async function fetchUnapprovedOrgs() {
    const fetch = await DatabaseService.getByQueriesElastic<Organization>(
      ELASTIC_ORGS_PATH,
      organizationElasticConverter,
      { enablePagination: true, limit: 30 },
      { enableSorting: false },
      { enableSearchByTerm: false },
      { field: 'approved', value: false, queryType: 'boolean' }
    );
    if (fetch.results.length > 0) {
      setCurrentOrgs(fetch.results);
    } else {
      dispatch(openToastAction('Não existem organizações para aprovar.', 'info'));
    }
  }
  function back() {
    if (currentOrgs.length <= 1) {
      fetchOrgs();
    } else {
      navigate('/');
    }
  }
  const handleSubmitNewOrganizationBtnPressed = () => {
    setTitleModal('Submeter nova organização');
    setShowSubmitNewOrganizationModal(true);
  };

  return (
    <div className="container border p-3">
      <div className="row justify-content-md-start p-2">
        <div className="col-md-auto">
          <Button text="Voltar" size="medium" handleButtonPressed={back}></Button>
        </div>
        <div className="col-md-auto">
          <Button text="Pesquisar por Projetos" size="medium" handleButtonPressed={() => navigate('edit/')}></Button>
        </div>
        <div className="col-md-auto">
          <Button
            text="Nova organização"
            size="medium"
            handleButtonPressed={() => handleSubmitNewOrganizationBtnPressed()}
          ></Button>
        </div>
        <div className="col-md-auto">
          <Button
            text="Organizações não aprovadas"
            size="medium"
            handleButtonPressed={() => fetchUnapprovedOrgs()}
          ></Button>
        </div>
        <div className="col d-flex justify-content-end">
          <ElasticSearchBoxComponent
            fields={['name', 'regions', 'mission', 'orgname']}
            titleField={'name'}
            engine={'projects'}
            callback={async (result) => {
              await getOrgElastic(result as SearchBoxProjectInterface);
            }}
          />
        </div>
      </div>
      <div className="row justify-content-md-center p-2">
        {currentMeta && <PaginationComponent currentMeta={currentMeta} changePage={changePage} />}
      </div>
      <OrganizationList orgs={currentOrgs} setOrgs={setCurrentOrgs} refreshOrg={refreshOrg}></OrganizationList>

      <div className="row justify-content-md-center p-2">
        {currentMeta && <PaginationComponent currentMeta={currentMeta} changePage={changePage} />}
      </div>
      <Modal
        setShowModal={setShowSubmitNewOrganizationModal}
        showModal={showSubmitNewOrganizationModal}
        title={titleModal}
        Component={
          <SubmitOrganizationModalBody setShowModal={setShowSubmitNewOrganizationModal} handleSubmitForm={newOrg} />
        }
      />
    </div>
  );
}
export default MainDocumentEditor;
