import {
  Dispatch,
  MouseEvent as ReactMouseEvent,
  ReactNode,
  Ref,
  SetStateAction,
  forwardRef,
  useRef,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import BootstrapDropdown from 'react-bootstrap/Dropdown';
import useClickAway from 'react-use/lib/useClickAway';

import './dropdown.styles.scss';

type CustomToggleProps = {
  children?: ReactNode;
  onClick?: (event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => unknown;
  dropdownIsOpen: boolean;
};

const CustomToggle = forwardRef(
  ({ children, onClick, dropdownIsOpen }: CustomToggleProps, ref: Ref<HTMLAnchorElement>) => (
    <a
      href="/actions"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        if (onClick) onClick(e);
      }}
      className="u-dropdown-toggle"
    >
      {children}
      {dropdownIsOpen ? <MdKeyboardArrowUp className="ms-1" /> : <MdKeyboardArrowDown className="ms-1" />}
    </a>
  )
);

CustomToggle.displayName = 'CustomToggle';

type DropdownProps = {
  list: { title: string; path: string }[];
  title: string;
  expandNavbar: Dispatch<SetStateAction<boolean>>;
};

function Dropdown({ list, title }: DropdownProps) {
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useClickAway(dropdownRef, () => {
    if (dropdownIsOpen) {
      setDropdownIsOpen(false);
    }
  });

  const handleDropdownClick = () => {
    setDropdownIsOpen(!dropdownIsOpen);
  };

  return (
    <BootstrapDropdown className="u-dropdown" ref={dropdownRef} onClick={handleDropdownClick}>
      <BootstrapDropdown.Toggle id="u-custom-dropdown" as={CustomToggle} dropdownIsOpen={dropdownIsOpen}>
        {title}
      </BootstrapDropdown.Toggle>
      <BootstrapDropdown.Menu className="u-dropdown-menu">
        {list.map((option, index) => (
          <BootstrapDropdown.Item key={index} as={Link} to={option.path} className="u-dropdown-item">
            {option.title}
          </BootstrapDropdown.Item>
        ))}
      </BootstrapDropdown.Menu>
    </BootstrapDropdown>
  );
}

export default Dropdown;
