import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Timestamp } from 'firebase/firestore';
import { date, object, string } from 'yup';
import { useContext } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import BootstrapContainer from 'react-bootstrap/container';
import BootstrapForm from 'react-bootstrap/form';

import { AppContext } from '../../state/app-state-context';
import { DISTRICTS } from '../../model/options';
import { Datepicker } from '../../components';
import { NewButton } from '../../components';
import { User, userEquals } from '../../interfaces/user.interface';
import { UserService } from '../../services';
import { titleCase } from '../../utils/helpers.util';

import './user-profile.styles.scss';
import { createFirestoreUserStateChangedAction, openToastAction } from '../../state/actions';

type FormValues = {
  name: string;
  surname: string;
  birthDate: Date;
  city: string;
  district: string;
  email: string;
};

const validationSchema = object().shape({
  name: string().required('Nome é um campo obrigatório'),
  surname: string().required('Apelido é um campo obrigatório'),
  birthDate: date()
    .required('Data de nascimento é um campo obrigatório')
    .transform((curr, orig) => (orig === '' ? null : curr))
    .nullable()
    .default(undefined),
  city: string().required('Cidade é um campo obrigatório'),
  district: string().oneOf(DISTRICTS, 'Tem que selecionar um distrito'),
  email: string().required('Email é um campo obrigatório.').email('Email inválido.'),
});

function UserProfile() {
  const {
    dispatch,
    state: { currentFirestoreUser },
  } = useContext(AppContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: titleCase(currentFirestoreUser?.name ?? ''),
      surname: titleCase(currentFirestoreUser?.surname ?? ''),
      birthDate: (currentFirestoreUser?.birthDate as Timestamp)?.toDate() ?? new Date(),
      city: titleCase(currentFirestoreUser?.city ?? ''),
      district: currentFirestoreUser?.district ?? '',
      email: currentFirestoreUser?.email ?? '',
    },
  });

  const onSubmit: SubmitHandler<FormValues> = async (formValues) => {
    if (currentFirestoreUser) {
      const updatedUser: User = {
        id: currentFirestoreUser.id,
        name: formValues.name.toLowerCase(),
        surname: formValues.surname.toLowerCase(),
        email: formValues.email,
        birthDate: Timestamp.fromDate(formValues.birthDate),
        city: formValues.city.toLowerCase(),
        district: formValues.district,
        created: currentFirestoreUser.created,
        role: currentFirestoreUser.role,
        migrated: currentFirestoreUser.migrated,
      };

      if (!userEquals(currentFirestoreUser, updatedUser)) {
        try {
          await UserService.updateUser(updatedUser);
          dispatch(createFirestoreUserStateChangedAction(updatedUser));
          dispatch(openToastAction('Dados pessoais atualizados com sucesso.', 'success'));
        } catch {
          dispatch(openToastAction('Não foi possível atualizar os dados pessoais!', 'danger'));
        }
      } else {
        dispatch(openToastAction('Não existe alterações para salvar!', 'info'));
      }
    }
  };

  return (
    <div className="user-profile-container">
      <BootstrapContainer className="pt-5 pb-5">
        <h2>Os meus dados pessoais</h2>
        <h3>Editar dados</h3>
        <BootstrapForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <BootstrapForm.Group className="custom-bootstrap-form-group" controlId="formName">
            <BootstrapForm.Label>Nome</BootstrapForm.Label>
            <BootstrapForm.Control
              type="text"
              placeholder="Nome"
              {...register('name')}
              isInvalid={!!errors.name}
              className="custom-input"
              disabled
            />
            <BootstrapForm.Control.Feedback type="invalid">{errors.name?.message}</BootstrapForm.Control.Feedback>
          </BootstrapForm.Group>
          <BootstrapForm.Group className="custom-bootstrap-form-group" controlId="formSurname">
            <BootstrapForm.Label>Apelido</BootstrapForm.Label>
            <BootstrapForm.Control
              type="text"
              placeholder="Apelido"
              {...register('surname')}
              isInvalid={!!errors.surname}
              className="custom-input"
            />
            <BootstrapForm.Control.Feedback type="invalid">{errors.surname?.message}</BootstrapForm.Control.Feedback>
          </BootstrapForm.Group>

          <BootstrapForm.Group className="custom-bootstrap-form-group" controlId="formBirthDate">
            <BootstrapForm.Label>Data de nascimento</BootstrapForm.Label>
            <Controller
              control={control}
              name="birthDate"
              render={({ field }) => (
                <Datepicker isInvalid={!!errors.birthDate} field={field.value} onChange={field.onChange} />
              )}
            />

            <div className={`invalid-feedback ${errors.birthDate && 'd-block'}`}>{errors.birthDate?.message}</div>
          </BootstrapForm.Group>
          <BootstrapForm.Group className="custom-bootstrap-form-group" controlId="formCity">
            <BootstrapForm.Label>Cidade</BootstrapForm.Label>
            <BootstrapForm.Control
              type="text"
              placeholder="Cidade"
              {...register('city')}
              isInvalid={!!errors.city}
              className="custom-input"
            />
            <BootstrapForm.Control.Feedback type="invalid">{errors.city?.message}</BootstrapForm.Control.Feedback>
          </BootstrapForm.Group>
          <BootstrapForm.Group className="custom-bootstrap-form-group" controlId="formDistrict">
            <BootstrapForm.Label>Distrito</BootstrapForm.Label>
            <BootstrapForm.Select
              {...register('district')}
              isInvalid={!!errors.district}
              defaultValue="selectDistrictDefaultValue"
              className="custom-input-select"
            >
              <option disabled value="selectDistrictDefaultValue">
                Selecionar distrito
              </option>
              {DISTRICTS.map((d) => (
                <option key={d} value={d}>
                  {d}
                </option>
              ))}
            </BootstrapForm.Select>
            <BootstrapForm.Control.Feedback type="invalid">{errors.district?.message}</BootstrapForm.Control.Feedback>
          </BootstrapForm.Group>
          <BootstrapForm.Group className="custom-bootstrap-form-group" controlId="formEmail">
            <BootstrapForm.Label>Email</BootstrapForm.Label>
            <BootstrapForm.Control
              type="text"
              placeholder="Email"
              {...register('email')}
              isInvalid={!!errors.email}
              className="custom-input"
            />
            <BootstrapForm.Control.Feedback type="invalid">{errors.email?.message}</BootstrapForm.Control.Feedback>
          </BootstrapForm.Group>
          <div className="d-flex justify-content-center">
            <NewButton size="lg" type="submit" className="user-profile-submit-button">
              Guardar alterações
            </NewButton>
          </div>
        </BootstrapForm>
      </BootstrapContainer>
    </div>
  );
}

export default UserProfile;
