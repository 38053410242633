import { ElasticConverter } from '../../interfaces/elastic/elastic-converter.interface';
import { Entity } from '../Entity';
import { FieldValue, QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { FirestoreConverter } from '../../interfaces/database.interface';
import { SearchApiResponse } from '../../interfaces/elastic/search-api.interface';

export class Organization extends Entity {
  orgName?: string;
  approved: boolean;
  mainProjectId: string | 'none';

  public static COLLECTION_NAME = '/orgs';
  public static PATH = '/main' + Organization.COLLECTION_NAME;
  public static PREFIX = 'org';

  constructor(
    id: string,
    orgName: string,
    approved: boolean,
    mainProjectId = 'none',
    createdAt?: FieldValue,
    modifiedAt?: FieldValue,
    createdById?: string,
    createdByName?: string
  ) {
    super(id, Organization.PREFIX, createdAt, modifiedAt, createdById, createdByName);
    this.orgName = orgName;
    this.approved = approved;
    this.mainProjectId = mainProjectId;
  }

  static newUnapprovedOrganization(name: string): Organization {
    return new Organization(Organization.generateId(this.PREFIX), name, false, '');
  }
}

export const organizationConverter: FirestoreConverter<Organization> = {
  toFirestore: (organization: Organization) => {
    return {
      id: organization.id,
      orgName: organization.orgName,
      approved: organization.approved,
      mainProjectId: organization.mainProjectId,
      createdAt: organization?.createdAt,
      modifiedAt: organization?.modifiedAt,
      createdById: organization?.createdById,
      createdByName: organization?.createdByName,
    };
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot, options?: SnapshotOptions) => {
    const data = options ? snapshot.data(options) : snapshot.data();
    return new Organization(
      data.id,
      data.orgName,
      data.approved,
      data.mainProjectId,
      data?.createdAt,
      data?.modifiedAt,
      data?.createdById,
      data?.createdByName
    );
  },
};

export const organizationElasticConverter: ElasticConverter<Organization> = {
  fromElastic(results: unknown[]): Organization[] {
    return (results as ElasticOrganizationInterface[]).map(convertElasticEntityToOrganization);
  },
};

const convertElasticEntityToOrganization = (entity: ElasticOrganizationInterface) =>
  new Organization(
    entity.id.raw,
    entity.orgname.raw,
    entity.approved.raw === 'true',
    entity.mainprojectid.raw,
    entity.createdat.raw,
    entity.modifiedat.raw,
    entity.createdbyid.raw,
    entity.createdbyname.raw
  );

export interface ElasticOrganizationInterface {
  id: { raw: string };
  orgname: { raw: string };
  mainprojectid: { raw: string };
  approved: { raw: string };
  createdat: { raw: FieldValue };
  modifiedat: { raw: FieldValue };
  createdbyid: { raw: string };
  createdbyname: { raw: string };
}
