import * as Yup from 'yup';
import { Dispatch, SetStateAction } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import BsButton from 'react-bootstrap/Button';
import BsModal from 'react-bootstrap/Modal';
import InputText from '../../input-text/input-text.component';

type newOpinionLeaderFormValues = {
  opinionLeaderName: string;
};

const newOpinionLeaderValidationSchema = Yup.object().shape({
  opinionLeaderName: Yup.string().required('Nome do líder de opinião é um campo obrigatório'),
});

type SubmitOpinionLeaderProps = {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  handleSubmitForm: (value: string) => void;
};

function SubmitOpinionLeaderModalBody({ setShowModal, handleSubmitForm }: SubmitOpinionLeaderProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<newOpinionLeaderFormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(newOpinionLeaderValidationSchema),
  });

  const onSubmit: SubmitHandler<newOpinionLeaderFormValues> = (data) => {
    if (Object.keys(errors).length === 0 && handleSubmitForm) {
      handleSubmitForm(data.opinionLeaderName);
      setShowModal(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BsModal.Body>
        <InputText<newOpinionLeaderFormValues>
          id="formLeaderName"
          name="opinionLeaderName"
          label="Nome do líder de opinião"
          showLabel={false}
          register={register}
          errorMessage={errors.opinionLeaderName?.message}
        />
      </BsModal.Body>
      <BsModal.Footer>
        <BsButton variant="secondary" onClick={() => setShowModal(false)}>
          Fechar
        </BsButton>
        <BsButton variant="primary" type="submit" className="text-white">
          Submeter
        </BsButton>
      </BsModal.Footer>
    </form>
  );
}

export default SubmitOpinionLeaderModalBody;
