import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { FirebaseError } from 'firebase/app';
import { IconBaseProps } from 'react-icons';
import { Link, useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { useContext, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

// Components
import { AppContext } from '../../state/app-state-context';
import { ArrowLeftIcon, NewButton, SocialLogin } from '../../components';
import { FirebaseAuthErrors, UnknownError } from '../../constants/errors.constants';
import { openToastAction, toggleShowFooterAction } from '../../state/actions';
import AuthService from '../../services/auth.service';

// Images
import comunidade from '../../images/homepage/comunidade.svg';

// Styles
import './signin.component.scss';

type FormValues = {
  email: string;
  password: string;
};

const validationSchema = object().shape({
  email: string().required('Email é um campo obrigatório').email('Email inválido'),
  password: string()
    .required('Password é um campo obrigatório')
    .min(6, 'Password tem que ter pelo o menos 6 caracteres')
    .max(40, 'Password não pode exceder os 40 caracteres'),
});

const iconProps: IconBaseProps = {
  color: 'rgba(23, 72, 74, 0.5)',
  size: '18',
};

function SignIn() {
  const [passwordShown, setPasswordShown] = useState(false);
  const {
    dispatch,
    state: { currentAuthUser, currentFirestoreUser },
  } = useContext(AppContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({ mode: 'onSubmit', resolver: yupResolver(validationSchema) });
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(toggleShowFooterAction(false));

    return () => {
      dispatch(toggleShowFooterAction(true));
    };
  });

  useEffect(() => {
    const nextStep = async () => {
      if (currentFirestoreUser) {
        navigate('/');
      } else {
        await AuthService.deleteUser(currentAuthUser!);
        dispatch(openToastAction('Antes de iniciar sessão, necessita de se registar!', 'danger'));
      }
    };

    if (currentAuthUser) {
      nextStep();
    }
  }, [currentAuthUser, currentFirestoreUser]);

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      await AuthService.signInWithEmailAndPassword(data.email, data.password);
    } catch (error) {
      if (error instanceof FirebaseError) {
        const errorMessage = FirebaseAuthErrors[error.code] ?? UnknownError;
        dispatch(openToastAction(errorMessage, 'danger'));
      }
    }
  };

  const handleShowPassword = () => setPasswordShown(!passwordShown);

  return (
    <div className="sign-in-container">
      <a className="go-back" href="https://www.udream.pt/">
        <ArrowLeftIcon />
        <span>VOLTAR AO SITE</span>
      </a>
      <div className="content">
        <img src={comunidade}></img>
        <span className="title">Entrar na plataforma</span>
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Form.Group className="custom-bootstrap-form-group" controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              placeholder="Insira o seu endereço email"
              {...register('email')}
              isInvalid={!!errors.email}
              className="custom-input"
            />
            <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="custom-bootstrap-form-group" controlId="formPassword">
            <div className="d-flex justify-content-between">
              <Form.Label>Password</Form.Label>
              <Link to={'/esqueceu-palavra-passe'} className="annotation">
                Recuperar password
              </Link>
            </div>
            <InputGroup>
              <Form.Control
                type={passwordShown ? 'text' : 'password'}
                placeholder="Insira a sua password"
                {...register('password')}
                isInvalid={!!errors.password}
                className="custom-input-password"
              />
              <InputGroup.Text onClick={handleShowPassword} role="button">
                {passwordShown ? <AiOutlineEyeInvisible {...iconProps} /> : <AiOutlineEye {...iconProps} />}
              </InputGroup.Text>
            </InputGroup>

            <div className={`invalid-feedback ${errors.password && 'd-block'}`}>{errors.password?.message}</div>
          </Form.Group>
          <NewButton theme="green" accent="white" outlined={false} size="lg" type="submit">
            Entrar
          </NewButton>
        </Form>
        <p className="text-center create-account">
          Ainda não tem uma conta?&nbsp;
          <Link to="/registar">Criar conta</Link>
        </p>
        <div className="sso-container">
          <span>ou entrar com</span>
          <SocialLogin />
        </div>
      </div>
    </div>
  );
}

export default SignIn;
