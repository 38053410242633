import { DocumentData, FieldValue, QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { ElasticConverter } from '../../interfaces/elastic/elastic-converter.interface';
import { Entity } from '../Entity';
import { FirestoreConverter } from '../../interfaces/database.interface';
import { SearchBoxMeta } from '../../interfaces/elastic/search-api.interface';
import { problemsToSchema, schemaToProblems } from '../../utils/helpers.util';

export class Action extends Entity {
  public static COLLECTION_NAME = '/actions';
  public static PATH = '/service-actions' + Action.COLLECTION_NAME;
  public static PREFIX = 'act';
  /*
     key: ProblemGroup (Grupo do Problema)
     value: Array of SocialProblems (Problemas Sociais)
      */
  problems?: { [key: string]: string[] };
  targetAudience?: string[];
  durationDescription?: string;
  actionDescripton?: string;
  actionHowTo?: string;
  inspirationLinks?: string;
  approved?: boolean;

  constructor(
    id: string,
    problems: { [key: string]: string[] },
    targetAudience: string[],
    durationDescription: string,
    actionDescripton: string,
    actionHowTo: string,
    inspirationLinks: string,
    approved: boolean,
    createdAt?: FieldValue,
    modifiedAt?: FieldValue,
    createdById?: string,
    createdByName?: string
  ) {
    super(id, Action.PREFIX, createdAt, modifiedAt, createdById, createdByName);
    this.problems = problems;
    this.targetAudience = targetAudience;
    this.durationDescription = durationDescription;
    this.actionDescripton = actionDescripton;
    this.actionHowTo = actionHowTo;
    this.inspirationLinks = inspirationLinks;
    this.approved = approved;
  }

  static newUnapprovedAction(actionDescription: string, actionHowTo: string): Action {
    return new Action(Action.generateId(this.PREFIX), {}, [], '', actionDescription, actionHowTo, '', false);
  }
}

export const actionConverter: FirestoreConverter<Action> = {
  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions | undefined): Action {
    const data = options ? snapshot.data(options) : snapshot.data();
    return new Action(
      data.id,
      schemaToProblems(data.problems),
      data.targetAudience,
      data.durationDescription,
      data.actionDescripton,
      data.actionHowTo,
      data.inspirationLinks,
      data.approved,
      data.createdAt,
      data.modifiedAt,
      data.createdById,
      data.createdByName
    );
  },
  toFirestore(action: Action): DocumentData {
    return {
      id: action.id,
      problems: problemsToSchema(action.problems),
      targetAudience: action.targetAudience,
      durationDescription: action.durationDescription === '' ? null : action.durationDescription,
      actionDescripton: action.actionDescripton === '' ? null : action.actionDescripton,
      actionHowTo: action.actionHowTo === '' ? null : action.actionHowTo,
      inspirationLinks: action.inspirationLinks === '' ? null : action.inspirationLinks,
      approved: action.approved ?? null,
      createdAt: action.createdAt,
      modifiedAt: action.modifiedAt,
      createdByName: action.createdByName,
      createdById: action.createdById,
    };
  },
};

export const actionElasticConverter: ElasticConverter<Action> = {
  fromElastic(results: unknown[]): Action[] {
    return (results as ElasticActionInterface[]).map(convertElasticEntityToAction);
  },
};

const convertElasticEntityToAction = (entity: ElasticActionInterface) =>
  new Action(
    entity.id.raw,
    JSON.parse(entity.problems.raw),
    entity.targetaudience.raw,
    entity.durationdescription.raw,
    entity.actiondescripton.raw,
    entity.actionhowto.raw,
    entity.inspirationlinks.raw,
    entity.approved.raw === 'true',
    entity.createdat.raw,
    entity.modifiedat.raw,
    entity.createdbyid.raw,
    entity.createdbyname.raw
  );

export interface ElasticActionInterface {
  id: { raw: string };
  problems: { raw: string };
  targetaudience: { raw: string[] };
  durationdescription: { raw: string };
  actiondescripton: { raw: string };
  actionhowto: { raw: string };
  inspirationlinks: { raw: string };
  approved: { raw: string };
  createdat: { raw: FieldValue };
  modifiedat: { raw: FieldValue };
  createdbyid: { raw: string };
  createdbyname: { raw: string };
}

export interface SearchBoxActionInterface {
  id: { raw: string };
  problems: { raw: string };
  targetaudience: { raw: string[] };
  durationdescription: { raw: string };
  actiondescripton: { raw: string };
  actionhowto: { raw: string };
  inspirationlinks: { raw: string };
  approved: { raw: string };
  createdat: { raw: FieldValue };
  modifiedat: { raw: FieldValue };
  createdbyid: { raw: string };
  createdbyname: { raw: string };
  _meta: SearchBoxMeta;
}
