type RemoveIconProps = { classes?: string };

function RemoveIcon({ classes = '' }: RemoveIconProps) {
  return (
    <svg width="20" height="4" viewBox="0 0 32 4" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2C0 0.89543 0.89543 0 2 0H30C31.1046 0 32 0.89543 32 2C32 3.10457 31.1046 4 30 4H2C0.89543 4 0 3.10457 0 2Z"
        fill="black"
      />
    </svg>
  );
}

export default RemoveIcon;
