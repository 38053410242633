import { ButtonHTMLAttributes, ReactNode } from 'react';

import './new-button.styles.scss';

interface NewButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  theme?: 'white' | 'beige' | 'green' | 'teal' | 'yellow';
  accent?: 'white' | 'green' | 'teal' | 'yellow';
  size?: 'sm' | 'lg';
  type?: 'button' | 'submit';
  outlined?: boolean;
}

function NewButton({
  theme = 'green',
  accent = 'white',
  size = 'sm',
  type = 'button',
  children,
  outlined = false,
  className,
  ...rest
}: NewButtonProps) {
  const buttonProps = {
    className: `u-button u-button-${theme} u-button-accent-${accent} u-button-${size} u-button-${
      outlined ? 'outlined' : 'solid'
    } ${className}`,
    ...rest,
  };

  return <button {...buttonProps}>{children}</button>;
}

export default NewButton;
