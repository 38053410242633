import { Button } from '..';
import { SearchApiMeta } from '../../interfaces/elastic/search-api.interface';

export type PaginationProps = {
  currentMeta: SearchApiMeta;
  changePage: (direction: 'next' | 'previous') => void;
};

function PaginationComponent({ currentMeta, changePage }: PaginationProps) {
  return (
    <div className="col-md-auto">
      <Button
        text="Página anterior"
        disabled={currentMeta.page.current === 1}
        outline={true}
        size="small"
        handleButtonPressed={() => changePage('previous')}
      ></Button>
      {currentMeta.page.current + '/' + currentMeta.page.total_pages}
      <Button
        text="Próxima página"
        outline={true}
        disabled={currentMeta.page.current === currentMeta?.page.total_pages}
        size="small"
        handleButtonPressed={() => changePage('next')}
      ></Button>
    </div>
  );
}
export default PaginationComponent;
