type AddIconProps = { outerClasses?: string; innerClasses?: string };

function AddIcon({ outerClasses = '', innerClasses = '' }: AddIconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={outerClasses}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 -3.8147e-06C17.1046 -3.8147e-06 18 0.895427 18 2V14H30C31.1046 14 32 14.8954 32 16C32 17.1046 31.1046 18 30 18H18V30C18 31.1046 17.1046 32 16 32C14.8954 32 14 31.1046 14 30V18H2C0.89543 18 0 17.1046 0 16C0 14.8954 0.89543 14 2 14H14V2C14 0.895427 14.8954 -3.8147e-06 16 -3.8147e-06Z"
        fill="black"
        className={innerClasses}
      />
    </svg>
  );
}

export default AddIcon;
