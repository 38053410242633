import { User as AuthUser } from 'firebase/auth';
import { ReactNode } from 'react';
import { Variant } from 'react-bootstrap/types';

import { Action, Payload } from './types';
import { ActionTypes } from '../enums/action-types.enum';
import { Entity } from '../model/Entity';
import { User as FirestoreUser } from '../interfaces/user.interface';

const ACTION_PAYLOAD: Payload = {
  authUser: null,
  firestoreUser: null,
  sidebarContent: null,
  learnWorlds: {
    url: '',
  },
  databaseEntity: null,
  toast: {
    text: '',
    show: false,
    backgroundColor: '',
  },
  showNavbar: true,
  showFooter: true,
};

function createAuthUserStateChangedAction(authUser: AuthUser | null): Action {
  return {
    type: ActionTypes.SET_CURRENT_AUTH_USER,
    payload: {
      ...ACTION_PAYLOAD,
      authUser: authUser,
    },
  };
}

function createFirestoreUserStateChangedAction(firestoreUser: FirestoreUser | null): Action {
  return {
    type: ActionTypes.SET_CURRENT_FIRESTORE_USER,
    payload: {
      ...ACTION_PAYLOAD,
      firestoreUser: firestoreUser,
    },
  };
}

function finishAppLoadingAction(): Action {
  return {
    type: ActionTypes.FINISH_APP_LOADING,
    payload: {
      ...ACTION_PAYLOAD,
    },
  };
}

function openSidebarAction(sidebarContent: ReactNode): Action {
  return {
    type: ActionTypes.SIDEBAR_OPEN,
    payload: {
      ...ACTION_PAYLOAD,
      sidebarContent: sidebarContent,
    },
  };
}

function closeSidebarAction(): Action {
  return {
    type: ActionTypes.SIDEBAR_CLOSE,
    payload: {
      ...ACTION_PAYLOAD,
    },
  };
}

function loadLearnWorldsAction(url: string): Action {
  return {
    type: ActionTypes.LOAD_LEARN_WORLDS,
    payload: {
      ...ACTION_PAYLOAD,
      learnWorlds: { url },
    },
  };
}

function setDatabaseEntityAction(entity?: Entity): Action {
  return {
    type: ActionTypes.SET_DATABASE_ENTITY,
    payload: {
      ...ACTION_PAYLOAD,
      databaseEntity: entity ? entity : null,
    },
  };
}

function openToastAction(text: string, backgroundColor: Variant): Action {
  return {
    type: ActionTypes.TOAST_OPEN,
    payload: {
      ...ACTION_PAYLOAD,
      toast: {
        text,
        show: true,
        backgroundColor,
      },
    },
  };
}

function closeToastAction(): Action {
  return {
    type: ActionTypes.TOAST_CLOSE,
    payload: {
      ...ACTION_PAYLOAD,
    },
  };
}

function toggleShowNavbarAction(show: boolean): Action {
  return {
    type: ActionTypes.TOGGLE_SHOW_NAVBAR,
    payload: {
      ...ACTION_PAYLOAD,
      showNavbar: show,
    },
  };
}

function toggleShowFooterAction(show: boolean): Action {
  return {
    type: ActionTypes.TOGGLE_SHOW_FOOTER,
    payload: {
      ...ACTION_PAYLOAD,
      showFooter: show,
    },
  };
}

export {
  createAuthUserStateChangedAction,
  createFirestoreUserStateChangedAction,
  finishAppLoadingAction,
  openSidebarAction,
  closeSidebarAction,
  loadLearnWorldsAction,
  setDatabaseEntityAction,
  openToastAction,
  closeToastAction,
  toggleShowNavbarAction,
  toggleShowFooterAction,
};
