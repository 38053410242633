import { Fragment, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import Container from 'react-bootstrap/Container';

// Components
import { AppContext } from '../../state/app-state-context';
import {
  ArrowLeftIcon,
  DetailsPageFeedback,
  DetailsPageHeader,
  DetailsPageSection,
  DetailsPageTag,
  DetailsPageText,
  NewButton,
} from '../../components';
import { DatabaseService } from '../../services/database.service';
import { Organization, organizationConverter } from '../../model/main/organization';
import { Project, projectConverter } from '../../model/main/project';
import { REGIONS } from '../../model/options';
import { ScreenBreakpoint } from '../../enums/screen-breakpoint.enum';
import { cleanString } from '../../utils/helpers.util';
import { fetchImage } from '../../utils';
import { setDatabaseEntityAction } from '../../state/actions';

// Styles
import './project-detail.component.scss';

function ProjectDetail() {
  const { width: windowWidth } = useWindowSize();
  const { projectId } = useParams();
  const [project, setProject] = useState<Project>();
  const [organization, setOrganization] = useState<Organization>();
  const [projectImageSrc, setProjectImageSrc] = useState('');
  const [appIsLoading, setAppIsLoading] = useState(true);
  const { dispatch } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (projectId) {
      getProject(projectId);
    }
  }, [projectId]);

  const getProject = async (projectId: string) => {
    const projectFromDb = await DatabaseService.getById<Project>(Project.PATH, projectId, projectConverter);

    if (projectFromDb) {
      setProject(projectFromDb);
      fetchProjectImage(projectFromDb.regions);
      fetchOrganization(projectFromDb.orgId);
    }

    setAppIsLoading(false);
  };

  const fetchProjectImage = async (regions?: string[]) => {
    const placeholderSrcImg = (await fetchImage('placeholder.png')).image;

    if (regions && regions.length > 0) {
      let imageSrc = '';

      if (
        regions.some((region) =>
          [REGIONS.PORTUGAL_CONTINENTAL, REGIONS.INTERNACIONAL, REGIONS.TODO_O_PAIS].includes(region)
        )
      ) {
        imageSrc = (await importImage('portugal-continental-todo-o-pais-internacional')) ?? placeholderSrcImg;
      } else if (regions.length >= 6) {
        imageSrc = (await importImage('6-a-20-distritos')) ?? placeholderSrcImg;
      } else if (regions.length >= 2) {
        imageSrc = (await importImage('2-a-5-distritos')) ?? placeholderSrcImg;
      } else if (regions.length === 1) {
        imageSrc = (await importImage('1-distrito')) ?? placeholderSrcImg;
      }

      setProjectImageSrc(imageSrc);
    } else {
      setProjectImageSrc(placeholderSrcImg);
    }
  };

  const importImage = async (imageName: string) => {
    const { image, error } = await fetchImage(`abrangencia-geografica/${imageName}.jpg`);

    if (!error) {
      return image;
    }
  };

  const fetchOrganization = async (orgId?: string) => {
    if (orgId) {
      const org = await DatabaseService.getById<Organization>(Organization.PATH, orgId!, organizationConverter);
      setOrganization(org);
    }
  };

  const giveFeedbackHandler = () => {
    dispatch(setDatabaseEntityAction(project));

    if (project && organization && project.id === organization.mainProjectId) {
      navigate(
        `/projetos/${
          project && project.name ? project?.name?.replace(/\s+/g, '') : 'nome-indefinido'
        }/organizacao/feedback`
      );
    } else {
      navigate(
        `/projetos/${project && project.name ? project?.name?.replace(/\s+/g, '') : 'nome-indefinido'}/projeto/feedback`
      );
    }
  };

  return (
    <Fragment>
      {appIsLoading ? (
        <Fragment />
      ) : (
        <Container className="py-5">
          <a className="go-back" href="/projetos">
            <ArrowLeftIcon />
            <span>VOLTAR À LISTA</span>
          </a>
          <div className="details-page-content">
            <div className="details-page-content-left">
              {windowWidth < ScreenBreakpoint.Large ? <img src={projectImageSrc} alt={project?.name} /> : <></>}
              <DetailsPageHeader
                sectionTitle="Projetos & Valências Sociais"
                title={project?.name}
                subTitle={organization?.orgName}
                description={project?.mission}
              ></DetailsPageHeader>
              {Object.entries(project?.problems || {}).length ? (
                <DetailsPageSection title="Problemáticas">
                  {Object.entries(project?.problems || {}).map(([problematic, problems], problemIndex) => {
                    return (
                      <DetailsPageText
                        key={`problem-${problemIndex}`}
                        title={cleanString(problematic)}
                        labels={problems.map((problem) => `· ${problem}`)}
                      ></DetailsPageText>
                    );
                  })}
                </DetailsPageSection>
              ) : (
                <></>
              )}
              {(project?.services || []).length ? (
                <DetailsPageSection title="Serviços">
                  <DetailsPageText
                    labels={(project?.services || []).map((service) => `· ${cleanString(service)}`)}
                  ></DetailsPageText>
                </DetailsPageSection>
              ) : (
                <></>
              )}
              {Object.entries(project?.professionalServices || {}).length ? (
                <DetailsPageSection title="Equipa Profissional">
                  {Object.entries(project?.professionalServices || {}).map(
                    ([profession, professionFields], professionIndex) => {
                      return (
                        <DetailsPageText
                          key={`profession-${professionIndex}`}
                          title={cleanString(profession)}
                          labels={professionFields.map((professionField) => `· ${cleanString(professionField)}`)}
                        ></DetailsPageText>
                      );
                    }
                  )}
                </DetailsPageSection>
              ) : (
                <></>
              )}
              {(project?.volunteeringProfile || []).length ? (
                <DetailsPageSection title="Perfil do Voluntário">
                  <DetailsPageText
                    labels={(project?.volunteeringProfile || []).map((profile) => `· ${cleanString(profile)}`)}
                  ></DetailsPageText>
                </DetailsPageSection>
              ) : (
                <></>
              )}
              {project?.activity ? (
                <DetailsPageSection title="Principais atividades">
                  <DetailsPageText labels={[project?.activity]}></DetailsPageText>
                </DetailsPageSection>
              ) : (
                <></>
              )}
              {project?.volunteeringDescription ? (
                <DetailsPageSection title="Atividades do voluntário">
                  <DetailsPageText labels={[project?.volunteeringDescription]}></DetailsPageText>
                </DetailsPageSection>
              ) : (
                <></>
              )}
            </div>
            <div className="details-page-content-right">
              {windowWidth < ScreenBreakpoint.Large ? <></> : <img src={projectImageSrc} alt={project?.name} />}
              {project?.site ? (
                <DetailsPageSection title="Links">
                  <NewButton
                    theme="teal"
                    accent="white"
                    outlined={false}
                    size="sm"
                    onClick={() => window.open(project.site, '_blank')}
                  >
                    Visitar site
                  </NewButton>
                </DetailsPageSection>
              ) : (
                <></>
              )}
              {(project?.targetAudience || []).length ? (
                <DetailsPageSection title="Público-Alvo">
                  <div className="details-page-tags">
                    {(project?.targetAudience || []).map((targetAudienceType, index) => {
                      return (
                        <DetailsPageTag key={`target-audience-${index}`}>
                          {cleanString(targetAudienceType)}
                        </DetailsPageTag>
                      );
                    })}
                  </div>
                </DetailsPageSection>
              ) : (
                <></>
              )}
              {project?.regions || [] ? (
                <DetailsPageSection title="Locais Alvo">
                  <div className="details-page-tags">
                    {(project?.regions || []).map((region, index) => {
                      return <DetailsPageTag key={`region-${index}`}>{cleanString(region)}</DetailsPageTag>;
                    })}
                  </div>
                </DetailsPageSection>
              ) : (
                <></>
              )}
            </div>
          </div>
          <DetailsPageFeedback onClickHandler={giveFeedbackHandler}></DetailsPageFeedback>
        </Container>
      )}
    </Fragment>
  );
}

export default ProjectDetail;
