import { FieldValues, Path, UseFormRegister } from 'react-hook-form';
import styles from './input-text.component.module.scss';

type InputTextProps<TFormValues extends FieldValues> = {
  id: string;
  name: Path<TFormValues>;
  label: string;
  showLabel?: boolean;
  disabled?: boolean;
  description?: string;
  register: UseFormRegister<TFormValues>;
  errorMessage?: string;
  classes?: string;
};

function InputText<TFormValues extends FieldValues>({
  id,
  name,
  label,
  showLabel = true,
  disabled = false,
  description,
  register,
  errorMessage,
  classes,
}: InputTextProps<TFormValues>) {
  return (
    <div className={`d-flex flex-column ${classes}`}>
      {showLabel && (
        <label htmlFor={id} className={`${disabled && styles['c-disabled']}`}>
          {label}
        </label>
      )}
      <input
        type="text"
        id={id}
        className={`mt-2 mb-2 ${styles['c-input']} ${errorMessage && styles['c-input-error']}`}
        placeholder={label}
        disabled={disabled}
        {...register(name)}
        aria-label={label}
      />
      {errorMessage ? (
        <p className={`${styles['c-description-and-error']} ${styles['c-error']}`}>{errorMessage}</p>
      ) : (
        description && (
          <p className={`${styles['c-description-and-error']} ${disabled && styles['c-disabled']}`}>{description}</p>
        )
      )}
    </div>
  );
}

export default InputText;
