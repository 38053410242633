import * as Yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

// Components
import { AppContext } from '../../state/app-state-context';
import { EmailService } from '../../services';
import { openToastAction, toggleShowFooterAction } from '../../state/actions';

// Images
import comunidade from '../../images/homepage/comunidade.svg';

// Styles
import './forgot-password.component.scss';
import { ArrowLeftIcon, NewButton } from '../../components';

type FormValues = {
  email: string;
};

const schemaValidation = Yup.object().shape({
  email: Yup.string().email('Email não é válido').required('Email é um campo obrigatório'),
});

function ForgotPassword() {
  const { dispatch } = useContext(AppContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(schemaValidation),
  });
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(toggleShowFooterAction(false));

    return () => {
      dispatch(toggleShowFooterAction(true));
    };
  });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      await EmailService.resetUserPassword(data.email);
      dispatch(
        openToastAction('Verifique o seu email para iniciar o processo de recuperação da palavra-passe', 'info')
      );
      navigate('/login');
    } catch {
      dispatch(openToastAction('Não foi possível recuperar a palavra-passe', 'danger'));
    }
  };

  return (
    <div className="forgot-password-container">
      <a className="go-back" href="https://www.udream.pt/">
        <ArrowLeftIcon />
        <span>VOLTAR AO SITE</span>
      </a>
      <div className="content">
        <img src={comunidade}></img>
        <span className="title">Recuperar password</span>
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Form.Group className="custom-bootstrap-form-group" controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              placeholder="Insira o seu endereço email"
              {...register('email')}
              isInvalid={!!errors.email}
              className="custom-input"
            />
            <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
          </Form.Group>
          <NewButton theme="green" accent="white" outlined={false} size="lg" type="submit">
            Obter nova palavra-passe
          </NewButton>
        </Form>
      </div>
    </div>
  );
}

export default ForgotPassword;
