import { Dispatch, Fragment, SetStateAction, TouchEvent, useContext, useState } from 'react';
import { GrFormNextLink, GrFormPreviousLink } from 'react-icons/gr';
import { useNavigate } from 'react-router-dom';
import BootstrapImage from 'react-bootstrap/Image';
import useEffectOnce from 'react-use/lib/useEffectOnce';
import useList from 'react-use/lib/useList';
import useWindowSize from 'react-use/lib/useWindowSize';

import { AppContext } from '../../state/app-state-context';
import { Banner, Card, NewButton } from '../../components';
import { Course } from '../../interfaces/learnworlds/course.interface';
import { LearnWorldsService } from '../../services';
import { ScreenBreakpoint } from '../../enums/screen-breakpoint.enum';
import { Theme } from '../../enums/theme.enum';
import { UserCourse } from '../../interfaces/learnworlds/user-courses';
import { getDownloadUrl } from '../../firebase-app';
import { goTo } from '../../utils/helpers.util';
import { toggleShowNavbarAction } from '../../state/actions';
import { useIsOverflowHorizontally } from '../../hooks/useIsOverflowHorizontally';
import { useStateRef } from '../../hooks/useStateRef';
import STATIC_DATA from './data';

import comunidade from '../../images/homepage/comunidade.svg';
import impactoLocal from '../../images/homepage/impacto-local.png';
import impactoSimples from '../../images/homepage/impacto-simples.png';

import discover from '../../images/homepage/banner/discover.svg';
import heart from '../../images/homepage/banner/heart.svg';
import heartTeal from '../../images/homepage/banner/heart-teal.svg';
import megaphone from '../../images/homepage/banner/megaphone.svg';
import world from '../../images/homepage/banner/world.svg';
import worldYellow from '../../images/homepage/banner/world-yellow.svg';

import './home.styles.scss';

function Home() {
  const {
    dispatch,
    state: { currentFirestoreUser, learnWorlds },
  } = useContext(AppContext);
  const learnWorldsUrl = learnWorlds.url || 'https://udream.learnworlds.com/courses';
  const { width: windowWidth } = useWindowSize();
  const navigate = useNavigate();

  // Impact actions variables
  const [impactActionsContainerRef, impactActionsContainerNode] = useStateRef();
  const impactActionsContainerOverflows = useIsOverflowHorizontally(impactActionsContainerNode);
  const [impactActionsTouchStart, setImpactActionsTouchStart] = useState<number | null>(null);
  const [impactActionsTouchEnd, setImpactActionsTouchEnd] = useState<number | null>(null);

  // Opinion leaders variables
  const [opinionLeadersContainerRef, opinionLeadersContainerNode] = useStateRef();
  const [opinionLeadersTouchStart, setOpinionLeadersTouchStart] = useState<number | null>(null);
  const [opinionLeadersTouchEnd, setOpinionLeadersTouchEnd] = useState<number | null>(null);
  const [staticOpinionLeaders, { set: setStaticOpinionLeaders }] = useList<(typeof STATIC_DATA.OPINION_LEADERS)[0]>([]);

  // Courses variables
  const [userCoursesContainerRef, userCoursesContainerNode] = useStateRef();
  const userCoursesContainerOverflows = useIsOverflowHorizontally(userCoursesContainerNode);
  const [coursesTouchStart, setCoursesTouchStart] = useState<number | null>(null);
  const [coursesTouchEnd, setCoursesTouchEnd] = useState<number | null>(null);
  const [userCourses, { set: setUserCourses }] = useList<UserCourse>([]);
  const [learnWorldsCourses, { set: setLearnWorldsCourses }] = useList<Course>([]);
  const [learnWorldsCoursesContainerRef, learnWorldsCoursesContainerNode] = useStateRef();
  const learnWorldsCoursesContainerOverflows = useIsOverflowHorizontally(learnWorldsCoursesContainerNode);
  const [learnWorldsCoursesTouchStart, setLearnWorldsCoursesTouchStart] = useState<number | null>(null);
  const [learnWorldsCoursesTouchEnd, setLearnWorldsCoursesTouchEnd] = useState<number | null>(null);

  useEffectOnce(() => {
    if (currentFirestoreUser?.email) {
      getUserCourses(currentFirestoreUser.email);
    }

    getCourses();

    dispatch(toggleShowNavbarAction(true));
  });

  useEffectOnce(() => {
    const getOpinionLeadersImages = async () => {
      const opls: (typeof STATIC_DATA.OPINION_LEADERS)[0][] = [];

      for (const opl of STATIC_DATA.OPINION_LEADERS) {
        const oplImageUrl = await getDownloadUrl(opl.storageImageUrl);
        opls.push({ ...opl, imageUrl: oplImageUrl });
      }

      setStaticOpinionLeaders(opls);
    };

    getOpinionLeadersImages();
  });

  const scrollHorizontally = (type: 'previous' | 'next', node: HTMLDivElement | null) => {
    if (node) {
      const scrollDistance = windowWidth < ScreenBreakpoint.Large ? 350 : 450;
      const newPosition = node.scrollLeft + (type === 'previous' ? -scrollDistance : scrollDistance);

      node.scrollTo({
        left: newPosition,
        behavior: 'smooth',
      });
    }
  };

  const onTouchStart = (
    e: TouchEvent<HTMLDivElement>,
    setTouchEnd: Dispatch<SetStateAction<number | null>>,
    setTouchStart: Dispatch<SetStateAction<number | null>>
  ) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e: TouchEvent<HTMLDivElement>, setTouchEnd: Dispatch<SetStateAction<number | null>>) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const onTouchEnd = (node: HTMLDivElement | null, touchStart: number | null, touchEnd: number | null) => {
    if (!touchStart || !touchEnd) {
      return;
    }

    const distance = touchStart - touchEnd;

    // the required distance between touchStart and touchEnd to be detected as a swipe
    const minSwipeDistance = 50;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe || isRightSwipe) {
      scrollHorizontally(isLeftSwipe ? 'next' : 'previous', node);
    }
  };

  async function getUserCourses(userEmail: string) {
    try {
      const userProgress = await LearnWorldsService.getUserProgress(userEmail);

      const userCoursesWithProgress: UserCourse[] = [];

      for (const courseProgress of userProgress) {
        const courseInformation = await LearnWorldsService.getCourseInformation(courseProgress.courseId);
        userCoursesWithProgress.push({ ...courseProgress, ...courseInformation });
      }

      setUserCourses(userCoursesWithProgress);
    } catch {
      setUserCourses([]);
    }
  }

  async function getCourses() {
    const coursesToFetch = [
      'a-deficincia-no-morde',
      'pobreza',
      'cuidadoresinformais',
      'isolamentoidosos',
      'lutoparental',
      'ods',
    ];

    try {
      const promises = coursesToFetch.map((courseId) => LearnWorldsService.getCourseInformation(courseId));
      const courses = await Promise.all(promises);

      setLearnWorldsCourses(courses);
    } catch {
      setLearnWorldsCourses([]);
    }
  }

  return (
    <Fragment>
      <div className="initial-banner padding-x">
        <div className="initial-banner-img">
          <img src={comunidade}></img>
        </div>
        <div className="initial-banner-title">
          <p>Vê o mundo</p>
          <p>
            com um <span className="accent">novo olhar</span>
          </p>
        </div>
        <div className="initial-banner-subtitle">
          <p>Fica informada/o e consciente dos problemas e mais preparada/o e capaz de gerar mudança!</p>
        </div>
        <div className="initial-banner-imgs-banner">
          <div className="initial-banner-imgs-banner-line">
            <a
              href="#to-intervene-section"
              className="initial-banner-imgs-banner-item initial-banner-imgs-banner-item-1"
            >
              <img src={megaphone}></img>
              <span className="initial-banner-imgs-banner-item-title">intervir</span>
              <span className="initial-banner-imgs-banner-item-description">Sentir os problemas à flor da pele.</span>
              <span className="initial-banner-imgs-banner-item-know-more">Saber mais</span>
            </a>
            <a href="#to-inspire-section" className="initial-banner-imgs-banner-item initial-banner-imgs-banner-item-2">
              <img src={heart}></img>
              <span className="initial-banner-imgs-banner-item-title">inspirar</span>
              <span className="initial-banner-imgs-banner-item-description">
                Inspirar e ser inspirado por quem sonha um mundo melhor.
              </span>
              <span className="initial-banner-imgs-banner-item-know-more">Saber mais</span>
            </a>
          </div>
          <div className="initial-banner-imgs-banner-line">
            <a
              href="#to-discover-section"
              className="initial-banner-imgs-banner-item initial-banner-imgs-banner-item-3"
            >
              <img src={discover}></img>
              <span className="initial-banner-imgs-banner-item-title">descobrir</span>
              <span className="initial-banner-imgs-banner-item-description">
                Aprender sobre o mundo, sobre os outros e sobre nós.
              </span>
              <span className="initial-banner-imgs-banner-item-know-more">Saber mais</span>
            </a>
            <a href="#to-dream-section" className="initial-banner-imgs-banner-item initial-banner-imgs-banner-item-4">
              <img src={world}></img>
              <span className="initial-banner-imgs-banner-item-title">sonhar</span>
              <span className="initial-banner-imgs-banner-item-description">
                Colocar em ações concretas a crença de que podemos mudar o mundo.
              </span>
              <span className="initial-banner-imgs-banner-item-know-more">Saber mais</span>
            </a>
          </div>
        </div>
      </div>
      <Banner theme={Theme.Teal}>
        <div className="u-impact-actions-banner padding-x" id="to-intervene-section">
          <div className="u-impact-actions-header">
            <div className="u-impact-actions-header-title">
              <h2>intervir</h2>
              <img src={megaphone}></img>
            </div>
            <div className="u-impact-actions-header-subtitle">
              <h2>Explora as nossas ações de impacto</h2>
              <div className="u-impact-actions-header-buttons">
                <NewButton
                  theme="teal"
                  accent="white"
                  size="sm"
                  outlined={true}
                  onClick={() => goTo('/voluntariado', '_self')}
                >
                  Ver todos
                </NewButton>
                {impactActionsContainerOverflows ? (
                  <div className="u-impact-banner-scroll-buttons">
                    <NewButton
                      theme="teal"
                      accent="white"
                      size="sm"
                      outlined={true}
                      onClick={() => scrollHorizontally('previous', impactActionsContainerNode)}
                    >
                      <GrFormPreviousLink size={windowWidth < ScreenBreakpoint.Large ? 12 : 17} />
                    </NewButton>
                    <NewButton
                      theme="teal"
                      accent="white"
                      size="sm"
                      outlined={true}
                      onClick={() => scrollHorizontally('next', impactActionsContainerNode)}
                    >
                      <GrFormNextLink size={windowWidth < ScreenBreakpoint.Large ? 12 : 17} />
                    </NewButton>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div
            className="u-impact-actions-banner-scroll-container"
            ref={impactActionsContainerRef}
            onTouchStart={(e) => onTouchStart(e, setImpactActionsTouchEnd, setImpactActionsTouchStart)}
            onTouchMove={(e) => onTouchMove(e, setImpactActionsTouchEnd)}
            onTouchEnd={() => onTouchEnd(impactActionsContainerNode, impactActionsTouchStart, impactActionsTouchEnd)}
          >
            <div className="u-card-outer-container">
              <div className="u-card-inner-container">
                {STATIC_DATA.IMPACT_ACTIONS.map((action, index: number) => (
                  <Card
                    key={index}
                    title={action.title}
                    description={action.description}
                    isLocalImage={true}
                    imageURL={action.image}
                    buttonChildren="Saber mais"
                    buttonTheme="teal"
                    buttonOutlined={true}
                    handleButtonClick={() => goTo(action.link, '_blank')}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="u-impact-actions-banner-local-and-simple-messages">
            <div className="impact-cards">
              <div className="local-simple-impact">
                <img src={impactoLocal} />
                <h5>Impacto Local</h5>
                <p>
                  Encontra aqui organizações e projetos sociais de todo o país que estão a resolver os problemas sociais
                  que mais te incomodam!
                </p>
                <NewButton
                  theme="teal"
                  accent="white"
                  outlined={false}
                  size="lg"
                  onClick={() => {
                    /* navigate('/projetos') */
                  }}
                >
                  Brevemente
                </NewButton>
              </div>
              <div className="local-simple-impact">
                <img src={impactoSimples} />
                <h5>Impacto Simples</h5>
                <p>
                  Aqui encontrarás sugestões de ações simples de impacto que podes fazer sozinho/a ou com os teus
                  familiares e amigos para ajudar a resolver os problemas da tua comunidade.
                </p>
                <NewButton
                  theme="teal"
                  accent="white"
                  outlined={false}
                  size="lg"
                  onClick={() => {
                    /* navigate('/acoes-de-impacto') */
                  }}
                >
                  Brevemente
                </NewButton>
              </div>
            </div>
          </div>
        </div>
      </Banner>
      <Banner theme={Theme.Light}>
        <div className="u-opinion-leaders-banner padding-x" id="to-inspire-section">
          <div className="u-opinion-leaders-header">
            <div className="u-opinion-leaders-header-title">
              <h3>inspirar</h3>
              <img src={heartTeal}></img>
            </div>
            <div className="u-opinion-leaders-header-subtitle">
              <h3>As nossas referências de liderança comunitária e social</h3>
              <p>
                Conhece estes e mais líderes sociais, referência pelo seu trabalho na resolução de vários problemas
                sociais.
              </p>
              <NewButton
                theme="beige"
                accent="green"
                size="sm"
                outlined={true}
                onClick={() => {
                  /* navigate('/lideres-de-opiniao') */
                }}
              >
                Brevemente
              </NewButton>
            </div>
          </div>
          <div
            className="u-opinion-leaders-banner-list"
            ref={opinionLeadersContainerRef}
            onTouchStart={(e) => onTouchStart(e, setOpinionLeadersTouchEnd, setOpinionLeadersTouchStart)}
            onTouchMove={(e) => onTouchMove(e, setOpinionLeadersTouchEnd)}
            onTouchEnd={() => onTouchEnd(opinionLeadersContainerNode, opinionLeadersTouchStart, opinionLeadersTouchEnd)}
          >
            <div className="u-opinion-leaders-banner-list-outer">
              <div className="u-opinion-leaders-banner-list-inner">
                {staticOpinionLeaders.length > 0 ? (
                  staticOpinionLeaders.map((opl, index) => (
                    <div className="u-opinion-leader" key={opl.id}>
                      <BootstrapImage
                        src={opl.imageUrl}
                        className={`u-opinion-leader-image ${
                          index % 2 === 0 ? 'u-opinion-leader-image-even' : 'u-opinion-leader-image-odd'
                        }`}
                        fluid
                      />
                      <span className="u-opinion-leader-name">{opl.name}</span>
                      {/*
                      <NewButton
                        theme="beige"
                        accent="green"
                        size="sm"
                        outlined={true}
                        onClick={() =>
                          navigate(`/lideres-de-opiniao/${opl.name.replace(/\s+/g, '')}/${encodeURIComponent(opl.id)}`)
                        }
                      >
                        Ver perfil
                      </NewButton>
                      */}
                    </div>
                  ))
                ) : (
                  <Fragment />
                )}
              </div>
            </div>
          </div>
        </div>
      </Banner>
      <Banner theme={Theme.Green}>
        <div className="u-courses-banner padding-x" id="to-discover-section">
          <div className="u-courses-banner-title">
            <h2>descobrir</h2>
            <img src={discover}></img>
          </div>
          {userCourses.length > 0 ? (
            <div className="u-courses-banner-usercourses">
              <div className="u-courses-banner-usercourses-header">
                <h2>Os teus cursos</h2>
                {userCoursesContainerOverflows ? (
                  <div className="u-courses-banner-usercourses-header-buttons">
                    <NewButton
                      theme="green"
                      accent="white"
                      size="sm"
                      outlined={true}
                      onClick={() => scrollHorizontally('previous', userCoursesContainerNode)}
                    >
                      <GrFormPreviousLink size={windowWidth < ScreenBreakpoint.Large ? 12 : 17} />
                    </NewButton>
                    <NewButton
                      theme="green"
                      accent="white"
                      size="sm"
                      outlined={true}
                      onClick={() => scrollHorizontally('next', userCoursesContainerNode)}
                    >
                      <GrFormNextLink size={windowWidth < ScreenBreakpoint.Large ? 12 : 17} />
                    </NewButton>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div
                className="u-courses-banner-usercourses-container"
                ref={userCoursesContainerRef}
                onTouchStart={(e) => onTouchStart(e, setCoursesTouchEnd, setCoursesTouchStart)}
                onTouchMove={(e) => onTouchMove(e, setCoursesTouchEnd)}
                onTouchEnd={() => onTouchEnd(userCoursesContainerNode, coursesTouchStart, coursesTouchEnd)}
              >
                <div className="u-courses-banner-usercourses-outter-container">
                  <div className="u-courses-banner-usercourses-inner-container">
                    {userCourses.map((c) => (
                      <Card
                        key={c.id}
                        progressPercentage={c.progressRate}
                        imageURL={c.courseImage!}
                        title={c.title}
                        description={c.description!}
                        buttonChildren="Continuar curso"
                        buttonOutlined={false}
                        buttonTheme="teal"
                        handleButtonClick={() => goTo(`https://udream.learnworlds.com/course/${c.id}`, '_blank')}
                        isLocalImage={false}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Fragment />
          )}
          {learnWorldsCourses.length > 0 ? (
            <div className="u-courses-banner-learnworlds">
              <div className="u-courses-banner-learnworlds-header">
                <h2>A nossa seleção de cursos U.DREAM</h2>
                <div className="u-courses-banner-learnworlds-header-buttons">
                  <NewButton
                    theme="green"
                    accent="white"
                    size="sm"
                    outlined={true}
                    onClick={() => goTo(learnWorldsUrl, '_blank')}
                  >
                    Ver todos
                  </NewButton>
                  {learnWorldsCoursesContainerOverflows ? (
                    <div className="u-courses-learnworlds-scroll-buttons">
                      <NewButton
                        theme="green"
                        accent="white"
                        size="sm"
                        outlined={true}
                        onClick={() => scrollHorizontally('previous', learnWorldsCoursesContainerNode)}
                      >
                        <GrFormPreviousLink size={windowWidth < ScreenBreakpoint.Large ? 12 : 17} />
                      </NewButton>
                      <NewButton
                        theme="green"
                        accent="white"
                        size="sm"
                        outlined={true}
                        onClick={() => scrollHorizontally('next', learnWorldsCoursesContainerNode)}
                      >
                        <GrFormNextLink size={windowWidth < ScreenBreakpoint.Large ? 12 : 17} />
                      </NewButton>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div
                className="u-courses-banner-learnworlds-container"
                ref={learnWorldsCoursesContainerRef}
                onTouchStart={(e) => onTouchStart(e, setLearnWorldsCoursesTouchEnd, setLearnWorldsCoursesTouchStart)}
                onTouchMove={(e) => onTouchMove(e, setLearnWorldsCoursesTouchEnd)}
                onTouchEnd={() =>
                  onTouchEnd(learnWorldsCoursesContainerNode, learnWorldsCoursesTouchStart, learnWorldsCoursesTouchEnd)
                }
              >
                <div className="u-courses-banner-learnworlds-outter-container">
                  <div className="u-courses-banner-learnworlds-inner-container">
                    {learnWorldsCourses.map((c) => (
                      <Card
                        key={c.id}
                        imageURL={c.courseImage!}
                        title={c.title}
                        description={c.description!}
                        buttonChildren="Iniciar"
                        buttonTheme="green"
                        buttonOutlined={true}
                        handleButtonClick={() => goTo(`https://udream.learnworlds.com/course/${c.id}`, '_blank')}
                        isLocalImage={false}
                      />
                    ))}
                    <div className="u-courses-banner-learnworlds-more-information">
                      <p className="u-courses-banner-learnworlds-more-information-title">
                        Vê o nosso catálogo de cursos e formações.
                      </p>
                      <p className="u-courses-banner-learnworlds-more-information-subtitle">
                        Estamos constantemente a criar novos conteúdos para ti!
                      </p>
                      <NewButton
                        theme="green"
                        accent="white"
                        size="sm"
                        className="u-courses-banner-learnworlds-more-information-button"
                        onClick={() => goTo('https://udream.learnworlds.com/courses', '_blank')}
                      >
                        Ver todos
                      </NewButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </Banner>
      <Banner theme={Theme.Light}>
        <div className="u-dreams-banner padding-x" id="to-dream-section">
          <div className="u-dreams-header">
            <div className="u-dreams-header-title">
              <h3>sonhar</h3>
              <img src={worldYellow}></img>
            </div>
            <div className="u-dreams-header-subtitle">
              <h3>
                Capacidade de <span className="underline">celebrar</span> o que parece impossível, de criar{' '}
                <span className="underline">esperança</span>, de provar que podemos{' '}
                <span className="underline">transformar</span> a vida uns dos outros, de que podemos{' '}
                <span className="accent">mudar o mundo.</span>
              </h3>
              <NewButton
                className="u-dreams-header-button"
                theme="yellow"
                accent="white"
                size="sm"
                outlined={false}
                onClick={() => goTo('https://www.youtube.com/@UDREAM4you', '_blank')}
              >
                Explora o nosso canal de YouTube
              </NewButton>
            </div>
          </div>
          <div className="u-dreams-videos">
            <div className="u-dreams-videos-video u-dreams-videos-video-1">
              <iframe
                width={windowWidth < ScreenBreakpoint.Large ? 340 : 697}
                height={windowWidth < ScreenBreakpoint.Large ? 191 : 392}
                src="https://www.youtube.com/embed/YSqNKLnqpCY?si=waXowkLgACkWCHkB&amp;controls=0&amp;modestbranding=1&amp;rel=0"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
            <div className="u-dreams-videos-video u-dreams-videos-video-2">
              <iframe
                width={windowWidth < ScreenBreakpoint.Large ? 340 : 708}
                height={windowWidth < ScreenBreakpoint.Large ? 191 : 398}
                src="https://www.youtube.com/embed/aq4VJEOth0s?si=Q9QRk_GgehoRoq4N&amp;controls=0&amp;modestbranding=1&amp;rel=0"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
            <div className="u-dreams-videos-video u-dreams-videos-video-3">
              <iframe
                width={windowWidth < ScreenBreakpoint.Large ? 340 : 708}
                height={windowWidth < ScreenBreakpoint.Large ? 191 : 398}
                src="https://www.youtube.com/embed/eDgYibQBF_o?si=jvNfxesGZgPi8rWX&amp;controls=0&amp;modestbranding=1&amp;rel=0"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
            <div className="u-dreams-videos-video u-dreams-videos-video-4">
              <iframe
                width={windowWidth < ScreenBreakpoint.Large ? 340 : 640}
                height={windowWidth < ScreenBreakpoint.Large ? 191 : 360}
                src="https://www.youtube.com/embed/Bh6hy8Cxvk8?si=0-fxH2PDUcgfMXws&amp;controls=0&amp;modestbranding=1&amp;rel=0"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </Banner>
    </Fragment>
  );
}

export default Home;
