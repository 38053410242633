import { getAuth } from 'firebase/auth';
import { getDownloadURL, getStorage, ref as storageRef } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCj-EZuMkdhIuibYjJUFZhGmemHphL1aI8',
  authDomain: 'udream-platform.firebaseapp.com',
  projectId: 'udream-platform',
  storageBucket: 'udream-platform.appspot.com',
  messagingSenderId: '704795257545',
  appId: '1:704795257545:web:56fda82e194ce6f8b2fb18',
  measurementId: 'G-V35Y2LNNYB',
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp, 'gs://udream-platform');

async function getDownloadUrl(path: string) {
  return await getDownloadURL(storageRef(storage, path));
}

export { firebaseApp, auth, db, storage, getDownloadUrl };
