import { Dispatch, ReactNode, SetStateAction } from 'react';
import BsModal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

type ModalProps = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  title: string;
  bodyText?: string;
  primaryButtonText?: string;
  primaryButtonColorVariant?: string;
  handlePrimaryButtonClicked?: (value: string) => void;
  id?: string;
  Component?: ReactNode;
  centered?: boolean;
  size?: 'sm' | 'lg' | 'xl';
};

function Modal({
  showModal,
  setShowModal,
  title,
  bodyText,
  primaryButtonText,
  primaryButtonColorVariant,
  handlePrimaryButtonClicked,
  id,
  Component,
  centered = false,
  size,
}: ModalProps) {
  const options: { size?: 'sm' | 'lg' | 'xl' } = {};

  if (size) {
    options.size = size;
  }

  return (
    <BsModal
      show={showModal}
      onHide={() => setShowModal(false)}
      backdrop="static"
      keyboard={false}
      centered={centered}
      scrollable={true}
      {...options}
    >
      <BsModal.Header closeButton>
        <BsModal.Title>{title}</BsModal.Title>
      </BsModal.Header>
      {Component ? (
        Component
      ) : (
        <>
          <BsModal.Body>{bodyText}</BsModal.Body>
          <BsModal.Footer>
            {id && handlePrimaryButtonClicked ? (
              <>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                  Fechar
                </Button>
                <Button variant={primaryButtonColorVariant} onClick={() => handlePrimaryButtonClicked(id)}>
                  {primaryButtonText}
                </Button>
              </>
            ) : (
              <Button variant="primary" onClick={() => setShowModal(false)}>
                Ok
              </Button>
            )}
          </BsModal.Footer>
        </>
      )}
    </BsModal>
  );
}

export default Modal;
