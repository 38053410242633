import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import { Action, SearchBoxActionInterface, actionConverter, actionElasticConverter } from '../../model/actions/action';
import { AppContext } from '../../state/app-state-context';
import { Button, Modal } from '../../components';
import { DatabaseService } from '../../services/database.service';
import { ELASTIC_ACTIONS_PATH, SearchApiMeta, SearchApiOptions } from '../../interfaces/elastic/search-api.interface';
import { FilteredFormValues } from '../../interfaces/filtered-form-values.interface';
import { PaginationSettings, QueryFormat } from '../../interfaces/database.interface';
import { QueryTypes } from '../../enums/query-types.enum';
import { ResultsType } from '../../enums/results-type.enum';
import { openSidebarAction, openToastAction } from '../../state/actions';
import ActionsList from '../../components/database/actions-form/actions-list';
import DatabaseQuery from '../../components/database/database-query/database-query';
import ElasticSearchBoxComponent from '../../components/elastic/elastic-search-box.component';
import PaginationComponent from '../../components/pagination/pagination.component';
import SubmitActionModalBody from '../../components/modals-body/submit-organization-modal-body/submit-action-modal-body.component';

const CONVERTER = actionConverter;

function ActionsEditor() {
  const { dispatch } = useContext(AppContext);
  const [queryFormatArray, setQueryFormatArray] = useState<QueryFormat[]>([]);
  const [selectedFilteredValues, setSelectedFilteredValues] = useState<FilteredFormValues>({});
  const [actionsWithFilters, setActionsWithFilters] = useState<Action[]>([]);
  const [resultsType, setResultsType] = useState<ResultsType>(ResultsType.Normal);
  const navigate = useNavigate();
  const [showSubmitNewActionModal, setShowSubmitNewActionModal] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [currentActions, setCurrentActions] = useState<Action[]>([]);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [currentMeta, setCurrentMeta] = useState<SearchApiMeta>();
  const [showPagination, setShowPagination] = useState<boolean>(true);

  useEffect(() => {
    fetchActions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (queryFormatArray.length > 0) {
      fetchFilteredActions();
    } else {
      setResultsType(ResultsType.Normal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryFormatArray]);

  const fetchActions = async (page = 1) => {
    try {
      const options: SearchApiOptions = {
        engine: ELASTIC_ACTIONS_PATH,
        pagination: { size: 10, current: page },
      };
      const response = await DatabaseService.searchWithElastic(options, actionElasticConverter);
      setCurrentActions(response?.results);
      setCurrentMeta(response?.meta);
    } catch (error) {
      dispatch(openToastAction('Erro na busca. Tente novamente.', 'danger'));
    }
  };

  const fetchFilteredActions = async () => {
    try {
      const actionsFromDb = await DatabaseService.getByQueriesElastic<Action>(
        ELASTIC_ACTIONS_PATH,
        actionElasticConverter,
        { enablePagination: true, limit: 100 },
        { enableSorting: false },
        { enableSearchByTerm: false },
        ...queryFormatArray
      );

      if (actionsFromDb.results.length > 0) {
        setResultsType(ResultsType.Filter);
        setActionsWithFilters(actionsFromDb.results);
      } else {
        setResultsType(ResultsType.Normal);
        dispatch(openToastAction('Não foram encontradas ações com os filtros selecionados', 'warning'));
        resetFilter();
      }
    } catch {
      dispatch(openToastAction('Não foi possível carregar as ações.', 'danger'));
    }
  };

  const refreshAction = async (id: string) => {
    try {
      const action = await DatabaseService.getById(Action.PATH, id, actionConverter);
      setCurrentActions(currentActions.map((o) => (o.id === action.id ? action : o)));
    } catch (error) {
      dispatch(openToastAction('Erro na busca. Tente novamente.', 'danger'));
    }
  };

  async function changePage(direction: 'next' | 'previous') {
    if (!currentMeta) {
      return;
    }
    if (direction === 'next') {
      await fetchActions(currentMeta.page.current + 1);
    }
    if (direction === 'previous') {
      await fetchActions(currentMeta.page.current - 1);
    }
  }

  function handleSubmitNewActionBtnPressed() {
    setTitleModal('Submeter nova Ação de Serviço');
    setShowSubmitNewActionModal(true);
  }

  async function newAction(description: string) {
    const org = new Action('', {}, [], '', description, '', '', true);
    try {
      const newEntry = await DatabaseService.addEntry(Action.PATH, org, CONVERTER);
      setCurrentActions([newEntry]);
      dispatch(openToastAction('Ação submetida com sucesso.', 'success'));
    } catch (error) {
      dispatch(openToastAction('Erro ao submeter ação, tente novamente.', 'danger'));
    }
  }

  const resetFilter = () => {
    setSelectedFilteredValues({});
    setQueryFormatArray([]);
  };

  async function getActionElastic(result: SearchBoxActionInterface) {
    try {
      const action = await DatabaseService.getById<Action>(Action.PATH, result.id.raw, actionConverter);
      setCurrentMeta(undefined);
      setCurrentActions([action]);
    } catch (e) {
      dispatch(openToastAction('Erro na busca. Tente novamente mais tarde.', 'danger'));
    }
  }

  async function searchIncompleteDocs() {
    const pageSize = 30;
    let incompleteDocs: Action[] = [];
    try {
      incompleteDocs = await incompleteActionsSearch('actionDescripton', 'null', pageSize);
      if (incompleteDocs.length === 0) {
        incompleteDocs = await incompleteActionsSearch('actionHowTo', 'null', pageSize);
      }
      if (incompleteDocs.length === 0) {
        incompleteDocs = await incompleteActionsSearch('problems', 'emptyMap', pageSize);
      }
      if (incompleteDocs.length === 0) {
        dispatch(openToastAction('Não existem ações incompletas neste momento.', 'info'));
        return;
      }
      dispatch(
        openToastAction('Carregado ' + incompleteDocs?.length + ' documentos incompletos com sucesso.', 'success')
      );
      setCurrentActions(incompleteDocs);
      setShowPagination(false);
    } catch (e) {
      dispatch(openToastAction('Erro na busca. Tente novamente mais tarde.', 'danger'));
      setShowPagination(true);
    }
  }

  async function incompleteActionsSearch(
    field: string,
    queryType: 'null' | 'emptyMap',
    pageSize: number
  ): Promise<Action[]> {
    const paginationSettings: PaginationSettings<Action> = {
      enablePagination: true,
      limit: pageSize,
      currentPage: 1,
    };
    // value parameter doesn't matter in null and emptyMap searches
    return await DatabaseService.getByQueries(
      Action.PATH,
      actionConverter,
      paginationSettings,
      ...[{ field, value: '', queryType }]
    );
  }

  return (
    <div>
      <div className="container border p-3">
        {!isEditing && (
          <div className="row justify-content-md-start p-2">
            <div className="col-md-auto">
              <Button text="Voltar" size="medium" handleButtonPressed={() => navigate('/')}></Button>
            </div>
            <div className="col-md-auto">
              <Button
                text="Filtrar"
                size="medium"
                handleButtonPressed={() =>
                  dispatch(
                    openSidebarAction(
                      <DatabaseQuery
                        key={Math.random()} // Create new instance
                        setQueryFormat={setQueryFormatArray}
                        queryOptions={[
                          QueryTypes.TargetAudience,
                          QueryTypes.Problems,
                          QueryTypes.Duration,
                          QueryTypes.Approval,
                        ]}
                        selectedFilters={selectedFilteredValues}
                        setSelectedFilters={setSelectedFilteredValues}
                      />
                    )
                  )
                }
              ></Button>
            </div>
            <div className="col-md-auto">
              <Button
                text="Ver campos incompletos"
                size="medium"
                handleButtonPressed={() => searchIncompleteDocs()}
              ></Button>
            </div>
            <div className="col-md-auto">
              <div>
                <Button
                  text="Nova ação de serviço"
                  size="medium"
                  handleButtonPressed={() => handleSubmitNewActionBtnPressed()}
                ></Button>
              </div>
            </div>
            <div className="col d-flex justify-content-end">
              <ElasticSearchBoxComponent
                fields={['actionhowto', 'actiondescripton']}
                titleField={'actiondescripton'}
                engine={'actions'}
                callback={async (result) => {
                  await getActionElastic(result as SearchBoxActionInterface);
                }}
              />
            </div>
          </div>
        )}
        <div className="m-3 p-4">
          <h2> Ação simples de serviço</h2>
          <p>
            Escreve aqui a tua ideia para uma ação simples de serviço, que possa servir para inspiração destes jovens (a
            fazerem ou incentivarem outros a fazer) algo simples por um problema social
          </p>
        </div>
        <div className="row justify-content-md-center p-2">
          {showPagination && currentMeta && <PaginationComponent currentMeta={currentMeta} changePage={changePage} />}
        </div>
        <ActionsList
          actions={resultsType === ResultsType.Normal ? currentActions : actionsWithFilters}
          setActions={resultsType === ResultsType.Normal ? setCurrentActions : setActionsWithFilters}
          setIsEditing={setIsEditing}
          refreshAction={refreshAction}
        />
        <div className="row justify-content-md-center p-2">
          {showPagination && currentMeta && <PaginationComponent currentMeta={currentMeta} changePage={changePage} />}
        </div>
      </div>
      <Modal
        setShowModal={setShowSubmitNewActionModal}
        showModal={showSubmitNewActionModal}
        title={titleModal}
        Component={<SubmitActionModalBody setShowModal={setShowSubmitNewActionModal} handleSubmitForm={newAction} />}
      />
    </div>
  );
}

export default ActionsEditor;
