import { Course } from './learnworlds/course.interface';
import { FieldValue } from 'firebase/firestore';
import { Role } from '../enums/role.enum';
import { Tag } from '../model/tag/tag';

export interface User {
  id: string;
  name: string;
  surname: string;
  email: string;
  birthDate: FieldValue;
  city: string;
  district: string;
  created: FieldValue;
  role: Role;
  migrated: boolean;
}

export interface UserWithTagsAndCourses extends User {
  tags: Tag[];
  courses: Course[];
}

export const UserCollectionPath = 'users';

export const userEquals = (a: User, b: User) => {
  return (
    a.id === b.id &&
    a.name === b.name &&
    a.surname === b.surname &&
    a.email === b.email &&
    a.birthDate.isEqual(b.birthDate) &&
    a.city === b.city &&
    a.district === b.district &&
    a.created.isEqual(b.created) &&
    a.role === b.role
  );
};
