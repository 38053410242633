import { Entity } from '../../model/Entity';
import { OpinionLeader } from '../../model/opinion-leaders/opinion-leader';
import { parse } from '../file-parser';

function trimmedToOpinionLeader(trimmed: string[][]): OpinionLeader[] {
  const opinionLeaders: OpinionLeader[] = [];
  trimmed.forEach((row, index) => {
    const opinionLeader = new OpinionLeader(
      Entity.generateId(OpinionLeader.PREFIX),
      setProblems(row.slice(0, 13)),
      row[13],
      row[14],
      row.slice(15, 17).filter((e: string) => e),
      row[18],
      row[19],
      row[20],
      row[21],
      row[22],
      row[23],
      row[24],
      row[25],
      true
    );
    opinionLeaders.push(opinionLeader);
  });
  return opinionLeaders;
}

export async function parseOpinionLeaders(file: File): Promise<OpinionLeader[]> {
  const rawFile = await parse(file);
  const trimmed = trimRawFile(rawFile);
  return trimmedToOpinionLeader(trimmed);
}

function trimRawFile(rawFile: string[][]): string[][] {
  //remove first two rows
  rawFile.splice(0, 2);
  rawFile.forEach((row) => {
    //remove unused final columns
    row.splice(28, row.length);
  });
  console.log('Number of opinion leaders parsed:', rawFile.length);
  return rawFile;
}

function setProblems(rawProblems: string[]): { [key: string]: string[] } {
  const problems = new Map<string, string[]>();
  if (rawProblems[0]) {
    problems.set(
      rawProblems[0],
      rawProblems.slice(1, 5).filter((e: string) => e)
    );
  }
  if (rawProblems[5]) {
    problems.set(
      rawProblems[5],
      rawProblems.slice(6, 8).filter((e: string) => e)
    );
  }
  if (rawProblems[8]) {
    problems.set(
      rawProblems[8],
      rawProblems.slice(9, 11).filter((e: string) => e)
    );
  }
  if (rawProblems[11]) {
    problems.set(rawProblems[11], [rawProblems[12]]);
  }
  return Object.fromEntries(problems);
}
