import { BsPerson } from 'react-icons/bs';
import { Fragment, MouseEvent as ReactMouseEvent, ReactNode, Ref, forwardRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import BootstrapDropdown from 'react-bootstrap/Dropdown';

import { AppContext } from '../../../../state/app-state-context';
import { Role } from '../../../../enums/role.enum';

import './navbar-dropdown-profile.styles.scss';

type CustomToggleProps = {
  children: ReactNode;
  onClick?: (event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => unknown;
};

const CustomToggle = forwardRef(({ children, onClick }: CustomToggleProps, ref: Ref<HTMLAnchorElement>) => (
  <a
    href="/actions"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      if (onClick) onClick(e);
    }}
    className="u-navbar-dropdown-profile-toggle"
  >
    {children}
  </a>
));

CustomToggle.displayName = 'CustomToggle';

function NavbarDropdownProfile() {
  const {
    state: { currentAuthUser, currentFirestoreUser },
  } = useContext(AppContext);

  const isAdmin = currentAuthUser?.emailVerified && currentFirestoreUser?.role === Role.Admin;

  return (
    <BootstrapDropdown className="u-navbar-dropdown-profile" drop="start">
      {currentFirestoreUser ? (
        <BootstrapDropdown.Toggle id="navbar-dropdown-profile" as={CustomToggle}>
          {<BsPerson size="16" />}
        </BootstrapDropdown.Toggle>
      ) : (
        <></>
      )}
      <BootstrapDropdown.Menu className="navbar-dropdown-profile-dropdown-menu">
        <BootstrapDropdown.Item as={Link} to="/perfil" className="navbar-dropdown-profile-dropdown-item">
          Dados pessoais
        </BootstrapDropdown.Item>
        {/*<BootstrapDropdown.Item as={Link} to="/meus-cursos" className="navbar-dropdown-profile-dropdown-item">
          Os meus cursos
        </BootstrapDropdown.Item>
        */}
        {isAdmin ? (
          <Fragment>
            <BootstrapDropdown.Divider className="navbar-dropdown-profile-dropdown-item-divider" />
            <BootstrapDropdown.Item as={Link} to="/data-center" className="navbar-dropdown-profile-dropdown-item">
              Gerir base de dados
            </BootstrapDropdown.Item>
            <BootstrapDropdown.Item
              as={Link}
              to="/gerir-utilizadores"
              className="navbar-dropdown-profile-dropdown-item"
            >
              Gerir utilizadores
            </BootstrapDropdown.Item>
            <BootstrapDropdown.Item as={Link} to="/gerir-opcoes" className="navbar-dropdown-profile-dropdown-item">
              Gerir opções
            </BootstrapDropdown.Item>
          </Fragment>
        ) : (
          <Fragment />
        )}
        <BootstrapDropdown.Divider className="navbar-dropdown-profile-dropdown-item-divider" />
        <BootstrapDropdown.Item as={Link} to="/terminar-sessao" className="navbar-dropdown-profile-dropdown-item">
          Terminar sessão
        </BootstrapDropdown.Item>
      </BootstrapDropdown.Menu>
    </BootstrapDropdown>
  );
}

export default NavbarDropdownProfile;
