import { useCallback, useState } from 'react';

export const useStateRef = () => {
  const [node, setNode] = useState<HTMLDivElement | null>(null);

  const ref = useCallback((node: HTMLDivElement | null) => {
    if (node) {
      setNode(node);
    }
  }, []);

  return [ref, node] as const;
};
