import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import { AppContext } from '../../state/app-state-context';
import { DatabaseService } from '../../services/database.service';
import { ELASTIC_LEADERS_PATH, SearchApiMeta, SearchApiOptions } from '../../interfaces/elastic/search-api.interface';
import { FilteredFormValues } from '../../interfaces/filtered-form-values.interface';
import {
  OpinionLeader,
  SearchBoxLeaderInterface,
  leaderElasticConverter,
  opinionLeaderConverter,
} from '../../model/opinion-leaders/opinion-leader';
import { PaginationSettings, QueryFormat } from '../../interfaces/database.interface';
import { QueryTypes } from '../../enums/query-types.enum';
import { ResultsType } from '../../enums/results-type.enum';
import { openSidebarAction, openToastAction } from '../../state/actions';
import Button from '../../components/button/button.component';
import DatabaseQuery from '../../components/database/database-query/database-query';
import ElasticSearchBoxComponent from '../../components/elastic/elastic-search-box.component';
import Modal from '../../components/modal/modal.component';
import OpinionLeadersList from '../../components/database/opinion-leaders-form/opinion-leaders-list';
import PaginationComponent from '../../components/pagination/pagination.component';
import SubmitOpinionLeaderModalBody from '../../components/modals-body/submit-organization-modal-body/submit-opinion-leader-modal-body.component';

function OpinionLeadersEditor() {
  const { dispatch } = useContext(AppContext);
  const [queryFormatArray, setQueryFormatArray] = useState<QueryFormat[]>([]);
  const [selectedFilteredValues, setSelectedFilteredValues] = useState<FilteredFormValues>({});
  const [opinionLeadersWithFilters, setOpinionLeadersWithFilters] = useState<OpinionLeader[]>([]);
  const [resultsType, setResultsType] = useState<ResultsType>(ResultsType.Normal);
  const navigate = useNavigate();
  const [currentOpinionLeaders, setCurrentOpinionLeaders] = useState<OpinionLeader[]>([]);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [showSubmitNewActionModal, setShowSubmitNewActionModal] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [currentMeta, setCurrentMeta] = useState<SearchApiMeta>();
  const [showPagination, setShowPagination] = useState<boolean>(true);

  useEffect(() => {
    fetchOpinionLeaders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (queryFormatArray.length > 0) {
      fetchFilteredOpinionLeaders();
    } else {
      setResultsType(ResultsType.Normal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryFormatArray]);

  const fetchOpinionLeaders = async (page = 1) => {
    try {
      const options: SearchApiOptions = {
        engine: ELASTIC_LEADERS_PATH,
        pagination: { size: 10, current: page },
      };
      const response = await DatabaseService.searchWithElastic(options, leaderElasticConverter);
      setCurrentOpinionLeaders(response?.results);
      setCurrentMeta(response?.meta);
    } catch (error) {
      dispatch(openToastAction('Erro na busca. Tente novamente.', 'danger'));
    }
  };

  const fetchFilteredOpinionLeaders = async () => {
    try {
      const opinionLeadersFromDb = await DatabaseService.getByQueriesElastic<OpinionLeader>(
        ELASTIC_LEADERS_PATH,
        leaderElasticConverter,
        { enablePagination: true, limit: 100 },
        { enableSorting: false },
        { enableSearchByTerm: false },
        ...queryFormatArray
      );
      if (opinionLeadersFromDb.results.length > 0) {
        setResultsType(ResultsType.Filter);
        setOpinionLeadersWithFilters(opinionLeadersFromDb.results);
      } else {
        setResultsType(ResultsType.Normal);
        dispatch(openToastAction('Não foram encontrados líderes de opinião com os filtros selecionados.', 'warning'));
        resetFilter();
      }
    } catch {
      dispatch(openToastAction('Não foi possível carregar os líderes de opinião.', 'danger'));
    }
  };

  const refreshLeader = async (id: string) => {
    try {
      const leader = await DatabaseService.getById(OpinionLeader.PATH, id, opinionLeaderConverter);
      setCurrentOpinionLeaders(currentOpinionLeaders.map((o) => (o.id === leader.id ? leader : o)));
    } catch (error) {
      dispatch(openToastAction('Erro na busca. Tente novamente.', 'danger'));
    }
  };

  async function changePage(direction: 'next' | 'previous') {
    if (!currentMeta) {
      return;
    }
    if (direction === 'next') {
      await fetchOpinionLeaders(currentMeta.page.current + 1);
    }
    if (direction === 'previous') {
      await fetchOpinionLeaders(currentMeta.page.current - 1);
    }
  }

  function handleSubmitNewActionBtnPressed() {
    setTitleModal('Submeter novo Líder de Opinião');
    setShowSubmitNewActionModal(true);
  }

  async function getLeaderElastic(result: SearchBoxLeaderInterface) {
    try {
      const leader = await DatabaseService.getById<OpinionLeader>(
        OpinionLeader.PATH,
        result.id.raw,
        opinionLeaderConverter
      );
      setCurrentMeta(undefined);
      setShowPagination(false);
      setCurrentOpinionLeaders([leader]);
    } catch (e) {
      dispatch(openToastAction('Não foi possível carregar os líderes de opinião.', 'danger'));
    }
  }

  async function newOpinionLeader(name: string) {
    const leader = new OpinionLeader('', {}, name, '', [], '', '', '', '', '', '', '', '', true);
    try {
      const newEntry = await DatabaseService.addEntry(OpinionLeader.PATH, leader, opinionLeaderConverter);
      setCurrentOpinionLeaders([newEntry]);
      dispatch(openToastAction('Líder de opinião submetido com sucesso.', 'success'));
    } catch (error) {
      dispatch(openToastAction('Error ao submeter líder de opinião. Tente novamente.', 'danger'));
    }
  }

  async function searchIncompleteDocs() {
    const pageSize = 30;
    let incompleteDocs: OpinionLeader[];
    try {
      incompleteDocs = await incompleteLeaderSearch('name', 'null', pageSize);
      if (incompleteDocs.length === 0) {
        incompleteDocs = await incompleteLeaderSearch('description', 'null', pageSize);
      }
      if (incompleteDocs.length === 0) {
        incompleteDocs = await incompleteLeaderSearch('problems', 'emptyMap', pageSize);
      }
      if (incompleteDocs.length === 0) {
        dispatch(openToastAction('Não existem líderes de opinião incompletos neste momento.', 'info'));
        return;
      }
      dispatch(
        openToastAction('Carregado ' + incompleteDocs?.length + ' documentos incompletos com sucesso.', 'success')
      );
      setCurrentOpinionLeaders(incompleteDocs ? incompleteDocs : []);
      setShowPagination(false);
    } catch (error) {
      dispatch(openToastAction('Erro na busca. Tente novamente mais tarde.', 'danger'));
      setShowPagination(true);
    }
  }

  async function incompleteLeaderSearch(
    field: string,
    queryType: 'null' | 'emptyMap',
    pageSize: number
  ): Promise<OpinionLeader[]> {
    const paginationSettings: PaginationSettings<OpinionLeader> = {
      enablePagination: true,
      limit: pageSize,
      currentPage: 1,
    };
    // value parameter doesn't matter in null and emptyMap searches
    return await DatabaseService.getByQueries(
      OpinionLeader.PATH,
      opinionLeaderConverter,
      paginationSettings,
      ...[{ field, value: '', queryType }]
    );
  }

  const resetFilter = () => {
    setSelectedFilteredValues({});
    setQueryFormatArray([]);
  };

  return (
    <div>
      <div className="container border p-3">
        {!isEditing && (
          <div className="row justify-content-md-start p-2">
            <div className="col-md-auto">
              <Button text="Voltar" size="medium" handleButtonPressed={() => navigate('/')}></Button>
            </div>
            <div className="col-md-auto">
              <Button
                text="Filtrar"
                size="medium"
                handleButtonPressed={() =>
                  dispatch(
                    openSidebarAction(
                      <DatabaseQuery
                        key={Math.random()} // Create new instance
                        setQueryFormat={setQueryFormatArray}
                        queryOptions={[QueryTypes.AcademicBackground, QueryTypes.Problems, QueryTypes.Approval]}
                        selectedFilters={selectedFilteredValues}
                        setSelectedFilters={setSelectedFilteredValues}
                      />
                    )
                  )
                }
              ></Button>
            </div>
            <div className="col-md-auto">
              <Button
                text="Ver campos incompletos"
                size="medium"
                disabled={currentMeta === undefined}
                handleButtonPressed={() => searchIncompleteDocs()}
              ></Button>
            </div>
            <div className="col-md-auto">
              <div>
                <Button
                  text="Novo líder de opinião"
                  size="medium"
                  handleButtonPressed={() => handleSubmitNewActionBtnPressed()}
                ></Button>
              </div>
            </div>
            <div className="col d-flex justify-content-end">
              <ElasticSearchBoxComponent
                fields={['name', 'description']}
                titleField={'name'}
                engine={'leaders'}
                callback={async (result) => {
                  await getLeaderElastic(result as SearchBoxLeaderInterface);
                }}
              />
            </div>
          </div>
        )}
        <div className="row justify-content-md-center p-2">
          {showPagination && currentMeta && <PaginationComponent currentMeta={currentMeta} changePage={changePage} />}
        </div>
        <OpinionLeadersList
          leaders={resultsType === ResultsType.Normal ? currentOpinionLeaders : opinionLeadersWithFilters}
          setLeaders={resultsType === ResultsType.Normal ? setCurrentOpinionLeaders : setOpinionLeadersWithFilters}
          setIsEditing={setIsEditing}
          refreshLeader={refreshLeader}
        />
        <div className="row justify-content-md-center p-2">
          {showPagination && currentMeta && <PaginationComponent currentMeta={currentMeta} changePage={changePage} />}
        </div>
      </div>
      <Modal
        setShowModal={setShowSubmitNewActionModal}
        showModal={showSubmitNewActionModal}
        title={titleModal}
        Component={
          <SubmitOpinionLeaderModalBody
            setShowModal={setShowSubmitNewActionModal}
            handleSubmitForm={newOpinionLeader}
          />
        }
      />
    </div>
  );
}

export default OpinionLeadersEditor;
