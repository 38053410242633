import { Entity } from '../../model/Entity';
import { Organization } from '../../model/main/organization';
import { Project, ProjectDTO } from '../../model/main/project';
import { parse } from '../file-parser';

export type MainParserHelper = {
  orgs: Organization[];
  projects: Project[];
};

function mapToMain(map: Map<number, string[][]>): MainParserHelper {
  const orgs: Organization[] = [];
  const projects: Project[] = [];
  map.forEach((org) => {
    let mainProj = '';
    const orgId = Entity.generateId(Organization.PREFIX);
    org.forEach((project, index) => {
      // map each value of array to relevant parameter
      const mappedProject: ProjectDTO = {
        id: Entity.generateId(Project.PREFIX),
        name: project[3],
        mission: project[4],
        activity: project[5],
        volunteeringDescription: project[6],
        site: project[7],
        email: project[8],
        phoneInfo: project[9],
        rawRegions: project.slice(10, 24),
        problems: setProblems(project.slice(25, 56)),
        rawTargetAudience: project.slice(57, 64),
        rawServices: project.slice(65, 71),
        professionalServices: setProfessionalServices(project.slice(72, 106)),
        acceptsVolunteering: project[107],
        rawVolunteeringProfile: project.slice(108, 117),
        rawSustainabilityObjectives: project.slice(118, 134),
        observations: project[135],
        approved: true,
        orgId: orgId,
        orgName: org[0][2],
      };
      const newProject = Project.projectFromFile(mappedProject);
      projects.push(newProject);
      if (index === 0) {
        mainProj = newProject.id;
      }
    });
    const newOrg: Organization = new Organization(orgId, org[0][2], true, mainProj);
    mainProj = '';
    orgs.push(newOrg);
  });
  return { orgs, projects };
}

export async function parseMain(file: File): Promise<MainParserHelper> {
  const rawFile = await parse(file);
  const map = mapRawFile(rawFile);
  return mapToMain(map);
}

function mapRawFile(rawFile: string[][]): Map<number, string[][]> {
  const orgs: Map<number, string[][]> = new Map();
  let currentOrg = -1;
  //remove first two rows
  rawFile.splice(0, 2);
  rawFile.forEach((row) => {
    //remove unused final columns
    row.splice(137, row.length);
    if (row[0] !== '') {
      currentOrg = parseInt(row[0]);
      orgs.set(currentOrg, []);
    }
    //remove first column
    row.shift();
    orgs.get(currentOrg)?.push(row);
  });
  console.log('Number of organizations parsed:', orgs.size);
  return orgs;
}

function setProblems(rawProblems: string[]): { [key: string]: string[] } {
  const problems = new Map<string, string[]>();
  if (rawProblems[0]) {
    problems.set(
      rawProblems[0],
      rawProblems.slice(1, 7).filter((e: string) => e)
    );
  }
  if (rawProblems[7]) {
    problems.set(
      rawProblems[7],
      rawProblems.slice(8, 12).filter((e: string) => e)
    );
  }
  for (let i = 12; i < 31; i = i + 4) {
    if (rawProblems[i]) {
      problems.set(
        rawProblems[i],
        rawProblems.slice(i + 1, i + 4).filter((e: string) => e)
      );
    }
  }
  return Object.fromEntries(problems);
}

function setProfessionalServices(rawProfessionalServices: string[]): { [key: string]: string[] } {
  const professionalServices = new Map<string, string[]>();
  for (let i = 0; i < 34; i = i + 5) {
    if (rawProfessionalServices[i]) {
      professionalServices.set(
        rawProfessionalServices[i],
        rawProfessionalServices.slice(i + 1, i + 5).filter((e: string) => e)
      );
    }
  }
  return Object.fromEntries(professionalServices);
}
