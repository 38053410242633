import { Fragment, useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

// Components
import { AppContext } from '../../state/app-state-context';
import { Footer, Navbar, Sidebar, Toast } from '..';

// Styles
import styles from './layout.component.module.scss';

function Layout() {
  const { pathname } = useLocation();
  const {
    state: { sidebar, showNavbar, showFooter },
  } = useContext(AppContext);

  const getBgColor = () => {
    return pathname.includes('login') ||
      pathname.includes('registar') ||
      pathname.includes('continuar-com-registo') ||
      pathname.includes('esqueceu-palavra-passe') ||
      pathname === '/acoes'
      ? 'inner-container-new-color'
      : 'inner-container';
  };

  return (
    <Fragment>
      <div className="page" id="outer-container">
        <Sidebar>{sidebar.content}</Sidebar>
        <div id="page-wrap" className={styles[getBgColor()]}>
          {showNavbar ? <Navbar /> : <Fragment />}
          <main>
            <Outlet />
          </main>
          {showFooter ? <Footer /> : <Fragment />}
        </div>
      </div>
      <Toast />
    </Fragment>
  );
}

export default Layout;
