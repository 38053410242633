import { Action, actionConverter } from '../../model/actions/action';
import { DatabaseService } from '../../services/database.service';
import { Dropdown } from 'react-bootstrap';
import { MainParserHelper, parseMain } from '../../migration/main-document/main-parser-helper';
import { OpinionLeader, opinionLeaderConverter } from '../../model/opinion-leaders/opinion-leader';
import { Organization, organizationConverter } from '../../model/main/organization';
import { Project, projectConverter } from '../../model/main/project';
import { parseActions } from '../../migration/actions/actions-parser-helper';
import { parseOpinionLeaders } from '../../migration/opinion-leaders/opinion-leaders-parser-helper';
import Button from 'react-bootstrap/Button';
import React, { ChangeEvent, useState } from 'react';

export function Upload() {
  const types = ['Principal', 'Ações de Serviço', 'Lideres de Opinião'];
  const [upload, setUploadFile] = useState<File>();
  const [typeOfParse, setParse] = useState<string>(types[3]);

  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    event.preventDefault();
    if (event.target.files) {
      setUploadFile(event.target['files'][0]);
    }
  }

  async function handleUpload(): Promise<void> {
    if (!upload) {
      alert('Escolha um ficheiro para carregar.');
      return;
    }
    switch (typeOfParse) {
      case types[0]: {
        const parsed: MainParserHelper = await parseMain(upload);
        try {
          console.log(parsed);
          await DatabaseService.saveAll<Organization>(Organization.PATH, parsed.orgs, organizationConverter);
          await DatabaseService.saveAll<Project>(Project.PATH, parsed.projects, projectConverter);
        } catch (e) {
          console.error(e);
        }
        break;
      }
      case types[1]: {
        const actions: Action[] = await parseActions(upload);
        console.log(actions);
        await DatabaseService.saveAll(Action.PATH, actions, actionConverter);
        break;
      }
      case types[2]: {
        const opinionLeaders: OpinionLeader[] = await parseOpinionLeaders(upload);
        console.log(opinionLeaders);
        await DatabaseService.saveAll<OpinionLeader>(OpinionLeader.PATH, opinionLeaders, opinionLeaderConverter);
        break;
      }
      default: {
        alert('Escolha o tipo de ficheiro a enviar');
      }
    }
  }

  return (
    <div id={'upload'}>
      <Button onClick={handleUpload}>Carregar Ficheiro</Button>
      <input type="file" accept=".csv" onChange={handleChange} />
      <Dropdown>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          {typeOfParse}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => setParse(types[0])} href="#/action-1">
            {types[0]}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setParse(types[1])} href="#/action-2">
            {types[1]}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setParse(types[2])} href="#/action-3">
            {types[2]}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default Upload;
