import { useEffect, useState } from 'react';

import { NewButton } from '../../components';
import { fetchImage } from '../../utils';
import { goTo } from '../../utils/helpers.util';

import './list-element.component.scss';

type ListElementProps = {
  title: string;
  description: string;
  tags: string[];
  link: string;
  imageSrc: string;
};

function ListElement({ title, description, tags, link, imageSrc }: ListElementProps) {
  const goToDetailPage = () => {
    const target = link.startsWith('/') ? '_self' : '_blank';
    goTo(link, target);
  };
  const [image, setImage] = useState('');

  useEffect(() => {
    (async () => {
      const { image: localImage, error } = await fetchImage(imageSrc);

      if (!error) {
        setImage(localImage);
      }
    })();
  }, [imageSrc]);

  return (
    <div className="list-element">
      <img className="list-element-img" src={image}></img>
      <div className="list-element-content">
        <div className="list-element-tags">
          {tags.map((tag, index) => (
            <div key={`tag-${index}`} className="list-element-tag">
              <span>{tag}</span>
            </div>
          ))}
        </div>
        <div className="list-element-title">{title}</div>
        <div className="list-element-description">{description}</div>
        <NewButton
          className="list-element-button"
          theme="white"
          accent="green"
          size="sm"
          outlined={true}
          onClick={goToDetailPage}
        >
          Saber mais
        </NewButton>
      </div>
    </div>
  );
}

export default ListElement;
