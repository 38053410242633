import { Dispatch, SetStateAction } from 'react';
import BsButton from 'react-bootstrap/Button';
import BsModal from 'react-bootstrap/Modal';

type SubmitOrganizationProps = {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  submitDelete: () => Promise<void>;
};

function ConfirmDeleteModalBodyComponent({ setShowModal, submitDelete }: SubmitOrganizationProps) {
  return (
    <div>
      <BsModal.Footer>
        <BsButton variant="secondary" onClick={() => setShowModal(false)}>
          Fechar
        </BsButton>
        <BsButton variant="danger" className="text-white" onClick={submitDelete}>
          Apagar
        </BsButton>
      </BsModal.Footer>
    </div>
  );
}

export default ConfirmDeleteModalBodyComponent;
