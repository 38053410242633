type FirebaseAuthErrorsType = {
  [key: string]: string;
};

export const FirebaseAuthErrors: FirebaseAuthErrorsType = {
  'auth/admin-restricted-operation': 'Esta operação é restrita apenas a administradores.',
  'auth/argument-error': '',
  'auth/app-not-authorized':
    'Este aplicativo, identificado pelo domínio onde está hospedado, não é ' +
    'autorizado a usar o Firebase Authentication com a chave de API fornecida. ' +
    'Revise sua configuração de chave no console de API do Google.',
  'auth/app-not-installed':
    'O aplicativo móvel solicitado correspondente ao identificador (' +
    'Nome do pacote Android ou ID do pacote iOS) fornecido não está instalado em ' +
    'este aparelho.',
  'auth/captcha-check-failed':
    'O token de resposta reCAPTCHA fornecido é inválido, expirou, ' +
    'já usado ou o domínio associado a ele não corresponde à lista ' +
    'de domínios na lista de permissões.',
  'auth/code-expired': 'O código SMS expirou. Por favor, reenvie o código de verificação para tentar novamente.',
  'auth/cordova-not-ready': 'A estrutura Cordova não está pronta.',
  'auth/cors-unsupported': 'Este navegador não é suportado.',
  'auth/credential-already-in-use': 'Esta credencial já está associada a uma conta de utilizador diferente.',
  'auth/custom-token-mismatch': 'O token personalizado corresponde a um público diferente.',
  'auth/requires-recent-login':
    'Esta operação é sensível e requer autenticação recente. Entrar ' +
    'novamente antes de tentar novamente este pedido.',
  'auth/dependent-sdk-initialized-before-auth':
    'Outro SDK do Firebase foi inicializado e está tentando usar o Auth antes que o Auth seja ' +
    'inicializado. Certifique-se de chamar `initializeAuth` ou `getAuth` antes de ' +
    'iniciando qualquer outro SDK do Firebase.',
  'auth/dynamic-link-not-activated':
    'Ative os links dinâmicos no Firebase Console e concorde com os termos e condições.',
  'auth/email-change-needs-verification': 'Utilizadores multifatores devem sempre ter um e-mail verificado.',
  'auth/email-already-in-use': 'O endereço de e-mail já está sendo usado por outra conta.',
  'auth/emulator-config-failed':
    'A instância de autenticação já foi usada para fazer uma chamada de rede. Autenticação pode ' +
    'não pode mais ser configurado para usar o emulador. Tente ligar para ' +
    '"useEmulator()" antes.',
  'auth/expired-action-code': 'O código de ação expirou.',
  'auth/cancelled-popup-request': 'Esta operação foi cancelada devido à abertura de outro popup conflitante.',
  'auth/internal-error': 'Ocorreu um AuthError interno.',
  'auth/invalid-api-key': 'Sua chave de API é inválida, verifique se você a copiou corretamente.',
  'auth/invalid-app-credential':
    'A solicitação de verificação por telefone contém um verificador de aplicativo inválido.' +
    ' A resposta do token reCAPTCHA é inválida ou expirou.',
  'auth/invalid-app-id': 'O identificador do aplicativo móvel não está registrado para o projeto atual.',
  'auth/invalid-user-token':
    'A credencial deste utilizador não é válida para este projeto. Isso pode acontecer ' +
    'se o token do utilizador foi adulterado, ou se o utilizador não for ' +
    'o projeto associado a esta chave de API.',
  'auth/invalid-auth-event': 'Ocorreu um AuthError interno.',
  'auth/invalid-cert-hash': 'O hash do certificado SHA-1 fornecido é inválido.',
  'auth/invalid-verification-code':
    'O código de verificação SMS usado para criar a credencial de autenticação de telefone é ' +
    'inválido. Por favor, reenvie o sms do código de verificação e certifique-se de usar o ' +
    'código de verificação fornecido pelo utilizador.',
  'auth/invalid-continue-uri': 'O URL de continuação fornecido na solicitação é inválido.',
  'auth/invalid-cordova-configuration':
    'Os seguintes plugins do Cordova devem ser instalados para habilitar o login OAuth: ' +
    'cordova-plugin-buildinfo, cordova-universal-links-plugin, ' +
    'cordova-plugin-browsertab, cordova-plugin-inappbrowser e ' +
    'cordova-plugin-customurlscheme.',
  'auth/invalid-custom-token': 'O formato do token personalizado está incorreto. Por favor, verifique a documentação.',
  'auth/invalid-dynamic-link-domain':
    'O domínio de link dinâmico fornecido não está configurado ou autorizado para o projeto atual.',
  'auth/invalid-email': 'Endereço de e-mail inválido',
  'auth/invalid-emulator-scheme': 'A URL do emulador deve começar com um esquema válido (http:// ou https://).',
  'auth/invalid-credential': 'As credenciais de autenticação fornecidas estão inválidas ou expiraram.',
  'auth/invalid-message-payload':
    'O modelo de email correspondente a esta ação contém caracteres inválidos em sua mensagem. ' +
    'Corrija acessando a seção de modelos de e-mail de autenticação no Firebase Console.',
  'auth/invalid-multi-factor-session':
    'A solicitação não contém uma prova válida de login bem-sucedido do primeiro fator.',
  'auth/invalid-oauth-client-id':
    'O ID do cliente OAuth fornecido é inválido ou não corresponde ao chave de API especificada.',
  'auth/invalid-oauth-provider':
    'EmailAuthProvider não é compatível com esta operação. Esta operação suporta apenas provedores OAuth.',
  'auth/invalid-action-code':
    'O código de ação é inválido. Isso pode acontecer se o código estiver malformado, expirado, ou já foi usado.',
  'auth/unauthorized-domain':
    'Este domínio não está autorizado para operações OAuth para seu Firebase ' +
    'projeto. Edite a lista de domínios autorizados no console do Firebase.',
  'auth/wrong-password': 'Password inválida ou o utilizador não tem password.',
  'auth/invalid-persistence-type':
    'O tipo de persistência especificado é inválido. Só pode ser local, sessão ou nenhuma.',
  'auth/invalid-phone-number':
    'O formato do número de telefone fornecido está incorreto. Por favor, digite o ' +
    'número de telefone no formato seguinte [+][código do país][assinante número incluindo código de área].',
  'auth/invalid-provider-id': 'O ID do provedor especificado é inválido.',
  'auth/invalid-recipient-email': 'Endereço de e-mail do destinatário é inválido.',
  'auth/invalid-sender':
    'O modelo de e-mail correspondente a esta ação contém um e-mail ou nome de remetente inválido. ' +
    'Corrija acessando a seção de modelos de e-mail de autenticação no Firebase Console.',
  'auth/invalid-verification-id':
    'O ID de verificação usado para criar a credencial de autenticação do telefone é inválido.',
  'auth/invalid-tenant-id': 'O ID do locatário da instância Auth é inválido.',
  'auth/multi-factor-info-not-found':
    'O utilizador não possui um segundo fator que corresponda ao identificador fornecido.',
  'auth/multi-factor-auth-required': 'É necessária uma prova de propriedade de um segundo fator para concluir o login.',
  'auth/missing-android-pkg-name':
    'Um nome de pacote Android deve ser fornecido se o aplicativo Android precisar ser instalado.',
  'auth/missing-app-credential':
    'A solicitação de verificação por telefone não possui um verificador de aplicativo ' +
    'afirmação. Um token de resposta reCAPTCHA precisa ser fornecido.',
  'auth/auth-domain-config-required':
    'Certifique-se de incluir authDomain ao chamar firebase.initializeApp(), ' +
    'seguindo as instruções no console do Firebase.',
  'auth/missing-verification-code':
    'A credencial de autenticação por telefone foi criada com um código de verificação SMS vazio.',
  'auth/missing-continue-uri': 'Um URL de continuação deve ser fornecido na solicitação.',
  'auth/missing-iframe-start': 'Ocorreu um AuthError interno.',
  'auth/missing-ios-bundle-id': 'Um ID de pacote iOS deve ser fornecido se um ID da App Store for fornecido.',
  'auth/missing-or-invalid-nonce':
    'A solicitação não contém um nonce válido. Isso pode ocorrer se o ' +
    'O hash SHA-256 do nonce bruto fornecido não corresponde ao nonce com hash ' +
    'na carga útil do token de ID.',
  'auth/missing-multi-factor-info': 'Nenhum identificador de segundo fator é fornecido.',
  'auth/missing-multi-factor-session': 'A solicitação não tem prova de login bem-sucedido do primeiro fator.',
  'auth/missing-phone-number': 'Para enviar códigos de verificação, forneça um número de telefone para o destinatário.',
  'auth/missing-verification-id':
    'A credencial de autenticação por telefone foi criada com um ID de verificação vazio.',
  'auth/app-deleted': 'Esta instância do FirebaseApp foi excluída.',
  'auth/account-exists-with-different-credential':
    'Já existe uma conta com o mesmo endereço de e-mail mas diferente ' +
    'credenciais de login. Faça login usando um provedor associado a este ' +
    'endereço de email.',
  'auth/network-request-failed':
    'Ocorreu um erro de autenticação de rede (como tempo limite, conexão interrompida ou host inacessível).',
  'auth/null-user':
    'Um objeto de utilizador nulo foi fornecido como argumento para uma operação que ' +
    'requer um objeto de utilizador não nulo.',
  'auth/no-auth-event': 'Ocorreu um AuthError interno.',
  'auth/no-such-provider': 'O utilizador não foi vinculado a uma conta com o provedor fornecido.',
  'auth/operation-not-allowed':
    'O provedor de login fornecido está desativado para este projeto do Firebase. ' +
    'Ative-o no console do Firebase, na guia do método de login do ' +
    'Seção de autenticação.',
  'auth/operation-not-supported-in-this-environment':
    'Esta operação não é suportada no ambiente em que esta aplicação é ' +
    'correndo. "location.protocol" deve ser http, https ou chrome-extension' +
    ' e o armazenamento na web deve estar ativado.',
  'auth/popup-blocked': 'Não foi possível estabelecer uma conexão com o popup. Pode ter sido bloqueado pelo navegador.',
  'auth/popup-closed-by-user': 'O popup foi fechado pelo utilizador antes de finalizar a operação.',
  'auth/provider-already-linked': 'O utilizador só pode ser vinculado a uma identidade para o provedor fornecido.',
  'auth/quota-exceeded': 'A cota do projeto para esta operação foi excedida.',
  'auth/redirect-cancelled-by-user': 'A operação de redirecionamento foi cancelada pelo utilizador antes de finalizar.',
  'auth/redirect-operation-pending': 'Uma operação de login de redirecionamento já está pendente.',
  'auth/rejected-credential': 'A solicitação contém credenciais malformadas ou incompatíveis.',
  'auth/second-factor-already-in-use': 'O segundo fator já está inscrito nesta conta.',
  'auth/maximum-second-factor-count-exceeded':
    'O número máximo permitido de segundos fatores em um utilizador foi excedido.',
  'auth/tenant-id-mismatch': 'O ID do locatário fornecido não corresponde ao ID do locatário da instância Auth',
  'auth/timeout': 'A operação expirou.',
  'auth/user-token-expired': 'A credencial do utilizador não é mais válida. O utilizador deve entrar novamente.',
  'auth/too-many-requests':
    'Nós bloqueamos todas as solicitações deste dispositivo devido a atividades incomuns. Tente mais tarde.',
  'auth/unauthorized-continue-uri':
    'O domínio da URL de continuação não está na lista de permissões. Por favor, coloque na lista de permissões ' +
    'o domínio no console do Firebase.',
  'auth/unsupported-first-factor':
    'Inscrever um segundo fator ou entrar com uma conta multifator requer entrar com um primeiro fator suportado.',
  'auth/unsupported-persistence-type': 'O ambiente atual não suporta o tipo de persistência especificado.',
  'auth/unsupported-tenant-operation': 'Esta operação não é suportada em um contexto multilocatário.',
  'auth/unverified-email': 'A operação requer um e-mail verificado.',
  'auth/user-cancelled': 'O utilizador não concedeu ao seu aplicativo as permissões solicitadas.',
  'auth/user-not-found': 'Utilizador não foi encontrado, por favor verifique as suas credenciais.',
  'auth/user-disabled': 'A conta de utilizador foi desabilitada por um administrador.',
  'auth/user-mismatch': 'As credenciais fornecidas não correspondem ao utilizador conectado anteriormente.',
  'auth/user-signed-out': '',
  'auth/weak-password': 'A senha deve ter 6 caracteres ou mais.',
  'auth/web-storage-unsupported':
    'Este navegador não é suportado ou cookies e dados de terceiros podem estar desabilitados.',
  'auth/already-initialized':
    'initializeAuth() já foi chamado com ' +
    'diferentes opções. Para evitar esse erro, chame initializeAuth() com as ' +
    'mesmas opções de quando foi chamado originalmente, ou chame getAuth() para retornar o' +
    ' instância já inicializada.',
  'auth/missing-email': 'Não tem email associado ao provedor que selecionou.',
};

export const UnknownError = 'Erro desconhecido';
