import { Outlet, useNavigate } from 'react-router-dom';
import styles from '../list-projects/list-projects.component.module.scss';

function DataCenter() {
  const navigate = useNavigate();
  return (
    <div className="py-5">
      <h1>Bases de dados de impacto</h1>
      <div className="btn-group d-flex align-items-start">
        <div className="btn btn-primary p-3 border bg-light text-primary" onClick={() => navigate('main-document')}>
          Organizações de impacto social
        </div>
        <div className="btn btn-primary p-3 border bg-light text-primary" onClick={() => navigate('actions')}>
          Ações de Serviço
        </div>
        <div className="btn btn-primary p-3 border bg-light text-primary" onClick={() => navigate('opinion-leaders')}>
          Líderes de Opinião
        </div>
      </div>
      <Outlet />
    </div>
  );
}

export default DataCenter;
