import { Fragment, useContext, useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useList, useToggle, useWindowSize } from 'react-use';
import { useNavigate } from 'react-router-dom';
import BootstrapButton from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

// Components
import { AppContext } from '../../state/app-state-context';
import { Colors } from '../../constants/colors.constants';
import { DatabaseService } from '../../services/database.service';
import { ELASTIC_PROJECTS_PATH, PaginationSettings } from '../../interfaces/elastic/search-api.interface';
import { FilterButton, InputSearch, ListElement, NewButton, PageHeader, Tag } from '../../components';
import { FilteredFormValues } from '../../interfaces/filtered-form-values.interface';
import { Project, projectElasticConverter } from '../../model/main/project';
import { QueryFormat } from '../../interfaces/database.interface';
import { QueryTypes } from '../../enums/query-types.enum';
import { REGIONS } from '../../model/options';
import { ResultsType } from '../../enums/results-type.enum';
import { Role } from '../../enums/role.enum';
import { ScreenBreakpoint } from '../../enums/screen-breakpoint.enum';
import { cleanString, updateQueryFormatArray, updateSetSelectedFilteredValues } from '../../utils/helpers.util';
import { openSidebarAction, openToastAction, setDatabaseEntityAction } from '../../state/actions';
import DatabaseQuery from '../../components/database/database-query/database-query';

import headerImg from '../../images/projetos/header.png';

import './list-projects.component.scss';

const PAGE_SIZE = Number(process.env.REACT_APP_PAGE_SIZE) || 12;

interface ProjectDetail {
  organizationName: string;
  projectName: string;
  projectImage: string;
  projectMission: string;
  projectRegions: string[];
  projectDetailsLink: string;
  projectId: string;
}

function ListProjects() {
  const { width: windowWidth } = useWindowSize();
  const {
    dispatch,
    state: { currentFirestoreUser },
  } = useContext(AppContext);
  const [projectsDetails, setProjectsDetails] = useState<ProjectDetail[]>([]);
  const [projects, setProjects] = useState<Project[]>([]);
  const [filteredProjects, setFilteredProjects] = useState<Project[]>([]);
  const [resultsType, setResultsType] = useState<ResultsType>(ResultsType.Normal);
  const [searchTerm, setSearchTerm] = useState('');
  const [queryFormatArray, { set: setQueryFormatArray }] = useList<QueryFormat>([]);
  const [selectedFilteredValues, setSelectedFilteredValues] = useState<FilteredFormValues>({});
  const [appIsLoading, setAppIsLoading] = useState(true);
  const [projectsPaginationSettings, setProjectsPaginationSettings] = useState<PaginationSettings>();
  const [filteredProjectsPaginationSettings, setFilteredProjectsPaginationSettings] = useState<PaginationSettings>();
  const [resetSearchTerm, setResetSearchTerm] = useToggle(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (projectsDetails.length === 0) {
      fetchProjects();
    }
  }, []);

  useEffect(() => {
    const hasFilters = async () => {
      if (queryFormatArray.length > 0) {
        await fetchFilteredProjects(1, true);
      } else {
        if (projects.length > 0) {
          buildProjectsDetails(projects);
        }
      }
    };

    hasFilters();
  }, [queryFormatArray]);

  const handleSubmitNewProjectBtnPressed = () => {
    dispatch(setDatabaseEntityAction());
    navigate('novo-projeto');
  };

  const handleSubmitNewOrganizationBtnPressed = () => {
    dispatch(setDatabaseEntityAction());
    navigate('nova-organizacao');
  };

  const handleFilterBtnPressed = () =>
    dispatch(
      openSidebarAction(
        <DatabaseQuery
          key={Math.random()} // Create new instance
          setQueryFormat={setQueryFormatArray}
          queryOptions={[
            QueryTypes.Problems,
            QueryTypes.Regions,
            QueryTypes.TargetAudience,
            QueryTypes.Services,
            QueryTypes.ProfessionalServices,
            QueryTypes.Ods,
            QueryTypes.VolunteeringProfile,
          ]}
          selectedFilters={selectedFilteredValues}
          setSelectedFilters={setSelectedFilteredValues}
        />
      )
    );

  const handleGetMoreBtnPressed = async () => {
    if (projectsDetails.length > 0) {
      if (
        resultsType === ResultsType.Normal &&
        projectsPaginationSettings &&
        projectsPaginationSettings.current < projectsPaginationSettings.total_pages
      ) {
        await fetchProjects(projectsPaginationSettings.current + 1);
        return;
      }

      if (
        resultsType === ResultsType.Filter &&
        filteredProjectsPaginationSettings &&
        filteredProjectsPaginationSettings.current < filteredProjectsPaginationSettings.total_pages
      ) {
        await fetchFilteredProjects(filteredProjectsPaginationSettings.current + 1);
        return;
      }

      if (
        resultsType === ResultsType.Name &&
        filteredProjectsPaginationSettings &&
        filteredProjectsPaginationSettings.current < filteredProjectsPaginationSettings.total_pages
      ) {
        await fetchProjectsBySearch(filteredProjectsPaginationSettings.current + 1, false);
        return;
      }
    }
  };

  const fetchProjects = async (pageNumber = 1) => {
    const filterArr = [...queryFormatArray];

    if (currentFirestoreUser?.role !== Role.Admin) {
      filterArr.push({ field: 'approved', value: true, queryType: 'boolean' });
    }

    try {
      const {
        meta: { page: paginationSettings },
        results: projectsFromDb,
      } = await DatabaseService.getByQueriesElastic<Project>(
        ELASTIC_PROJECTS_PATH,
        projectElasticConverter,
        { enablePagination: true, currentPage: pageNumber, limit: PAGE_SIZE },
        { enableSorting: false },
        { enableSearchByTerm: false },
        ...filterArr
      );

      setProjectsPaginationSettings(paginationSettings);

      if (projectsFromDb.length > 0) {
        setResultsType(ResultsType.Normal);
        setProjects([...projects, ...projectsFromDb]);
        buildProjectsDetails([...projects, ...projectsFromDb]);
      }
    } catch {
      dispatch(openToastAction('Não foi possível carregar os projetos!', 'danger'));
    }
  };

  const fetchFilteredProjects = async (pageNumber = 1, replace = false) => {
    const filterArr = [...queryFormatArray];

    if (currentFirestoreUser?.role !== Role.Admin) {
      filterArr.push({ field: 'approved', value: true, queryType: 'boolean' });
    }

    try {
      const {
        meta: { page: paginationSettings },
        results: projectsFromDb,
      } = await DatabaseService.getByQueriesElastic<Project>(
        ELASTIC_PROJECTS_PATH,
        projectElasticConverter,
        { enablePagination: true, currentPage: pageNumber, limit: PAGE_SIZE },
        { enableSorting: false },
        { enableSearchByTerm: false },
        ...filterArr
      );

      setFilteredProjectsPaginationSettings(paginationSettings);

      if (projectsFromDb.length > 0) {
        setResultsType(ResultsType.Filter);

        if (replace) {
          setFilteredProjects(projectsFromDb);
          buildProjectsDetails(projectsFromDb);
        } else {
          setFilteredProjects([...filteredProjects, ...projectsFromDb]);
          buildProjectsDetails([...filteredProjects, ...projectsFromDb]);
        }
      } else {
        dispatch(openToastAction('Não foram encontrados projetos com os filtros selecionados.', 'warning'));
        resetFilter();
      }
    } catch {
      dispatch(openToastAction('Não foi possível carregar os projetos!', 'danger'));
    }
  };

  const searchTermCallback = async (searchTermFromInput: string) => {
    if (searchTermFromInput.length === 0) {
      setResetSearchTerm(false);
      resetFilter();
    } else {
      fetchProjectsBySearch(1, true, searchTermFromInput);
    }
  };

  const fetchProjectsBySearch = async (pageNumber = 1, replace = false, searchTermFromInput?: string) => {
    if (searchTermFromInput) {
      setSearchTerm(searchTermFromInput);
    } else {
      searchTermFromInput = searchTerm;
    }

    const filterArr = [...queryFormatArray];

    if (currentFirestoreUser?.role !== Role.Admin) {
      filterArr.push({ field: 'approved', value: true, queryType: 'boolean' });
    }

    filterArr.push({ field: '', value: searchTermFromInput, queryType: 'substring' });

    try {
      const {
        meta: { page: paginationSettings },
        results: projectsFromDb,
      } = await DatabaseService.getByQueriesElastic<Project>(
        ELASTIC_PROJECTS_PATH,
        projectElasticConverter,
        { enablePagination: true, currentPage: pageNumber, limit: PAGE_SIZE },
        { enableSorting: true, sortBy: { name: 'asc' } },
        { enableSearchByTerm: true, searchBy: { name: {}, regions: {}, orgname: {} } },
        ...filterArr
      );

      setFilteredProjectsPaginationSettings(paginationSettings);

      if (projectsFromDb.length > 0) {
        setResultsType(ResultsType.Name);

        if (replace) {
          setFilteredProjects(projectsFromDb);
          buildProjectsDetails(projectsFromDb);
        } else {
          setFilteredProjects([...filteredProjects, ...projectsFromDb]);
          buildProjectsDetails([...filteredProjects, ...projectsFromDb]);
        }
      } else {
        dispatch(openToastAction('Não foram encontrados projetos para o termo procurado.', 'warning'));
        resetFilter();
      }
    } catch {
      dispatch(openToastAction('Não foi possível carregar os projetos!', 'danger'));
    }
  };

  const buildProjectsDetails = (projectsFromDb: Project[]) => {
    const pDetails: ProjectDetail[] = [];

    for (const project of projectsFromDb) {
      if (project.orgId) {
        const projectImage = fetchProjectImage(project.regions);
        const regions = getRegions(project.regions);

        const pDetail: ProjectDetail = {
          organizationName: project.orgName ?? '',
          projectName: project.name ?? '',
          projectImage: projectImage,
          projectMission: project.mission ?? '',
          projectRegions: regions ?? [],
          projectDetailsLink: `/projetos/${
            project.name ? project.name.replace(/\s+/g, '') : 'nome-indefinido'
          }/${encodeURIComponent(project.id)}`,
          projectId: project.id,
        };

        pDetails.push(pDetail);
      }
    }

    setProjectsDetails(pDetails);
    setAppIsLoading(false);
  };

  const fetchProjectImage = (regions?: string[]) => {
    if (!regions || regions.length === 0) {
      return 'placeholder.png';
    }

    let projectImageSrc = '';

    if (
      regions.some((region) =>
        [REGIONS.PORTUGAL_CONTINENTAL, REGIONS.INTERNACIONAL, REGIONS.TODO_O_PAIS].includes(region)
      )
    ) {
      projectImageSrc = 'abrangencia-geografica/portugal-continental-todo-o-pais-internacional.jpg';
    } else if (regions.length >= 6) {
      projectImageSrc = 'abrangencia-geografica/6-a-20-distritos.jpg';
    } else if (regions.length >= 2) {
      projectImageSrc = 'abrangencia-geografica/2-a-5-distritos.jpg';
    } else if (regions.length === 1) {
      projectImageSrc = 'abrangencia-geografica/1-distrito.jpg';
    }

    return projectImageSrc;
  };

  const getRegions = (regions?: string[]) => {
    if (!regions || regions.length === 0) {
      return;
    }

    if (regions.length <= 2) {
      return regions.map((region) => region.slice(region.indexOf(' ') + 1));
    } else {
      const [firstRegion, secondRegion, ...rest] = regions.map((region) => region.slice(region.indexOf(' ') + 1));

      return [firstRegion, secondRegion, `+ ${rest.length} Locais`];
    }
  };

  const resetFilter = () => {
    setSelectedFilteredValues({});
    setQueryFormatArray([]);
    setFilteredProjectsPaginationSettings(undefined);
    setFilteredProjects([]);
    setResultsType(ResultsType.Normal);
  };

  const handleDeleteFilter = (key: string, filterToRemove: string) => {
    const updatedFilter = updateSetSelectedFilteredValues({ ...selectedFilteredValues }, key, filterToRemove);

    if (
      updatedFilter &&
      Object.values(updatedFilter).some(
        (v) => (v && Array.isArray(v) && (v as string[]).length > 0) || (v && typeof v === 'string' && v.length > 0)
      )
    ) {
      const duplicateQueryFormatArray = updateQueryFormatArray([...queryFormatArray], key, filterToRemove);

      setSelectedFilteredValues(updatedFilter);
      setQueryFormatArray(duplicateQueryFormatArray);
    } else {
      resetFilter();
      buildProjectsDetails(projects);
    }
  };

  return (
    <Fragment>
      {appIsLoading ? (
        <Fragment />
      ) : (
        <Container className="py-5">
          <PageHeader
            title="Organizações e Projetos Sociais"
            description="Organizações do setor social ou do setor empresarial com grandes políticas de impacto social. Conhece as principais organizações e projetos sociais do nosso país, inspira-te, usa esta informação para ajudares e direcionares pessoas que precisem e descobre como podes fazer lá voluntariado ou até, quem sabe, integrares a sua equipa profissional."
            buttons={[
              <NewButton
                key="new-org-btn"
                theme="teal"
                accent="white"
                size={windowWidth < ScreenBreakpoint.Large ? 'sm' : 'lg'}
                outlined={false}
                onClick={handleSubmitNewOrganizationBtnPressed}
              >
                Submeter Organização
              </NewButton>,
              <NewButton
                key="new-project-btn"
                theme="beige"
                accent="teal"
                size={windowWidth < ScreenBreakpoint.Large ? 'sm' : 'lg'}
                outlined={true}
                onClick={handleSubmitNewProjectBtnPressed}
              >
                Submeter Projeto
              </NewButton>,
            ]}
            img={<img src={headerImg} style={{ width: '390px', height: '395px' }}></img>}
          />
          <div className="filters">
            <FilterButton handleButtonPressed={handleFilterBtnPressed} />
            <InputSearch searchButtonClicked={searchTermCallback} resetSearchTerm={resetSearchTerm} />
          </div>
          {resultsType === ResultsType.Filter && (
            <div className="mt-2 d-flex flex-wrap">
              {Object.entries(selectedFilteredValues).map(([key, value]) => {
                if (Array.isArray(value)) {
                  return value.map((v: string, index) => (
                    <div key={`${v}-${index}`} className="me-2 mt-2">
                      <Tag
                        text={
                          v.includes('#')
                            ? key === 'professionalServices'
                              ? cleanString(v.split('#')[1])
                              : v.split('#')[1]
                            : cleanString(v)
                        }
                        backgroundColor={Colors.blue04}
                        filterTags={true}
                        filterObjectKey={key}
                        filterObjectValue={v}
                        filterHandleCloseIconClick={handleDeleteFilter}
                      />
                    </div>
                  ));
                }
              })}
            </div>
          )}
          {resultsType === ResultsType.Name && (
            <div className="mt-4">
              <h6>
                <b>Resultados ({filteredProjectsPaginationSettings?.total_results})</b>
              </h6>
              <div className="d-flex align-items-center">
                {filteredProjects.length ? (
                  <p className="mb-0">
                    Existem resultados para <b>&quot;{searchTerm}&quot;</b>
                  </p>
                ) : (
                  <p className="mb-0">
                    Não existem resultados para <b>&quot;{searchTerm}&quot;</b>
                  </p>
                )}
                <BootstrapButton
                  variant="danger"
                  className="py-1 px-2 ms-2"
                  onClick={() => {
                    resetFilter();
                    setResetSearchTerm(true);
                  }}
                >
                  <MdClose size={18} color="#fff" />
                </BootstrapButton>
              </div>
            </div>
          )}
          {projectsDetails.length > 0 && (
            <>
              <div className="results-list">
                {projectsDetails.map((projectWithDetails) => (
                  <ListElement
                    key={projectWithDetails.projectId}
                    title={projectWithDetails.projectName}
                    description={projectWithDetails.projectMission}
                    tags={projectWithDetails.projectRegions}
                    link={projectWithDetails.projectDetailsLink}
                    imageSrc={projectWithDetails.projectImage}
                  />
                ))}
              </div>
              <div>
                {ResultsType.Normal === resultsType &&
                  projectsPaginationSettings &&
                  projectsPaginationSettings.current < projectsPaginationSettings.total_pages && (
                    <div className="row">
                      <div className="col-12 d-flex justify-content-center">
                        <NewButton
                          theme="green"
                          accent="white"
                          outlined={false}
                          size="lg"
                          onClick={handleGetMoreBtnPressed}
                        >
                          Carregar mais
                        </NewButton>
                      </div>
                    </div>
                  )}
                {ResultsType.Filter === resultsType &&
                  filteredProjectsPaginationSettings &&
                  filteredProjectsPaginationSettings.current < filteredProjectsPaginationSettings.total_pages && (
                    <div className="row">
                      <div className="col-12 d-flex justify-content-center">
                        <NewButton
                          theme="green"
                          accent="white"
                          outlined={false}
                          size="lg"
                          onClick={handleGetMoreBtnPressed}
                        >
                          Carregar mais
                        </NewButton>
                      </div>
                    </div>
                  )}
                {ResultsType.Name === resultsType &&
                  filteredProjectsPaginationSettings &&
                  filteredProjectsPaginationSettings.current < filteredProjectsPaginationSettings.total_pages && (
                    <div className="row">
                      <div className="col-12 d-flex justify-content-center">
                        <NewButton
                          theme="green"
                          accent="white"
                          outlined={false}
                          size="lg"
                          onClick={handleGetMoreBtnPressed}
                        >
                          Carregar mais
                        </NewButton>
                      </div>
                    </div>
                  )}
              </div>
            </>
          )}
        </Container>
      )}
    </Fragment>
  );
}

export default ListProjects;
