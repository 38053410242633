import { FieldValue, QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';

// Components
import { Entity } from '../Entity';
import { FeedbackType } from '../../enums/feedback-type.enum';
import { FirestoreConverter } from '../../interfaces/database.interface';
import { OpinionLeader } from '../opinion-leaders/opinion-leader';
import { Organization } from '../main/organization';
import { Project } from '../main/project';
import {
  problemsToSchema,
  professionalServicesToSchema,
  schemaToProblems,
  schemaToProfessionalServices,
} from '../../utils/helpers.util';

export class Feedback<T extends OpinionLeader | Project | Organization> extends Entity {
  public static OPINION_LEADER_PATH = 'feedback/leaders';
  public static PROJECT_PATH = 'feedback/projects';
  public static ORGANIZATION_PATH = 'feedback/orgs';
  public static PREFIX = 'fdk';

  entity: T;
  type: FeedbackType;

  constructor(
    entity: T,
    type: FeedbackType,
    id: string = Feedback.generateId(Feedback.PREFIX),
    createdAt?: FieldValue,
    createdById?: string,
    createdByName?: string
  ) {
    super(id, Feedback.PREFIX, createdAt, undefined, createdById, createdByName);
    this.entity = entity;
    this.type = type;
  }
}

export const feedbackOpinionLeaderConverter: FirestoreConverter<Feedback<OpinionLeader>> = {
  fromFirestore(snapshot: QueryDocumentSnapshot, options?: SnapshotOptions) {
    const data = (options ? snapshot.data(options) : snapshot.data()) as Feedback<OpinionLeader>;
    const opinionLeader = new OpinionLeader(
      data.entity.id,
      schemaToProblems(data.entity.problems),
      data.entity.name ?? '',
      data.entity.description ?? '',
      data.entity.academicBackground ?? [],
      data.entity.linkedin ?? '',
      data.entity.observations ?? '',
      data.entity.twitter ?? '',
      data.entity.contacted ?? '',
      data.entity.contactResponsability ?? '',
      data.entity.facebook ?? '',
      data.entity.sites ?? '',
      data.entity.focusArea ?? '',
      data.entity.approved,
      data.entity.createdAt,
      data.entity.modifiedAt,
      data.entity.createdById,
      data.entity.createdByName
    );

    return new Feedback(opinionLeader, data.type, data.id, data.createdAt, data.createdById, data.createdByName);
  },
  toFirestore(feedback: Feedback<OpinionLeader>) {
    const opinionLeader = feedback.entity;

    return {
      id: feedback.id,
      createdAt: feedback.createdAt,
      createdById: feedback.createdById,
      createdByName: feedback.createdByName,
      type: feedback.type,
      entity: {
        id: opinionLeader.id,
        name: opinionLeader.name,
        description: opinionLeader.description,
        academicBackground: opinionLeader.academicBackground,
        linkedin: opinionLeader.linkedin,
        observations: opinionLeader.observations,
        twitter: opinionLeader.twitter,
        facebook: opinionLeader.facebook,
        sites: opinionLeader.sites,
        contacted: opinionLeader.contacted,
        contactResponsability: opinionLeader.contactResponsability,
        focusArea: opinionLeader.focusArea,
        problems: problemsToSchema(opinionLeader.problems),
        approved: opinionLeader.approved,
        createdAt: opinionLeader.createdAt,
        modifiedAt: opinionLeader.modifiedAt,
        createdById: opinionLeader.createdById,
        createdByName: opinionLeader.createdByName,
      },
    };
  },
};

export const feedbackProjectConverter: FirestoreConverter<Feedback<Project>> = {
  fromFirestore(snapshot: QueryDocumentSnapshot, options?: SnapshotOptions) {
    const data = (options ? snapshot.data(options) : snapshot.data()) as Feedback<Project>;
    const project = new Project(
      data.entity.id,
      data.entity.name ?? '',
      data.entity.mission ?? '',
      data.entity.activity ?? '',
      data.entity.volunteeringDescription ?? '',
      data.entity.site ?? '',
      data.entity.email ?? '',
      data.entity.phoneInfo ?? '',
      data.entity.regions ?? [],
      schemaToProblems(data.entity.problems),
      data.entity.targetAudience ?? [],
      data.entity.services ?? [],
      schemaToProfessionalServices(data.entity.professionalServices),
      data.entity.acceptsVolunteering ?? '',
      data.entity.volunteeringProfile ?? [],
      data.entity.sustainabilityObjectives ?? [],
      data.entity.observations ?? '',
      data.entity.approved ?? '',
      data.entity.orgId ?? '',
      data.entity.orgName ?? '',
      data.entity.createdAt,
      data.entity.modifiedAt,
      data.entity.createdById,
      data.entity.createdByName
    );

    return new Feedback(project, data.type, data.id, data.createdAt, data.createdById, data.createdByName);
  },
  toFirestore(feedback: Feedback<Project>) {
    const project = feedback.entity;
    return {
      id: feedback.id,
      createdAt: feedback.createdAt,
      createdById: feedback.createdById,
      createdByName: feedback.createdByName,
      type: feedback.type,
      entity: {
        id: project.id,
        name: project.name,
        mission: project.mission,
        activity: project.activity,
        volunteeringDescription: project.volunteeringDescription,
        site: project.site,
        email: project.email,
        phoneInfo: project.phoneInfo,
        regions: project.regions,
        problems: problemsToSchema(project.problems),
        targetAudience: project.targetAudience,
        services: project.services,
        professionalServices: professionalServicesToSchema(project.professionalServices),
        acceptsVolunteering: project.acceptsVolunteering,
        volunteeringProfile: project.volunteeringProfile,
        sustainabilityObjectives: project.sustainabilityObjectives,
        observations: project.observations,
        approved: project.approved,
        orgId: project.orgId,
        orgName: project.orgName,
        createdAt: project.createdAt,
        modifiedAt: project.modifiedAt,
        createdById: project.createdById,
        createdByName: project.createdByName,
      },
    };
  },
};

export const feedbackOrganizationConverter: FirestoreConverter<Feedback<Organization>> = {
  fromFirestore(snapshot: QueryDocumentSnapshot, options?: SnapshotOptions) {
    const data = (options ? snapshot.data(options) : snapshot.data()) as Feedback<Organization>;
    const organization = new Organization(
      data.entity.id,
      data.entity.orgName ?? '',
      data.entity.approved,
      data.entity.mainProjectId,
      data.entity.createdAt,
      data.entity.modifiedAt,
      data.entity.createdById,
      data.entity.createdByName
    );

    return new Feedback(organization, data.type, data.id, data.createdAt, data.createdById, data.createdByName);
  },
  toFirestore(feedback: Feedback<Organization>) {
    const organization = feedback.entity;

    return {
      id: feedback.id,
      createdAt: feedback.createdAt,
      createdById: feedback.createdById,
      createdByName: feedback.createdByName,
      type: feedback.type,
      entity: {
        id: organization.id,
        orgName: organization.orgName,
        approved: organization.approved,
        mainProjectId: organization.mainProjectId,
        createdAt: organization?.createdAt,
        modifiedAt: organization?.modifiedAt,
        createdById: organization?.createdById,
        createdByName: organization?.createdByName,
      },
    };
  },
};
