import {
  AuthProvider,
  User,
  applyActionCode,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  deleteUser,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updatePassword,
  verifyPasswordResetCode,
} from 'firebase/auth';
import { User as AuthUser } from 'firebase/auth';
import { auth } from '../firebase-app';

const AuthService = {
  creaseUser: async (email: string, password: string) => {
    return await createUserWithEmailAndPassword(auth, email, password);
  },
  signInWithEmailAndPassword: async (email: string, password: string) => {
    return await signInWithEmailAndPassword(auth, email, password);
  },
  signInWithProvider: async (provider: AuthProvider) => {
    return await signInWithPopup(auth, provider);
  },
  signOut: async () => {
    await signOut(auth);
  },
  validateEmailVerification: async (actionCode: string) => {
    await applyActionCode(auth, actionCode);
  },
  sendEmailVerification: async (user: User) => {
    await sendEmailVerification(user);
  },
  sendPasswordResetEmail: async (email: string) => {
    await sendPasswordResetEmail(auth, email);
  },
  verifyPasswordResetCode: async (actionCode: string) => {
    return await verifyPasswordResetCode(auth, actionCode);
  },
  confirmPasswordReset: async (actionCode: string, newPassword: string) => {
    await confirmPasswordReset(auth, actionCode, newPassword);
  },
  updatePassword: async (user: User, newPassword: string) => {
    await updatePassword(user, newPassword);
  },
  deleteUser: async (user: AuthUser) => {
    await deleteUser(user);
  },
};

export default AuthService;
