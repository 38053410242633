import { AiOutlineWarning } from 'react-icons/ai';
import { useContext, useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { useNavigate } from 'react-router-dom';

import { AppContext } from '../../state/app-state-context';
import { toggleShowFooterAction, toggleShowNavbarAction } from '../../state/actions';

import './work-in-progress.styles.scss';

const INITIAL_SECONDS_VALUE = 5;

function WorkInProgress() {
  const { dispatch } = useContext(AppContext);
  const [seconds, setSeconds] = useState(INITIAL_SECONDS_VALUE);
  const navigate = useNavigate();

  useEffectOnce(() => {
    dispatch(toggleShowNavbarAction(false));
    dispatch(toggleShowFooterAction(false));
  });

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    }
  }, [seconds]);

  if (seconds === 0) {
    navigate('/');
  }

  return (
    <div className="work-in-progress-container">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <AiOutlineWarning size={200} />
        <h1>Página a ser trabalhada</h1>
        <p>A ser redirecionado para a página inicial em {seconds}</p>
      </div>
    </div>
  );
}

export default WorkInProgress;
