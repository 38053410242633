import { Fragment, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import Container from 'react-bootstrap/Container';

// Components
import { AppContext } from '../../state/app-state-context';
import {
  ArrowLeftIcon,
  DetailsPageFeedback,
  DetailsPageHeader,
  DetailsPageSection,
  DetailsPageText,
  NewButton,
} from '../../components';
import { DatabaseService } from '../../services/database.service';
import { OpinionLeader, opinionLeaderConverter } from '../../model/opinion-leaders/opinion-leader';
import { SOCIAL_PROBLEMS } from '../../model/options';
import { ScreenBreakpoint } from '../../enums/screen-breakpoint.enum';
import { cleanString } from '../../utils/helpers.util';
import { fetchImage } from '../../utils';
import { setDatabaseEntityAction } from '../../state/actions';

// Styles
import './opinion-leader-detail.component.scss';

function OpinionLeaderDetail() {
  const { width: windowWidth } = useWindowSize();
  const { opinionLeaderId } = useParams();
  const [opinionLeader, setOpinionLeader] = useState<OpinionLeader>();
  const [opinionLeaderImageSrc, setOpinionLeaderImageSrc] = useState('');
  const [appIsLoading, setAppIsLoading] = useState(true);
  const navigate = useNavigate();
  const { dispatch } = useContext(AppContext);

  useEffect(() => {
    if (opinionLeaderId) {
      getOpinionLeader(opinionLeaderId);
    }
  }, []);

  const getOpinionLeader = async (opId: string) => {
    const opinionLeaderFromDb = await DatabaseService.getById<OpinionLeader>(
      OpinionLeader.PATH,
      opId,
      opinionLeaderConverter
    );

    if (opinionLeaderFromDb) {
      setOpinionLeader(opinionLeaderFromDb);
      fetchOpinionLeaderImage(opinionLeaderFromDb.problems ?? {});
    }

    setAppIsLoading(false);
  };

  const fetchOpinionLeaderImage = async (socialProblems: { [key: string]: string[] }) => {
    const placeholderSrcImg = (await fetchImage('placeholder.png')).image;

    if (Object.keys(socialProblems).length === 0) {
      setOpinionLeaderImageSrc(placeholderSrcImg);
    } else {
      const socialProblem = Object.keys(socialProblems)[0];
      const socialProblemImage = SOCIAL_PROBLEMS.find((sP) => sP.group === socialProblem)?.image_social_leader;

      if (!socialProblemImage) {
        setOpinionLeaderImageSrc(placeholderSrcImg);
      } else {
        const { image, error } = await fetchImage(`lideres-sociais/${socialProblemImage}.jpg`);

        if (error) {
          setOpinionLeaderImageSrc(placeholderSrcImg);
        } else {
          setOpinionLeaderImageSrc(image);
        }
      }
    }
  };

  const giveFeedbackHandler = () => {
    dispatch(setDatabaseEntityAction(opinionLeader));
    navigate(
      `/lideres-de-opiniao/${
        opinionLeader && opinionLeader.name ? opinionLeader.name.replace(/\s+/g, '') : 'nome-indefinido'
      }/feedback`
    );
  };

  return (
    <Fragment>
      {appIsLoading ? (
        <Fragment />
      ) : (
        <Container className="py-5">
          <a className="go-back" href="/lideres-de-opiniao">
            <ArrowLeftIcon />
            <span>VOLTAR À LISTA</span>
          </a>
          <div className="details-page-content">
            <div className="details-page-content-left">
              {windowWidth < ScreenBreakpoint.Large ? (
                <img src={opinionLeaderImageSrc} alt={opinionLeader?.name} />
              ) : (
                <></>
              )}
              <DetailsPageHeader
                sectionTitle="Líder de Opinião"
                title={opinionLeader?.name}
                description={opinionLeader?.description}
              ></DetailsPageHeader>
              {opinionLeader?.academicBackground ? (
                <DetailsPageSection title="Background Académico">
                  <DetailsPageText
                    labels={(opinionLeader?.academicBackground || []).map((value) => `· ${cleanString(value)}`)}
                  ></DetailsPageText>
                </DetailsPageSection>
              ) : (
                <></>
              )}
              {Object.entries(opinionLeader?.problems || {}).length ? (
                <DetailsPageSection title="Problemáticas">
                  {Object.entries(opinionLeader?.problems || {}).map(([problematic, problems], problemIndex) => {
                    return (
                      <DetailsPageText
                        key={`problem-${problemIndex}`}
                        title={cleanString(problematic)}
                        labels={problems.map((problem) => `· ${problem}`)}
                      ></DetailsPageText>
                    );
                  })}
                </DetailsPageSection>
              ) : (
                <></>
              )}
            </div>
            <div className="details-page-content-right">
              {windowWidth < ScreenBreakpoint.Large ? (
                <></>
              ) : (
                <img src={opinionLeaderImageSrc} alt={opinionLeader?.name} />
              )}
              {opinionLeader?.linkedin || opinionLeader?.facebook || opinionLeader?.twitter ? (
                <DetailsPageSection title="Links">
                  <div className="details-page-links">
                    {opinionLeader.linkedin ? (
                      <NewButton
                        theme="white"
                        accent="green"
                        outlined={true}
                        size="sm"
                        onClick={() => window.open(opinionLeader.linkedin, '_blank')}
                      >
                        Perfil Linkedin
                      </NewButton>
                    ) : (
                      <></>
                    )}
                    {opinionLeader.facebook ? (
                      <NewButton
                        theme="white"
                        accent="green"
                        outlined={true}
                        size="sm"
                        onClick={() => window.open(opinionLeader.facebook, '_blank')}
                      >
                        Perfil Facebook
                      </NewButton>
                    ) : (
                      <></>
                    )}
                    {opinionLeader.twitter ? (
                      <NewButton
                        theme="white"
                        accent="green"
                        outlined={true}
                        size="sm"
                        onClick={() => window.open(opinionLeader.twitter, '_blank')}
                      >
                        Perfil Twitter
                      </NewButton>
                    ) : (
                      <></>
                    )}
                  </div>
                </DetailsPageSection>
              ) : (
                <></>
              )}
            </div>
          </div>
          <DetailsPageFeedback onClickHandler={giveFeedbackHandler}></DetailsPageFeedback>
        </Container>
      )}
    </Fragment>
  );
}

export default OpinionLeaderDetail;
