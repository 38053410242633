import { Fragment, MouseEvent as ReactMouseEvent, ReactNode, useState } from 'react';
import BootstrapCard from 'react-bootstrap/Card';
import useEffectOnce from 'react-use/lib/useEffectOnce';

import { fetchImage } from '../../utils';
import NewButton from '../button/new-button.component';
import ProgressBar from '../progress-bar/progress-bar.component';

import './card.styles.scss';

const PLACEHOLDER_IMAGE_SOURCE = 'placeholder.png';

type CardProps = {
  title: string;
  description: string;
  progressPercentage?: number;
  buttonChildren: ReactNode;
  buttonTheme: 'beige' | 'green' | 'teal';
  buttonOutlined: boolean;
  isLocalImage?: boolean;
  imageURL?: string;
  handleButtonClick: (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

function Card({
  title,
  progressPercentage,
  description,
  buttonChildren,
  buttonTheme,
  buttonOutlined,
  isLocalImage = false,
  imageURL,
  handleButtonClick,
}: CardProps) {
  const [image, setImage] = useState('');

  useEffectOnce(() => {
    const fetchImg = async () => {
      let imageUrlTemp: string | undefined;

      if (isLocalImage) {
        if (imageURL) {
          const { image: localImage, error } = await fetchImage(imageURL);

          if (!error) {
            imageUrlTemp = localImage;
          }
        }
      } else {
        if (imageURL) {
          imageUrlTemp = imageURL;
        }
      }

      setImage(imageUrlTemp ?? (await fetchImage(PLACEHOLDER_IMAGE_SOURCE)).image);
    };

    fetchImg();
  });

  return (
    <BootstrapCard className={`u-card ${progressPercentage !== undefined ? 'u-card-progress' : ''}`}>
      <BootstrapCard.Img src={image} className="u-card-img" />
      <BootstrapCard.Body className="u-card-body">
        <div>
          <BootstrapCard.Title className="u-card-title">{title}</BootstrapCard.Title>
          <BootstrapCard.Text className="u-card-description">{description}</BootstrapCard.Text>
        </div>
        <div className="u-card-button-container">
          <NewButton theme={buttonTheme} outlined={buttonOutlined} size="sm" onClick={handleButtonClick}>
            {buttonChildren}
          </NewButton>
        </div>
        {progressPercentage !== undefined ? <ProgressBar percentage={progressPercentage} /> : <></>}
      </BootstrapCard.Body>
    </BootstrapCard>
  );
}

export default Card;
