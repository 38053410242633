import * as Yup from 'yup';
import { AppContext } from '../../../state/app-state-context';
import { Button, Modal } from '../../index';
import { DatabaseService } from '../../../services/database.service';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { Entity } from '../../../model/Entity';
import { OpinionLeader, opinionLeaderConverter } from '../../../model/opinion-leaders/opinion-leader';
import { SubmitHandler, useForm } from 'react-hook-form';
import { TRUE_FALSE, VOLUNTEERING_PROFILE } from '../../../model/options';
import { openToastAction } from '../../../state/actions';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import ConfirmDeleteModalBodyComponent from '../../modals-body/confirm-modal/confirm-delete-modal.component';
import Form from 'react-bootstrap/esm/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import ProblemsForm from '../problems-form';

type LeaderListProps = {
  leaders: OpinionLeader[];
  setLeaders: Dispatch<SetStateAction<OpinionLeader[]>>;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  refreshLeader: (id: string) => Promise<void>;
};

type FormValues = {
  id: string;
  name: string;
  description: string;
  linkedin: string;
  observations: string;
  twitter: string;
  contacted: string;
  contactResponsability: string;
  facebook: string;
  sites: string;
  focusArea: string;
  approved: boolean;
};

function OpinionLeadersList({ leaders, setLeaders, setIsEditing, refreshLeader }: LeaderListProps) {
  const [edit, setEdit] = useState<OpinionLeader>();
  const { dispatch } = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const [modalLeader, setModalLeader] = useState<OpinionLeader>();

  const validationSchema = Yup.object().shape({
    id: Yup.string().default(edit?.id),
    name: Yup.string().required('Nome é um campo obrigatório'),
    description: Yup.string(),
    linkedin: Yup.string(),
    observations: Yup.string(),
    twitter: Yup.string(),
    contacted: Yup.string(),
    contactResponsability: Yup.string(),
    facebook: Yup.string(),
    sites: Yup.string(),
    focusArea: Yup.string(),
    approved: Yup.boolean(),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  const onSubmit: SubmitHandler<FormValues> = async (formValues) => {
    const approved = complexForms.approved === TRUE_FALSE.true;
    try {
      const leader = new OpinionLeader(
        formValues.id.toString(),
        complexForms.problems,
        formValues.name,
        formValues.description,
        complexForms.academicBackground,
        formValues.linkedin,
        formValues.observations,
        formValues.twitter,
        formValues.contacted,
        formValues.contactResponsability,
        formValues.facebook,
        formValues.sites,
        formValues.focusArea,
        approved,
        edit?.createdAt,
        edit?.modifiedAt,
        edit?.createdById,
        edit?.createdByName
      );
      await DatabaseService.updateEntry(OpinionLeader.PATH, leader, opinionLeaderConverter);
      setEdit(undefined);
      setComplexForms(undefined);
      setIsEditing(false);
      setLeaders(leaders.map((l) => (l.id === leader.id ? leader : l)));
      reset();
      dispatch(openToastAction('Líder de opinião salvo com sucesso!', 'success'));
    } catch (error) {
      dispatch(openToastAction('Erro ao salvar o lider de opinião. Tente novamente mais tarde', 'danger'));
    }
  };

  const [complexForms, setComplexForms] = useState<any>({
    problems: '',
    academicBackground: '',
  });

  function handleEdit(leader: OpinionLeader) {
    setEdit(leader);
    setComplexForms({
      problems: leader.problems,
      academicBackground: leader.academicBackground,
    });
    setIsEditing(true);
  }

  async function reverseApproved(leader: OpinionLeader) {
    const copy = leader;
    try {
      copy.approved = !copy.approved;
      await DatabaseService.updateEntry<OpinionLeader>(OpinionLeader.PATH, copy, opinionLeaderConverter);
      dispatch(openToastAction('Líder de opinião alterado com sucesso.', 'success'));
    } catch (error) {
      dispatch(openToastAction('Erro ao alterar líder de opinião, tente novamente.', 'success'));
      copy.approved = !copy.approved;
    } finally {
      leader.approved = copy.approved;
      await refreshLeader(leader.id);
    }
  }

  async function deleteLeader() {
    if (!modalLeader) {
      setShowModal(false);
      return;
    }
    try {
      await DatabaseService.softDelete(
        OpinionLeader.PATH,
        OpinionLeader.COLLECTION_NAME,
        modalLeader.id,
        opinionLeaderConverter
      );
      setLeaders([...leaders.filter((l) => l.id !== modalLeader.id)]);
      dispatch(openToastAction('Líder de opinião apagada com sucesso.', 'success'));
    } catch (error) {
      dispatch(openToastAction('Erro ao apagar líder de opinião, tente novamente.', 'success'));
    }
    setModalLeader(undefined);
    setShowModal(false);
  }

  function descriptionHandler(description?: string) {
    if (!description) {
      return 'Sem descrição';
    }
    if (description.length > 200) {
      return description.substring(0, 200) + '...';
    }
    return description;
  }

  return (
    <div id="org-list">
      {edit && (
        <div className="p-2">
          <Button
            text="Voltar"
            size="medium"
            handleButtonPressed={() => {
              setEdit(undefined);
              setIsEditing(false);
            }}
          ></Button>
          <div id="edit" className="border rounded shadow-sm p-4 mb-3">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3" controlId="name">
                <InputGroup.Text id="basic-addon1">Nome</InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="nome"
                  {...register('name', { value: edit?.name })}
                  isInvalid={!!errors.name}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="description">
                <InputGroup.Text id="basic-addon1">Descrição</InputGroup.Text>
                <Form.Control
                  as="textarea"
                  rows={4}
                  placeholder="Descrição"
                  {...register('description', { value: edit?.description })}
                  isInvalid={!!errors.name}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="linkedin">
                <InputGroup.Text id="basic-addon1">Linkedin</InputGroup.Text>
                <Form.Control
                  as="textarea"
                  rows={2}
                  placeholder="Linkedin"
                  {...register('linkedin', { value: edit?.linkedin })}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="observations">
                <InputGroup.Text id="basic-addon1">Observações</InputGroup.Text>
                <Form.Control
                  as="textarea"
                  rows={2}
                  placeholder="Observações"
                  {...register('observations', { value: edit?.observations })}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="twitter">
                <InputGroup.Text id="basic-addon1">Twitter</InputGroup.Text>
                <Form.Control type="text" placeholder="Twitter" {...register('twitter', { value: edit?.twitter })} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="contacted">
                <InputGroup.Text id="basic-addon1">Contactado</InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="contactado?"
                  {...register('contacted', { value: edit?.contacted })}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="contactResponsability">
                <InputGroup.Text id="basic-addon1">Responsável pelo contacto</InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Descrição"
                  {...register('contactResponsability', { value: edit?.contactResponsability })}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="facebook">
                <InputGroup.Text id="basic-addon1">Facebook</InputGroup.Text>
                <Form.Control type="text" placeholder="Facebook" {...register('facebook', { value: edit?.facebook })} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="sites">
                <InputGroup.Text id="basic-addon1">Websites relevantes</InputGroup.Text>
                <Form.Control type="text" placeholder="Sites" {...register('sites', { value: edit?.sites })} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="focusArea">
                <InputGroup.Text id="basic-addon1">Áreas de foco</InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Áreas de foco"
                  {...register('focusArea', { value: edit?.focusArea })}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="academic-background">
                <Form.Label>Background Académico</Form.Label>
                <ListGroup>
                  {complexForms.academicBackground?.map((background: string, index: number) => {
                    return (
                      <ListGroup.Item key={index}>
                        {background}
                        <button
                          className="btn btn-primary small"
                          onClick={() => {
                            complexForms.academicBackground.splice(index, 1);
                            setComplexForms({
                              ...complexForms,
                              academicBackground: complexForms.academicBackground,
                            });
                          }}
                        >
                          Remover
                        </button>
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
                <Form.Select
                  aria-label="Background Académico"
                  onChange={(e) => {
                    if (!complexForms.academicBackground.includes(e.target.value)) {
                      complexForms.academicBackground.push(e.target.value);
                      setComplexForms({
                        ...complexForms,
                        academicBackground: complexForms.academicBackground,
                      });
                    }
                  }}
                >
                  {Object.values(VOLUNTEERING_PROFILE).map((profile, index) => {
                    return (
                      <option key={index} value={profile}>
                        {profile}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
              <p>Problemática social dominante do seu percurso</p>
              <ProblemsForm complexForm={complexForms} setComplexForm={setComplexForms}></ProblemsForm>
              <div>
                <button type="submit" disabled={!isValid} className="btn btn-primary mb-3">
                  Gravar
                </button>
              </div>
            </Form>
          </div>
        </div>
      )}
      {!edit && (
        <>
          {leaders.map((leader, index) => {
            return (
              <div className="border rounded shadow-lg p-3 mb-1" key={index}>
                <div className="row">
                  <div className="col">
                    <h4 className="mb-0">{leader.name}</h4>
                    <div>{descriptionHandler(leader.description)}</div>
                    <div>Criado: {Entity.fieldValueToString(leader.createdAt)}</div>
                    <div>Modificado: {Entity.fieldValueToString(leader.modifiedAt)}</div>
                    <div>Criado por: {leader.createdByName ? leader.createdByName : 'Sem utilizador definido'}</div>
                    <div>Aprovado: {leader.approved ? 'Sim' : 'Não'}</div>
                  </div>
                  <div className="col d-flex justify-content-end align-items-center bd-highlight ">
                    <Button
                      text="Detalhe"
                      outline={true}
                      size="small"
                      handleButtonPressed={() => (window.location.pathname = 'lideres-de-opiniao/' + leader.id)}
                    ></Button>
                    <Button
                      text="Editar"
                      outline={true}
                      size="small"
                      handleButtonPressed={() => handleEdit(leader)}
                    ></Button>
                    <Button
                      text="Aprovar/Rejeitar"
                      outline={true}
                      size="small"
                      handleButtonPressed={() => reverseApproved(leader)}
                    ></Button>
                    <Button
                      text="Apagar"
                      outline={true}
                      size="small"
                      handleButtonPressed={() => {
                        setModalLeader(leader);
                        setShowModal(true);
                      }}
                    ></Button>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
      <Modal
        setShowModal={setShowModal}
        showModal={showModal}
        title={'Tem a certeza que pretende apagar?'}
        Component={<ConfirmDeleteModalBodyComponent setShowModal={setShowModal} submitDelete={() => deleteLeader()} />}
      />
    </div>
  );
}

export default OpinionLeadersList;
