import { BsSearch } from 'react-icons/bs';
import { FormEvent, useEffect, useState } from 'react';

import './input-search.styles.scss';

type InputSearchProps = {
  searchButtonClicked: (text: string) => void;
  resetSearchTerm: boolean;
};

function InputSearch({ searchButtonClicked, resetSearchTerm }: InputSearchProps) {
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (resetSearchTerm) {
      setSearchTerm('');
      searchButtonClicked('');
    }
  }, [resetSearchTerm]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (searchTerm.length > 0) {
      searchButtonClicked(searchTerm);
    }
  };

  return (
    <div className="u-input-search">
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Pesquise aqui"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button type="submit" disabled={searchTerm.length === 0}>
          <BsSearch size={18} />
        </button>
      </form>
    </div>
  );
}

export default InputSearch;
