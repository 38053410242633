import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { useWindowSize } from 'react-use';
import Nav from 'react-bootstrap/Nav';

import { AppContext } from '../../state/app-state-context';

import academiaLogo from '../../images/apoios/academias.png';
import blueLogo from '../../images/blue-logo.png';
import facebookLogo from '../../images/redes-sociais/facebook.png';
import fundacaoLogo from '../../images/apoios/gulbenkian.png';
import inovacaoSocialLogo from '../../images/apoios/inovacao-social.png';
import instagramLogo from '../../images/redes-sociais/instagram.png';
import linkedinLogo from '../../images/redes-sociais/linkedin.png';
import lisboaLogo from '../../images/apoios/lisboa.png';
import poiseLogo from '../../images/apoios/poise.png';
import pt2020Logo from '../../images/apoios/pt2020.png';
import tiktokLogo from '../../images/redes-sociais/tiktok.png';
import ueLogo from '../../images/apoios/ue.png';

import './footer.styles.scss';

function Footer() {
  const {
    state: { learnWorlds },
  } = useContext(AppContext);
  const learnWorldsUrl = learnWorlds.url || 'https://udream.learnworlds.com/courses';

  return (
    <div className="footer-container padding-x">
      <div className="footer-container-first-line">
        <div className="footer-container-general-section">
          <img src={blueLogo} className="footer-container-logo" />
          <span className="footer-container-item">Rua Óscar da Silva Nº179, 4200-434</span>
          <span className="footer-container-item">+351 933 684 006</span>
          <span className="footer-container-item email">udream.portugal@udream.pt</span>
          <span className="footer-container-item email">apoio.cliente@udream.pt</span>
          <div className="footer-container-social-networks">
            <Nav.Link href="https://www.instagram.com/udream.pt" target="_blank">
              <img src={instagramLogo} alt="instagram logo" />
            </Nav.Link>
            <Nav.Link href="https://www.tiktok.com/@udream.pt" target="_blank">
              <img src={tiktokLogo} alt="tiktok logo" />
            </Nav.Link>
            <Nav.Link href="https://www.facebook.com/udream.pt" target="_blank">
              <img src={facebookLogo} alt="facebook logo" />
            </Nav.Link>
            <Nav.Link href="https://www.linkedin.com/company/udream-pt" target="_blank">
              <img src={linkedinLogo} alt="linkedin logo" />
            </Nav.Link>
          </div>
        </div>
        <div className="footer-container-sections">
          <div className="footer-container-section">
            <span className="footer-container-section-title">COMUNIDADE</span>
            {/*
            <Nav.Link className="footer-container-item" as={Link} to="/acoes-de-impacto">
              Ações de impacto
            </Nav.Link>
            <Nav.Link className="footer-container-item" as={Link} to="/lideres-de-opiniao">
              Líderes sociais
            </Nav.Link>
            */}
            <Nav.Link className="footer-container-item" as={Link} to="/voluntariado">
              Ações de voluntariado
            </Nav.Link>
            <Nav.Link className="footer-container-item" href={learnWorldsUrl}>
              Cursos e formações
            </Nav.Link>
          </div>
          <div className="footer-container-section">
            <span className="footer-container-section-title">U.DREAM</span>
            <Nav.Link className="footer-container-item" href="https://www.udream.pt/a-nossa-missao">
              A nossa missão
            </Nav.Link>
            <Nav.Link className="footer-container-item" href="https://www.udream.pt/o-que-fazemos">
              O que fazemos
            </Nav.Link>
            <Nav.Link className="footer-container-item" href="https://www.udream.pt/sobre-nos">
              Sobre nós
            </Nav.Link>
            <Nav.Link className="footer-container-item" href="https://www.udream.pt/as-nossas-historias">
              As nossas histórias
            </Nav.Link>
            <Nav.Link className="footer-container-item" href="https://www.udream.pt/contacto">
              Tira as tuas dúvidas
            </Nav.Link>
          </div>
          <div className="footer-container-section">
            <span className="footer-container-section-title">LEGAL</span>
            <Nav.Link className="footer-container-item" href="https://www.udream.pt/termos-e-condicoes">
              Termos & Condições
            </Nav.Link>
            <Nav.Link
              className="footer-container-item"
              href="https://issuu.com/udream.pt/docs/u.dream_relatorio_atividades_2021_2_"
            >
              Relatório de Atividades
            </Nav.Link>
            <Nav.Link
              className="footer-container-item"
              href="https://drive.google.com/file/d/1vhnyvcfBiuHQau2NPXWN0gX6Tsn5iM6a/view"
            >
              Relatório de Gestão e Contas
            </Nav.Link>
            <Nav.Link className="footer-container-item" href="https://comunidade.udream.pt">
              Relatório de Impacto
            </Nav.Link>
          </div>
        </div>
      </div>
      <div className="footer-container-second-line">
        <img src={pt2020Logo} alt="Portugal 2020 logo" />
        <img src={poiseLogo} alt="Programa operacional inclusão social e emprego logo" />
        <img src={lisboaLogo} alt="Lisboa logo" />
        <img src={inovacaoSocialLogo} alt="Portugal inovação social logo" />
        <img src={ueLogo} alt="União europeia logo" />
        <img src={fundacaoLogo} alt="Fundação Calouste Gulbenkian logo" />
        <img src={academiaLogo} alt="Programa Academias Gulbenkian logo" />
      </div>
      <div className="footer-container-third-line">
        <p>U.DREAM © 2024 ALL RIGHTS RESERVED</p>
      </div>
    </div>
  );
}

export default Footer;
