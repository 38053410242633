import BootstrapForm from 'react-bootstrap/Form';
import ReactDatePicker from 'react-datepicker';

import { range } from '../../utils/helpers.util';

type DatePickerProps = {
  isInvalid: boolean;
  field: Date;
  onChange: (...event: any[]) => void;
};

function Datepicker({ isInvalid, field, onChange }: DatePickerProps) {
  const currentYear = new Date().getFullYear();
  const years = range(currentYear - 100, currentYear);
  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];
  return (
    <ReactDatePicker
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            margin: 10,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            {'<'}
          </button>
          <select
            value={date.getFullYear()}
            onChange={({ target: { value } }) => changeYear(new Date(value).getFullYear())}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
            value={months[date.getMonth()]}
            onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            {'>'}
          </button>
        </div>
      )}
      placeholderText="Data de nascimento"
      selected={field}
      onChange={(date) => onChange(date)}
      customInput={<BootstrapForm.Control type="text" className="custom-input" isInvalid={isInvalid} />}
      maxDate={new Date()}
      dateFormat="dd/MM/yyyy"
    />
  );
}

export default Datepicker;
