import { DocumentData, FieldValue, QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';

// Components
import { Entity } from '../Entity';
import { FirestoreConverter } from '../../interfaces/database.interface';

export class Tag extends Entity {
  public static PATH = 'tags/tags';
  public static PREFIX = 'tag';

  title: string;
  usersIds: string[];

  constructor(
    title: string,
    usersIds: string[] = [],
    id: string = Tag.generateId(Tag.PREFIX),
    createdAt?: FieldValue,
    createdById?: string,
    createdByName?: string
  ) {
    super(id, Tag.PREFIX, createdAt, undefined, createdById, createdByName);
    this.title = title;
    this.usersIds = usersIds;
  }
}

export const tagConverter: FirestoreConverter<Tag> = {
  toFirestore: function (tag: Tag): DocumentData {
    return {
      id: tag.id,
      createdAt: tag.createdAt,
      createdById: tag.createdById,
      createdByName: tag.createdByName,
      title: tag.title,
      usersIds: tag.usersIds,
    };
  },
  fromFirestore: function (snapshot: QueryDocumentSnapshot<DocumentData>, options?: SnapshotOptions | undefined): Tag {
    return (options ? snapshot.data(options) : snapshot.data()) as Tag;
  },
};
