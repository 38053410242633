import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { Variant } from 'react-bootstrap/types';

// Components
import { Action } from '../../state/types';
import { AppContext } from '../../state/app-state-context';
import { Button } from '../../components';
import { DatabaseService } from '../../services/database.service';
import { Feedback } from '../../model/feedback/feedback';
import { FeedbackType } from '../../enums/feedback-type.enum';
import { OpinionLeader, opinionLeaderConverter } from '../../model/opinion-leaders/opinion-leader';
import { Organization, organizationConverter } from '../../model/main/organization';
import { Project, projectConverter } from '../../model/main/project';
import { openToastAction } from '../../state/actions';
import { trimStringArray } from '../../utils/helpers.util';
import FeedbackFieldComparator from '../../components/entity-list/feedback-field-comparator.component';

type EditFeedbackProps = {
  feedback: Feedback<OpinionLeader | Project | Organization>;
  setFeedback: Dispatch<SetStateAction<Feedback<OpinionLeader | Project | Organization> | undefined>>;
  currentFeedback: Feedback<OpinionLeader | Project | Organization>[];
  setCurrentFeedback: Dispatch<SetStateAction<Feedback<OpinionLeader | Project | Organization>[]>>;
};

function projectComparison(entity: Project, compareEntity: Project, user?: string) {
  return (
    <div className="border rounded">
      <div className="m-3">Sugerido por: {user || 'Sem utilizador definido'}</div>
      <div className="container">
        <div className="row align-items-center">
          <h1 className="col">Atual</h1>
          <h1 className="col">Sugestão</h1>
        </div>
      </div>
      <FeedbackFieldComparator
        title={'Organização'}
        feedbackField={entity.orgName}
        entityField={compareEntity.orgName}
      />
      <FeedbackFieldComparator title={'Projeto'} feedbackField={entity.name} entityField={compareEntity.name} />
      <FeedbackFieldComparator title={'Missão'} feedbackField={entity.mission} entityField={compareEntity.mission} />
      <FeedbackFieldComparator
        title={'Atividade'}
        feedbackField={entity.activity}
        entityField={compareEntity.activity}
      />
      <FeedbackFieldComparator title={'Website'} feedbackField={entity.site} entityField={compareEntity.site} />
      <FeedbackFieldComparator title={'Email'} feedbackField={entity.email} entityField={compareEntity.email} />
      <FeedbackFieldComparator
        title={'Contactos'}
        feedbackField={entity.phoneInfo}
        entityField={compareEntity.phoneInfo}
      />
      <FeedbackFieldComparator
        title={'Observações'}
        feedbackField={entity.observations}
        entityField={compareEntity.observations}
      />
      <FeedbackFieldComparator
        title={'Aceita Voluntários'}
        feedbackField={entity.acceptsVolunteering}
        entityField={compareEntity.acceptsVolunteering}
      />
      <FeedbackFieldComparator
        title={'Atividade do voluntário'}
        feedbackField={entity.volunteeringDescription}
        entityField={compareEntity.volunteeringDescription}
      />
      <FeedbackFieldComparator
        title={'Regiões'}
        feedbackField={JSON.stringify(trimStringArray(entity.regions), null, '\t') || ''}
        entityField={JSON.stringify(trimStringArray(compareEntity.regions), null, '\t') || ''}
      />
      <FeedbackFieldComparator
        title={'Público Alvo'}
        feedbackField={JSON.stringify(trimStringArray(entity.targetAudience), null, '\t') || ''}
        entityField={JSON.stringify(trimStringArray(compareEntity.targetAudience), null, '\t') || ''}
      />
      <FeedbackFieldComparator
        title={'Regiões'}
        feedbackField={JSON.stringify(trimStringArray(entity.services), null, '\t') || ''}
        entityField={JSON.stringify(trimStringArray(compareEntity.services), null, '\t') || ''}
      />
      <FeedbackFieldComparator
        title={'ODS'}
        feedbackField={JSON.stringify(entity.sustainabilityObjectives, null, '\t') || ''}
        entityField={JSON.stringify(compareEntity.sustainabilityObjectives, null, '\t') || ''}
      />
      <FeedbackFieldComparator
        title={'Problemas Sociais'}
        feedbackField={JSON.stringify(entity.problems, null, '\t') || ''}
        entityField={JSON.stringify(compareEntity.problems, null, '\t') || ''}
      />
      <FeedbackFieldComparator
        title={'Serviços Profissionais'}
        feedbackField={JSON.stringify(entity.problems, null, '\t') || ''}
        entityField={JSON.stringify(compareEntity.problems, null, '\t') || ''}
      />
    </div>
  );
}

function leaderComparison(entity: OpinionLeader, compareEntity: OpinionLeader, user?: string) {
  return (
    <div className="border rounded">
      <div className="m-3">Sugerido por: {user || 'Sem utilizador definido'}</div>
      <div className="container">
        <div className="row align-items-center">
          <h1 className="col">Atual</h1>
          <h1 className="col">Sugestão</h1>
        </div>
      </div>
      <FeedbackFieldComparator title={'Nome'} feedbackField={entity.name} entityField={compareEntity.name} />
      <FeedbackFieldComparator
        title={'Descrição'}
        feedbackField={entity.description}
        entityField={compareEntity.description}
      />
      <FeedbackFieldComparator
        title={'Observações'}
        feedbackField={entity.observations}
        entityField={compareEntity.observations}
      />
      <FeedbackFieldComparator
        title={'Áreas de Foco'}
        feedbackField={entity.focusArea}
        entityField={compareEntity.focusArea}
      />
      <FeedbackFieldComparator
        title={'Linkedin'}
        feedbackField={entity.linkedin}
        entityField={compareEntity.linkedin}
      />
      <FeedbackFieldComparator title={'Twitter'} feedbackField={entity.twitter} entityField={compareEntity.twitter} />
      <FeedbackFieldComparator
        title={'Facebook'}
        feedbackField={entity.facebook}
        entityField={compareEntity.facebook}
      />
      <FeedbackFieldComparator
        title={'Contactado'}
        feedbackField={entity.contacted}
        entityField={compareEntity.contacted}
      />
      <FeedbackFieldComparator
        title={'Responsavel pelo Contato'}
        feedbackField={entity.contactResponsability}
        entityField={compareEntity.contactResponsability}
      />
      <FeedbackFieldComparator
        title={'Background Académico'}
        feedbackField={JSON.stringify(entity.academicBackground, null, '\t') || ''}
        entityField={JSON.stringify(compareEntity.academicBackground, null, '\t') || ''}
      />
      <FeedbackFieldComparator
        title={'Problemas Sociais'}
        feedbackField={JSON.stringify(entity.problems, null, '\t') || ''}
        entityField={JSON.stringify(compareEntity.problems, null, '\t') || ''}
      />
      <FeedbackFieldComparator
        title={'Site(s) Úteis'}
        feedbackField={JSON.stringify(entity.sites, null, '\t') || ''}
        entityField={JSON.stringify(compareEntity.sites, null, '\t') || ''}
      />
    </div>
  );
}

function orgComparison(entity: Organization, compareEntity: Organization, user?: string) {
  return (
    <div className="border rounded">
      <div className="m-3">Sugerido por: {user || 'Sem utilizador definido'}</div>
      <div className="container">
        <div className="row align-items-center">
          <h1 className="col">Atual</h1>
          <h1 className="col">Sugestão</h1>
        </div>
      </div>
      <FeedbackFieldComparator title={'Nome'} feedbackField={entity.orgName} entityField={compareEntity.orgName} />
    </div>
  );
}

function EditFeedback(props: EditFeedbackProps) {
  const [compareEntity, setCompareEntity] = useState<OpinionLeader | Project | Organization>();
  const { dispatch } = useContext(AppContext);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    fetchEntity();
  }, []);

  const fetchEntity = async () => {
    let entity;
    try {
      switch (props.feedback.type) {
        case FeedbackType.OpinionLeader:
          entity = await DatabaseService.getById<OpinionLeader>(
            OpinionLeader.PATH,
            props.feedback.entity.id,
            opinionLeaderConverter
          );
          break;
        case FeedbackType.Project:
          entity = await DatabaseService.getById<Project>(Project.PATH, props.feedback.entity.id, projectConverter);
          break;
        case FeedbackType.Organization:
          entity = await DatabaseService.getById<Organization>(
            Organization.PATH,
            props.feedback.entity.id,
            organizationConverter
          );
          break;
        default:
          throw new Error('Invalid feedback type');
      }
      if (!entity) {
        throw new Error('Entity not found with feedback id' + props.feedback.entity.id);
      }
      setCompareEntity(entity);
      setIsLoaded(true);
    } catch (error) {
      dispatch(openToastAction('Não foi possível carregar o feedback', 'danger'));
    }
  };

  function drawComparison() {
    switch (props.feedback.type) {
      case FeedbackType.OpinionLeader:
        return leaderComparison(
          props.feedback.entity as OpinionLeader,
          compareEntity as OpinionLeader,
          props.feedback.createdByName
        );
      case FeedbackType.Project:
        return projectComparison(
          props.feedback.entity as Project,
          compareEntity as Project,
          props.feedback.createdByName
        );
      case FeedbackType.Organization:
        return orgComparison(
          props.feedback.entity as Organization,
          compareEntity as Organization,
          props.feedback.createdByName
        );
    }
  }

  async function deleteFeedback(inputFeedback: Feedback<OpinionLeader | Project | Organization>) {
    switch (inputFeedback.type) {
      case FeedbackType.OpinionLeader:
        await DatabaseService.hardDelete(Feedback.OPINION_LEADER_PATH, inputFeedback.id);
        break;
      case FeedbackType.Project:
        await DatabaseService.hardDelete(Feedback.PROJECT_PATH, inputFeedback.id);
        break;
      case FeedbackType.Organization:
        await DatabaseService.hardDelete(Feedback.ORGANIZATION_PATH, inputFeedback.id);
        break;
    }
    setCompareEntity(undefined);
    props.setFeedback(undefined);
  }

  async function approveFeedback(inputFeedback: Feedback<OpinionLeader | Project | Organization>) {
    try {
      switch (inputFeedback.type) {
        case FeedbackType.OpinionLeader:
          await DatabaseService.updateEntry(
            OpinionLeader.PATH,
            inputFeedback.entity as OpinionLeader,
            opinionLeaderConverter
          );
          break;
        case FeedbackType.Project:
          await DatabaseService.updateEntry(Project.PATH, inputFeedback.entity as Project, projectConverter);
          break;
        case FeedbackType.Organization:
          await DatabaseService.updateEntry(
            Organization.PATH,
            inputFeedback.entity as Organization,
            organizationConverter
          );
          break;
      }
      await deleteFeedback(inputFeedback);
      dispatch(openToastAction('Feedback aprovado com sucesso.', 'success'));
      const aux = [...props.currentFeedback.filter((feedback) => feedback.id !== inputFeedback.id)];
      props.setCurrentFeedback(aux);
    } catch (error) {
      dispatch(openToastAction('Não foi possível aprovar o feedback', 'danger'));
    }
  }

  return (
    <div>
      {isLoaded && <div>{drawComparison()}</div>}
      <div className="d-flex justify-content-center">
        <Button
          text="Aprovar"
          outline={false}
          size="medium"
          handleButtonPressed={() => approveFeedback(props.feedback)}
        ></Button>
      </div>
    </div>
  );
}

export default EditFeedback;
