import * as Yup from 'yup';
import { Dispatch, SetStateAction, useContext } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import { AppContext } from '../../../state/app-state-context';
import { EmailService } from '../../../services';
import { openToastAction } from '../../../state/actions';

type SendEmailsModalProps = {
  emailList: string[];
  setShowModal: Dispatch<SetStateAction<boolean>>;
  setEmailList: Dispatch<SetStateAction<string[]>>;
};

type FormValues = {
  subject: string;
  bodyText: string;
};

const formValuesValidationSchema = Yup.object().shape({
  subject: Yup.string().required('Assunto é um campo obrigatório!'),
  bodyText: Yup.string().required('Corpo do email é um campo obrigatório!'),
});

function SendEmailsModal({ emailList, setShowModal, setEmailList }: SendEmailsModalProps) {
  const { dispatch } = useContext(AppContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({ mode: 'onSubmit', resolver: yupResolver(formValuesValidationSchema) });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      // Send messages with new lines with sendgrid
      // https://stackoverflow.com/a/60825296/10975038
      const bodyTextWithNewLines = data.bodyText.replace(/[\n\r]/g, '<br>');
      dispatch(openToastAction('A enviar email...', 'info'));
      setShowModal(false);
      await EmailService.send(data.subject, bodyTextWithNewLines, emailList);
      dispatch(openToastAction('Email enviado com sucesso!', 'success'));
    } catch {
      dispatch(openToastAction('Não foi possível enviar email', 'danger'));
    } finally {
      setEmailList([]);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Body>
        <Form.Group className="mb-3" controlId="formSubject">
          <Form.Label visuallyHidden>Assunto</Form.Label>
          <Form.Control type="text" placeholder="Assunto" {...register('subject')} isInvalid={!!errors.subject} />
          <Form.Control.Feedback type="invalid">{errors.subject?.message}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBodyText">
          <Form.Label visuallyHidden>Corpo do email</Form.Label>
          <Form.Control as="textarea" rows={8} {...register('bodyText')} isInvalid={!!errors.bodyText} />
          <Form.Control.Feedback type="invalid">{errors.bodyText?.message}</Form.Control.Feedback>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Fechar
        </Button>
        <Button type="submit" className="text-white">
          Enviar email
        </Button>
      </Modal.Footer>
    </Form>
  );
}

export default SendEmailsModal;
