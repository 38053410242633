import { AuthService } from '../../services';
import { Navigate } from 'react-router-dom';
import { useEffect } from 'react';

function SignOut() {
  useEffect(() => {
    const logout = async () => {
      await AuthService.signOut();
    };

    logout();
  }, []);

  return <Navigate to={{ pathname: '/' }} />;
}

export default SignOut;
