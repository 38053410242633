import { Dispatch, useState } from 'react';
import { PROFESSIONAL_CATEGORIES } from '../../model/options';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';

interface ProblemProps {
  complexForm: any;
  setComplexForm: Dispatch<any>;
}

function getProfessionalServices(professionalServiceChoice: string): string[] {
  let services: string[] = [];
  Object.values(PROFESSIONAL_CATEGORIES).forEach((entries) => {
    if (entries.area === professionalServiceChoice) {
      if (entries.professions) {
        services = entries.professions;
      }
    }
  });
  return services;
}

function ProfessionalServicesForm({ complexForm, setComplexForm }: ProblemProps) {
  const [professionalServiceChoice, setProfessionalServiceChoice] = useState<string>();

  return (
    <Form.Group className="mb-3" controlId="professionals">
      <p>Serviços Profissionais</p>
      <Table className={'table table-hover table-responsive table-striped table-bordered"'}>
        <tbody>
          {Object.entries(complexForm.professionalServices).map(([professionalGroup, services], index) => {
            return (
              <tr key={index}>
                <td className="fw-bold">
                  {professionalGroup}
                  <Button
                    variant="warning"
                    size="sm"
                    onClick={() => {
                      delete complexForm.professionalServices[professionalGroup];
                      setComplexForm({
                        ...complexForm,
                        professionalServices: complexForm.professionalServices,
                      });
                    }}
                  >
                    🗑️
                  </Button>
                </td>
                {(services as string[]).map((service, i) => {
                  return (
                    <td key={i}>
                      {service}
                      <Button
                        variant="warning"
                        size="sm"
                        onClick={() => {
                          complexForm.professionalServices[professionalGroup].splice(i, 1);
                          setComplexForm({
                            ...complexForm,
                            professionalServices: complexForm.professionalServices,
                          });
                        }}
                      >
                        {'   '}🗑️
                      </Button>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Form.Select
        aria-label="professional-services-group"
        onChange={(e) => {
          setProfessionalServiceChoice(e.target.value);
        }}
      >
        {Object.values(PROFESSIONAL_CATEGORIES).map((service, index) => {
          return (
            <option key={index} value={service.area}>
              {service.area}
            </option>
          );
        })}
      </Form.Select>
      <Form.Select
        aria-label="professional-service"
        onChange={(e) => {
          const aux = new Map(Object.entries(complexForm.professionalServices)) as Map<string, string[]>;
          if (professionalServiceChoice && aux) {
            if (aux.get(professionalServiceChoice)) {
              aux.get(professionalServiceChoice)?.push(e.target.value);
            } else {
              aux.set(professionalServiceChoice, [e.target.value]);
            }
            setComplexForm({ ...complexForm, professionalServices: Object.fromEntries(aux) });
          }
        }}
      >
        {professionalServiceChoice &&
          getProfessionalServices(professionalServiceChoice).map((service, index) => {
            return (
              <option key={index} value={service}>
                {service}
              </option>
            );
          })}
      </Form.Select>
    </Form.Group>
  );
}

export default ProfessionalServicesForm;
