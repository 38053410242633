import {
  QueryConstraint,
  collection,
  deleteDoc,
  doc,
  endBefore,
  query as firestoreQuery,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  limitToLast,
  orderBy,
  setDoc,
  startAfter,
  updateDoc,
  where,
} from 'firebase/firestore';

// Components
import { GetUsersOptions } from '../interfaces/get-users-options.interface';
import { Role } from '../enums/role.enum';
import { User } from '../interfaces/user.interface';
import { UserCollectionPath } from '../interfaces/user.interface';
import { UserFilter } from '../interfaces/user-filter.interface';
import { UserHelper } from '../interfaces/user-helper.interface';
import { UserPaginationOptions } from '../interfaces/user-pagination-options.interface';
import { db as firebaseDb } from '../firebase-app';

const PAGE_SIZE = Number(process.env.REACT_APP_USERS_DASHBOARD_ADMIN_PAGE_SIZE ?? 20);
const USERS_HELPER_DOCUMENT_NAME = 'helper';

const UserService = {
  creaseUserInDatabase: async (user: User) => {
    await setDoc(doc(firebaseDb, UserCollectionPath, user.id), user);
  },
  getUser: async (userUid: string) => {
    const docRef = doc(firebaseDb, UserCollectionPath, userUid);
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? (docSnap.data() as User) : null;
  },
  getUsers: async function (userOptions: GetUsersOptions) {
    const { pagination, userFilter } = userOptions;

    const collectionRef = collection(firebaseDb, UserCollectionPath);

    const paginationConstraints = buildPaginationConstraints(pagination);
    const conditionalConstraints = userFilter ? buildConditionalConstraints(userFilter) : [];

    const query = firestoreQuery(collectionRef, ...paginationConstraints, ...conditionalConstraints);
    const queryCount = firestoreQuery(collectionRef, ...conditionalConstraints);

    let count: number;
    if (userFilter) {
      count = (await getCountFromServer(queryCount)).data().count;
    } else {
      // -1 because of the usersHelper document
      count = (await getCountFromServer(queryCount)).data().count - 1;
    }

    const docSnaps = await getDocs(query);

    if (docSnaps.empty) {
      return {
        users: [],
        total: count,
      };
    }

    const users = docSnaps.docs.filter((doc) => doc.id !== USERS_HELPER_DOCUMENT_NAME).map((doc) => doc.data() as User);

    return {
      users,
      total: count,
    };
  },
  changeRole: async function (userId: string, role: Role) {
    const userRef = doc(firebaseDb, UserCollectionPath, userId);
    await updateDoc(userRef, { role });
  },
  deleteUser: async function (userId: string) {
    const userRef = doc(firebaseDb, UserCollectionPath, userId);
    await deleteDoc(userRef);
  },
  getUsersHelper: async function () {
    const userHelperRef = doc(firebaseDb, UserCollectionPath, USERS_HELPER_DOCUMENT_NAME);
    const docSnap = await getDoc(userHelperRef);

    return docSnap.exists() ? (docSnap.data() as UserHelper) : null;
  },
  updateUser: async function (user: User) {
    const userRef = doc(firebaseDb, UserCollectionPath, user.id);
    await updateDoc(userRef, { ...user });
  },
};

export default UserService;

const buildConditionalConstraints = (userFilter: UserFilter) => {
  const conditionalConstraints: QueryConstraint[] = [];

  if (userFilter.district.length > 0) {
    conditionalConstraints.push(where('district', '==', userFilter.district));
  }

  if (userFilter.city.length > 0) {
    conditionalConstraints.push(where('city', '==', userFilter.city));
  }

  if (userFilter.role.length > 0) {
    conditionalConstraints.push(where('role', '==', userFilter.role));
  }

  if (userFilter.keyword.length > 0) {
    conditionalConstraints.push(where('keywords', 'array-contains', userFilter.keyword));
  }

  if (userFilter.usersId.length > 0) {
    conditionalConstraints.push(where('id', 'in', userFilter.usersId));
  }

  return conditionalConstraints;
};

const buildPaginationConstraints = (paginationOptions: UserPaginationOptions) => {
  const pageSize = paginationOptions.pageSize ?? PAGE_SIZE;
  const orderByConstraintField = 'name';
  const paginationConstraints: QueryConstraint[] = [];

  switch (paginationOptions.type) {
    case 'init':
      if (typeof pageSize === 'string' && pageSize === 'all') {
        paginationConstraints.push(orderBy(orderByConstraintField));
      } else {
        paginationConstraints.push(orderBy(orderByConstraintField), limit(pageSize));
      }
      break;
    case 'backward':
      if (typeof pageSize === 'number') {
        paginationConstraints.push(
          orderBy(orderByConstraintField),
          endBefore(paginationOptions.moveBackward?.endBefore),
          limitToLast(pageSize)
        );
      }

      break;
    case 'forward':
      if (typeof pageSize === 'number') {
        paginationConstraints.push(
          orderBy(orderByConstraintField),
          startAfter(paginationOptions.moveForward?.startAfter),
          limit(pageSize)
        );
      }

      break;
  }

  return paginationConstraints;
};
