import { ReactNode, useContext } from 'react';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';

// Components
import AddIcon from '../icons/add-icon/add-icon.component';
import RemoveIcon from '../icons/remove-icon/remove-icon.component';

import './accordion-toggle.component.scss';

type AccordionToggleProps = { children: ReactNode; eventKey: string };

function AccordionToggle({ children, eventKey }: AccordionToggleProps) {
  const { activeEventKey } = useContext(AccordionContext);

  const isCurrentEventKey = activeEventKey === eventKey;

  const decoratedOnClick = useAccordionButton(eventKey);

  return (
    <div
      className="d-flex justify-content-between align-items-center accordion-toggle"
      onClick={decoratedOnClick}
      role="button"
    >
      <span className="mb-0">{children}</span>
      <div className="d-flex justify-content-between align-items-center">
        {isCurrentEventKey ? <RemoveIcon /> : <AddIcon />}
      </div>
    </div>
  );
}

export default AccordionToggle;
