import { ComplexFormProps } from './complex-form.interface';
import { SERVICES } from '../../model/options';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';

function ServicesForm({ complexForm, setComplexForm }: ComplexFormProps) {
  return (
    <Form.Group className="mb-3" controlId="services">
      <Form.Label>Serviços</Form.Label>
      <ListGroup>
        {complexForm.services.map((service: string, index: number) => {
          return (
            <ListGroup.Item key={index}>
              {service}
              <Button
                size={'sm'}
                onClick={() => {
                  complexForm.services.splice(index, 1);
                  setComplexForm({ ...complexForm, services: complexForm.services });
                }}
              >
                Remover
              </Button>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
      <Form.Select
        aria-label="Serviços"
        onChange={(e) => {
          if (!complexForm.services.includes(e.target.value)) {
            complexForm.services.push(e.target.value);
            setComplexForm({ ...complexForm, services: complexForm.services });
          }
        }}
      >
        {Object.values(SERVICES).map((service, index) => {
          return (
            <option key={index} value={service}>
              {service}
            </option>
          );
        })}
      </Form.Select>
    </Form.Group>
  );
}

export default ServicesForm;
