import { ComplexFormProps } from './complex-form.interface';
import { VOLUNTEERING_PROFILE } from '../../model/options';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';

function VolunteeringProfileForm({ complexForm, setComplexForm }: ComplexFormProps) {
  return (
    <Form.Group className="mb-3" controlId="volunteering-profile">
      <Form.Label>Perfil de voluntariado</Form.Label>
      <ListGroup>
        {complexForm.volunteeringProfile.map((profile: string, index: number) => {
          return (
            <ListGroup.Item key={index}>
              {profile}
              <Button
                size={'sm'}
                onClick={() => {
                  complexForm.volunteeringProfile.splice(index, 1);
                  setComplexForm({
                    ...complexForm,
                    volunteeringProfile: complexForm.volunteeringProfile,
                  });
                }}
              >
                Remover
              </Button>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
      <Form.Select
        aria-label="Perfil Voluntariado"
        onChange={(e) => {
          if (!complexForm.volunteeringProfile.includes(e.target.value)) {
            complexForm.volunteeringProfile.push(e.target.value);
            setComplexForm({ ...complexForm, volunteeringProfile: complexForm.volunteeringProfile });
          }
        }}
      >
        {Object.values(VOLUNTEERING_PROFILE).map((profile, index) => {
          return (
            <option key={index} value={profile}>
              {profile}
            </option>
          );
        })}
      </Form.Select>
    </Form.Group>
  );
}

export default VolunteeringProfileForm;
