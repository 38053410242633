import './details-page-feedback.component.scss';
import NewButton from '../button/new-button.component';

export default function DetailsPageFeedback({ onClickHandler }: { onClickHandler: () => void }) {
  return (
    <div className="details-page-feedback">
      <p>Esta informação está incompleta ou desatualizada?</p>
      <NewButton theme="beige" accent="green" outlined={true} size="sm" onClick={onClickHandler}>
        Deixa feedback
      </NewButton>
    </div>
  );
}
