import { slide as Menu } from 'react-burger-menu';
import { ReactNode, useContext } from 'react';
import { useWindowSize } from 'react-use';

// Components
import { AppContext } from '../../state/app-state-context';
import { ScreenBreakpoint } from '../../enums/screen-breakpoint.enum';
import { closeSidebarAction } from '../../state/actions';
import CloseIcon from '../icons/close-icon/close-icon.component';

// Styles
import './sidebar.component.scss';

type SidebarProps = { children: ReactNode };

function Sidebar({ children }: SidebarProps) {
  const { width } = useWindowSize();
  const {
    dispatch,
    state: {
      sidebar: { open },
    },
  } = useContext(AppContext);

  const handleOnClose = () => dispatch(closeSidebarAction());

  return (
    <Menu
      right
      pageWrapId="page-wrap"
      outerContainerId="outer-container"
      customBurgerIcon={false}
      customCrossIcon={false}
      isOpen={open}
      disableOverlayClick
      width={width <= ScreenBreakpoint.Large ? '100%' : '608px'}
      menuClassName="bg-white px-4 py-5"
    >
      <div className="d-flex justify-content-between">
        <h3 className="c-title">Filtros</h3>
        <div onClick={handleOnClose}>
          <CloseIcon outerClasses="c-close-icon" />
        </div>
      </div>
      {children}
    </Menu>
  );
}

export default Sidebar;
