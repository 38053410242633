import { SearchApiOptions, SearchApiResponse } from '../interfaces/elastic/search-api.interface';
import { auth } from '../firebase-app';
import ky from 'ky';

const API_URL = process.env.REACT_APP_API_URL;

export const ElasticService = {
  /* Searches for a string in a document, querying all fields in the document that are similar to the string
        
          @param string - The name of the collection to search in
          @param engine - the engine to search in
        
          @returns the DTOs that match the search query
          */
  search: async (options: SearchApiOptions) => {
    try {
      const userIdToken = await auth.currentUser?.getIdToken();
      const res = await ky
        .post(API_URL + '/elastic/search', {
          json: options,
          headers: { Authorization: 'Bearer ' + userIdToken },
        })
        .json();
      return res as SearchApiResponse;
    } catch (error) {
      console.error(error);
    }
  },
};
