interface FeedbackFieldComparatorProps {
  title: string;
  feedbackField?: string;
  entityField?: string;
}

function FeedbackFieldComparator({ title, feedbackField, entityField }: FeedbackFieldComparatorProps) {
  return (
    <div>
      <h5 className="m-2">{title}</h5>
      <div className="container">
        <div className="row align-items-center">
          {feedbackField === entityField ? (
            <>
              <div className="col">{entityField ? entityField : '(Não definido)'}</div>
              <div className="col">{feedbackField ? feedbackField : '(Não definido)'}</div>
            </>
          ) : (
            <>
              <div className="col text-danger">{entityField ? entityField : '(Não definido)'}</div>
              <div className="col text-success">{feedbackField ? feedbackField : '(Não definido)'}</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default FeedbackFieldComparator;
