import './details-page-header.component.scss';

export default function DetailsPageHeader({
  sectionTitle,
  title,
  subTitle,
  description,
}: {
  sectionTitle: string;
  title: string | undefined;
  subTitle?: string | undefined;
  description?: string | undefined;
}) {
  return (
    <div className="details-page-header">
      <div className="details-page-header-section-title">{sectionTitle}</div>
      <div className="details-page-header-title">{title}</div>
      {subTitle && title != subTitle ? <div className="details-page-header-subtitle">{subTitle}</div> : <></>}
      {description ? <div className="details-page-header-description">{description}</div> : <></>}
    </div>
  );
}
