import { BsSearch } from 'react-icons/bs';
import { Fragment, useContext, useState } from 'react';
import { SearchBox, SearchProvider } from '@elastic/react-search-ui';
import { SearchDriverOptions } from '@elastic/search-ui';
import { useEffectOnce } from 'react-use';
import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector';

import { AppContext } from '../../state/app-state-context';
import { Role } from '../../enums/role.enum';
import { assign } from '../../utils/helpers.util';

import '@elastic/react-search-ui-views/lib/styles/styles.css';
import styles from './elastic-search-box.component.module.scss';

interface SearchBoxProps {
  fields: string[];
  titleField: string;
  engine: string;
  callback: (result: unknown) => void;
}

const ENDPOINT_BASE = process.env.REACT_APP_ELASTIC_ENDPOINT_BASE;
const SEARCH_KEY = process.env.REACT_APP_ELASTIC_PUBLIC_SEARCH_KEY;

export default function ElasticSearchBoxComponent({ fields, titleField, engine, callback }: SearchBoxProps) {
  const {
    state: { currentFirestoreUser },
  } = useContext(AppContext);
  const [config, setConfig] = useState<SearchDriverOptions>({
    alwaysSearchOnInitialLoad: true,
    apiConnector: new AppSearchAPIConnector({
      searchKey: SEARCH_KEY,
      engineName: engine,
      endpointBase: ENDPOINT_BASE || '',
    }),
    hasA11yNotifications: true,
    searchQuery: {
      disjunctiveFacets: [''],
      facets: {},
    },
  });

  useEffectOnce(() => {
    const configuration = setUpConfig();
    setConfig(configuration);
  });

  function setUpConfig() {
    if (currentFirestoreUser?.role !== Role.Admin && config.searchQuery) {
      config.searchQuery.filters = [{ field: 'approved', values: ['true'], type: 'all' }];
    }

    fields.forEach((field) => {
      assign(config.searchQuery, ['result_fields', field, 'raw'], {});
      assign(config.searchQuery, ['search_fields', field], {});
    });
    return config;
  }

  return (
    <SearchProvider config={config}>
      <SearchBox
        autocompleteResults={{
          titleField: titleField,
          urlField: 'nps_link',
        }}
        autocompleteMinimumCharacters={2}
        onSelectAutocomplete={(result: unknown) => {
          callback(result);
        }}
        inputView={({ getAutocomplete, getInputProps }) => (
          <Fragment>
            <div className={`${styles['c-input-search']} d-flex justify-content-between`}>
              <input
                {...getInputProps({
                  placeholder: 'Pesquise aqui',
                  className: 'border-0 px-0 w-100',
                })}
              />
              <div className="ms-2 d-flex align-items-center" role="button">
                <BsSearch size={18} />
              </div>
            </div>
            {getAutocomplete()}
          </Fragment>
        )}
      />
    </SearchProvider>
  );
}
