export enum ActionTypes {
  SET_CURRENT_AUTH_USER = 'SET_CURRENT_AUTH_USER',
  SET_CURRENT_FIRESTORE_USER = 'SET_CURRENT_FIRESTORE_USER',
  FINISH_APP_LOADING = 'FINISH_APP_LOADING',
  SIDEBAR_OPEN = 'SIDEBAR_OPEN',
  SIDEBAR_CLOSE = 'SIDEBAR_CLOSE',
  LOAD_LEARN_WORLDS = 'LOAD_LEARN_WORLDS',
  SET_DATABASE_ENTITY = 'SET_DATABASE_ENTITY',
  TOAST_OPEN = 'TOAST_OPEN',
  TOAST_CLOSE = 'TOAST_CLOSE',
  TOGGLE_SHOW_NAVBAR = 'TOGGLE_SHOW_NAVBAR',
  TOGGLE_SHOW_FOOTER = 'TOGGLE_SHOW_FOOTER',
}
