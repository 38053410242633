import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import { AppContext } from '../../state/app-state-context';
import { Button, Modal } from '../../components';
import { DatabaseService } from '../../services/database.service';
import { Entity } from '../../model/Entity';
import {
  Feedback,
  feedbackOpinionLeaderConverter,
  feedbackOrganizationConverter,
  feedbackProjectConverter,
} from '../../model/feedback/feedback';
import { FeedbackType } from '../../enums/feedback-type.enum';
import { OpinionLeader } from '../../model/opinion-leaders/opinion-leader';
import { Organization } from '../../model/main/organization';
import { Project } from '../../model/main/project';
import { openToastAction } from '../../state/actions';
import ConfirmDeleteModalBodyComponent from '../../components/modals-body/confirm-modal/confirm-delete-modal.component';
import EditFeedback from './edit-feedback.component';

function ManageFeedback() {
  const navigate = useNavigate();
  const { dispatch } = useContext(AppContext);
  const [currentFeedback, setCurrentFeedback] = useState<Feedback<OpinionLeader | Project | Organization>[]>([]);
  const [feedbackInEdit, setFeedbackInEdit] = useState<Feedback<OpinionLeader | Project | Organization>>();
  const [showModal, setShowModal] = useState(false);
  const [modalFeedback, setModalFeedback] = useState<Feedback<OpinionLeader | Project | Organization>>();

  useEffect(() => {
    fetchFeedback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchFeedback = async () => {
    try {
      const leaders = await DatabaseService.get<Feedback<OpinionLeader>>(
        Feedback.OPINION_LEADER_PATH,
        feedbackOpinionLeaderConverter
      );
      const projects = await DatabaseService.get<Feedback<Project>>(Feedback.PROJECT_PATH, feedbackProjectConverter);
      const organizations = await DatabaseService.get<Feedback<Organization>>(
        Feedback.ORGANIZATION_PATH,
        feedbackOrganizationConverter
      );
      const combined = [...leaders, ...projects, ...organizations];
      setCurrentFeedback(combined);
      if (combined.length === 0) {
        dispatch(openToastAction('Não existe feedback neste momento.', 'success'));
        return;
      }
    } catch (error) {
      dispatch(openToastAction('Não foi possível carregar o feedback', 'danger'));
    }
  };

  async function deleteFeedback() {
    if (!modalFeedback) {
      setShowModal(false);
      return;
    }
    try {
      switch (modalFeedback.type) {
        case FeedbackType.OpinionLeader:
          await DatabaseService.hardDelete(Feedback.OPINION_LEADER_PATH, modalFeedback.id);
          break;
        case FeedbackType.Project:
          await DatabaseService.hardDelete(Feedback.PROJECT_PATH, modalFeedback.id);
          break;
        case FeedbackType.Organization:
          await DatabaseService.hardDelete(Feedback.ORGANIZATION_PATH, modalFeedback.id);
          break;
      }
      setCurrentFeedback([...currentFeedback.filter((feedback) => feedback.id !== modalFeedback.id)]);
      dispatch(openToastAction('Feedback apagado com sucesso', 'success'));
    } catch (error) {
      dispatch(openToastAction('Erro ao apagar feedback, tente novamente', 'danger'));
    }
    setShowModal(false);
    setModalFeedback(undefined);
  }

  function getDescriptiveName(feedback: Feedback<OpinionLeader | Project | Organization>) {
    switch (feedback.type) {
      case FeedbackType.OpinionLeader:
        return (feedback as Feedback<OpinionLeader>).entity.name;
      case FeedbackType.Project:
        return (feedback as Feedback<Project>).entity.name;
      case FeedbackType.Organization:
        return (feedback as Feedback<Organization>).entity.orgName;
      default:
        return 'Sem tipo de feedback definido';
    }
  }

  function backButton() {
    if (feedbackInEdit) {
      setFeedbackInEdit(undefined);
    } else {
      navigate('/home');
    }
  }

  function feedbackTypeTranslate(feedbackType: FeedbackType) {
    switch (feedbackType) {
      case FeedbackType.OpinionLeader:
        return 'Líder de Opinião';
      case FeedbackType.Project:
        return 'Projeto';
      case FeedbackType.Organization:
        return 'Organização';
      default:
        return 'Tipo de feedback não definido';
    }
  }

  return (
    <div>
      <div className="container border p-3">
        <div className="row justify-content-md-start p-2">
          <div className="col-md-auto">
            <Button text="Voltar" size="medium" handleButtonPressed={backButton}></Button>
          </div>
        </div>
        <div className="m-3 p-4">
          <h2> Feedback</h2>
        </div>
        {!feedbackInEdit && (
          <>
            {currentFeedback.map((feedback, index) => {
              return (
                <div className="border rounded shadow-lg p-3 mb-1" key={index}>
                  <div className="row">
                    <div className="col">
                      <h4 className="h4 mb-0">{getDescriptiveName(feedback)}</h4>
                      <div>Tipo: {feedbackTypeTranslate(feedback.type)}</div>
                      <div>Criado: {Entity.fieldValueToString(feedback.createdAt)}</div>
                      <div>
                        Criado por: {feedback.createdByName ? feedback.createdByName : 'Sem utilizador definido'}
                      </div>
                    </div>
                    <div className="col d-flex justify-content-end align-items-center bd-highlight ">
                      <Button
                        text="Detalhe"
                        outline={true}
                        size="small"
                        classes="ms-2"
                        handleButtonPressed={() => setFeedbackInEdit(feedback)}
                      ></Button>
                      <Button
                        text="Apagar"
                        outline={true}
                        size="small"
                        classes="ms-2"
                        handleButtonPressed={() => {
                          setModalFeedback(feedback);
                          setShowModal(true);
                        }}
                      ></Button>
                      <Modal
                        setShowModal={setShowModal}
                        showModal={showModal}
                        title={'Tem a certeza que pretende apagar?'}
                        Component={
                          <ConfirmDeleteModalBodyComponent setShowModal={setShowModal} submitDelete={deleteFeedback} />
                        }
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}
        {feedbackInEdit && (
          <div>
            <EditFeedback
              feedback={feedbackInEdit}
              setFeedback={setFeedbackInEdit}
              setCurrentFeedback={setCurrentFeedback}
              currentFeedback={currentFeedback}
            ></EditFeedback>
          </div>
        )}
      </div>
      <Modal
        setShowModal={setShowModal}
        showModal={showModal}
        title={'Tem a certeza que pretende apagar?'}
        Component={
          <ConfirmDeleteModalBodyComponent setShowModal={setShowModal} submitDelete={() => deleteFeedback()} />
        }
      />
    </div>
  );
}

export default ManageFeedback;
