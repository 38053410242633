import { ElasticConverter } from '../interfaces/elastic/elastic-converter.interface';
import { ElasticService } from './elastic.service';
import { Entity } from '../model/Entity';
import {
  FirestoreConverter,
  PaginationSettings,
  QueryFormat,
  SearchByTermSettings,
  SortingSettings,
} from '../interfaces/database.interface';
import {
  Query,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  startAfter,
  where,
  writeBatch,
} from 'firebase/firestore';
import { SearchApiFilter, SearchApiMeta, SearchApiOptions } from '../interfaces/elastic/search-api.interface';
import { auth, db } from '../firebase-app';

const MAX_BATCH_LIMIT = 500;

const DATABASE_PATH = 'databases/';

export const DatabaseService = {
  get: async <T extends Entity>(path: string, converter: FirestoreConverter<T>) => {
    const col = collection(db, DATABASE_PATH, path).withConverter(converter);
    const docSnap = await getDocs(col);
    return docSnap.docs.map((doc) => {
      return doc.data() as T;
    });
  },
  saveAll: async <T extends Entity>(path: string, entities: T[], converter: FirestoreConverter<T>) => {
    let batch = writeBatch(db);
    let counter = 0;
    let index = 0;
    const userId = auth.currentUser?.uid || 'unknown';
    const userName = auth.currentUser?.displayName || 'unknown';
    for (const entity of entities) {
      if (counter < MAX_BATCH_LIMIT) {
        const ref = doc(db, DATABASE_PATH, path + '/' + entity.id).withConverter(converter);
        entity.createdAt = serverTimestamp();
        entity.modifiedAt = serverTimestamp();
        entity.createdById = userId;
        entity.createdByName = userName;
        batch.set(ref, entity);
        counter++;
        index++;
      } else {
        console.log('saving until index ' + index);
        await batch.commit();
        batch = writeBatch(db);
        counter = 0;
      }
    }
    await batch.commit();
    console.log('saved to path ' + path + ' ' + entities.length + entities.constructor.name + 's');
  },
  getById: async <T extends Entity>(path: string, id: string, converter: FirestoreConverter<T>) => {
    const docSnap = await getDoc(doc(db, DATABASE_PATH, path + '/' + id).withConverter(converter));
    if (docSnap.exists()) {
      return docSnap.data() as T;
    }
    throw new Error('Documento não encontrado');
  },
  getPage: async <T extends Entity>(
    path: string,
    pageSize: number,
    converter: FirestoreConverter<T>,
    startAfterId?: string
  ) => {
    let q: Query;
    startAfterId
      ? (q = query(
          collection(db, DATABASE_PATH, path),
          orderBy('id'),
          startAfter(startAfterId),
          limit(pageSize)
        ).withConverter(converter))
      : (q = query(collection(db, DATABASE_PATH, path), orderBy('id'), limit(pageSize)).withConverter(converter));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => {
      return doc.data() as T;
    });
  },
  /*
    Adds a new entry at the end of the document specified in the path

    @param idPrefix a prefix for the id (convention is a 3 letter abbreviation - Project becomes 'prj', Organization becomes 'org')
    @param path: the path of the document to add the entry to
    @param obj: the object to add to the document
    @param converter: the converter to use to convert the object to firestore format

    @returns the object with the id field set to the id of the database
   */
  addEntry: async <T extends Entity>(path: string, obj: T, converter: FirestoreConverter<T>): Promise<T> => {
    obj.id = obj.id.length > 0 ? obj.id : Entity.generateId(obj.idPrefix);
    const userID = auth.currentUser?.uid || 'unknown';
    const userName = auth.currentUser?.displayName || 'unknown';
    const ref = doc(db, 'databases', path + '/' + obj.id).withConverter(converter);
    obj.createdAt = serverTimestamp();
    obj.modifiedAt = serverTimestamp();
    obj.createdById = userID;
    obj.createdByName = userName;
    await setDoc(ref, obj);
    return obj;
  },
  updateEntry: async <T extends Entity>(path: string, obj: T, converter: FirestoreConverter<T>): Promise<T> => {
    const ref = doc(db, 'databases', path + '/' + obj.id).withConverter(converter);
    obj.modifiedAt = serverTimestamp();
    await setDoc(ref, obj);
    return obj;
  },
  softDelete: async <T extends Entity>(
    path: string,
    deletePath: string,
    id: string,
    converter: FirestoreConverter<T>
  ) => {
    const deletedEntity = await DatabaseService.getById(path, id, converter);
    try {
      await DatabaseService.updateEntry('/deleted' + deletePath, deletedEntity, converter);
      await deleteDoc(doc(db, 'databases', path + '/' + id));
    } catch (e) {
      console.error(e);
    }
  },
  hardDelete: async (path: string, id: string) => {
    await deleteDoc(doc(db, 'databases', path + '/' + id));
  },

  /*
   Composites multiple queries defined in the queryFormat array and fetches them.

   @param path: the path of the document to add the entry to
   @param converter: the converter to use to convert the object to firestore format
   @param queryFormat: the array of queries to composite

   @returns the objects that satisfy the queries provided, if any
   */
  getByQueries: async <T extends Entity>(
    path: string,
    converter: FirestoreConverter<T>,
    paginationSettings: PaginationSettings<T>,
    ...queryList: QueryFormat[]
  ): Promise<T[]> => {
    const col = collection(db, DATABASE_PATH, path + '/');
    let mainQuery: Query = query(col).withConverter(converter);
    if (paginationSettings.enablePagination) {
      if (paginationSettings.limit) {
        mainQuery = query(mainQuery, limit(paginationSettings.limit));
      }
      if (paginationSettings.startAfter) {
        mainQuery = query(mainQuery, startAfter(paginationSettings.startAfter));
      }
    }
    const otherQueries: Query[] = [];
    let alreadyHasArrayContainsAny = false;
    for (const queryFormat of queryList) {
      switch (queryFormat.queryType) {
        case 'arrays-contains-any': {
          if (typeof queryFormat.value !== 'object') {
            throw new Error('Value must be a string array to use array-contains-any');
          }
          if (!alreadyHasArrayContainsAny) {
            mainQuery = queryByArrayContainsAny(mainQuery, queryFormat.field, queryFormat.value as string[]);
            alreadyHasArrayContainsAny = true;
          } else {
            // firestore only accepts one 'array-contains-any' clause per query. So, if we already have one
            // we need to query separately and join them in the end manually
            otherQueries.push(
              query(col, where(queryFormat.field, 'array-contains-any', queryFormat.value)).withConverter(converter)
            );
          }
          break;
        }
        case 'boolean': {
          if (typeof queryFormat.value !== 'boolean') {
            throw new Error('Value must be a boolean to use boolean query');
          }
          mainQuery = queryByBoolean(mainQuery, queryFormat.field, queryFormat.value as boolean);
          break;
        }
        case 'map': {
          if (!alreadyHasArrayContainsAny) {
            mainQuery = queryByMap(mainQuery, queryFormat.field, queryFormat.value as Map<string, string[]>);
            alreadyHasArrayContainsAny = true;
          } else {
            // firestore only accepts one 'array-contains-any' clause per query. So, if we already have one
            // we need to query separately and join them in the end manually
            (queryFormat.value as Map<string, string[]>).forEach((arrayValue, key) => {
              otherQueries.push(
                query(col, where(queryFormat.field + '.' + key, 'array-contains-any', arrayValue)).withConverter(
                  converter
                )
              );
            });
          }
          break;
        }
        case 'substring': {
          if (typeof queryFormat.value !== 'string') {
            throw new Error('Value must be a string to search by text');
          }
          mainQuery = queryBySubstring(mainQuery, queryFormat.field, queryFormat.value);
          break;
        }
        case 'string': {
          if (typeof queryFormat.value !== 'string') {
            throw new Error('Value must be a string to search by text');
          }
          mainQuery = queryByString(mainQuery, queryFormat.field, queryFormat.value);
          break;
        }
        case 'null': {
          mainQuery = queryByEmpty(mainQuery, queryFormat.field);
          break;
        }
        case 'emptyMap': {
          mainQuery = queryByEmptyMap(mainQuery, queryFormat.field);
          break;
        }
        default:
          break;
      }
    }

    const querySnapshot = await getDocs(mainQuery);
    const mainFetch = querySnapshot.docs.map((doc) => {
      return doc.data() as T;
    });
    if (otherQueries.length === 0) {
      return mainFetch;
    } else {
      const otherFetch: T[] = [];
      for (const query of otherQueries) {
        const otherSnapshot = await getDocs(query);
        otherFetch.push(
          ...otherSnapshot.docs.map((doc) => {
            return doc.data() as T;
          })
        );
      }
      return joinQueries([...mainFetch, ...otherFetch]);
    }
  },

  getByQueriesElastic: async <T extends Entity>(
    elasticPath: string,
    elasticConverter: ElasticConverter<T>,
    paginationSettings: PaginationSettings<T>,
    sortingSettings: SortingSettings,
    searchByTermSettings: SearchByTermSettings,
    ...queryList: QueryFormat[]
  ): Promise<{ meta: SearchApiMeta; results: T[] }> => {
    const queryOption: string[] = [];
    let paginationOption;
    if (paginationSettings.enablePagination) {
      if (paginationSettings.limit && paginationSettings.currentPage) {
        paginationOption = { size: paginationSettings.limit, current: paginationSettings.currentPage };
      }
    }
    const filterOption: SearchApiFilter[] = [];
    queryList.forEach((queryFormat) => {
      switch (queryFormat.queryType) {
        case 'substring': {
          queryOption.push(queryFormat.value as string);
          break;
        }
        case 'string': {
          filterOption.push({
            filter: { key: queryFormat.field.toLowerCase(), values: [queryFormat.value as string] },
            logic: 'all',
          });
          break;
        }
        case 'boolean': {
          const bool = queryFormat.value as boolean;
          filterOption.push({
            filter: { key: queryFormat.field.toLowerCase(), values: [bool.toString()] },
            logic: 'all',
          });
          break;
        }
        case 'arrays-contains-any': {
          filterOption.push({
            filter: { key: queryFormat.field.toLowerCase(), values: queryFormat.value as string[] },
            logic: 'any',
          });
          break;
        }
        case 'map': {
          if (queryOption.length <= 10) {
            const map = queryFormat.value as Map<string, string[]>;
            map.forEach((arrayValue, key) => {
              arrayValue.forEach((value) => {
                queryOption.push(queryFormat.field.toLowerCase() + '.' + key + ':' + value);
              });
            });
            queryOption.push('NOT {}');
          }
          break;
        }
      }
    });
    const options: SearchApiOptions = {
      query: queryOption,
      engine: elasticPath,
      pagination: paginationOption,
      filterList: filterOption,
    };

    if (sortingSettings.enableSorting) {
      options.sort = sortingSettings.sortBy;
    }

    if (searchByTermSettings.enableSearchByTerm) {
      options.searchFields = searchByTermSettings.searchBy;
    }

    return await DatabaseService.searchWithElastic(options, elasticConverter);
  },

  searchWithElastic: async <T extends Entity>(
    options: SearchApiOptions,
    elasticConverter: ElasticConverter<T>
  ): Promise<{ results: T[]; meta: SearchApiMeta }> => {
    let response;
    try {
      response = await ElasticService.search(options);
      if (response) {
        return { results: elasticConverter.fromElastic(response.results), meta: response.meta };
      }
    } catch (e) {
      console.error(e);
    }
    throw new Error('There was a problem with the search. Elastic response is' + response);
  },
};

/*
   Compounds to a query a constraint to find all documents in firestore for a substring. The string must be the beginning of the fields value.
   The character \uf8ff used in the query is a very high code point in the Unicode range (it is a Private Usage Area [PUA] code).
   Because it is after the most regular characters in Unicode, the query matches all values that start with queryText

   @param q the query to compound
   @param field the field to search in
   @param fieldValue the value to search for in field for substring

   @returns the compounded query
 */
function queryBySubstring(q: Query, field: string, fieldValue: string): Query {
  return query(q, where(field, '>=', fieldValue), where(field, '<=', fieldValue + '\uf8ff'));
}
/*
   Compounds to a query a constraint to find all documents in firestore for a specific string.

   @param q the query to compound
   @param field the field to search in
   @param fieldValue the value to search for in field for equal string

 */
function queryByString(q: Query, field: string, fieldValue: string): Query {
  return query(q, where(field, '==', fieldValue));
}
/*
   Compounds to a query a constraint to find all documents in firestore for a specific boolean

    @param q the query to compound
   @param field the field name to search in
   @param fieldValue the value to search in the field, must be true or false
 */
function queryByBoolean(q: Query, field: string, value: boolean): Query {
  return query(q, where(field, '==', value));
}
/*
   Compounds to a query a constraint to find all documents in a field whose string values match *any* of the values in the array.
   You may only use up to 10 values for each key.

   @param q the query to compound
   @param field the field to search in
   @param fieldValue the array of values to search for

   @returns the compounded query
 */
function queryByArrayContainsAny(q: Query, field: string, arrayValues: string[]): Query {
  return query(q, where(field, 'array-contains-any', arrayValues));
}
/*
   Queries the database for documents in a field whose map values match *any* of the values in the map.
   You may only use up to 10 values for each key.

   @param q the query to compound
   @param field the field to search in
   @param fieldValue the map of values to search for

   @returns the compounded query
 */
function queryByMap(q: Query, field: string, map: Map<string, string[]>): Query {
  map.forEach((value, key) => {
    q = query(q, where(field + '.' + key, 'array-contains-any', value));
  });

  return q;
}

/*
  Queries the database for documents for null values in string or number fields

    @param q the query to compound
    @param field the field to search in

    @returns the compounded query
 */
function queryByEmpty(q: Query, field: string): Query {
  return query(q, where(field, '==', null));
}

/*
    Queries the database for documents for empty maps in map fields

    @param q the query to compound
    @param field the field to search in

    @returns the compounded query
 */
function queryByEmptyMap(q: Query, field: string): Query {
  return query(q, where(field, '==', {}));
}

function joinQueries<T extends Entity>(queries: T[]): T[] {
  const uniqueIds = new Set();

  return queries.filter((entity) => {
    const isDuplicate = uniqueIds.has(entity.id);

    uniqueIds.add(entity.id);

    return !isDuplicate;
  });
}
