import { useContext } from 'react';
import BsToast from 'react-bootstrap/Toast';
import BsToastContainer from 'react-bootstrap/ToastContainer';

// Components
import { AppContext } from '../../state/app-state-context';
import { closeToastAction } from '../../state/actions';

function Toast() {
  const {
    dispatch,
    state: {
      toast: { backgroundColor, show: showToast, text },
    },
  } = useContext(AppContext);

  const handleCloseToast = () => dispatch(closeToastAction());

  return (
    <BsToastContainer position="top-end">
      <BsToast onClose={handleCloseToast} show={showToast} bg={backgroundColor} delay={3000} autohide>
        <BsToast.Body className="text-white lead">{text}</BsToast.Body>
      </BsToast>
    </BsToastContainer>
  );
}

export default Toast;
