import { ComplexFormProps } from './complex-form.interface';
import { REGIONS } from '../../model/options';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';

function RegionsForm({ complexForm, setComplexForm }: ComplexFormProps) {
  return (
    <Form.Group className="mb-3" controlId="regions">
      <Form.Label>Regiões</Form.Label>
      <ListGroup>
        {complexForm.regions.map((region: string, index: number) => {
          return (
            <ListGroup.Item key={index}>
              {region}
              <Button
                size={'sm'}
                onClick={() => {
                  complexForm.regions.splice(index, 1);
                  setComplexForm({ ...complexForm, regions: complexForm.regions });
                }}
              >
                Remover
              </Button>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
      <Form.Select
        aria-label="Regions"
        onChange={(e) => {
          if (!complexForm.regions.includes(e.target.value)) {
            complexForm.regions.push(e.target.value);
            setComplexForm({ ...complexForm, regions: complexForm.regions });
          }
        }}
      >
        {Object.values(REGIONS).map((region, index) => {
          return (
            <option key={index} value={region}>
              {region}
            </option>
          );
        })}
      </Form.Select>
    </Form.Group>
  );
}

export default RegionsForm;
