import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

// Components
import { AppContext } from '../../../state/app-state-context';
import { DatabaseService } from '../../../services/database.service';
import { Entity } from '../../../model/Entity';
import { FilteredFormValues } from '../../../interfaces/filtered-form-values.interface';
import { Modal } from '../../index';
import { Organization, organizationConverter } from '../../../model/main/organization';
import { PaginationSettings, QueryFormat } from '../../../interfaces/database.interface';
import { Project, projectConverter, projectElasticConverter } from '../../../model/main/project';
import { QueryTypes } from '../../../enums/query-types.enum';
import { ResultsType } from '../../../enums/results-type.enum';
import { TRUE_FALSE } from '../../../model/options';
import { openSidebarAction, openToastAction } from '../../../state/actions';
import Button from '../../button/button.component';
import DatabaseQuery from '../database-query/database-query';
import InputGroup from 'react-bootstrap/InputGroup';
import OdsForm from '../ods-form';
import ProblemsForm from '../problems-form';
import ProfessionalServicesForm from '../professional-services-form';
import RegionsForm from '../regions-form';
import ServicesForm from '../services-form';
import SubmitProjectModalBody from '../../modals-body/submit-organization-modal-body/submit-project-modal-body.component';
import TargetAudienceForm from '../target-audience-form';
import VolunteeringProfileForm from '../volunteering-profile-form';

// Styles
import { ELASTIC_PROJECTS_PATH } from '../../../interfaces/elastic/search-api.interface';
import ConfirmDeleteModalBodyComponent from '../../modals-body/confirm-modal/confirm-delete-modal.component';
import ElasticSearchBoxComponent from '../../elastic/elastic-search-box.component';

function ProjectList() {
  const { dispatch } = useContext(AppContext);
  const [queryFormatArray, setQueryFormatArray] = useState<QueryFormat[]>([]);
  const [selectedFilteredValues, setSelectedFilteredValues] = useState<FilteredFormValues>({});
  const [projectsWithFilters, setProjectsWithFilters] = useState<Project[]>([]);
  const [resultsType, setResultsType] = useState<ResultsType>(ResultsType.Normal);
  const navigate = useNavigate();
  const { orgId } = useParams();
  const [showSubmitNewProjectModal, setShowSubmitNewProjectModal] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [currentOrg, setCurrentOrg] = useState<Organization>();
  const [projects, setProjects] = useState<Project[]>([]);
  const [projectInEdit, setProjectInEdit] = useState<Project>();
  const [showModal, setShowModal] = useState(false);
  const [modalProject, setModalProject] = useState<Project>();
  const [formControls, setFormControls] = useState<any>({
    status: '',
    contactStatus: '',
    name: '',
    mission: '',
    activity: '',
    volunteeringDescription: '',
    site: '',
    email: '',
    phoneInfo: '',
    regions: '',
    problems: '',
    targetAudience: '',
    services: '',
    professionalServices: '',
    acceptsVolunteering: '',
    volunteeringProfile: '',
    sustainabilityObjectives: '',
    observations: '',
    approved: '',
  });

  useEffect(() => {
    if (orgId != null) {
      getProjects();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (queryFormatArray.length > 0) {
      fetchFilteredProjects();
    } else {
      setResultsType(ResultsType.Normal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryFormatArray]);

  const getProjects = async () => {
    try {
      if (orgId != null) {
        const q: QueryFormat = {
          field: 'orgId',
          value: orgId,
          queryType: 'string',
        };
        const projectsFetch = await DatabaseService.getByQueries<Project>(
          Project.PATH,
          projectConverter,
          {
            enablePagination: false,
          },
          q
        );
        setProjects(projectsFetch);
        const org = await DatabaseService.getById(Organization.PATH, orgId, organizationConverter);
        setCurrentOrg(org);
      }
    } catch (error) {
      dispatch(
        openToastAction(
          'Não foi possível encontrar a organização com o id: ' + orgId + '. Tente novamente mais tarde.',
          'danger'
        )
      );
    }
  };

  const fetchFilteredProjects = async () => {
    try {
      const projectsFromDb = await DatabaseService.getByQueriesElastic<Project>(
        ELASTIC_PROJECTS_PATH,
        projectElasticConverter,
        { enablePagination: true, limit: 100 },
        { enableSorting: false },
        { enableSearchByTerm: false },
        ...queryFormatArray
      );
      if (projectsFromDb.results.length > 0) {
        setResultsType(ResultsType.Filter);
        setProjectsWithFilters(projectsFromDb.results);
      } else {
        setResultsType(ResultsType.Normal);
        dispatch(openToastAction('Não foram encontrados projetos com os filtros selecionados.', 'info'));
        resetFilter();
      }
    } catch {
      dispatch(openToastAction('Tente novamente mais tarde.', 'danger'));
    }
  };

  function toggleEdit(proj: Project, index: number) {
    setFormControls({
      status: proj.status,
      contactStatus: proj.contactStatus,
      name: proj.name,
      mission: proj.mission,
      activity: proj.activity,
      volunteeringDescription: proj.volunteeringDescription,
      site: proj.site,
      email: proj.email,
      phoneInfo: proj.phoneInfo,
      regions: proj.regions,
      problems: proj.problems,
      targetAudience: proj.targetAudience,
      services: proj.services,
      professionalServices: proj.professionalServices,
      acceptsVolunteering: proj.acceptsVolunteering,
      volunteeringProfile: proj.volunteeringProfile,
      sustainabilityObjectives: proj.sustainabilityObjectives,
      observations: proj.observations,
      approved: proj.approved,
      index: index,
    });
    setProjectInEdit(proj);
  }

  async function fetchOrgName(orgId?: string): Promise<string | undefined> {
    if (currentOrg && currentOrg.orgName) {
      return currentOrg.orgName;
    }
    if (!orgId) {
      return undefined;
    }
    const org = await DatabaseService.getById(Organization.PATH, orgId, organizationConverter);
    return org.orgName;
  }

  async function handleSaveForm() {
    try {
      await saveForm();
      setProjectInEdit(undefined);
      await getProjects();
      dispatch(openToastAction('Projeto submetido com sucesso', 'success'));
    } catch (e) {
      dispatch(openToastAction('Error ao submeter projeto, tente novamente.', 'danger'));
    }
  }

  async function saveForm() {
    if (projectInEdit && projectInEdit.orgId && projectInEdit.orgName) {
      const approved = formControls.approved === TRUE_FALSE.true;
      const updatedProject = new Project(
        projectInEdit.id,
        formControls.name,
        formControls.mission,
        formControls.activity,
        formControls.volunteeringDescription,
        formControls.site,
        formControls.email,
        formControls.phoneInfo,
        formControls.regions,
        formControls.problems,
        formControls.targetAudience,
        formControls.services,
        formControls.professionalServices,
        formControls.acceptsVolunteering,
        formControls.volunteeringProfile,
        formControls.sustainabilityObjectives,
        formControls.observations,
        approved,
        projectInEdit.orgId,
        projectInEdit.orgName,
        projectInEdit.createdAt,
        projectInEdit.modifiedAt,
        projectInEdit.createdById,
        projectInEdit.createdByName
      );
      if (formControls.index != null && projects != null) {
        try {
          await DatabaseService.updateEntry<Project>(Project.PATH, updatedProject, projectConverter);
          await getProjects();
        } catch (error) {
          dispatch(openToastAction('Erro ao atualizar projeto, tente novamente.', 'danger'));
        }
      }
    }
  }

  function handleSubmitNewProjectBtnPressed() {
    setTitleModal('Submeter novo Projeto');
    setShowSubmitNewProjectModal(true);
  }

  async function newProject(projectName: string) {
    const newProject = Project.new(projectName);
    newProject.orgId = orgId;
    newProject.orgName = await fetchOrgName(orgId);
    newProject.id = Entity.generateId(Project.PREFIX);
    try {
      await DatabaseService.addEntry(Project.PATH, newProject, projectConverter);
      await getProjects();
      dispatch(openToastAction('Projeto submetido com sucesso.', 'success'));
    } catch (e) {
      dispatch(openToastAction('Erro ao submeter projeto, tente novamente.', 'danger'));
    }
  }

  const resetFilter = () => {
    setSelectedFilteredValues({});
    setQueryFormatArray([]);
  };

  async function getProjectElastic(result: any) {
    const id = result.id.raw;
    try {
      const project = await DatabaseService.getById<Project>(Project.PATH, id, projectConverter);
      setProjects([project]);
    } catch {
      dispatch(openToastAction('Erro ao obter o projeto selecionado. Tente novamente mais tarde', 'danger'));
    }
  }

  async function deleteProject() {
    if (!modalProject) {
      setShowModal(false);
      return;
    }
    try {
      await DatabaseService.softDelete(Project.PATH, Project.COLLECTION_NAME, modalProject.id, projectConverter);
      setProjects([...projects.filter((proj) => proj.id !== modalProject.id)]);
      dispatch(openToastAction('Projeto apagado com sucesso', 'success'));
    } catch (error) {
      dispatch(openToastAction('Não foi possível apagar o projeto', 'danger'));
    }
    setModalProject(undefined);
    setShowModal(false);
  }

  async function promoteToMainProject(proj: Project) {
    if (currentOrg?.mainProjectId === proj.id) {
      dispatch(openToastAction('Este projeto já é o projeto princial.', 'info'));
    } else {
      try {
        if (currentOrg) {
          const copy = currentOrg;
          copy.mainProjectId = proj.id;
          await DatabaseService.updateEntry(Organization.PATH, copy, organizationConverter);
          const project = proj;
          project.orgId = orgId;
          await DatabaseService.updateEntry(Project.PATH, project, projectConverter);
          await getProjects();
          dispatch(openToastAction('Projeto alterado com sucesso', 'success'));
        }
      } catch (error) {
        dispatch(openToastAction('Não foi possível alterar o projeto', 'success'));
      }
    }
  }

  async function searchIncompleteDocs() {
    const pageSize = 30;
    let incompleteDocs: Project[] = [];
    try {
      incompleteDocs = await incompleteProjectSearch('name', 'null', pageSize);
      if (incompleteDocs.length === 0) {
        incompleteDocs = await incompleteProjectSearch('mission', 'null', pageSize);
      }
      if (incompleteDocs.length === 0) {
        incompleteDocs = await incompleteProjectSearch('problems', 'emptyMap', pageSize);
      }
      if (incompleteDocs.length === 0) {
        dispatch(openToastAction('Não existem ações incompletas neste momento.', 'info'));
        return;
      }
      dispatch(
        openToastAction('Carregado ' + incompleteDocs?.length + ' documentos incompletos com sucesso.', 'success')
      );
      setProjects(incompleteDocs);
    } catch (e) {
      dispatch(openToastAction('Erro na busca. Tente novamente mais tarde.', 'danger'));
    }
  }

  async function incompleteProjectSearch(
    field: string,
    queryType: 'null' | 'emptyMap',
    pageSize: number
  ): Promise<Project[]> {
    const paginationSettings: PaginationSettings<Project> = {
      enablePagination: true,
      limit: pageSize,
      currentPage: 1,
    };
    // value parameter doesn't matter in null and emptyMap searches
    return await DatabaseService.getByQueries(
      Project.PATH,
      projectConverter,
      paginationSettings,
      ...[{ field, value: '', queryType }]
    );
  }

  return (
    <div className="container border p-3">
      {!projectInEdit && (
        <div className="row justify-content-md-start p-2">
          <div className="col-md-auto">
            <Button
              text="Voltar"
              size="medium"
              handleButtonPressed={() => navigate('/data-center/main-document')}
            ></Button>
          </div>
          {!orgId && (
            <>
              <div className="col-md-auto">
                <Button
                  text="Filtrar"
                  size="medium"
                  handleButtonPressed={() =>
                    dispatch(
                      openSidebarAction(
                        <DatabaseQuery
                          key={Math.random()} // Create new instance
                          setQueryFormat={setQueryFormatArray}
                          queryOptions={[
                            QueryTypes.Problems,
                            QueryTypes.Regions,
                            QueryTypes.TargetAudience,
                            QueryTypes.Services,
                            QueryTypes.ProfessionalServices,
                            QueryTypes.Ods,
                            QueryTypes.VolunteeringProfile,
                            QueryTypes.Approval,
                          ]}
                          selectedFilters={selectedFilteredValues}
                          setSelectedFilters={setSelectedFilteredValues}
                        />
                      )
                    )
                  }
                ></Button>
              </div>
              <div className="col-md-auto">
                <Button
                  text="Ver campos incompletos"
                  size="medium"
                  handleButtonPressed={() => searchIncompleteDocs()}
                ></Button>
              </div>
            </>
          )}
          <div className="col-md-auto">
            {orgId && (
              <div>
                <Button
                  text="Novo Projeto"
                  size="medium"
                  handleButtonPressed={() => handleSubmitNewProjectBtnPressed()}
                ></Button>
              </div>
            )}
          </div>
          {!orgId && (
            <div className="col d-flex justify-content-end">
              <ElasticSearchBoxComponent
                fields={['name', 'regions', 'orgname']}
                titleField={'name'}
                engine={'projects'}
                callback={async (result) => {
                  await getProjectElastic(result);
                }}
              />
            </div>
          )}
        </div>
      )}
      <div>
        {projectInEdit ? (
          <div>
            <div className="p-2">
              <Button text="Voltar" size="medium" handleButtonPressed={() => setProjectInEdit(undefined)}></Button>
            </div>
            <div className="border rounded shadow-sm p-4 mb-3">
              <Form>
                <Form.Group className="mb-3" controlId="name">
                  <InputGroup.Text id="basic-addon1">Nome do Projeto</InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="nome do projeto"
                    onChange={(e) => setFormControls({ ...formControls, name: e.target.value })}
                    value={formControls.name}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="mission">
                  <InputGroup.Text id="basic-addon1">Missão</InputGroup.Text>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="missão"
                    onChange={(e) => setFormControls({ ...formControls, mission: e.target.value })}
                    value={formControls.mission}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="activity">
                  <InputGroup.Text id="basic-addon1">Atividade</InputGroup.Text>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="atividade"
                    onChange={(e) => setFormControls({ ...formControls, activity: e.target.value })}
                    value={formControls.activity}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="volunteering-description">
                  <InputGroup.Text id="basic-addon1">Voluntariado</InputGroup.Text>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="voluntariado"
                    onChange={(e) =>
                      setFormControls({
                        ...formControls,
                        volunteeringDescription: e.target.value,
                      })
                    }
                    value={formControls.volunteeringDescription}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="website">
                  <InputGroup.Text id="basic-addon1">Site</InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="site"
                    onChange={(e) => setFormControls({ ...formControls, site: e.target.value })}
                    value={formControls.site}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="email">
                  <InputGroup.Text id="basic-addon1">Email</InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="site"
                    onChange={(e) => setFormControls({ ...formControls, email: e.target.value })}
                    value={formControls.email}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="phone-info">
                  <InputGroup.Text id="basic-addon1">Informações de contacto</InputGroup.Text>
                  <Form.Control
                    type="text"
                    onChange={(e) => setFormControls({ ...formControls, phoneInfo: e.target.value })}
                    value={formControls.phoneInfo}
                  />
                </Form.Group>
                <RegionsForm complexForm={formControls} setComplexForm={setFormControls}></RegionsForm>
                <TargetAudienceForm complexForm={formControls} setComplexForm={setFormControls}></TargetAudienceForm>
                <ServicesForm complexForm={formControls} setComplexForm={setFormControls}></ServicesForm>
                <Form.Group className="mb-3" controlId="accepts-volunteering">
                  <InputGroup.Text id="basic-addon1">Aceita Voluntariado</InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Aceita voluntariado?"
                    onChange={(e) =>
                      setFormControls({
                        ...formControls,
                        acceptsVolunteering: e.target.value,
                      })
                    }
                    value={formControls.acceptsVolunteering}
                  />
                </Form.Group>
                <VolunteeringProfileForm
                  complexForm={formControls}
                  setComplexForm={setFormControls}
                ></VolunteeringProfileForm>
                <OdsForm complexForm={formControls} setComplexForm={setFormControls}></OdsForm>
                <Form.Group className="mb-3" controlId="approved">
                  <Form.Label>Aprovado: {formControls.approved === TRUE_FALSE.true ? 'Sim' : 'Não'}</Form.Label>
                  <Form.Select
                    aria-label="Status"
                    onChange={(e) => setFormControls({ ...formControls, approved: e.target.value })}
                  >
                    {Object.values(TRUE_FALSE).map((status, index) => {
                      return (
                        <option key={index} value={status}>
                          {status}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="observations">
                  <InputGroup.Text id="basic-addon1">Observações</InputGroup.Text>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="observações"
                    onChange={(e) =>
                      setFormControls({
                        ...formControls,
                        observations: e.target.value,
                      })
                    }
                    value={formControls.observations}
                  />
                </Form.Group>
                <ProblemsForm complexForm={formControls} setComplexForm={setFormControls}></ProblemsForm>
                <ProfessionalServicesForm
                  complexForm={formControls}
                  setComplexForm={setFormControls}
                ></ProfessionalServicesForm>
                <Button text="Gravar" size="medium" handleButtonPressed={() => handleSaveForm()}></Button>
              </Form>
            </div>
          </div>
        ) : null}
      </div>
      {!projectInEdit &&
        (resultsType === ResultsType.Normal ? projects : projectsWithFilters).map((proj, index) => {
          return (
            <div className="container border rounded shadow-lg p-3" key={index}>
              <div className="row">
                <div className="col">
                  {currentOrg?.mainProjectId === proj.id ? (
                    <p className="h4 mb-0 bg-info">
                      {proj.name ? proj.name + ' [Projeto Principal]' : '(Projeto sem nome) [Projeto Principal]'}
                    </p>
                  ) : (
                    <p className="h4 mb-0">{proj.name ? proj.name : '(Projeto sem nome)'}</p>
                  )}
                  <div>Organização: {proj?.orgName ? proj.orgName : '(Organização sem nome)'}</div>
                  <div>Criado: {Entity.fieldValueToString(proj.createdAt)}</div>
                  <div>Modificado: {Entity.fieldValueToString(proj.modifiedAt)}</div>
                  <div>Criado por: {proj.createdByName ? proj.createdByName : 'Sem utilizador definido'} </div>
                  <div>Aprovado: {proj.approved ? 'Sim' : 'Não'}</div>
                </div>
                <div className="col d-flex justify-content-end align-items-center bd-highlight">
                  <Button
                    text="Detalhe"
                    outline={true}
                    size="small"
                    handleButtonPressed={() => (window.location.pathname = 'projetos/' + proj.orgName + '/' + proj.id)}
                  ></Button>
                  <Button
                    text="Principal"
                    outline={true}
                    size="small"
                    handleButtonPressed={() => promoteToMainProject(proj)}
                  ></Button>
                  <Button
                    text="Editar"
                    outline={true}
                    size="small"
                    handleButtonPressed={() => toggleEdit(proj, index)}
                  ></Button>
                  <Button
                    text="Apagar"
                    outline={true}
                    size="small"
                    handleButtonPressed={() => {
                      setModalProject(proj);
                      setShowModal(true);
                    }}
                  ></Button>
                </div>
              </div>
            </div>
          );
        })}
      <Modal
        setShowModal={setShowModal}
        showModal={showModal}
        title={'Tem a certeza que pretende apagar?'}
        Component={<ConfirmDeleteModalBodyComponent setShowModal={setShowModal} submitDelete={() => deleteProject()} />}
      />
      <Modal
        setShowModal={setShowSubmitNewProjectModal}
        showModal={showSubmitNewProjectModal}
        title={titleModal}
        Component={<SubmitProjectModalBody setShowModal={setShowSubmitNewProjectModal} handleSubmitForm={newProject} />}
      />
    </div>
  );
}

export default ProjectList;
