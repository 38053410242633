import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { FirebaseError } from 'firebase/app';
import { IconBaseProps } from 'react-icons';
import { Link, useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { bool, object, ref, string } from 'yup';
import { useContext, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Nav from 'react-bootstrap/Nav';

// Components
import { AppContext } from '../../state/app-state-context';
import { ArrowLeftIcon, NewButton, SocialLogin } from '../../components';
import { FirebaseAuthErrors, UnknownError } from '../../constants/errors.constants';
import { openToastAction, toggleShowFooterAction } from '../../state/actions';
import AuthService from '../../services/auth.service';

// Images
import comunidade from '../../images/homepage/comunidade.svg';

// Styles
import './signup.component.scss';

type FormValues = {
  email: string;
  password: string;
  confirmPassword: string;
  termsAndConditions: boolean;
};

const validationSchema = object().shape({
  email: string().required('Email é um campo obrigatório.').email('Email inválido.'),
  password: string()
    .required('Palavra-passe é um campo obrigatório.')
    .min(6, 'Palavra-passe tem que ter pelo o menos 6 caracteres.')
    .max(40, 'Palavra-passe não pode exceder os 40 caracteres.'),
  confirmPassword: string()
    .required('Confirmar palavra-passe é um campo obrigatório.')
    .min(6, 'Confirmar palavra-passe tem que ter pelo o menos 6 caracteres.')
    .max(40, 'Confirmar palavra-passe não pode exceder os 40 caracteres.')
    .oneOf([ref('password')], 'Passwords não correspondem.'),
  termsAndConditions: bool().oneOf([true], 'Necessita de aceitar os termos e condições para prosseguir com o registo.'),
});

const iconProps: IconBaseProps = {
  color: 'rgba(23, 72, 74, 0.5)',
  size: '16',
};

function SignUp() {
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const {
    dispatch,
    state: { currentAuthUser, currentFirestoreUser },
  } = useContext(AppContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(toggleShowFooterAction(false));

    return () => {
      dispatch(toggleShowFooterAction(true));
    };
  });

  useEffect(() => {
    if (currentAuthUser) {
      if (currentFirestoreUser) {
        dispatch(openToastAction('Já está registado', 'info'));
        navigate('/');
      } else {
        navigate('/continuar-com-registo');
      }
    }
  }, [currentAuthUser, currentFirestoreUser]);

  const onSubmit: SubmitHandler<FormValues> = async (formValues) => {
    try {
      await AuthService.creaseUser(formValues.email, formValues.password);
    } catch (error) {
      if (error instanceof FirebaseError) {
        const errorMessage = FirebaseAuthErrors[error.code] ?? UnknownError;
        dispatch(openToastAction(errorMessage, 'danger'));
      }
    }
  };

  const handleShowPassword = () => setPasswordShown(!passwordShown);
  const handleShowConfirmPassword = () => setConfirmPasswordShown(!confirmPasswordShown);

  return (
    <div className="sign-up-container">
      <a className="go-back" href="https://www.udream.pt/">
        <ArrowLeftIcon />
        <span>VOLTAR AO SITE</span>
      </a>
      <div className="content">
        <img src={comunidade}></img>
        <span className="title">Criar conta</span>
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Form.Group className="custom-bootstrap-form-group" controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              placeholder="Insira o seu endereço email"
              {...register('email')}
              isInvalid={!!errors.email}
              className="custom-input"
            />
            <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="custom-bootstrap-form-group" controlId="formPassword">
            <Form.Label>Password</Form.Label>
            <InputGroup>
              <Form.Control
                type={passwordShown ? 'text' : 'password'}
                placeholder="Insira a sua password"
                {...register('password')}
                isInvalid={!!errors.password}
                className="custom-input-password"
              />
              <InputGroup.Text onClick={handleShowPassword} role="button">
                {passwordShown ? <AiOutlineEyeInvisible {...iconProps} /> : <AiOutlineEye {...iconProps} />}
              </InputGroup.Text>
            </InputGroup>
            <div className={`invalid-feedback ${errors.password && 'd-block'}`}>{errors.password?.message}</div>
          </Form.Group>
          <Form.Group className="custom-bootstrap-form-group" controlId="formConfirmPassword">
            <Form.Label>Confirmar password</Form.Label>
            <InputGroup>
              <Form.Control
                type={confirmPasswordShown ? 'text' : 'password'}
                placeholder="Confirme a sua password"
                {...register('confirmPassword')}
                isInvalid={!!errors.confirmPassword}
                className="custom-input-password"
              />
              <InputGroup.Text onClick={handleShowConfirmPassword} role="button">
                {confirmPasswordShown ? <AiOutlineEyeInvisible {...iconProps} /> : <AiOutlineEye {...iconProps} />}
              </InputGroup.Text>
            </InputGroup>
            <div className={`invalid-feedback ${errors.confirmPassword && 'd-block'}`}>
              {errors.confirmPassword?.message}
            </div>
          </Form.Group>
          <Form.Group className="custom-bootstrap-form-group" controlId="formTermsAndConditions">
            <Form.Check
              type="checkbox"
              {...register('termsAndConditions')}
              label={
                <Nav.Link
                  href="https://www.udream.pt/termos-e-condicoes"
                  target="_blank"
                  rel="noreferrer"
                  className="text-decoration-underline"
                >
                  Li e concordo com os Termos e Condições
                </Nav.Link>
              }
              isInvalid={!!errors.termsAndConditions}
            />
          </Form.Group>
          <NewButton theme="green" accent="white" outlined={false} size="lg" type="submit">
            Registar
          </NewButton>
        </Form>
        <p className="text-center login-account">
          Já tem uma conta? <Link to="/login">Entrar</Link>
        </p>
        <div className="sso-container">
          <span>ou registar com</span>
          <SocialLogin registerUser={true} />
        </div>
      </div>
    </div>
  );
}

export default SignUp;
