import Papa from 'papaparse';

export async function parse(file: File): Promise<string[][]> {
  const rawFile: unknown[] = await new Promise((resolve) => {
    Papa.parse(file, {
      skipEmptyLines: 'greedy',
      complete: (results) => {
        resolve(results.data);
      },
    });
  });
  const array = rawFile as string[][];
  array.forEach((row) => {
    row.forEach((cell, index) => {
      row[index] = cell.replaceAll(' ', '');
      row[index] = cell.trim();
      row[index] = cell.replace(/\s\s+/g, ' '); //remove multiple whitespace characters if they exist
    });
  });
  return array;
}
