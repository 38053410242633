import { Fragment, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { AppContext } from '../../state/app-state-context';
import NewNavbar from '../new-design/navbar/navbar.component';

import blueLogo from '../../images/blue-logo.png';

import styles from './navbar.component.module.scss';

function NavBar() {
  const { pathname } = useLocation();
  const {
    state: { isAppLoading },
  } = useContext(AppContext);

  if (isAppLoading) {
    return <Fragment />;
  }

  if (
    pathname.includes('login') ||
    pathname.includes('registar') ||
    pathname.includes('continuar-com-registo') ||
    pathname.includes('esqueceu-palavra-passe') ||
    pathname === '/acoes'
  ) {
    return (
      <nav className={styles['navigation-bar']}>
        <div className="logo">
          <img src={blueLogo} alt="u.dream logo" />
        </div>
      </nav>
    );
  }

  return <NewNavbar />;
}

export default NavBar;
