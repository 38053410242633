import CloseIcon from '../icons/close-icon/close-icon.component';
import styles from './tag.component.module.scss';

type TagProps = {
  text: string;
  backgroundColor: string;
  filterTags?: boolean;
  filterObjectKey?: string;
  filterObjectValue?: string;
  filterHandleCloseIconClick?: (key: string, value: string) => void;
  classes?: string;
  userTags?: boolean;
  userId?: string;
  userTagId?: string;
  userHandleCloseIconClick?: (userId: string, userTagId: string) => void;
};

function Tag({
  text,
  backgroundColor,
  filterTags = false,
  filterObjectKey,
  filterObjectValue,
  filterHandleCloseIconClick,
  classes = '',
  userTags = false,
  userId,
  userTagId,
  userHandleCloseIconClick,
}: TagProps) {
  if (filterTags && filterObjectKey && filterObjectValue && filterHandleCloseIconClick) {
    return (
      <div style={{ backgroundColor }} className={`${styles['c-tag-filter']} d-flex align-items-center ${classes}`}>
        <div className="d-flex" onClick={() => filterHandleCloseIconClick(filterObjectKey, filterObjectValue)}>
          <CloseIcon outerClasses={styles['c-tag-filter-close-icon']} />
        </div>
        <p className="m-0 ms-2 text-nowrap">{text}</p>
      </div>
    );
  }

  if (userTags && userId && userTagId && userHandleCloseIconClick) {
    return (
      <div style={{ backgroundColor }} className={`${styles['c-tag']} ${classes}`}>
        <span>{text}</span>
        <div onClick={() => userHandleCloseIconClick(userId, userTagId)}>
          <CloseIcon outerClasses={styles['c-tag-user-tag-close-icon']} />
        </div>
      </div>
    );
  }

  return (
    <div style={{ backgroundColor }} className={`${styles['c-tag']} ${classes}`}>
      <p className="m-0 text-nowrap">{text}</p>
    </div>
  );
}

export default Tag;
