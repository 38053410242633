import { ChangeEvent, Dispatch, Fragment, SetStateAction, useState } from 'react';
import { UserWithTagsAndCourses } from '../../../interfaces/user.interface';
import BsButton from 'react-bootstrap/Button';
import BsModal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/form';

type AddTagToUserModalProps = {
  user: UserWithTagsAndCourses;
  setShowAddTagToUserModal: Dispatch<SetStateAction<boolean>>;
  handleAddTag: (tagTitle: string, userId: string) => void;
};

const AddTagToUserModal = ({ user, setShowAddTagToUserModal, handleAddTag }: AddTagToUserModalProps) => {
  const [tagTitle, setTagTitle] = useState('');

  const handleOnClick = () => {
    if (tagTitle) {
      handleAddTag(tagTitle, user.id);
      setShowAddTagToUserModal(false);
    }
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTagTitle(event.target.value);
  };

  const handleOnClickCloseButton = () => {
    setShowAddTagToUserModal(false);
  };

  return (
    <Fragment>
      <BsModal.Body>
        <Form.Control
          type="text"
          id="inputTag"
          aria-describedby="inputAddTag"
          value={tagTitle}
          onChange={handleOnChange}
          aria-label="Adicionar tag"
          placeholder="Adicionar tag"
        />
      </BsModal.Body>
      <BsModal.Footer>
        <BsButton variant="secondary" onClick={handleOnClickCloseButton}>
          Fechar
        </BsButton>
        <BsButton variant="primary" className="text-white" onClick={handleOnClick} disabled={!tagTitle}>
          Adicionar tag
        </BsButton>
      </BsModal.Footer>
    </Fragment>
  );
};

export default AddTagToUserModal;
