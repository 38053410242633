import { AxiosError } from 'axios';
import { ChangeEvent, Fragment, createRef, useContext, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Typeahead } from 'react-bootstrap-typeahead';
import BsButton from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Offcanvas from 'react-bootstrap/Offcanvas';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';

// Components
import { AddIcon, Button, ChangeUserRoleModal, SendEmailsModal } from '../../components';
import { AddTagToUserModal, InviteUsersModal, Modal, Tag as TagComponent } from '../../components';
import { AppContext } from '../../state/app-state-context';
import { AuthService, LearnWorldsService } from '../../services';
import { Colors } from '../../constants/colors.constants';
import { Course } from '../../interfaces/learnworlds/course.interface';
import { DISTRICTS } from '../../model/options';
import { DatabaseService } from '../../services/database.service';
import { GetUsersOptions } from '../../interfaces/get-users-options.interface';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { Role } from '../../enums/role.enum';
import { Tag, tagConverter } from '../../model/tag/tag';
import { UserFilter } from '../../interfaces/user-filter.interface';
import { UserWithTagsAndCourses } from '../../interfaces/user.interface';
import { emailValidator } from '../../utils';
import { exportUsersToExcel } from '../../utils/export-to-excel/export-users-to-excel';
import { openToastAction } from '../../state/actions';
import { titleCase } from '../../utils/helpers.util';
import EmailService from '../../services/email.service';
import UserService from '../../services/user.service';

// Styles
import styles from './manage-users.component.module.scss';

interface UserPopover {
  userId: string;
  popoverOpen: boolean;
}

type UserFilterFormValue = {
  district: string[];
  city: string[];
  keyword: string;
  tag: Tag[];
  role: Role[];
};

const GET_USER_OPTIONS_INIT: GetUsersOptions = {
  pagination: {
    type: 'init',
  },
};

const USER_PAGE_SIZE = Number(process.env.REACT_APP_USERS_DASHBOARD_ADMIN_PAGE_SIZE ?? 20);

function ManageUsers() {
  const [usersWithTagsAndCourses, setUsersWithTagsAndCourses] = useState<UserWithTagsAndCourses[]>([]);
  const [checkedState, setCheckedState] = useState<{ userId: string; checked: boolean }[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<UserWithTagsAndCourses[]>([]);
  const [unselectedUsers, setUnselectedUsers] = useState<UserWithTagsAndCourses[]>([]);
  const [selectedUser, setSelectedUser] = useState<UserWithTagsAndCourses>();
  const [selectAllUsers, setSelectAllUsers] = useState(false);
  const [showInviteUsersModal, setShowInviteUsersModal] = useState(false);
  const [emails, setEmails] = useState('');
  const [showAddTagToUserModal, setShowAddTagToUserModal] = useState(false);
  const [isCoursesAlreadySet, setIsCoursesAlreadySet] = useState(false);
  const [showChangeUserRoleModal, setShowChangeUserRoleModal] = useState(false);
  const [showUserPopover, setShowUserPopover] = useState<UserPopover[]>();
  const [appIsLoading, setAppIsLoading] = useState(true);
  const [cities, setCities] = useState<string[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);
  const [userFilter, setUserFilter] = useState<UserFilter>();
  const [totalUsers, setTotalUsers] = useState(0);
  const [page, setPage] = useState(1);
  const numberOfPages = Math.ceil(totalUsers / USER_PAGE_SIZE);
  const [helpersAndTagsAreLoaded, setHelpersAndTagsAreLoaded] = useState(true);
  const [showWriteEmailModal, setShowWriteEmailModal] = useState(false);
  const [emailList, setEmailList] = useState<string[]>([]);
  const { dispatch } = useContext(AppContext);
  const { register, handleSubmit, control, reset, setValue } = useForm<UserFilterFormValue>({
    mode: 'onSubmit',
  });
  // This refs are needed to clear the selection in the Typeahead components
  const cityRef = createRef<any>();
  const districtRef = createRef<any>();
  const tagRef = createRef<any>();
  const roleRef = createRef<any>();

  useEffect(() => {
    const init = async () => {
      await getHelper();
      await getTags();
      setHelpersAndTagsAreLoaded(false);
    };

    init();
  }, []);

  useEffect(() => {
    if (!helpersAndTagsAreLoaded) {
      buildUsers();
    }
  }, [helpersAndTagsAreLoaded]);

  useEffect(() => {
    if (!showAddTagToUserModal && !showChangeUserRoleModal) {
      setSelectedUser(undefined);
    }
  }, [showAddTagToUserModal, showChangeUserRoleModal]);

  // Save in state the selected or the unselected users so when the user changes pages the users remains selected or unselected.
  // When the check all users is checked, the application uses the unselectedUsers state to remove users.
  // When the users are individually checked the application uses the selectedUsers.
  useEffect(() => {
    if (usersWithTagsAndCourses.length) {
      if (selectAllUsers) {
        if (unselectedUsers.length) {
          setCheckedState(
            usersWithTagsAndCourses.map((u) => {
              if (unselectedUsers.some((sU) => sU.id === u.id)) {
                return { userId: u.id, checked: false };
              }

              return { userId: u.id, checked: true };
            })
          );
        } else {
          setCheckedState(usersWithTagsAndCourses.map((u) => ({ userId: u.id, checked: true })));
        }
      } else {
        if (selectedUsers.length) {
          setCheckedState(
            usersWithTagsAndCourses.map((u) => {
              if (selectedUsers.some((sU) => sU.id === u.id)) {
                return { userId: u.id, checked: true };
              }

              return { userId: u.id, checked: false };
            })
          );
        } else {
          setCheckedState(usersWithTagsAndCourses.map((u) => ({ userId: u.id, checked: false })));
        }
      }
    }
  }, [usersWithTagsAndCourses]);

  const buildUsers = async (userOptions?: GetUsersOptions) => {
    const users = (await getUsers(userOptions ?? GET_USER_OPTIONS_INIT)) ?? [];

    setUsersWithTagsAndCourses(users);
    setIsCoursesAlreadySet(false);
    getUserCourses(users);
  };

  const getUsers = async (userOptions: GetUsersOptions) => {
    try {
      const response = await UserService.getUsers(userOptions);
      const usersFromDb = response.users;
      setTotalUsers(response.total);

      const usersFromDbWithTags: UserWithTagsAndCourses[] = [];
      const usersPopovers: UserPopover[] = [];

      for (const userFromDb of usersFromDb) {
        const userTags = tags.filter((t) => t.usersIds.includes(userFromDb.id));
        usersFromDbWithTags.push({ ...userFromDb, tags: userTags, courses: [] as Course[] });
        usersPopovers.push({ userId: userFromDb.id, popoverOpen: false });
      }

      buildUsersPopovers(usersFromDbWithTags);
      return usersFromDbWithTags;
    } catch {
      dispatch(openToastAction('Não foi possível listar os utilizadores', 'danger'));
    } finally {
      setAppIsLoading(false);
    }
  };

  const getUserCourses = async (users: UserWithTagsAndCourses[]) => {
    try {
      const usersWithCourses: UserWithTagsAndCourses[] = [];

      for (const user of users) {
        const userCourses = await LearnWorldsService.getUserCourses(user.email);
        usersWithCourses.push({ ...user, courses: userCourses });
      }

      setUsersWithTagsAndCourses(usersWithCourses);
    } catch {
      dispatch(openToastAction('Não foi possível listar os cursos dos utilizadores utilizadores', 'danger'));
    } finally {
      setIsCoursesAlreadySet(true);
    }
  };

  const handleRoleChange = async (userId: string, role: Role) => {
    try {
      await UserService.changeRole(userId, role);

      const usersUpdated = usersWithTagsAndCourses.map((u) => {
        if (u.id === userId) {
          u.role = role;
        }

        return u;
      });

      setUsersWithTagsAndCourses(usersUpdated);
      dispatch(openToastAction('Função de utilizador atualizado com sucesso.', 'success'));
    } catch {
      dispatch(openToastAction('Não foi possível mudar a função do utilizador.', 'danger'));
    }
  };

  const handleInviteUsers = async () => {
    const emailsToSendInvite = emails.split(/\r?\n/).filter((email) => email && emailValidator(email));
    setShowInviteUsersModal(false);
    setEmails('');

    try {
      if (!emailsToSendInvite.length) {
        throw new Error('Não introduziu emails válidos.');
      } else {
        await EmailService.inviteUsers(emailsToSendInvite);
        dispatch(openToastAction('Utilizadores convidados com sucesso.', 'success'));
      }
    } catch (error) {
      let errorMessage = '';
      if (error instanceof AxiosError) {
        errorMessage = error.response?.data.message;
      } else {
        if (error instanceof Error) {
          errorMessage = error.message;
        }
      }

      dispatch(openToastAction(errorMessage, 'danger'));
    }
  };

  const handleResetUserPassword = async (email: string, userId: string) => {
    handleClosePopover(userId);

    try {
      await AuthService.sendPasswordResetEmail(email);
      dispatch(
        openToastAction(
          'Email enviado com sucesso para o utilizador iniciar o processo de redefinição da palavra-passe',
          'success'
        )
      );
    } catch {
      dispatch(
        openToastAction('Não foi possível enviar o email para redefinição da palavra passe do utilizador', 'danger')
      );
    }
  };

  const handleOpenAddTagToUserModal = (user: UserWithTagsAndCourses) => {
    handleClosePopover(user.id);
    setSelectedUser(user);
    setShowAddTagToUserModal(true);
  };

  const handleAddTag = async (tagTitle: string, userId: string) => {
    try {
      const { isNewTag, tag } = await getTag(tagTitle.toLowerCase());

      if (isNewTag) {
        tag.usersIds = [userId];
        await DatabaseService.addEntry<Tag>(Tag.PATH, tag, tagConverter);
        updateUserTags(tag, userId);
        setTags([...tags, tag]);
      } else {
        if (tag.usersIds.includes(userId)) {
          dispatch(openToastAction('Utilizador já está inserido nessa tag', 'warning'));
        } else {
          tag.usersIds = [...tag.usersIds, userId];
          const updatedTags = tags.map((t) => {
            if (t.id === tag.id) {
              t.usersIds = tag.usersIds;
            }

            return t;
          });
          await DatabaseService.updateEntry<Tag>(Tag.PATH, tag, tagConverter);
          updateUserTags(tag, userId);
          setTags(updatedTags);
        }
      }
    } catch {
      dispatch(openToastAction('Não foi possível adicionar a tag', 'danger'));
    }
  };

  const updateUserTags = (tag: Tag, userId: string) => {
    const usersUpdated = usersWithTagsAndCourses.map((u) => {
      if (u.id === userId) {
        u.tags = [...u.tags, tag];
      }

      return u;
    });

    setUsersWithTagsAndCourses(usersUpdated);
  };

  const getTag = async (tagTitle: string) => {
    const tagFromDB = await DatabaseService.getByQueries<Tag>(
      Tag.PATH,
      tagConverter,
      { enablePagination: false },
      { field: 'title', queryType: 'string', value: tagTitle }
    );

    return tagFromDB.length > 0 ? { isNewTag: false, tag: tagFromDB[0] } : { isNewTag: true, tag: new Tag(tagTitle) };
  };

  const handleRemoveTagFromUserOnClick = async (userId: string, userTagId: string) => {
    try {
      const user = usersWithTagsAndCourses.find((u) => u.id === userId);
      if (user) {
        const tagToRemoveFromUser = user.tags.find((t) => t.id === userTagId);

        if (tagToRemoveFromUser) {
          tagToRemoveFromUser.usersIds = tagToRemoveFromUser.usersIds.filter((id) => id !== user.id);

          if (tagToRemoveFromUser.usersIds.length > 0) {
            await DatabaseService.updateEntry<Tag>(Tag.PATH, tagToRemoveFromUser, tagConverter);
          } else {
            await DatabaseService.hardDelete(Tag.PATH, tagToRemoveFromUser.id);
          }

          const usersUpdated = usersWithTagsAndCourses.map((u) => {
            if (u.id === user.id) {
              u.tags = u.tags.filter((t) => t.id !== tagToRemoveFromUser.id);
            } else {
              const indexOfTagToUpdate = u.tags.findIndex((t) => t.id === tagToRemoveFromUser.id);

              // -1 equal to not found
              if (indexOfTagToUpdate !== -1) {
                u.tags[indexOfTagToUpdate] = tagToRemoveFromUser;
              }
            }

            return u;
          });

          const updatedTags = tags.reduce((arr, t) => {
            if (t.id !== tagToRemoveFromUser.id) {
              return [...arr, t];
            }

            if (tagToRemoveFromUser.usersIds.length > 0) {
              t.usersIds = tagToRemoveFromUser.usersIds;
              return [...arr, t];
            } else {
              return arr;
            }
          }, [] as Tag[]);

          setTags(updatedTags);
          setUsersWithTagsAndCourses(usersUpdated);
        } else {
          throw new Error('A tag selecionada não foi encontrada no utilizador');
        }
      } else {
        throw new Error('Não foi possível encontrar o utilizador para remover a tag selecionada');
      }
    } catch (error) {
      let errorMessage = '';

      if (error instanceof Error) {
        errorMessage = error.message;
      }

      dispatch(
        openToastAction(
          errorMessage.length > 0 ? errorMessage : 'Não foi possível remover a tag do utilizador',
          'danger'
        )
      );
    }
  };

  const handleOpenChangeUserRoleModal = (user: UserWithTagsAndCourses) => {
    handleClosePopover(user.id);
    setSelectedUser(user);
    setShowChangeUserRoleModal(true);
  };

  const handleClosePopover = (userId: string, nextShow?: boolean) => {
    const newShowUserPopover = showUserPopover?.map((u) => {
      if (u.userId === userId) {
        u.popoverOpen = nextShow ?? false;
      }

      return u;
    });

    setShowUserPopover(newShowUserPopover);
  };

  const handleExportUsersToExcel = async () => {
    try {
      dispatch(openToastAction('A exportar utilizadores para excel...', 'info'));
      await exportUsersToExcel();
      dispatch(openToastAction('A iniciar o download do ficheiro...', 'success'));
    } catch {
      dispatch(openToastAction('Erro ao tentar exportar o utilizadores para excel', 'danger'));
    }
  };

  const getHelper = async () => {
    const userHelper = await UserService.getUsersHelper();

    if (userHelper) {
      setCities(userHelper.cities);
    }
  };

  const getTags = async () => {
    try {
      const tags = await DatabaseService.get<Tag>(Tag.PATH, tagConverter);
      setTags(tags);
    } catch {
      dispatch(openToastAction('Não foi possível obter tags', 'danger'));
    }
  };

  const handleResetForm = () => {
    resetForm();
    setPage(1);
    buildUsers();
    setSelectAllUsers(false);
    setUnselectedUsers([]);
    setSelectedUsers([]);
  };

  const resetForm = () => {
    setUserFilter(undefined);

    reset({
      city: [],
      district: [],
      keyword: '',
      tag: [],
      role: [],
    });

    const instanceCityRef = cityRef.current;
    if (instanceCityRef) {
      instanceCityRef.clear();
    }

    const instanceDistrictRef = districtRef.current;
    if (instanceDistrictRef) {
      instanceDistrictRef.clear();
    }

    const instanceTagRef = tagRef.current;
    if (instanceTagRef) {
      instanceTagRef.clear();
    }

    const instanceRoleRef = roleRef.current;
    if (instanceRoleRef) {
      instanceRoleRef.clear();
    }
  };

  const onSubmit: SubmitHandler<UserFilterFormValue> = async (formValues) => {
    setSelectAllUsers(false);
    setUnselectedUsers([]);
    setSelectedUsers([]);

    const { city, district, keyword, tag, role } = formValues;

    const filter: UserFilter = {
      district: '',
      city: '',
      keyword: '',
      usersId: [],
      role: '',
    };

    let numberOfFormFieldsWithValue = 0;

    if (city && city.length > 0) {
      filter.city = city[0];
      numberOfFormFieldsWithValue++;
    }

    if (district && district.length > 0) {
      filter.district = district[0];
      numberOfFormFieldsWithValue++;
    }

    if (keyword) {
      if (emailValidator(keyword)) {
        filter.keyword = keyword.split('@')[0];
      } else {
        filter.keyword = keyword;
      }

      numberOfFormFieldsWithValue++;
    }

    if (tag && tag.length > 0) {
      filter.usersId = tag.reduce((arr, t) => {
        const usersId = t.usersIds.filter((id) => !arr.includes(id));

        return [...arr, ...usersId];
      }, [] as string[]);
      numberOfFormFieldsWithValue++;
    }

    if (role && role.length > 0) {
      filter.role = role[0];
      numberOfFormFieldsWithValue++;
    }

    if (numberOfFormFieldsWithValue === 0) {
      dispatch(openToastAction('Não tem filtros selecionados', 'warning'));
      return;
    }

    setUserFilter(filter);

    const userOptions: GetUsersOptions = {
      pagination: {
        type: 'init',
      },
      userFilter: filter,
    };

    buildUsers(userOptions);
  };

  const buildUsersPopovers = (users: UserWithTagsAndCourses[]) => {
    const usersPopovers: UserPopover[] = [];

    for (const user of users) {
      usersPopovers.push({ userId: user.id, popoverOpen: false });
    }

    setShowUserPopover(usersPopovers);
  };

  const moveBackward = () => {
    const userOptions: GetUsersOptions = {
      pagination: {
        type: 'backward',
        moveBackward: {
          endBefore: usersWithTagsAndCourses[0].name,
        },
      },
      userFilter: userFilter,
    };

    buildUsers(userOptions);
    setPage(page - 1);
  };

  const moveForward = () => {
    const userOptions: GetUsersOptions = {
      pagination: {
        type: 'forward',
        moveForward: {
          startAfter: usersWithTagsAndCourses[usersWithTagsAndCourses.length - 1].name,
        },
      },
      userFilter: userFilter,
    };

    buildUsers(userOptions);
    setPage(page + 1);
  };

  const getUsersEmailAndOpenWriteEmailModal = async () => {
    let userOptions: GetUsersOptions;

    if (selectAllUsers) {
      userOptions = {
        pagination: {
          type: 'init',
          pageSize: 'all',
        },
        userFilter: userFilter,
      };
    } else {
      userOptions = {
        pagination: {
          type: 'init',
          pageSize: 'all',
        },
        userFilter: {
          city: '',
          district: '',
          keyword: '',
          role: '',
          usersId: selectedUsers.map((u) => u.id),
        },
      };
    }

    try {
      const users = (await UserService.getUsers(userOptions)).users;

      const usersEmail =
        selectAllUsers && unselectedUsers.length
          ? users.filter((u) => !unselectedUsers.some((uSU) => uSU.id === u.id)).map((u) => u.email)
          : users.map((u) => u.email);

      setEmailList(usersEmail);
      setShowWriteEmailModal(true);
    } catch {
      dispatch(openToastAction('Não foi possível obter os emails dos utilizadores', 'danger'));
    }
  };

  const handleOpenWriteEmailModal = (user: UserWithTagsAndCourses) => {
    handleClosePopover(user.id);
    setEmailList([user.email]);
    setShowWriteEmailModal(true);
  };

  const getAllUsersEmailAndOpenWriteEmailModal = async () => {
    const userOptions: GetUsersOptions = {
      pagination: {
        type: 'init',
        pageSize: 'all',
      },
    };

    try {
      const users = (await UserService.getUsers(userOptions)).users;
      const usersEmail = users.map((u) => u.email);
      setEmailList(usersEmail);
      setShowWriteEmailModal(true);
    } catch {
      dispatch(openToastAction('Não foi possível obter os emails dos utilizadores', 'danger'));
    }
  };

  const handleCheckIndividualUserBox = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const stateCheckbox = checkedState[index];
    const stateCheckboxUser = usersWithTagsAndCourses.find((u) => u.id === stateCheckbox.userId);
    const nextStateCheckbox = !stateCheckbox.checked;

    if (stateCheckboxUser) {
      if (event.target.checked) {
        if (selectAllUsers) {
          if (unselectedUsers.some((u) => u.id === stateCheckboxUser.id)) {
            setUnselectedUsers(unselectedUsers.filter((u) => u.id !== stateCheckboxUser.id));
          }
        } else {
          if (!selectedUsers.some((u) => u.id === stateCheckboxUser.id)) {
            setSelectedUsers([...selectedUsers, stateCheckboxUser]);
          }
        }
      } else {
        if (selectAllUsers) {
          if (!unselectedUsers.some((u) => u.id === stateCheckboxUser.id)) {
            const unselectedUsersArr = [...unselectedUsers, stateCheckboxUser];

            if (unselectedUsersArr.length === totalUsers) {
              setSelectAllUsers(false);
            } else {
              setUnselectedUsers(unselectedUsersArr);
            }
          }
        } else {
          if (selectedUsers.some((u) => u.id === stateCheckbox.userId)) {
            setSelectedUsers(selectedUsers.filter((u) => u.id !== usersWithTagsAndCourses[index].id));
          }
        }
      }

      const nextCheckedState = [...checkedState];
      nextCheckedState[index] = {
        ...stateCheckbox,
        checked: nextStateCheckbox,
      };
      setCheckedState(nextCheckedState);
    }
  };

  const handleSetSelectAllUsers = () => {
    setUnselectedUsers([]);
    setSelectedUsers([]);
    const nextState = !selectAllUsers;

    setCheckedState(checkedState.map((cS) => ({ ...cS, checked: nextState })));
    setSelectAllUsers(nextState);
  };

  return (
    <Container className="py-5">
      {appIsLoading ? (
        <Fragment />
      ) : (
        <Fragment>
          <Form onSubmit={handleSubmit(onSubmit)} className="mb-2">
            <Row>
              <Form.Group as={Col} controlId="formNameOrEmail" xs={3} className="pe-0">
                <Form.Label visuallyHidden={true}>Procurar por nome ou email</Form.Label>
                <Form.Control type="text" placeholder="Procurar por nome ou email" {...register('keyword')} />
              </Form.Group>

              <Form.Group as={Col} controlId="formCity" xs={2} className="ms-2 p-0">
                <Form.Label visuallyHidden={true}>Procurar por cidade</Form.Label>
                <Controller
                  control={control}
                  name="city"
                  render={({ field }) => (
                    <Typeahead
                      {...field}
                      id="formCity-select"
                      labelKey={(option: Option) => titleCase(option.toString())}
                      options={cities}
                      placeholder="Procure por uma cidade"
                      aria-describedby="Procure por uma cidade"
                      caseSensitive={false}
                      emptyLabel="Não foi encontrada nenhuma cidade"
                      paginationText="Exibir resultados adicionais..."
                      onChange={(options: Option[]) => {
                        if (options.length > 0) {
                          const selectedOption = options[0] as string;
                          setValue('city', [selectedOption]);
                        } else {
                          setValue('city', []);
                        }
                      }}
                      ref={cityRef}
                    />
                  )}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formDistrict" xs={2} className="ms-2 p-0">
                <Form.Label visuallyHidden={true}>Procurar por Distrito</Form.Label>
                <Controller
                  control={control}
                  name="district"
                  render={({ field }) => (
                    <Typeahead
                      {...field}
                      id="formDistrict-select"
                      labelKey="district"
                      options={[...DISTRICTS, 'Não definido']}
                      placeholder="Procure por distrito"
                      aria-describedby="Procure por distrito"
                      caseSensitive={false}
                      emptyLabel="Não foi encontrado nenhum distrito"
                      paginationText="Exibir resultados adicionais..."
                      onChange={(options: Option[]) => {
                        if (options.length > 0) {
                          const selectedOption = options[0] as string;
                          setValue('district', [selectedOption]);
                        } else {
                          setValue('district', []);
                        }
                      }}
                      ref={districtRef}
                    />
                  )}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formTags" xs={2} className="ms-2 p-0">
                <Form.Label visuallyHidden={true}>Filtrar por tags</Form.Label>
                <Controller
                  control={control}
                  name="tag"
                  render={({ field }) => (
                    <Typeahead
                      {...field}
                      id="formTags-select"
                      labelKey={(option: Option) => (option as Tag).title}
                      options={tags}
                      placeholder="Filtrar por tag"
                      aria-describedby="Filtrar por tag"
                      caseSensitive={false}
                      emptyLabel="Não foi encontrado nenhuma tag"
                      paginationText="Exibir resultados adicionais..."
                      onChange={(options: Option[]) => {
                        if (options.length > 0) {
                          const selectedOption = options[0] as Tag;
                          setValue('tag', [selectedOption]);
                        } else {
                          setValue('tag', []);
                        }
                      }}
                      ref={tagRef}
                    />
                  )}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formRole" xs={2} className="ms-2 p-0">
                <Form.Label visuallyHidden={true}>Filtrar por função</Form.Label>
                <Controller
                  control={control}
                  name="role"
                  render={({ field }) => (
                    <Typeahead
                      {...field}
                      id="formRole-select"
                      labelKey={(option: Option) => titleCase(option.toString())}
                      options={Object.values(Role)}
                      placeholder="Filtrar por função"
                      aria-describedby="Filtrar por função"
                      caseSensitive={false}
                      emptyLabel="Não foi encontrado nenhuma função"
                      paginationText="Exibir resultados adicionais..."
                      onChange={(options: Option[]) => {
                        if (options.length > 0) {
                          const selectedOption = options[0] as Role;
                          setValue('role', [selectedOption]);
                        } else {
                          setValue('role', []);
                        }
                      }}
                      ref={roleRef}
                    />
                  )}
                />
              </Form.Group>
            </Row>
            <div className="mt-2">
              <BsButton type="submit" className="text-white">
                Procurar
              </BsButton>

              <BsButton
                type="button"
                variant="secondary"
                onClick={handleResetForm}
                className="text-white ms-2"
                disabled={!userFilter}
              >
                Limpar filtros
              </BsButton>
            </div>
          </Form>
          <div className="d-flex justify-content-between mb-2">
            <p className="mb-0 align-self-end">
              Mostrando {page} de {totalUsers < 20 ? totalUsers : USER_PAGE_SIZE} utilizadores de {totalUsers}
            </p>
            <div className="align-self-end">
              <Dropdown drop="down">
                <Dropdown.Toggle variant="primary" className="text-white" id="dropdown-options">
                  Opções
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item as={BsButton} onClick={() => setShowInviteUsersModal(true)}>
                    Convidar Utilizadores
                  </Dropdown.Item>
                  <Dropdown.Item as={BsButton} onClick={handleExportUsersToExcel}>
                    Exportar todos utilizadores para excel
                  </Dropdown.Item>
                  <Dropdown.Item as={BsButton} onClick={getAllUsersEmailAndOpenWriteEmailModal}>
                    Enviar email para todos os utilizadores
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <Table striped bordered hover size="sm" responsive="md">
            <thead>
              <tr>
                <th className="text-center">
                  <Form.Check
                    aria-label="Selecionar todos os utilizadores"
                    checked={selectAllUsers}
                    onChange={handleSetSelectAllUsers}
                  />
                </th>
                <th>Nome</th>
                <th>Email</th>
                <th>Cidade</th>
                <th>Distrito</th>
                <th>Tags</th>
                <th>Cursos</th>
                <th>Função</th>
                <th>Gerir</th>
              </tr>
            </thead>
            <tbody>
              {usersWithTagsAndCourses.map((user, index) => {
                return (
                  <tr key={user.id}>
                    <td className="text-center align-middle">
                      <Form.Check
                        aria-label="Selecionar todos os utilizadores"
                        checked={checkedState[index]?.checked ?? false}
                        onChange={(e) => handleCheckIndividualUserBox(e, index)}
                      />
                    </td>
                    <td className="align-middle">{titleCase(user.name + ' ' + user.surname)}</td>
                    <td className="align-middle">{user.email}</td>
                    <td className="align-middle">{titleCase(user.city)}</td>
                    <td className="align-middle">{titleCase(user.district)}</td>
                    <td className="align-middle">
                      <div className="d-flex flex-wrap">
                        {user.tags.map((tag) => (
                          <TagComponent
                            key={`${user.id} - ${tag.id}`}
                            text={tag.title}
                            backgroundColor={Colors.gray500}
                            classes="me-1 d-flex align-items-center"
                            userTags={true}
                            userId={user.id}
                            userTagId={tag.id}
                            userHandleCloseIconClick={handleRemoveTagFromUserOnClick}
                          />
                        ))}
                      </div>
                    </td>
                    <td className="align-middle">
                      <div className={`d-flex flex-wrap ${isCoursesAlreadySet ? '' : 'justify-content-center'}`}>
                        {isCoursesAlreadySet ? (
                          user.courses.length > 0 ? (
                            user.courses.map((course) => (
                              <TagComponent
                                key={`${user.id} - ${course.id}`}
                                text={course.title}
                                backgroundColor={Colors.color01}
                                classes="me-1"
                              />
                            ))
                          ) : (
                            <span className="ms-1">Não tem cursos</span>
                          )
                        ) : (
                          <div>
                            <Spinner animation="border" variant="secondary" role="status">
                              <span className="visually-hidden">A carregar os cursos...</span>
                            </Spinner>
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="align-middle">{titleCase(user.role)}</td>
                    <td className="d-flex justify-content-center">
                      <OverlayTrigger
                        rootClose
                        trigger="click"
                        placement="bottom"
                        show={showUserPopover?.find((u) => u.userId === user.id)?.popoverOpen ?? false}
                        onToggle={(nextShow) => handleClosePopover(user.id, nextShow)}
                        overlay={
                          <Popover id="popover-user-management">
                            <Popover.Body className="d-flex flex-column align-items-start justify-content-between">
                              <Button
                                link={true}
                                text="Redefinir palavra-passe"
                                classes="p-0"
                                handleButtonPressed={() => handleResetUserPassword(user.email, user.id)}
                              />
                              <Button
                                link={true}
                                text="Adicionar tag"
                                classes="p-0 mt-1"
                                handleButtonPressed={() => handleOpenAddTagToUserModal(user)}
                              />
                              <Button
                                link={true}
                                text="Mudar função de utilizador"
                                classes="p-0 mt-1"
                                handleButtonPressed={() => handleOpenChangeUserRoleModal(user)}
                              />
                              <Button
                                link={true}
                                text="Enviar email"
                                classes="p-0 mt-1"
                                handleButtonPressed={() => handleOpenWriteEmailModal(user)}
                              />
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <BsButton className="py-1 px-2">
                          <AddIcon outerClasses={styles['add-icon-outer']} innerClasses={styles['add-icon-inner']} />
                        </BsButton>
                      </OverlayTrigger>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div className="d-flex justify-content-between">
            <BsButton className="text-white" onClick={moveBackward} disabled={page === 1}>
              Página anterior
            </BsButton>
            <BsButton className="text-white" onClick={moveForward} disabled={page === numberOfPages}>
              Próxima página
            </BsButton>
          </div>
        </Fragment>
      )}

      <Modal
        setShowModal={setShowInviteUsersModal}
        showModal={showInviteUsersModal}
        title="Convidar Utilizadores"
        Component={
          <InviteUsersModal
            emails={emails}
            handleInviteUsers={handleInviteUsers}
            setEmails={setEmails}
            setShowInviteUsersModal={setShowInviteUsersModal}
          />
        }
      />
      {showAddTagToUserModal && selectedUser && (
        <Modal
          setShowModal={setShowAddTagToUserModal}
          showModal={showAddTagToUserModal}
          title="Adicionar Tag"
          Component={
            <AddTagToUserModal
              user={selectedUser}
              handleAddTag={handleAddTag}
              setShowAddTagToUserModal={setShowAddTagToUserModal}
            />
          }
        />
      )}

      {showChangeUserRoleModal && selectedUser && (
        <Modal
          setShowModal={setShowChangeUserRoleModal}
          showModal={showChangeUserRoleModal}
          title="Mudar função de utilizador"
          Component={
            <ChangeUserRoleModal
              user={selectedUser}
              handleRoleChange={handleRoleChange}
              setShowChangeUserRoleModal={setShowChangeUserRoleModal}
            />
          }
        />
      )}

      <Modal
        setShowModal={setShowWriteEmailModal}
        showModal={showWriteEmailModal}
        title="Enviar Email"
        Component={
          <SendEmailsModal setShowModal={setShowWriteEmailModal} emailList={emailList} setEmailList={setEmailList} />
        }
      />
      <div className="offcanvas-container-management-users">
        <Offcanvas show={selectAllUsers || selectedUsers.length} placement="bottom" backdrop={false} scroll={true}>
          <Offcanvas.Body>
            <div className="d-flex justify-content-between">
              <p className="mb-0 align-self-center">
                (com {selectAllUsers ? totalUsers - unselectedUsers.length : selectedUsers.length} utilizadores
                selecionados)
              </p>
              <BsButton
                type="button"
                variant="outline-light"
                onClick={getUsersEmailAndOpenWriteEmailModal}
                className="ms-3"
              >
                Enviar email
              </BsButton>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </Container>
  );
}

export default ManageUsers;
