import { Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';

type MessageProps = {
  header?: string;
  message?: string;
  variant?: string;
  link?: string;
  linkMessage?: string;
};

function Message({ header = 'Ooops! Algo deu errado!', message, variant = 'danger', link, linkMessage }: MessageProps) {
  return (
    <Alert variant={variant}>
      <Alert.Heading>{header}</Alert.Heading>
      {message && <p>{message}</p>}
      {link && linkMessage && (
        <Alert.Link as={Link} to={link}>
          {linkMessage}
        </Alert.Link>
      )}
    </Alert>
  );
}

export default Message;
