import { Fragment, useContext, useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useList, useToggle, useWindowSize } from 'react-use';
import { useNavigate } from 'react-router-dom';
import BootstrapButton from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

import { AppContext } from '../../state/app-state-context';
import { Colors } from '../../constants/colors.constants';
import { DatabaseService } from '../../services/database.service';
import { ELASTIC_LEADERS_PATH, PaginationSettings } from '../../interfaces/elastic/search-api.interface';
import { FilterButton, InputSearch, ListElement, NewButton, PageHeader, Tag } from '../../components';
import { FilteredFormValues } from '../../interfaces/filtered-form-values.interface';
import { OpinionLeader, leaderElasticConverter } from '../../model/opinion-leaders/opinion-leader';
import { QueryFormat } from '../../interfaces/database.interface';
import { QueryTypes } from '../../enums/query-types.enum';
import { ResultsType } from '../../enums/results-type.enum';
import { Role } from '../../enums/role.enum';
import { SOCIAL_PROBLEMS } from '../../model/options';
import { ScreenBreakpoint } from '../../enums/screen-breakpoint.enum';

import {
  cleanString,
  cutString,
  updateQueryFormatArray,
  updateSetSelectedFilteredValues,
} from '../../utils/helpers.util';
import { openSidebarAction, openToastAction, setDatabaseEntityAction } from '../../state/actions';
import DatabaseQuery from '../../components/database/database-query/database-query';

import headerImg from '../../images/lideres-sociais/header.png';

const PAGE_SIZE = Number(process.env.REACT_APP_PAGE_SIZE) || 12;

interface OpinionLeaderElementDetail {
  name: string;
  image: string;
  description: string;
  academicBackground: string[];
  detailsLinkPage: string;
  id: string;
}

function ListOpinionLeaders() {
  const { width: windowWidth } = useWindowSize();
  const {
    dispatch,
    state: { currentFirestoreUser },
  } = useContext(AppContext);
  const [opinionLeadersDetails, setOpinionLeadersDetails] = useState<OpinionLeaderElementDetail[]>([]);
  const [opinionLeaders, setOpinionLeaders] = useState<OpinionLeader[]>([]);
  const [filteredOpinionLeaders, setFilteredOpinionLeaders] = useState<OpinionLeader[]>([]);
  const [resultsType, setResultsType] = useState<ResultsType>(ResultsType.Normal);
  const [queryFormatArray, { set: setQueryFormatArray }] = useList<QueryFormat>([]);
  const [selectedFilteredValues, setSelectedFilteredValues] = useState<FilteredFormValues>({});
  const [appIsLoading, setAppIsLoading] = useState(true);
  const [opinionLeadersPaginationSettings, setOpinionLeadersPaginationSettings] = useState<PaginationSettings>();
  const [filteredOpinionLeadersPaginationSettings, setFilteredOpinionLeadersPaginationSettings] =
    useState<PaginationSettings>();
  const [resetSearchTerm, setResetSearchTerm] = useToggle(false);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (opinionLeaders.length === 0) {
      fetchOpinionLeaders();
    }
  }, []);

  useEffect(() => {
    const hasFilters = async () => {
      if (queryFormatArray.length > 0) {
        await fetchFilteredOpinionLeaders(1, true);
      } else {
        if (opinionLeaders.length > 0) {
          buildOpinionLeadersElementDetails(opinionLeaders);
        }
      }
    };

    hasFilters();
  }, [queryFormatArray]);

  const handleSubmitNewOpinionLeader = () => {
    dispatch(setDatabaseEntityAction());
    navigate('novo-lider-de-opiniao');
  };

  const fetchOpinionLeaders = async (pageNumber = 1) => {
    try {
      const { paginationSettings, opinionLeadersFromDb } = await fetchOpinionLeadersDataWithDescription(pageNumber);

      setOpinionLeadersPaginationSettings(paginationSettings);

      if (opinionLeadersFromDb.length > 0) {
        setResultsType(ResultsType.Normal);
        setOpinionLeaders([...opinionLeaders, ...opinionLeadersFromDb]);
        buildOpinionLeadersElementDetails([...opinionLeaders, ...opinionLeadersFromDb]);
      }
    } catch {
      dispatch(openToastAction('Não foi possível carregar os líderes de opinião!', 'danger'));
    }
  };

  const fetchFilteredOpinionLeaders = async (pageNumber = 1, replace = false) => {
    try {
      const { paginationSettings, opinionLeadersFromDb } = await fetchOpinionLeadersDataWithDescription(pageNumber);

      setFilteredOpinionLeadersPaginationSettings(paginationSettings);

      if (opinionLeadersFromDb.length > 0) {
        setResultsType(ResultsType.Filter);

        if (replace) {
          setFilteredOpinionLeaders(opinionLeadersFromDb);
          buildOpinionLeadersElementDetails(opinionLeadersFromDb);
        } else {
          setFilteredOpinionLeaders([...filteredOpinionLeaders, ...opinionLeadersFromDb]);
          buildOpinionLeadersElementDetails([...filteredOpinionLeaders, ...opinionLeadersFromDb]);
        }
      } else {
        dispatch(openToastAction('Não foram encontrados líderes de opinião com os filtros selecionados.', 'warning'));
        resetFilter();
      }
    } catch {
      dispatch(openToastAction('Não foram encontrados líderes de opinião com os filtros selecionados.', 'warning'));
    }
  };

  const fetchOpinionLeadersDataWithDescription = async (pageNumber: number) => {
    const max = opinionLeaders.length + PAGE_SIZE;
    const data = await fetchOpinionLeadersData(pageNumber);

    const opinionLeadersFromDb = data.opinionLeadersFromDb.filter(
      (outerOpl) =>
        outerOpl.description &&
        outerOpl.description.length > 0 &&
        !opinionLeaders.map((innerOpl) => innerOpl.id).includes(outerOpl.id)
    );

    if (max === opinionLeadersFromDb.length + opinionLeaders.length) {
      return {
        paginationSettings: data.paginationSettings,
        opinionLeadersFromDb,
      };
    }

    let paginationSettings = data.paginationSettings;

    for (pageNumber = paginationSettings.current + 1; pageNumber <= paginationSettings.total_pages; pageNumber++) {
      const moreData = await fetchOpinionLeadersData(pageNumber);

      for (const outerOpl of moreData.opinionLeadersFromDb) {
        if (opinionLeaders.map((innerOpl) => innerOpl.id).includes(outerOpl.id)) {
          continue;
        }

        if (outerOpl.description && outerOpl.description.length > 0) {
          opinionLeadersFromDb.push(outerOpl);

          if (max === opinionLeadersFromDb.length + opinionLeaders.length) {
            break;
          }
        }
      }

      if (max === opinionLeadersFromDb.length + opinionLeaders.length) {
        break;
      }

      paginationSettings = moreData.paginationSettings;
    }

    return {
      paginationSettings,
      opinionLeadersFromDb,
    };
  };

  const fetchOpinionLeadersData = async (pageNumber: number) => {
    const filterArr = [...queryFormatArray];

    if (currentFirestoreUser?.role !== Role.Admin) {
      filterArr.push({ field: 'approved', value: true, queryType: 'boolean' });
    }

    const {
      meta: { page: paginationSettings },
      results: opinionLeadersFromDb,
    } = await DatabaseService.getByQueriesElastic<OpinionLeader>(
      ELASTIC_LEADERS_PATH,
      leaderElasticConverter,
      { enablePagination: true, currentPage: pageNumber, limit: PAGE_SIZE },
      { enableSorting: true, sortBy: { name: 'asc' } },
      { enableSearchByTerm: false },
      ...filterArr
    );

    return {
      paginationSettings,
      opinionLeadersFromDb,
    };
  };

  const buildOpinionLeadersElementDetails = (opinionLeadersFromDb: OpinionLeader[]) => {
    const opinionLeadersElementDetails: OpinionLeaderElementDetail[] = [];

    for (const opinionLeader of opinionLeadersFromDb) {
      const opinionLeaderImage = fetchOpinionLeaderImage(opinionLeader.problems ?? {});
      const opinionLeaderAcademicBackground = getAcademicBackground(opinionLeader.academicBackground);

      const opinionLeaderElementDetail: OpinionLeaderElementDetail = {
        name: opinionLeader.name ?? '',
        description: cutString(opinionLeader.description ?? '', 300),
        id: opinionLeader.id,
        detailsLinkPage: `/lideres-de-opiniao/${
          opinionLeader && opinionLeader.name ? opinionLeader.name.replace(/\s+/g, '') : 'nome-indefinido'
        }/${encodeURIComponent(opinionLeader.id)}`,
        image: opinionLeaderImage,
        academicBackground: opinionLeaderAcademicBackground,
      };

      opinionLeadersElementDetails.push(opinionLeaderElementDetail);
    }

    setOpinionLeadersDetails(opinionLeadersElementDetails);
    setAppIsLoading(false);
  };

  const fetchOpinionLeaderImage = (socialProblems: { [key: string]: string[] }) => {
    const placeholderImg = 'placeholder.png';

    if (Object.keys(socialProblems).length === 0) {
      return placeholderImg;
    }

    const socialProblem = Object.keys(socialProblems)[0];
    const socialLeaderImage = SOCIAL_PROBLEMS.find((sP) => sP.id === Number(socialProblem))?.image_social_leader;

    if (!socialLeaderImage) {
      return placeholderImg;
    }

    return `lideres-sociais/${socialLeaderImage}.jpg`;
  };

  const getAcademicBackground = (academicBackground?: string[]) => {
    if (!academicBackground || academicBackground.length === 0) {
      return [];
    }

    if (academicBackground.length <= 2) {
      return academicBackground.map((ac) => ac.slice(ac.indexOf(' ') + 1));
    } else {
      const [firstAcademicBackground, secondAcademicBackground, ...rest] = academicBackground.map((ac) =>
        ac.slice(ac.indexOf(' ') + 1)
      );

      return [firstAcademicBackground, secondAcademicBackground, `+ ${rest.length} Background Académico`];
    }
  };

  const handleFilterBtnPressed = () =>
    dispatch(
      openSidebarAction(
        <DatabaseQuery
          key={Math.random()} // Create new instance
          setQueryFormat={setQueryFormatArray}
          queryOptions={[QueryTypes.AcademicBackground, QueryTypes.Problems]}
          selectedFilters={selectedFilteredValues}
          setSelectedFilters={setSelectedFilteredValues}
        />
      )
    );

  const handleGetMoreBtnPressed = async () => {
    if (opinionLeadersDetails.length > 0) {
      if (
        resultsType === ResultsType.Normal &&
        opinionLeadersPaginationSettings &&
        opinionLeadersPaginationSettings.current < opinionLeadersPaginationSettings.total_pages
      ) {
        await fetchOpinionLeaders(opinionLeadersPaginationSettings.current + 1);
        return;
      }

      if (
        resultsType === ResultsType.Filter &&
        filteredOpinionLeadersPaginationSettings &&
        filteredOpinionLeadersPaginationSettings.current < filteredOpinionLeadersPaginationSettings.total_pages
      ) {
        await fetchFilteredOpinionLeaders(filteredOpinionLeadersPaginationSettings.current + 1);
        return;
      }

      if (
        resultsType === ResultsType.Name &&
        filteredOpinionLeadersPaginationSettings &&
        filteredOpinionLeadersPaginationSettings.current < filteredOpinionLeadersPaginationSettings.total_pages
      ) {
        await fetchOpinionLeadersBySearch(filteredOpinionLeadersPaginationSettings.current + 1, false);
        return;
      }
    }
  };

  const handleDeleteFilter = (key: string, filterToRemove: string) => {
    const updatedFilter = updateSetSelectedFilteredValues({ ...selectedFilteredValues }, key, filterToRemove);

    if (
      updatedFilter &&
      Object.values(updatedFilter).some(
        (v) => (v && Array.isArray(v) && (v as string[]).length > 0) || (v && typeof v === 'string' && v.length > 0)
      )
    ) {
      const duplicateQueryFormatArray = updateQueryFormatArray([...queryFormatArray], key, filterToRemove);

      setSelectedFilteredValues(updatedFilter);
      setQueryFormatArray(duplicateQueryFormatArray);
    } else {
      resetFilter();
      buildOpinionLeadersElementDetails(opinionLeaders);
    }
  };

  const resetFilter = () => {
    setSelectedFilteredValues({});
    setQueryFormatArray([]);
    setFilteredOpinionLeadersPaginationSettings(undefined);
    setFilteredOpinionLeaders([]);
    setResultsType(ResultsType.Normal);
  };

  const searchTermCallback = async (searchTermFromInput: string) => {
    if (searchTermFromInput.length === 0) {
      setResetSearchTerm(false);
      resetFilter();
    } else {
      fetchOpinionLeadersBySearch(1, true, searchTermFromInput);
    }
  };

  const fetchOpinionLeadersBySearch = async (pageNumber = 1, replace = false, searchTermFromInput?: string) => {
    if (searchTermFromInput) {
      setSearchTerm(searchTermFromInput);
    } else {
      searchTermFromInput = searchTerm;
    }

    const filterArr = [...queryFormatArray];

    if (currentFirestoreUser?.role !== Role.Admin) {
      filterArr.push({ field: 'approved', value: true, queryType: 'boolean' });
    }

    filterArr.push({ field: '', value: searchTermFromInput, queryType: 'substring' });

    try {
      const {
        meta: { page: paginationSettings },
        results: opinionLeadersFromDb,
      } = await DatabaseService.getByQueriesElastic<OpinionLeader>(
        ELASTIC_LEADERS_PATH,
        leaderElasticConverter,
        { enablePagination: true, currentPage: pageNumber, limit: PAGE_SIZE },
        { enableSorting: true, sortBy: { name: 'asc' } },
        { enableSearchByTerm: true, searchBy: { name: {}, description: {} } },
        ...filterArr
      );

      setFilteredOpinionLeadersPaginationSettings(paginationSettings);

      if (opinionLeadersFromDb.length > 0) {
        setResultsType(ResultsType.Name);

        if (replace) {
          setFilteredOpinionLeaders(opinionLeadersFromDb);
          buildOpinionLeadersElementDetails(opinionLeadersFromDb);
        } else {
          setFilteredOpinionLeaders([...filteredOpinionLeaders, ...opinionLeadersFromDb]);
          buildOpinionLeadersElementDetails([...filteredOpinionLeaders, ...opinionLeadersFromDb]);
        }
      } else {
        dispatch(openToastAction('Não foram encontrados projetos para o termo procurado.', 'warning'));
        resetFilter();
      }
    } catch {
      dispatch(openToastAction('Não foi possível carregar os projetos!', 'danger'));
    }
  };

  return (
    <Fragment>
      {appIsLoading ? (
        <Fragment />
      ) : (
        <Container className="py-5">
          <PageHeader
            title="Líderes de Opinião"
            description="Pessoas que, pelo trabalho desenvolvido na área social na mitigação de algum problema, se tornaram referências de opinião e de ação nessa mesma área. Aqui podes conhecê-los, inspirar-te e até entrar em contacto com eles."
            buttons={[
              <NewButton
                key="new-org-btn"
                theme="teal"
                accent="white"
                size={windowWidth < ScreenBreakpoint.Large ? 'sm' : 'lg'}
                outlined={false}
                onClick={handleSubmitNewOpinionLeader}
              >
                Submeter Novo Líder
              </NewButton>,
            ]}
            img={<img src={headerImg} style={{ width: '450px', height: '450px' }}></img>}
          />
          <div className="filters">
            <FilterButton handleButtonPressed={handleFilterBtnPressed} />
            <InputSearch searchButtonClicked={searchTermCallback} resetSearchTerm={resetSearchTerm} />
          </div>
          {resultsType === ResultsType.Filter && (
            <div className="mt-2 d-flex flex-wrap">
              {Object.entries(selectedFilteredValues).map(([key, value]) => {
                if (Array.isArray(value)) {
                  return value.map((v: string, index) => (
                    <div key={`${v}-${index}`} className="me-2 mt-2">
                      <Tag
                        text={v.includes('#') ? v.split('#')[1] : cleanString(v)}
                        backgroundColor={Colors.blue04}
                        filterTags={true}
                        filterObjectKey={key}
                        filterObjectValue={v}
                        filterHandleCloseIconClick={handleDeleteFilter}
                      />
                    </div>
                  ));
                }
              })}
            </div>
          )}
          {resultsType === ResultsType.Name && (
            <div className="mt-4">
              <h6>
                <b>Resultados ({filteredOpinionLeadersPaginationSettings?.total_results})</b>
              </h6>
              <div className="d-flex align-items-center">
                {filteredOpinionLeaders.length ? (
                  <p className="mb-0">
                    Existem resultados para <b>&quot;{searchTerm}&quot;</b>
                  </p>
                ) : (
                  <p className="mb-0">
                    Não existem resultados para <b>&quot;{searchTerm}&quot;</b>
                  </p>
                )}
                <BootstrapButton
                  variant="danger"
                  className="py-1 px-2 ms-2"
                  onClick={() => {
                    resetFilter();
                    setResetSearchTerm(true);
                  }}
                >
                  <MdClose size={18} color="#fff" />
                </BootstrapButton>
              </div>
            </div>
          )}
          {opinionLeadersDetails.length > 0 && (
            <>
              <div className="results-list">
                {opinionLeadersDetails.map((opinionLeaderElementDetails) => (
                  <ListElement
                    key={opinionLeaderElementDetails.id}
                    title={opinionLeaderElementDetails.name}
                    description={opinionLeaderElementDetails.description}
                    tags={opinionLeaderElementDetails.academicBackground}
                    link={opinionLeaderElementDetails.detailsLinkPage}
                    imageSrc={opinionLeaderElementDetails.image}
                  />
                ))}
              </div>
              <div>
                {resultsType === ResultsType.Normal &&
                  opinionLeadersPaginationSettings &&
                  opinionLeadersPaginationSettings.current < opinionLeadersPaginationSettings.total_pages && (
                    <div className="row">
                      <div className="col-12 d-flex justify-content-center">
                        <NewButton
                          theme="green"
                          accent="white"
                          outlined={false}
                          size="lg"
                          onClick={handleGetMoreBtnPressed}
                        >
                          Carregar mais
                        </NewButton>
                      </div>
                    </div>
                  )}
                {resultsType === ResultsType.Filter &&
                  filteredOpinionLeadersPaginationSettings &&
                  filteredOpinionLeadersPaginationSettings.current <
                    filteredOpinionLeadersPaginationSettings.total_pages && (
                    <div className="row">
                      <div className="col-12 d-flex justify-content-center">
                        <NewButton
                          theme="green"
                          accent="white"
                          outlined={false}
                          size="lg"
                          onClick={handleGetMoreBtnPressed}
                        >
                          Carregar mais
                        </NewButton>
                      </div>
                    </div>
                  )}
                {resultsType === ResultsType.Name &&
                  filteredOpinionLeadersPaginationSettings &&
                  filteredOpinionLeadersPaginationSettings.current <
                    filteredOpinionLeadersPaginationSettings.total_pages && (
                    <div className="row">
                      <div className="col-12 d-flex justify-content-center">
                        <NewButton
                          theme="green"
                          accent="white"
                          outlined={false}
                          size="lg"
                          onClick={handleGetMoreBtnPressed}
                        >
                          Carregar mais
                        </NewButton>
                      </div>
                    </div>
                  )}
              </div>
            </>
          )}
        </Container>
      )}
    </Fragment>
  );
}

export default ListOpinionLeaders;
