import { Action } from '../../model/actions/action';
import { Entity } from '../../model/Entity';
import { parse } from '../file-parser';

function trimmedToActions(trimmed: string[][]): Action[] {
  const actions: Action[] = [];
  trimmed.forEach((row, index) => {
    const action = new Action(
      Entity.generateId(Action.PREFIX),
      setProblems(row.slice(0, 9)),
      row.slice(9, 11).filter((e: string) => e),
      row[11],
      row[12],
      row[13],
      row[14] + ' ' + row[15] + ' ' + row[16] + ' ' + row[17] + ' ' + row[18],
      true
    );
    actions.push(action);
  });
  console.log(actions);
  return actions;
}

export async function parseActions(file: File): Promise<Action[]> {
  const rawFile = await parse(file);
  const trimmed = trimRawFile(rawFile);
  return trimmedToActions(trimmed);
}

function trimRawFile(rawFile: string[][]): string[][] {
  //remove first two rows
  rawFile.splice(0, 2);
  rawFile.forEach((row) => {
    //remove unused final columns
    row.splice(19, row.length);
  });
  console.log('Number of actions parsed:', rawFile.length);
  return rawFile;
}

function setProblems(rawProblems: string[]): { [key: string]: string[] } {
  const problems = new Map<string, string[]>();
  if (rawProblems[0]) {
    problems.set(
      rawProblems[0],
      rawProblems.slice(1, 3).filter((e: string) => e)
    );
  }
  if (rawProblems[3]) {
    problems.set(rawProblems[3], [rawProblems[4]]);
  }
  if (rawProblems[5]) {
    problems.set(rawProblems[5], [rawProblems[6]]);
  }
  if (rawProblems[7]) {
    problems.set(rawProblems[7], [rawProblems[8]]);
  }
  return Object.fromEntries(problems);
}
