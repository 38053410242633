import './filter-button.component.scss';

type FilterButtonProps = {
  handleButtonPressed: () => void;
};

function FilterButton({ handleButtonPressed }: FilterButtonProps) {
  return (
    <button className="filter-button" type="button" onClick={handleButtonPressed}>
      <svg viewBox="0 0 32 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.60001 21.125C0.716265 21.125 9.59999e-06 20.3975 9.59999e-06 19.5C9.59999e-06 18.6672 0.617513 17.9804 1.41377 17.8864L1.60002 17.875H19.2C19.2 15.1823 21.3487 13 24 13H27.2C29.8513 13 32 15.1823 32 17.875V21.125C32 23.8177 29.8513 26 27.2 26H24C21.3487 26 19.2 23.8177 19.2 21.125L1.60001 21.125ZM27.2 16.25H24C23.18 16.25 22.5037 16.8771 22.4113 17.6858L22.4 17.875V21.125C22.4 21.9578 23.0175 22.6446 23.8138 22.7386L24 22.75H27.2C28.02 22.75 28.6963 22.1228 28.7888 21.3141L28.8 21.125V17.875C28.8 17.0422 28.1825 16.3553 27.3862 16.2614L27.2 16.25ZM8 1.42574e-05C10.6513 1.42574e-05 12.8 2.18232 12.8 4.87501H30.4C31.2837 4.87501 32 5.60246 32 6.50001C32 7.33282 31.3825 8.01964 30.5862 8.11357L30.4 8.12499H12.8C12.8 10.8177 10.6513 13 8 13H4.8C2.14873 13 0 10.8177 0 8.12499V4.87499C0 2.18231 2.14873 0 4.8 0L8 1.42574e-05ZM8 3.25001H4.80001C3.98001 3.25001 3.30375 3.87716 3.21127 4.68586L3.20002 4.87502V8.12502C3.20002 8.95783 3.81753 9.64465 4.61378 9.73857L4.80003 9.75H8.00003C8.82003 9.75 9.49628 9.12285 9.58876 8.31415L9.60001 8.12499V4.87499C9.60001 4.04218 8.98251 3.35536 8.18625 3.26144L8 3.25001Z" />
      </svg>
      <p>Filtrar resultados</p>
    </button>
  );
}

export default FilterButton;
