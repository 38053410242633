import * as Yup from 'yup';
import { Action, actionConverter } from '../../../model/actions/action';
import { AppContext } from '../../../state/app-state-context';
import { DatabaseService } from '../../../services/database.service';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { Entity } from '../../../model/Entity';
import { Modal } from '../../index';
import { SubmitHandler, useForm } from 'react-hook-form';
import { TRUE_FALSE } from '../../../model/options';
import { openToastAction } from '../../../state/actions';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import Button from '../../button/button.component';
import ConfirmDeleteModalBodyComponent from '../../modals-body/confirm-modal/confirm-delete-modal.component';
import Form from 'react-bootstrap/esm/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ProblemsForm from '../problems-form';
import TargetAudienceForm from '../target-audience-form';

type LeaderListProps = {
  actions: Action[];
  setActions: Dispatch<SetStateAction<Action[]>>;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  refreshAction: (id: string) => Promise<void>;
};

type FormValues = {
  id: string;
  problems: { [key: string]: string[] };
  targetAudience: string[];
  durationDescription: string;
  actionDescription: string;
  actionHowTo: string;
  inspirationLinks: string;
};

function ActionsList({ actions, setActions, setIsEditing, refreshAction }: LeaderListProps) {
  const { dispatch } = useContext(AppContext);
  const [edit, setEdit] = useState<Action>();
  const [complexForms, setComplexForms] = useState<any>({
    problems: '',
    targetAudience: '',
  });
  const [showModal, setShowModal] = useState(false);
  const [modalAction, setModalAction] = useState<Action>();

  const validationSchema = Yup.object().shape({
    id: Yup.string().default(edit?.id),
    targetAudience: Yup.array(),
    durationDescription: Yup.string(),
    actionDescripton: Yup.string(),
    actionHowTo: Yup.string(),
    inspirationLinks: Yup.string(),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm<FormValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  const onSubmit: SubmitHandler<FormValues> = async (formValues) => {
    try {
      const approved = complexForms.approved === TRUE_FALSE.true;
      const action = new Action(
        formValues.id,
        complexForms.problems,
        complexForms.targetAudience,
        formValues.durationDescription,
        formValues.actionDescription,
        formValues.actionHowTo,
        formValues.inspirationLinks,
        approved,
        edit?.createdAt,
        edit?.modifiedAt,
        edit?.createdById,
        edit?.createdByName
      );
      await DatabaseService.updateEntry(Action.PATH, action, actionConverter);
      setEdit(undefined);
      setIsEditing(false);
      setComplexForms(undefined);
      setIsEditing(false);
      setActions(actions.map((l) => (l.id === action.id ? action : l)));
      reset();
      dispatch(openToastAction('Ação de serviço salva com sucesso!', 'success'));
    } catch (error) {
      dispatch(openToastAction('Problema ao editar o lider de opinião. Tente novamente mais tarde.', 'success'));
    }
  };

  function handleEdit(action: Action) {
    setEdit(action);
    setIsEditing(true);
    setComplexForms({
      problems: action.problems,
      targetAudience: action.targetAudience,
    });
  }

  async function reverseApproved(action: Action) {
    const copy = action;
    try {
      copy.approved = !copy.approved;
      await DatabaseService.updateEntry<Action>(Action.PATH, copy, actionConverter);
      dispatch(openToastAction('Ação de serviço salva com sucesso!', 'success'));
    } catch (error) {
      copy.approved = !copy.approved;
      dispatch(openToastAction('Erro ao alterar Ação de serviço, tente novamente.', 'success'));
    } finally {
      action.approved = copy.approved;
    }
    await refreshAction(action.id);
  }

  async function deleteAction() {
    if (!modalAction) {
      setShowModal(false);
      return;
    }
    try {
      await DatabaseService.softDelete(Action.PATH, Action.COLLECTION_NAME, modalAction.id, actionConverter);
      setActions([...actions.filter((action) => action.id !== modalAction.id)]);
      dispatch(openToastAction('Ação de serviço apagada com sucesso.', 'success'));
    } catch (error) {
      dispatch(openToastAction('Error ao apagar ação de serviço, tente novamente.', 'danger'));
    }
    setModalAction(undefined);
    setShowModal(false);
  }

  return (
    <div id="org-list">
      {edit && (
        <div className="p-2">
          <Button
            text="Voltar"
            size="medium"
            handleButtonPressed={() => {
              setEdit(undefined);
              setIsEditing(false);
            }}
          ></Button>
          <div id="edit" className="border rounded shadow-sm p-4 mb-3">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <b> Objetivo da ação</b>
                </InputGroup.Text>
                <Form.Control
                  as="textarea"
                  rows={2}
                  placeholder="o quê? (objetivo da ação) - sensibilizar as pessoas"
                  {...register('actionDescription', { value: edit?.actionDescripton })}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <InputGroup.Text id="basic-addon1">Como será a ação</InputGroup.Text>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="como? (como será a ação) - distribuir flyers na saida da faculdade sobre"
                  {...register('actionHowTo', { value: edit?.actionHowTo })}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <InputGroup.Text id="basic-addon1">Descrição da duração</InputGroup.Text>
                <Form.Control
                  as="textarea"
                  rows={1}
                  placeholder="Quanto tempo necessito dedicar para concluir a ação?"
                  {...register('durationDescription', { value: edit?.durationDescription })}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <InputGroup.Text id="basic-addon1">Conteúdos inspiradores</InputGroup.Text>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder="Coloca aqui onde encontraste a inspiração para a ação que encontraste ou criaste (se criaste e não tem nada na internet sobre isso, deixa em branco)"
                  {...register('inspirationLinks', { value: edit?.inspirationLinks })}
                />
              </Form.Group>
              <TargetAudienceForm complexForm={complexForms} setComplexForm={setComplexForms} />
              <p className="p-3">
                <b>Problemática dominante a ser impactada pela ação que vamos criar</b>
              </p>
              <ProblemsForm complexForm={complexForms} setComplexForm={setComplexForms}></ProblemsForm>
              <div>
                <button type="submit" disabled={!isValid} className="btn btn-primary">
                  Gravar
                </button>
              </div>
            </Form>
          </div>
        </div>
      )}
      {!edit && (
        <>
          {actions.map((action, index) => {
            return (
              <div className="border rounded shadow-lg p-3 mb-1" key={index}>
                <div className="row">
                  <div className="col">
                    <h4 className="h4 mb-0">{action.actionDescripton}</h4>
                    <div>{action.actionHowTo}</div>
                    <div>Criado: {Entity.fieldValueToString(action.createdAt)}</div>
                    <div>Modificado: {Entity.fieldValueToString(action.modifiedAt)}</div>
                    <div>Criado por: {action.createdByName ? action.createdByName : 'Sem utilizador definido'}</div>
                    <div>Aprovado: {action.approved ? 'Sim' : 'Não'}</div>
                  </div>
                  <div className="col d-flex justify-content-end align-items-center bd-highlight ">
                    <Button
                      text="Detalhe"
                      outline={true}
                      size="small"
                      handleButtonPressed={() => (window.location.pathname = 'acoes-de-impacto/' + action.id)}
                    ></Button>
                    <Button
                      text="Editar"
                      outline={true}
                      size="small"
                      handleButtonPressed={() => handleEdit(action)}
                    ></Button>
                    <Button
                      text="Aprovar/Rejeitar"
                      outline={true}
                      size="small"
                      handleButtonPressed={() => reverseApproved(action)}
                    ></Button>
                    <Button
                      text="Apagar"
                      outline={true}
                      size="small"
                      handleButtonPressed={() => {
                        setModalAction(action);
                        setShowModal(true);
                      }}
                    ></Button>
                  </div>{' '}
                </div>
              </div>
            );
          })}
        </>
      )}
      <Modal
        setShowModal={setShowModal}
        showModal={showModal}
        title={'Tem a certeza que pretende apagar?'}
        Component={<ConfirmDeleteModalBodyComponent setShowModal={setShowModal} submitDelete={() => deleteAction()} />}
      />
    </div>
  );
}

export default ActionsList;
