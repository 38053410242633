import { ReactNode } from 'react';

import './details-page-section.component.scss';

export default function DetailsPageSection({ title, children }: { title: string; children: ReactNode }) {
  return (
    <div className="details-page-section">
      <div className="details-page-section-title">{title}</div>
      <div className="details-page-section-children">{children}</div>
    </div>
  );
}
