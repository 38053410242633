import {
  Actions,
  DataCenter,
  Forbidden,
  ForgotPassword,
  Home,
  ImpactActionDetail,
  ListImpactActions,
  ListOpinionLeaders,
  ListProjects,
  ListVolunteeringActions,
  ManageOptions,
  ManageUsers,
  NewImpactAction,
  OpinionLeaderDetail,
  OpinionLeaderSubmission,
  ProjectDetail,
  ProjectSubmission,
  SignIn,
  SignOut,
  SignUp,
  SignUpConfirmation,
  UserCourses,
  UserProfile,
} from './pages';
import { AdminRoutes, AuthenticatedRoutes } from './components';
import { AppContext } from './state/app-state-context';
import { Fragment, useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Upload } from './pages/data-center/upload.component';
import ActionsEditor from './pages/data-center/actions-editor.component';
import Layout from './components/layout/layout.component';
import MainDocumentEditor from './pages/data-center/main-document-editor.component';
import ManageFeedback from './pages/manage-feedback/manage-feedback.component';
import OpinionLeadersEditor from './pages/data-center/opinion-leaders-editor.component';
import ProjectList from './components/database/main-document-form/project-list';

function App() {
  const {
    state: { isAppLoading },
  } = useContext(AppContext);

  if (isAppLoading) {
    return <Fragment />;
  }

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route element={<AuthenticatedRoutes />}>
          <Route path="/terminar-sessao" element={<SignOut />} />
          <Route path="/projetos" element={<ListProjects />} />
          <Route path="/projetos/:projectName/:projectId" element={<ProjectDetail />} />
          <Route path="/projetos/novo-projeto" element={<ProjectSubmission />} />
          <Route path="/projetos/nova-organizacao" element={<ProjectSubmission />} />
          <Route path="/projetos/:projectName/projeto/feedback" element={<ProjectSubmission />} />
          <Route path="/projetos/:orgName/organizacao/feedback" element={<ProjectSubmission />} />
          <Route path="/lideres-de-opiniao" element={<ListOpinionLeaders />} />
          <Route path="/lideres-de-opiniao/:opinionLeaderName/:opinionLeaderId" element={<OpinionLeaderDetail />} />
          <Route path="/lideres-de-opiniao/novo-lider-de-opiniao" element={<OpinionLeaderSubmission />} />
          <Route path="/lideres-de-opiniao/:opinionLeaderName/feedback" element={<OpinionLeaderSubmission />} />
          <Route path="/acoes-de-impacto" element={<ListImpactActions />} />
          <Route path="/acoes-de-impacto/:actionId" element={<ImpactActionDetail />} />
          <Route path="/acoes-de-impacto/nova-acao-de-impacto" element={<NewImpactAction />} />
          <Route path="/perfil" element={<UserProfile />} />
          {/*<Route path="/meus-cursos" element={<UserCourses />} />*/}
          <Route element={<AdminRoutes />}>
            <Route path="/gerir-utilizadores" element={<ManageUsers />} />
            <Route path="/gerir-opcoes" element={<ManageOptions />} />
            <Route path="/manage-feedback" element={<ManageFeedback />} />
            <Route path="/data-center" element={<DataCenter />}>
              <Route path="main-document" element={<MainDocumentEditor />} />
              <Route path="main-document/edit/" element={<ProjectList />} />
              <Route path="main-document/edit/:orgId" element={<ProjectList />} />
              <Route path="actions" element={<ActionsEditor />} />
              <Route path="opinion-leaders" element={<OpinionLeadersEditor />} />
              <Route path="upload" element={<Upload />} />
            </Route>
          </Route>
        </Route>
        <Route path="/" element={<Home />} />
        <Route path="/voluntariado" element={<ListVolunteeringActions />} />
        <Route path="/login" element={<SignIn />} />
        <Route path="/registar" element={<SignUp />} />
        <Route path="/continuar-com-registo" element={<SignUpConfirmation />} />
        <Route path="/esqueceu-palavra-passe" element={<ForgotPassword />} />
        <Route path="/forbidden" element={<Forbidden />} />
        <Route path="/acoes" element={<Actions />} />
      </Route>
    </Routes>
  );
}

export default App;
