const IMPACT_ACTIONS: { title: string; description: string; image: string; link: string }[] = [
  {
    title: 'Pré-inscrição na Tertúlia de Impacto U.DREAM 2024',
    description:
      'No dia 10 de outubro vamos organizar mais uma sessão de inspiração sobre alguma problemática social emergente. Com tanta informação dispersa e tantas opiniões diferentes (muitas vezes pouco flexíveis), urge a necessidade de encontrar espaços seguros, onde reinam os factos e as opiniões sustentadas. Esta é uma ação online que promete deixar descomplicar temas importantes.',
    image: 'homepage/impact-actions/impact-talk.jpg',
    link: 'https://forms.gle/69QuwZPyUzbBUSEx7',
  },
  {
    title: 'Pré-inscrição no Jantar de impacto U.DREAM 2024',
    description:
      'Na sexta-feira dia 8 de novembro, preparamos para ti um jantar com uma particularidade: 4 convidados especiais que vão partilhar as suas histórias de vida com o objetivo de nos fazer entender realidades que nem sempre somos capazes de aceitar. É um jantar onde o prato principal é empatia e o resto… é surpresa. Deixamos uma só certeza, esta é uma ementa que nunca mais vais esquecer.',
    image: 'homepage/impact-actions/impact-dinner.jpg',
    link: 'https://forms.gle/69QuwZPyUzbBUSEx7',
  },
  {
    title: 'Pré-inscrição no Impact Day U.DREAM 2024',
    description:
      'No dia 30 de novembro, vamos preparar uma manhã dedicada a mergulhar nos desafios sociais da cidade do Porto. Só terás de escolher um público alvo e estar às 9h30 no ponto de encontro que será nos escritórios da U.DREAM no Porto. Quanto ao resto, não precisas de te preocupar, nós preparamos tudo, só não esqueças o teu melhor sorriso. A ação tem a duração máxima de 3 horas.',
    image: 'homepage/impact-actions/impact-day.jpg',
    link: 'https://forms.gle/69QuwZPyUzbBUSEx7',
  },
  {
    title: 'Pré-inscrição na Campanha U.DREAM 2024',
    description:
      'No sábado, dia 21 de dezembro vamos sair às ruas do Porto (manhã) e de Braga (tarde) para levar um sorriso às pessoas por quem passamos, seja com serenatas, com mensagens de bom dia, com pequenas ofertas ou abraços, a certeza é que magia de Natal vem connosco para um dia que vai ficar na história! Juntas-te a nós?',
    image: 'homepage/impact-actions/campanha.jpg',
    link: 'https://forms.gle/69QuwZPyUzbBUSEx7',
  },
];

const OPINION_LEADERS: { id: string; name: string; storageImageUrl: string; imageUrl?: string }[] = [
  {
    id: 'opl#1cl3m15',
    name: 'Diogo Cruz',
    storageImageUrl: '/databases/opinion-leaders/opl#1cl3m15.jpg',
  },
  {
    id: 'opl#jo6au1d',
    name: 'Diogo Varandas',
    storageImageUrl: '/databases/opinion-leaders/opl#jo6au1d.jpg',
  },
  {
    id: 'opl#0jxe8yb',
    name: 'Pedro Almeida',
    storageImageUrl: '/databases/opinion-leaders/opl#0jxe8yb.jpg',
  },
  {
    id: 'opl#huohlv5',
    name: 'Joana Moreira',
    storageImageUrl: '/databases/opinion-leaders/opl#huohlv5.jpg',
  },
  {
    id: 'opl#3s1vkwt',
    name: 'Mafalda Sarmento',
    storageImageUrl: '/databases/opinion-leaders/opl#3s1vkwt.jpg',
  },
  {
    id: 'opl#9g9x586',
    name: 'Lokas Cruz - Ana Paula Cruz',
    storageImageUrl: '/databases/opinion-leaders/opl#9g9x586.jpg',
  },
];

const STATIC_DATA = {
  IMPACT_ACTIONS,
  OPINION_LEADERS,
};

export default STATIC_DATA;
