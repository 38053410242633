type AddIconProps = { classes?: string };

function ArrowLeftIcon({ classes = '' }: AddIconProps) {
  return (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes}>
      <path d="M8.09998 1.70024L6.69936 0.299622L0 6.99989L6.69936 13.7002L8.09998 12.2996L3.7998 8.0002L17.8998 8.0002V6.00053L3.79891 6.00053L8.09998 1.70024Z" />
    </svg>
  );
}

export default ArrowLeftIcon;
