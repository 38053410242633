import * as Yup from 'yup';
import { ChangeEvent, Dispatch, SetStateAction, useContext } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import useEffectOnce from 'react-use/lib/useEffectOnce';
import useToggle from 'react-use/lib/useToggle';

// Components
import { AppContext } from '../../../state/app-state-context';
import { BooleanType } from '../../../enums/boolean-type.enum';
import { FilteredFormValues } from '../../../interfaces/filtered-form-values.interface';
import {
  IMPACT_ACTIONS_DURATIONS,
  ODS,
  PROFESSIONAL_CATEGORIES,
  REGIONS,
  SERVICES,
  SOCIAL_PROBLEMS,
  TARGET_AUDIENCE,
  VOLUNTEERING_PROFILE,
} from '../../../model/options';
import { QueryFormat } from '../../../interfaces/database.interface';
import { QueryTypes } from '../../../enums/query-types.enum';
import { cleanString } from '../../../utils/helpers.util';
import { closeSidebarAction } from '../../../state/actions';
import AccordionArray from '../../accordion-array/accordion-array.component';
import AccordionToggle from '../../accordion-toggle/accordion-toggle.component';

// Styles
import './database-query.component.scss';
import NewButton from '../../button/new-button.component';

const SOCIAL_PROBLEMS_TRANSFORMED = [...SOCIAL_PROBLEMS].map((problematic) => {
  problematic.problems = [...problematic.problems.filter((problem) => problem.trim().toLowerCase() !== 'todos')].sort();
  return problematic;
});

const PROFESSIONAL_CATEGORIES_TRANSFORMED = [...PROFESSIONAL_CATEGORIES].map((professionalCategory) => {
  professionalCategory.professions = [
    ...professionalCategory.professions.filter((profession) => profession.trim().toLowerCase() !== 'indefinido'),
  ].sort();
  return professionalCategory;
});

type Problematic = (typeof SOCIAL_PROBLEMS_TRANSFORMED)[0];
type ProfessionalCategory = (typeof PROFESSIONAL_CATEGORIES_TRANSFORMED)[0];

const PROBLEMATIC_ALL_ID = 13;
const UNDEFINED_PROFESSION_ID = 10;

const formValuesSchema = Yup.object().shape({
  academicBackground: Yup.array().of(Yup.string()).nullable(),
  problems: Yup.array().of(Yup.string()).nullable(),
  targetAudience: Yup.array().of(Yup.string()).nullable(),
  regions: Yup.array().of(Yup.string()).nullable(),
  professionalServices: Yup.array().of(Yup.string()).nullable(),
  services: Yup.array().of(Yup.string()).nullable(),
  ods: Yup.array().of(Yup.string()).nullable(),
  volunteeringProfile: Yup.array().of(Yup.string()).nullable(),
  approval: Yup.string().nullable(),
  organization: Yup.string(),
  duration: Yup.string().nullable(),
});

type DatabaseQueryProps = {
  setQueryFormat: Dispatch<SetStateAction<QueryFormat[]>>;
  queryOptions: QueryTypes[];
  selectedFilters?: FilteredFormValues;
  setSelectedFilters?: Dispatch<SetStateAction<FilteredFormValues>>;
};

function DatabaseQuery({ setQueryFormat, queryOptions, selectedFilters = {}, setSelectedFilters }: DatabaseQueryProps) {
  const { dispatch } = useContext(AppContext);
  const { register, handleSubmit, reset, setValue, watch, getValues } = useForm<FilteredFormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(formValuesSchema),
    defaultValues: { ...selectedFilters },
  });
  const watchProblematics = watch('problems');
  const watchProfessions = watch('professionalServices');
  const [selectAllProblematics, setSelectAllProblematics] = useToggle(false);
  const [selectUndefinedProfession, setSelectUndefinedProfession] = useToggle(false);

  useEffectOnce(() => {
    if (selectedFilters) {
      if (selectedFilters.problems && selectedFilters.problems.length > 0) {
        const problemsMap = buildMap(selectedFilters.problems);
        const problematicAll = problemsMap.get(PROBLEMATIC_ALL_ID.toString());

        if (problematicAll && problematicAll.length > 0) {
          setSelectAllProblematics(true);
        }
      }

      if (selectedFilters.professionalServices && selectedFilters.professionalServices.length > 0) {
        const professionsMap = buildMap(selectedFilters.professionalServices);
        const undefinedProfession = professionsMap.get(UNDEFINED_PROFESSION_ID.toString());

        if (undefinedProfession && undefinedProfession.length > 0) {
          setSelectUndefinedProfession(true);
        }
      }
    }
  });

  const onSubmit: SubmitHandler<FilteredFormValues> = async (data) => {
    const queryFormatArray: QueryFormat[] = [];

    if (
      queryOptions.includes(QueryTypes.AcademicBackground) &&
      data.academicBackground &&
      data.academicBackground.length > 0
    ) {
      queryFormatArray.push({
        field: 'academicBackground',
        value: data.academicBackground,
        queryType: 'arrays-contains-any',
      });
    }

    if (queryOptions.includes(QueryTypes.Approval) && data.approval) {
      queryFormatArray.push({ field: 'approved', value: data.approval === BooleanType.True, queryType: 'boolean' });
    }

    if (queryOptions.includes(QueryTypes.Duration) && data.duration) {
      queryFormatArray.push({ field: 'durationDescription', value: data.duration, queryType: 'string' });
    }

    if (queryOptions.includes(QueryTypes.Ods) && data.ods && data.ods.length > 0) {
      queryFormatArray.push({ field: 'sustainabilityObjectives', value: data.ods, queryType: 'arrays-contains-any' });
    }

    if (queryOptions.includes(QueryTypes.Problems) && data.problems && data.problems.length > 0) {
      const map = buildMap(data.problems);

      map.forEach((arr, id) => {
        const socialProblem = SOCIAL_PROBLEMS_TRANSFORMED.find((sP) => sP.id === Number(id));
        const customMap = new Map<string, string[]>();

        if (socialProblem?.problems.length === arr.length) {
          customMap.set(id, ['Todos']);
        } else {
          customMap.set(id, arr);
        }

        queryFormatArray.push({
          field: 'problems',
          value: customMap,
          queryType: 'map',
        });
      });
    }

    if (
      queryOptions.includes(QueryTypes.ProfessionalServices) &&
      data.professionalServices &&
      data.professionalServices.length > 0
    ) {
      const map = buildMap(data.professionalServices);

      map.forEach((value, key) => {
        const customMap = new Map<string, string[]>();
        customMap.set(key, value);

        queryFormatArray.push({
          field: 'professionalServices',
          value: customMap,
          queryType: 'map',
        });
      });
    }

    if (queryOptions.includes(QueryTypes.Regions) && data.regions && data.regions.length > 0) {
      queryFormatArray.push({ field: 'regions', value: data.regions, queryType: 'arrays-contains-any' });
    }

    if (queryOptions.includes(QueryTypes.Services) && data.services && data.services.length > 0) {
      queryFormatArray.push({ field: 'services', value: data.services, queryType: 'arrays-contains-any' });
    }

    if (queryOptions.includes(QueryTypes.TargetAudience) && data.targetAudience && data.targetAudience.length > 0) {
      queryFormatArray.push({ field: 'targetAudience', value: data.targetAudience, queryType: 'arrays-contains-any' });
    }

    if (
      queryOptions.includes(QueryTypes.VolunteeringProfile) &&
      data.volunteeringProfile &&
      data.volunteeringProfile.length > 0
    ) {
      queryFormatArray.push({
        field: 'volunteeringProfile',
        value: data.volunteeringProfile,
        queryType: 'arrays-contains-any',
      });
    }

    setQueryFormat(queryFormatArray);

    if (setSelectedFilters) {
      setSelectedFilters(data);
    }

    dispatch(closeSidebarAction());
  };

  const resetForm = () => {
    if (setSelectedFilters) {
      setSelectedFilters({});
    }

    setQueryFormat([]);
    reset({
      problems: [],
      academicBackground: [],
      targetAudience: [],
      regions: [],
      professionalServices: [],
      services: [],
      ods: [],
      volunteeringProfile: [],
      approval: '',
      organization: '',
      duration: '',
    });
  };

  const buildMap = (arr: string[]) => {
    if (!Array.isArray(arr)) {
      return new Map<string, string[]>();
    }

    return arr.reduce((map, value) => {
      const [id, option] = value.split('#');

      if (map.has(id)) {
        map.get(id)?.push(option);
      } else {
        map.set(id, [option]);
      }

      return map;
    }, new Map<string, string[]>());
  };

  const checkAllProblems = (event: ChangeEvent<HTMLInputElement>, id: number) => {
    const socialProblem = SOCIAL_PROBLEMS_TRANSFORMED.find((sP) => sP.id === id);

    if (socialProblem) {
      const allProblems =
        socialProblem.id !== PROBLEMATIC_ALL_ID
          ? socialProblem.problems.map((problem) => `${socialProblem.id}#${problem}`)
          : [`${PROBLEMATIC_ALL_ID}#Todos`];
      const selectedProblems = getValues('problems');

      if (event.target.checked) {
        if (selectedProblems) {
          setValue('problems', [...new Set([...selectedProblems, ...allProblems])]);
        } else {
          setValue('problems', allProblems);
        }
      } else {
        setValue(
          'problems',
          selectedProblems?.filter((problem) => !allProblems.includes(problem))
        );
      }

      if (socialProblem.id === PROBLEMATIC_ALL_ID) {
        setSelectAllProblematics(!selectAllProblematics);
      }
    }
  };

  const checkAllProfessions = (event: ChangeEvent<HTMLInputElement>, id: number) => {
    const professionalService = PROFESSIONAL_CATEGORIES_TRANSFORMED.find((pC) => pC.id === id);

    if (professionalService) {
      const allProfessionalServices =
        professionalService.id !== UNDEFINED_PROFESSION_ID
          ? professionalService.professions.map((profession) => `${professionalService.id}#${profession}`)
          : [`${UNDEFINED_PROFESSION_ID}#indefinido`];
      const selectedProfessionalServices = getValues('professionalServices');

      if (event.target.checked) {
        if (selectedProfessionalServices) {
          setValue('professionalServices', [...new Set([...selectedProfessionalServices, ...allProfessionalServices])]);
        } else {
          setValue('professionalServices', allProfessionalServices);
        }
      } else {
        setValue(
          'professionalServices',
          selectedProfessionalServices?.filter(
            (professionalService) => !allProfessionalServices.includes(professionalService)
          )
        );
      }

      if (professionalService.id === UNDEFINED_PROFESSION_ID) {
        setSelectUndefinedProfession(!selectUndefinedProfession);
      }
    }
  };

  const isAllProblemsFromProblematicChecked = (problematic: Problematic) => {
    if (problematic.id === PROBLEMATIC_ALL_ID) {
      return selectAllProblematics;
    }

    const problemsMap = buildMap(watchProblematics ?? []);
    const problem = problemsMap.get(problematic.id.toString());

    return problem?.length === problematic.problems.length;
  };

  const isAllProfessionsFromProfessionalServicesChecked = (professionalCategory: ProfessionalCategory) => {
    if (professionalCategory.id === UNDEFINED_PROFESSION_ID) {
      return selectUndefinedProfession;
    }

    const professionsMap = buildMap(watchProfessions ?? []);
    const professions = professionsMap.get(professionalCategory.id.toString());

    return professions?.length === professionalCategory.professions.length;
  };

  return (
    <Form className="c-form d-flex flex-column justify-content-between" onSubmit={handleSubmit(onSubmit)}>
      <Accordion flush className="c-filter">
        {queryOptions.includes(QueryTypes.AcademicBackground) && (
          <AccordionArray
            title="Background Académico"
            formRegister={register}
            formRegisterValue="academicBackground"
            eventKey="0"
            arr={VOLUNTEERING_PROFILE}
          />
        )}
        {queryOptions.includes(QueryTypes.Problems) && (
          <div className="mt-3">
            <AccordionToggle eventKey="1">Grupos de problemática</AccordionToggle>
            <Accordion.Collapse eventKey="1">
              <>
                {SOCIAL_PROBLEMS_TRANSFORMED.map((socialProblemInfo, outerIndex) => (
                  <div key={`${socialProblemInfo.group}-${outerIndex}`} className={`${outerIndex === 0 && 'mt-4'}`}>
                    <div className="d-flex justify-content-between align-items-center me-1">
                      <p className="c-filter-sub-title">{cleanString(socialProblemInfo.group)}</p>
                      <Form.Group controlId={`formSocialProblem-${socialProblemInfo.group}`} className="ms-3">
                        <Form.Check
                          aria-label={`Selecionar todos os subproblemas relacionados com ${cleanString(
                            socialProblemInfo.group
                          )}`}
                          onChange={(e) => checkAllProblems(e, socialProblemInfo.id)}
                          checked={isAllProblemsFromProblematicChecked(socialProblemInfo)}
                        />
                      </Form.Group>
                    </div>
                    <Table borderless={true} size="sm">
                      <tbody>
                        {socialProblemInfo.problems.map((problem, innerIndex) => (
                          <tr key={`${problem}-${innerIndex}`}>
                            <td>
                              <p className="c-option">{problem}</p>
                            </td>
                            <td className="align-middle">
                              <Form.Group controlId={`formSocialProblem-${problem}-${innerIndex}`} className="ms-3">
                                <Form.Check
                                  className="float-end"
                                  aria-label={problem}
                                  value={`${socialProblemInfo.id}#${problem}`}
                                  {...register('problems')}
                                />
                              </Form.Group>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                ))}
              </>
            </Accordion.Collapse>
          </div>
        )}
        {queryOptions.includes(QueryTypes.TargetAudience) && (
          <AccordionArray
            title="Público Alvo"
            formRegister={register}
            formRegisterValue="targetAudience"
            eventKey="2"
            arr={TARGET_AUDIENCE}
          />
        )}
        {queryOptions.includes(QueryTypes.Regions) && (
          <AccordionArray
            title="Locais de atuação"
            formRegister={register}
            formRegisterValue="regions"
            eventKey="3"
            arr={REGIONS}
          />
        )}
        {queryOptions.includes(QueryTypes.ProfessionalServices) && (
          <div className="mt-3">
            <AccordionToggle eventKey="4">Equipa Profissional</AccordionToggle>
            <Accordion.Collapse eventKey="4">
              <>
                {PROFESSIONAL_CATEGORIES_TRANSFORMED.map((professionInfo, outerIndex) => (
                  <div key={`${professionInfo.area}-${outerIndex}`} className={`${outerIndex === 0 && 'mt-4'}`}>
                    <div className="d-flex justify-content-between align-items-center me-1">
                      <p className="c-filter-sub-title">{cleanString(professionInfo.area)}</p>
                      <Form.Group controlId={`formProfessionalServices-${professionInfo.area}`} className="ms-3">
                        <Form.Check
                          aria-label={`Selecionar todas as profissões relacionadas com ${cleanString(
                            professionInfo.area
                          )}`}
                          onChange={(e) => checkAllProfessions(e, professionInfo.id)}
                          checked={isAllProfessionsFromProfessionalServicesChecked(professionInfo)}
                        />
                      </Form.Group>
                    </div>
                    <Table borderless={true} size="sm">
                      <tbody>
                        {professionInfo.professions.map((profession, innerIndex) => (
                          <tr key={`${profession}-${innerIndex}`}>
                            <td>
                              <p className="c-option">{cleanString(profession)}</p>
                            </td>
                            <td className="align-middle">
                              <Form.Group
                                controlId={`formProfessionalServices-${profession}-${innerIndex}`}
                                className="ms-3"
                              >
                                <Form.Check
                                  className="float-end"
                                  aria-label={profession}
                                  value={`${professionInfo.id}#${profession}`}
                                  {...register('professionalServices')}
                                />
                              </Form.Group>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                ))}
              </>
            </Accordion.Collapse>
          </div>
        )}
        {queryOptions.includes(QueryTypes.Services) && (
          <AccordionArray
            title="Principais Serviços"
            formRegister={register}
            formRegisterValue="services"
            eventKey="5"
            arr={SERVICES}
          />
        )}
        {queryOptions.includes(QueryTypes.Ods) && (
          <AccordionArray
            title="Objetivos de Sustentabilidade"
            formRegister={register}
            formRegisterValue="ods"
            eventKey="6"
            arr={ODS}
          />
        )}
        {queryOptions.includes(QueryTypes.VolunteeringProfile) && (
          <AccordionArray
            title="Perfil de voluntariado"
            formRegister={register}
            formRegisterValue="volunteeringProfile"
            eventKey="7"
            arr={VOLUNTEERING_PROFILE}
          />
        )}
        {queryOptions.includes(QueryTypes.Approval) && (
          <div className="mt-3">
            <AccordionToggle eventKey="8">Estado</AccordionToggle>
            <Accordion.Collapse eventKey="8">
              <Table borderless={true} size="sm" className="mt-2">
                <tbody>
                  <tr>
                    <td>
                      <p className="c-option">Não Aprovado</p>
                    </td>
                    <td className="align-middle">
                      <Form.Group controlId="formApproval-not-approved" className="ms-3">
                        <Form.Check
                          className="float-end"
                          aria-label="Não Aprovado"
                          value={BooleanType.False}
                          {...register('approval')}
                          type="radio"
                        />
                      </Form.Group>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="c-option">Aprovado</p>
                    </td>
                    <td className="align-middle">
                      <Form.Group controlId="formApproval-approved" className="ms-3">
                        <Form.Check
                          className="float-end"
                          aria-label="Aprovado"
                          value={BooleanType.True}
                          {...register('approval')}
                          type="radio"
                        />
                      </Form.Group>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Accordion.Collapse>
          </div>
        )}
        {queryOptions.includes(QueryTypes.Duration) && (
          <div className="mt-3">
            <AccordionToggle eventKey="9">Duração da ação</AccordionToggle>
            <Accordion.Collapse eventKey="9">
              <Table borderless={true} size="sm" className="mt-2">
                <tbody>
                  {IMPACT_ACTIONS_DURATIONS.map((duration, index) => (
                    <tr key={`${duration}-${index}`}>
                      <td>
                        <p className="c-option">{duration}</p>
                      </td>
                      <td className="align-middle">
                        <Form.Group controlId={`formImpactActionDuration-${duration}-${index}`} className="ms-3">
                          <Form.Check
                            className="float-end"
                            aria-label={duration}
                            value={duration}
                            {...register('duration')}
                            type="radio"
                          />
                        </Form.Group>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Accordion.Collapse>
          </div>
        )}
      </Accordion>
      <hr />
      <div className="d-flex justify-content-end">
        <NewButton theme="white" accent="green" outlined={true} size="sm" onClick={resetForm}>
          Limpar
        </NewButton>
        <button
          className="u-button u-button-green u-button-accent-white u-button-sm u-button-solid submit-btn"
          type="submit"
        >
          Mostrar
        </button>
      </div>
    </Form>
  );
}
export default DatabaseQuery;
