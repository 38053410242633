import { Timestamp } from 'firebase/firestore';
import { utils, writeFileXLSX } from 'xlsx';

// Components
import { DatabaseService } from '../../services/database.service';
import { GetUsersOptions } from '../../interfaces/get-users-options.interface';
import { LearnWorldsService, UserService } from '../../services';
import { Tag, tagConverter } from '../../model/tag/tag';
import { UserWithTagsAndCourses } from '../../interfaces/user.interface';
import { formatDate, titleCase } from '../helpers.util';

export const exportUsersToExcel = async () => {
  const userOptions: GetUsersOptions = {
    pagination: {
      type: 'init',
      pageSize: 'all',
    },
  };
  const users = (await UserService.getUsers(userOptions)).users;

  const usersWithTagsAndCourses: UserWithTagsAndCourses[] = [];

  for (const user of users) {
    const userTags = await getUserTags(user.id);
    const userCourses = await LearnWorldsService.getUserCourses(user.email);
    usersWithTagsAndCourses.push({ ...user, tags: userTags, courses: userCourses });
  }

  const rows = usersWithTagsAndCourses.map((u) => ({
    nome: titleCase(u.name + ' ' + u.surname),
    email: u.email,
    'data de nascimento': formatDate((u.birthDate as Timestamp).toDate()),
    cidade: titleCase(u.city),
    distrito: u.district,
    função: u.role,
    'data de registo': formatDate((u.created as Timestamp).toDate()),
    tags: u.tags.map((t) => t.title).join('\n'),
    cursos: u.courses.map((c) => c.title).join('\n'),
  }));

  const worksheet = utils.json_to_sheet(rows);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, 'Utilizadores');

  writeFileXLSX(workbook, 'Utilizadores.xlsx', { compression: true });
};

const getUserTags = async (userId: string) => {
  return await DatabaseService.getByQueries<Tag>(
    Tag.PATH,
    tagConverter,
    { enablePagination: false },
    { field: 'usersIds', queryType: 'arrays-contains-any', value: [userId] }
  );
};
