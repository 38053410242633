import { MdClose } from 'react-icons/md';
import { useContext, useEffect, useState } from 'react';
import { useList, useToggle, useWindowSize } from 'react-use';
import { useNavigate } from 'react-router-dom';
import BootstrapButton from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

import { Action, actionElasticConverter } from '../../model/actions/action';
import { AppContext } from '../../state/app-state-context';
import { Colors } from '../../constants/colors.constants';
import { DatabaseService } from '../../services/database.service';
import { ELASTIC_ACTIONS_PATH, PaginationSettings } from '../../interfaces/elastic/search-api.interface';
import { FilterButton, InputSearch, ListElement, NewButton, PageHeader, Tag } from '../../components';
import { FilteredFormValues } from '../../interfaces/filtered-form-values.interface';
import { QueryFormat } from '../../interfaces/database.interface';
import { QueryTypes } from '../../enums/query-types.enum';
import { ResultsType } from '../../enums/results-type.enum';
import { Role } from '../../enums/role.enum';
import { SOCIAL_PROBLEMS } from '../../model/options';
import { ScreenBreakpoint } from '../../enums/screen-breakpoint.enum';
import {
  cleanString,
  cutString,
  updateQueryFormatArray,
  updateSetSelectedFilteredValues,
} from '../../utils/helpers.util';
import { openSidebarAction, openToastAction } from '../../state/actions';
import DatabaseQuery from '../../components/database/database-query/database-query';

import headerImg from '../../images/acoes-de-impacto/header.png';

const PAGE_SIZE = Number(process.env.REACT_APP_PAGE_SIZE) || 12;

interface ImpactActionsDetail {
  what: string;
  image: string;
  how: string;
  targetAudience: string[];
  link: string;
  id: string;
}

function ListImpactActions() {
  const { width: windowWidth } = useWindowSize();
  const {
    dispatch,
    state: { currentFirestoreUser },
  } = useContext(AppContext);
  const [impactActionsDetails, setImpactActionsDetails] = useState<ImpactActionsDetail[]>([]);
  const [impactActions, setImpactActions] = useState<Action[]>([]);
  const [filteredImpactActions, setFilteredImpactActions] = useState<Action[]>([]);
  const [resultsType, setResultsType] = useState<ResultsType>(ResultsType.Normal);
  const [searchTerm, setSearchTerm] = useState('');
  const [queryFormatArray, { set: setQueryFormatArray }] = useList<QueryFormat>([]);
  const [selectedFilteredValues, setSelectedFilteredValues] = useState<FilteredFormValues>({});
  const [actionsPaginationSettings, setActionsPaginationSettings] = useState<PaginationSettings>();
  const [filteredActionsPaginationSettings, setFilteredActionsPaginationSettings] = useState<PaginationSettings>();
  const [resetSearchTerm, setResetSearchTerm] = useToggle(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (impactActionsDetails.length === 0) {
      fetchImpactActions();
    }
  }, []);

  useEffect(() => {
    const hasFilters = async () => {
      if (queryFormatArray.length > 0) {
        await fetchFilteredActions(1, true);
      } else {
        if (impactActions.length > 0) {
          buildImpactActionsDetails(impactActions);
        }
      }
    };

    hasFilters();
  }, [queryFormatArray]);

  const fetchImpactActions = async (pageNumber = 1) => {
    const filterArr = [...queryFormatArray];

    if (currentFirestoreUser?.role !== Role.Admin) {
      filterArr.push({ field: 'approved', value: true, queryType: 'boolean' });
    }

    try {
      const {
        meta: { page: paginationSettings },
        results: actionsFromDb,
      } = await DatabaseService.getByQueriesElastic<Action>(
        ELASTIC_ACTIONS_PATH,
        actionElasticConverter,
        { enablePagination: true, currentPage: pageNumber, limit: PAGE_SIZE },
        { enableSorting: false },
        { enableSearchByTerm: false },
        ...filterArr
      );

      setActionsPaginationSettings(paginationSettings);

      if (actionsFromDb.length > 0) {
        setResultsType(ResultsType.Normal);
        setImpactActions([...impactActions, ...actionsFromDb]);
        buildImpactActionsDetails([...impactActions, ...actionsFromDb]);
      }
    } catch {
      dispatch(openToastAction('Não foi possível carregar as ações!', 'danger'));
    }
  };

  const fetchFilteredActions = async (pageNumber = 1, replace = false) => {
    const filterArr = [...queryFormatArray];

    if (currentFirestoreUser?.role !== Role.Admin) {
      filterArr.push({ field: 'approved', value: true, queryType: 'boolean' });
    }

    try {
      const {
        meta: { page: paginationSettings },
        results: actionsFromDb,
      } = await DatabaseService.getByQueriesElastic<Action>(
        ELASTIC_ACTIONS_PATH,
        actionElasticConverter,
        { enablePagination: true, currentPage: pageNumber, limit: PAGE_SIZE },
        { enableSorting: false },
        { enableSearchByTerm: false },
        ...filterArr
      );

      setFilteredActionsPaginationSettings(paginationSettings);

      if (actionsFromDb.length > 0) {
        setResultsType(ResultsType.Filter);

        if (replace) {
          setFilteredImpactActions(actionsFromDb);
          buildImpactActionsDetails(actionsFromDb);
        } else {
          setFilteredImpactActions([...filteredImpactActions, ...actionsFromDb]);
          buildImpactActionsDetails([...filteredImpactActions, ...actionsFromDb]);
        }
      } else {
        dispatch(openToastAction('Não foram encontrados ações de impacto com os filtros selecionados.', 'warning'));
        resetFilter();
      }
    } catch {
      dispatch(openToastAction('Não foi possível carregar as ações de impacto!', 'danger'));
    }
  };

  const buildImpactActionsDetails = (actionsFromDb: Action[]) => {
    const aDetails: ImpactActionsDetail[] = [];

    for (const action of actionsFromDb) {
      const socialProblemImage = fetchSocialProblemImage(action.problems ?? {});
      const targetAudience = getTargetAudience(action.targetAudience);

      const aDetail: ImpactActionsDetail = {
        what: action.actionDescripton ?? '',
        how: cutString(action.actionHowTo ?? '', 300),
        id: action.id,
        link: `/acoes-de-impacto/${encodeURIComponent(action.id)}`,
        image: socialProblemImage,
        targetAudience: targetAudience ?? [],
      };

      aDetails.push(aDetail);
    }

    setImpactActionsDetails(aDetails);
  };

  const fetchSocialProblemImage = (socialProblems: { [key: string]: string[] }) => {
    const placeholderImg = 'placeholder.png';

    if (Object.keys(socialProblems).length === 0) {
      return placeholderImg;
    }

    const socialProblem = Object.keys(socialProblems)[0];
    const socialProblemImage = SOCIAL_PROBLEMS.find((sP) => sP.id === Number(socialProblem))?.image_social_problems;

    if (!socialProblemImage) {
      return placeholderImg;
    }

    return `acoes-de-impacto/${socialProblemImage}.jpg`;
  };

  const getTargetAudience = (targetAudience?: string[]) => {
    if (!targetAudience || targetAudience.length === 0) {
      return;
    }

    if (targetAudience.length <= 2) {
      return targetAudience.map((typeOfAudience) => cleanString(typeOfAudience));
    } else {
      const [firstTypeOfAudience, secondTypeOfAudience, ...rest] = targetAudience.map((typeOfAudience) =>
        cleanString(typeOfAudience)
      );

      return [firstTypeOfAudience, secondTypeOfAudience, `+ ${rest.length} tipos de população`];
    }
  };

  const handleGetMoreBtnPressed = async () => {
    if (impactActionsDetails.length > 0) {
      if (
        resultsType === ResultsType.Normal &&
        actionsPaginationSettings &&
        actionsPaginationSettings.current < actionsPaginationSettings.total_pages
      ) {
        await fetchImpactActions(actionsPaginationSettings.current + 1);
        return;
      }

      if (
        resultsType === ResultsType.Filter &&
        filteredActionsPaginationSettings &&
        filteredActionsPaginationSettings.current < filteredActionsPaginationSettings.total_pages
      ) {
        await fetchFilteredActions(filteredActionsPaginationSettings.current + 1);
        return;
      }

      if (
        resultsType === ResultsType.Name &&
        filteredActionsPaginationSettings &&
        filteredActionsPaginationSettings.current < filteredActionsPaginationSettings.total_pages
      ) {
        await fetchActionsBySearch(filteredActionsPaginationSettings.current + 1, false);
        return;
      }
    }
  };

  const handleSubmitNewActionBtnPressed = () => navigate('nova-acao-de-impacto');

  const handleFilterBtnPressed = () =>
    dispatch(
      openSidebarAction(
        <DatabaseQuery
          key={Math.random()} // Create new instance
          setQueryFormat={setQueryFormatArray}
          queryOptions={[QueryTypes.TargetAudience, QueryTypes.Problems, QueryTypes.Duration]}
          selectedFilters={selectedFilteredValues}
          setSelectedFilters={setSelectedFilteredValues}
        />
      )
    );

  const resetFilter = () => {
    setSelectedFilteredValues({});
    setQueryFormatArray([]);
    setFilteredActionsPaginationSettings(undefined);
    setFilteredImpactActions([]);
    setResultsType(ResultsType.Normal);
  };

  const handleDeleteFilter = async (key: string, filterToRemove: string) => {
    const updatedFilter = updateSetSelectedFilteredValues({ ...selectedFilteredValues }, key, filterToRemove);

    if (
      updatedFilter &&
      Object.values(updatedFilter).some(
        (v) => (v && Array.isArray(v) && (v as string[]).length > 0) || (v && typeof v === 'string' && v.length > 0)
      )
    ) {
      const duplicateQueryFormatArray = updateQueryFormatArray([...queryFormatArray], key, filterToRemove);

      setSelectedFilteredValues(updatedFilter);
      setQueryFormatArray(duplicateQueryFormatArray);
    } else {
      resetFilter();
      buildImpactActionsDetails(impactActions);
    }
  };

  const searchTermCallback = async (searchTermFromInput: string) => {
    if (searchTermFromInput.length === 0) {
      setResetSearchTerm(false);
      resetFilter();
    } else {
      fetchActionsBySearch(1, true, searchTermFromInput);
    }
  };

  const fetchActionsBySearch = async (pageNumber = 1, replace = false, searchTermFromInput?: string) => {
    if (searchTermFromInput) {
      setSearchTerm(searchTermFromInput);
    } else {
      searchTermFromInput = searchTerm;
    }

    const filterArr = [...queryFormatArray];

    if (currentFirestoreUser?.role !== Role.Admin) {
      filterArr.push({ field: 'approved', value: true, queryType: 'boolean' });
    }

    filterArr.push({ field: '', value: searchTermFromInput, queryType: 'substring' });

    try {
      const {
        meta: { page: paginationSettings },
        results: actionsFromDb,
      } = await DatabaseService.getByQueriesElastic<Action>(
        ELASTIC_ACTIONS_PATH,
        actionElasticConverter,
        { enablePagination: true, currentPage: pageNumber, limit: PAGE_SIZE },
        { enableSorting: true, sortBy: { actiondescripton: 'asc' } },
        { enableSearchByTerm: true, searchBy: { actionhowto: {}, actiondescripton: {} } },
        ...filterArr
      );

      setFilteredActionsPaginationSettings(paginationSettings);

      if (actionsFromDb.length > 0) {
        setResultsType(ResultsType.Name);

        if (replace) {
          setFilteredImpactActions(actionsFromDb);
          buildImpactActionsDetails(actionsFromDb);
        } else {
          setFilteredImpactActions([...impactActions, ...actionsFromDb]);
          buildImpactActionsDetails([...impactActions, ...actionsFromDb]);
        }
      } else {
        dispatch(openToastAction('Não foram encontrados projetos para o termo procurado.', 'warning'));
        resetFilter();
      }
    } catch {
      dispatch(openToastAction('Não foi possível carregar os projetos!', 'danger'));
    }
  };

  return (
    <Container className="py-5">
      <PageHeader
        title="Ações Simples de Impacto"
        description="Aqui encontras ações simples que podes concretizar com os teus familiares e amigos ou mesmo sozinho que têm impacto num determinado problema social."
        buttons={[
          <NewButton
            key="new-org-btn"
            theme="teal"
            accent="white"
            size={windowWidth < ScreenBreakpoint.Large ? 'sm' : 'lg'}
            outlined={false}
            onClick={handleSubmitNewActionBtnPressed}
          >
            Submeter Nova Ação
          </NewButton>,
        ]}
        img={<img src={headerImg} style={{ width: '394px', height: '445px' }}></img>}
      />
      <div className="filters">
        <FilterButton handleButtonPressed={handleFilterBtnPressed} />
        <InputSearch searchButtonClicked={searchTermCallback} resetSearchTerm={resetSearchTerm} />
      </div>
      {resultsType === ResultsType.Filter && Object.entries(selectedFilteredValues).length > 0 && (
        <div className="mt-2 d-flex flex-wrap">
          {Object.entries(selectedFilteredValues).map(([key, value]) => {
            if (Array.isArray(value)) {
              return value.map((v: string, index) => (
                <div key={`${v}-${index}`} className="me-2 mt-2">
                  <Tag
                    text={v.includes('#') ? v.split('#')[1] : cleanString(v)}
                    backgroundColor={Colors.blue04}
                    filterTags={true}
                    filterObjectKey={key}
                    filterObjectValue={v}
                    filterHandleCloseIconClick={handleDeleteFilter}
                  />
                </div>
              ));
            }

            if (typeof value === 'string' && value.length > 0) {
              return (
                <div key={value} className="me-2 mt-2">
                  <Tag
                    text={value}
                    backgroundColor={Colors.blue04}
                    filterTags={true}
                    filterObjectKey={key}
                    filterObjectValue={value}
                    filterHandleCloseIconClick={handleDeleteFilter}
                  />
                </div>
              );
            }
          })}
        </div>
      )}
      {resultsType === ResultsType.Name && (
        <div className="mt-4">
          <h6>
            <b>Resultados ({filteredActionsPaginationSettings?.total_results})</b>
          </h6>
          <div className="d-flex align-items-center">
            {filteredImpactActions.length > 0 ? (
              <p className="mb-0">
                Existem resultados para <b>&quot;{searchTerm}&quot;</b>
              </p>
            ) : (
              <p className="mb-0">
                Não existem resultados para <b>&quot;{searchTerm}&quot;</b>
              </p>
            )}
            <BootstrapButton
              variant="danger"
              className="py-1 px-2 ms-2"
              onClick={() => {
                resetFilter();
                setResetSearchTerm(true);
              }}
            >
              <MdClose size={18} color="#fff" />
            </BootstrapButton>
          </div>
        </div>
      )}
      {impactActionsDetails.length > 0 && (
        <>
          <div className="results-list">
            {impactActionsDetails.map((details) => (
              <ListElement
                key={details.id}
                title={details.what}
                description={details.how}
                tags={details.targetAudience}
                link={details.link}
                imageSrc={details.image}
              />
            ))}
          </div>
          <div>
            {resultsType === ResultsType.Normal &&
              actionsPaginationSettings &&
              actionsPaginationSettings.current < actionsPaginationSettings.total_pages && (
                <div className="row">
                  <div className="col-12 d-flex justify-content-center">
                    <NewButton
                      theme="green"
                      accent="white"
                      outlined={false}
                      size="lg"
                      onClick={handleGetMoreBtnPressed}
                    >
                      Carregar mais
                    </NewButton>
                  </div>
                </div>
              )}
            {resultsType === ResultsType.Filter &&
              filteredActionsPaginationSettings &&
              filteredActionsPaginationSettings.current < filteredActionsPaginationSettings.total_pages && (
                <div className="row">
                  <div className="col-12 d-flex justify-content-center">
                    <NewButton
                      theme="green"
                      accent="white"
                      outlined={false}
                      size="lg"
                      onClick={handleGetMoreBtnPressed}
                    >
                      Carregar mais
                    </NewButton>
                  </div>
                </div>
              )}
            {resultsType === ResultsType.Name &&
              filteredActionsPaginationSettings &&
              filteredActionsPaginationSettings.current < filteredActionsPaginationSettings.total_pages && (
                <div className="row">
                  <div className="col-12 d-flex justify-content-center">
                    <NewButton
                      theme="green"
                      accent="white"
                      outlined={false}
                      size="lg"
                      onClick={handleGetMoreBtnPressed}
                    >
                      Carregar mais
                    </NewButton>
                  </div>
                </div>
              )}
          </div>
        </>
      )}
    </Container>
  );
}

export default ListImpactActions;
