import { AppContext } from '../../../state/app-state-context';
import { DatabaseService } from '../../../services/database.service';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { Entity } from '../../../model/Entity';
import { Modal } from '../../index';
import { Organization, organizationConverter } from '../../../model/main/organization';
import { Project, projectConverter } from '../../../model/main/project';
import { openToastAction } from '../../../state/actions';
import { useNavigate } from 'react-router-dom';
import Button from '../../button/button.component';
import ConfirmDeleteModalBodyComponent from '../../modals-body/confirm-modal/confirm-delete-modal.component';

type OrganizationListProps = {
  orgs: Organization[];
  setOrgs: Dispatch<SetStateAction<Organization[]>>;
  refreshOrg: (id: string) => Promise<void>;
};

function OrganizationList({ orgs, setOrgs, refreshOrg }: OrganizationListProps) {
  const navigate = useNavigate();
  const { dispatch } = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const [modalOrg, setModalOrg] = useState<Organization>();

  async function deleteOrganization() {
    if (!modalOrg) {
      setShowModal(false);
      return;
    }
    try {
      const projects = await DatabaseService.getByQueries(
        Project.PATH,
        projectConverter,
        { enablePagination: false },
        { field: 'orgId', queryType: 'substring', value: modalOrg.id }
      );
      for (const project of projects) {
        await DatabaseService.softDelete(Project.PATH, Project.COLLECTION_NAME, project.id, projectConverter);
      }
      await DatabaseService.softDelete(
        Organization.PATH,
        Organization.COLLECTION_NAME,
        modalOrg.id,
        organizationConverter
      );
      setOrgs(orgs.filter((o) => o.id !== modalOrg.id));
      dispatch(openToastAction('Organização apagada com sucesso.', 'success'));
    } catch (error) {
      dispatch(openToastAction('Não foi possível apagar a organização. Tente novamente mais tarde', 'danger'));
    }
    setModalOrg(undefined);
    setShowModal(false);
  }

  async function reverseApproved(org: Organization) {
    try {
      org.approved = !org.approved;
      await DatabaseService.updateEntry(Organization.PATH, org, organizationConverter);
      dispatch(openToastAction('Organização alterada com sucesso.', 'success'));
    } catch (error) {
      dispatch(openToastAction('Não foi possível alterar a organização. Tente novamente mais tarde', 'danger'));
    }
    await refreshOrg(org.id);
  }
  return (
    <div id="org-list">
      {orgs.map((org, index) => {
        return (
          <div className="container border rounded shadow-lg p-3" key={index}>
            <div className="row">
              <div className="col">
                <h5 className="mb-0">{org.orgName}</h5>
                <div>Criado: {Entity.fieldValueToString(org.createdAt)}</div>
                <div>Modificado: {Entity.fieldValueToString(org.modifiedAt)}</div>
                <div>Criado por: {org.createdByName ? org.createdByName : 'Sem utilizador definido'}</div>
                <div>Aprovado: {org.approved ? 'Sim' : 'Não'}</div>
              </div>
              <div className="col d-flex justify-content-end align-items-center bd-highlight ">
                <Button
                  text="Projetos"
                  outline={true}
                  size="small"
                  handleButtonPressed={() => navigate('edit/' + encodeURIComponent(org.id))}
                ></Button>
                <Button
                  text="Aprovar/Rejeitar"
                  outline={true}
                  size="small"
                  handleButtonPressed={() => reverseApproved(org)}
                ></Button>
                <Button
                  text="Apagar"
                  outline={true}
                  size="small"
                  handleButtonPressed={() => {
                    setModalOrg(org);
                    setShowModal(true);
                  }}
                ></Button>
              </div>
            </div>
          </div>
        );
      })}
      <Modal
        setShowModal={setShowModal}
        showModal={showModal}
        title={'Tem a certeza que pretende apagar?'}
        Component={
          <ConfirmDeleteModalBodyComponent setShowModal={setShowModal} submitDelete={() => deleteOrganization()} />
        }
      />
    </div>
  );
}
export default OrganizationList;
