import * as Yup from 'yup';
import { Dispatch, SetStateAction } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import BsButton from 'react-bootstrap/Button';
import BsModal from 'react-bootstrap/Modal';
import InputText from '../../input-text/input-text.component';

type newProjectFormValues = {
  projectName: string;
};

const newProjectValidationSchema = Yup.object().shape({
  projectName: Yup.string().required('Nome do projeto é um campo obrigatório'),
});

type SubmitProjectProps = {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  handleSubmitForm: (value: string) => void;
};

function SubmitProjectModalBody({ setShowModal, handleSubmitForm }: SubmitProjectProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<newProjectFormValues>({ mode: 'onSubmit', resolver: yupResolver(newProjectValidationSchema) });

  const onSubmit: SubmitHandler<newProjectFormValues> = (data) => {
    if (Object.keys(errors).length === 0 && handleSubmitForm) {
      handleSubmitForm(data.projectName);
      setShowModal(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BsModal.Body>
        <InputText<newProjectFormValues>
          id="formOrgName"
          name="projectName"
          label="Nome do Projeto"
          showLabel={false}
          register={register}
          errorMessage={errors.projectName?.message}
        />
      </BsModal.Body>
      <BsModal.Footer>
        <BsButton variant="secondary" onClick={() => setShowModal(false)}>
          Fechar
        </BsButton>
        <BsButton variant="primary" type="submit" className="text-white">
          Submeter
        </BsButton>
      </BsModal.Footer>
    </form>
  );
}

export default SubmitProjectModalBody;
