import * as Yup from 'yup';
import { Fragment, useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import Accordion from 'react-bootstrap/Accordion';
import BsButton from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';

// Components
import { AddIcon, ArrowLeftIcon, Button, CloseIcon } from '../../components';
import { AppContext } from '../../state/app-state-context';
import { DatabaseService } from '../../services/database.service';
import { Feedback, feedbackOpinionLeaderConverter } from '../../model/feedback/feedback';
import { FeedbackType } from '../../enums/feedback-type.enum';
import { OpinionLeader, opinionLeaderConverter } from '../../model/opinion-leaders/opinion-leader';
import { SOCIAL_PROBLEMS, VOLUNTEERING_PROFILE } from '../../model/options';
import { arrayToObject, cleanString, isValidUrl, objectToArray } from '../../utils/helpers.util';
import { openToastAction } from '../../state/actions';

// Styles
import styles from './opinion-leader-submission.component.module.scss';

type OpinionLeaderFormValues = {
  name: string;
  description?: string;
  problems?: string[];
  academicBackground?: string[];
  observations?: string;
  focusArea?: string;
  linkedin?: string;
  twitter?: string;
  facebook?: string;
  sites?: string;
};

const OpinionLeaderSchema = Yup.object().shape({
  name: Yup.string().required('Nome é um campo obrigatório'),
  description: Yup.string(),
  problems: Yup.array().of(Yup.string()).nullable(),
  academicBackground: Yup.array().of(Yup.string()).nullable(),
  observations: Yup.string(),
  focusArea: Yup.string(),
  linkedin: Yup.string(),
  twitter: Yup.string(),
  facebook: Yup.string(),
  sites: Yup.string(),
});

function OpinionLeaderSubmission() {
  const [newSite, setNewSite] = useState('');
  const [newSites, setNewSites] = useState<string[]>([]);
  const [isNewSiteInvalid, setIsNewSiteInvalid] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<OpinionLeaderFormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(OpinionLeaderSchema),
  });
  const navigate = useNavigate();
  const {
    dispatch,
    state: { databaseEntity },
  } = useContext(AppContext);
  const [isAppLoading, setIsAppLoading] = useState(true);
  const opinionLeader =
    databaseEntity && databaseEntity instanceof OpinionLeader ? (databaseEntity as OpinionLeader) : null;

  useEffect(() => {
    setFormDefaultValues();
  }, []);

  const onSubmit: SubmitHandler<OpinionLeaderFormValues> = async (data) => {
    const opl = buildOpinionLeaderFromForm(data);

    try {
      if (opinionLeader) {
        await DatabaseService.addEntry<Feedback<OpinionLeader>>(
          Feedback.OPINION_LEADER_PATH,
          new Feedback(opl, FeedbackType.OpinionLeader),
          feedbackOpinionLeaderConverter
        );
        dispatch(openToastAction('Feedback introduzido com sucesso, requer aprovação', 'success'));
      } else {
        await DatabaseService.addEntry<OpinionLeader>(OpinionLeader.PATH, opl, opinionLeaderConverter);
        dispatch(openToastAction('Líder de opinião inserido com sucesso', 'success'));
      }

      navigate(`/lideres-de-opiniao/${opl?.name?.replace(/\s+/g, '')}/${encodeURIComponent(opl.id)}`);
    } catch {
      if (opinionLeader) {
        dispatch(openToastAction('Não foi possível deixar feedback', 'danger'));
      } else {
        dispatch(openToastAction('Não foi possível criar líder de opinião', 'danger'));
      }
    }
  };

  const handleNewSiteOnChange = (url: string) => {
    setIsNewSiteInvalid(false);
    setNewSite(url);
  };

  const handleNewSiteOnClick = () => {
    if (newSite) {
      if (isValidUrl(newSite)) {
        setNewSites([...newSites, newSite]);
        setNewSite('');
      } else {
        setIsNewSiteInvalid(true);
      }
    }
  };

  const handleRemoveNewSiteOnClick = (url: string) => {
    setNewSites(newSites.filter((site) => site !== url));
  };

  const buildOpinionLeaderFromForm = (data: OpinionLeaderFormValues) => {
    let opl: OpinionLeader;

    if (opinionLeader) {
      opl = opinionLeader;
      opl.name = data.name;
    } else {
      opl = OpinionLeader.newUnapprovedOrganization(data.name);
    }

    opl.academicBackground = data.academicBackground ?? [];
    opl.description = data.description;
    opl.facebook = data.facebook;
    opl.focusArea = data.focusArea;
    opl.linkedin = data.linkedin;
    opl.observations = data.observations;
    opl.problems = data.problems && Object.keys(data.problems).length > 0 ? arrayToObject(data.problems) : {};
    opl.sites = newSites.length > 0 ? newSites.join(' ') : '';
    opl.twitter = data.twitter;

    return opl;
  };

  const isProblemsAccordionOpen = () => {
    return opinionLeader && Object.keys(opinionLeader.problems ?? {}).length > 0;
  };

  const getProblemsAccordionItemKeys = () => {
    if (!opinionLeader || Object.keys(opinionLeader.problems ?? {}).length === 0) {
      return [];
    }

    return Object.keys(opinionLeader.problems ?? {}).map((problematic) => problematic.split('.')[0]);
  };

  const isAcademicBackgroundAccordionOpen = () => {
    return opinionLeader && (opinionLeader.academicBackground ?? []).length > 0;
  };

  const setFormDefaultValues = () => {
    if (opinionLeader) {
      setValue('name', opinionLeader.name ?? '');
      setValue('description', opinionLeader.description);
      setValue('observations', opinionLeader.observations);
      setValue('focusArea', opinionLeader.focusArea);
      setValue('linkedin', opinionLeader.linkedin);
      setValue('twitter', opinionLeader.twitter);
      setValue('facebook', opinionLeader.facebook);

      if (opinionLeader.sites) {
        setNewSites(
          opinionLeader.sites
            .split(/\s+/)
            .filter((url) => isValidUrl(url) || url.startsWith('www'))

            .map((url) => (url.startsWith('www') ? 'https://' + url : url))
        );
      }

      if (opinionLeader.problems && Object.keys(opinionLeader.problems).length > 0) {
        setValue('problems', objectToArray(opinionLeader.problems));
      }

      if (opinionLeader.academicBackground && opinionLeader.academicBackground.length > 0) {
        setValue(
          'academicBackground',
          opinionLeader.academicBackground.map((background) => background.trim())
        );
      }
    }

    setIsAppLoading(false);
  };

  const handleNavigationButton = () => {
    if (opinionLeader) {
      return {
        text: 'VOLTAR',
        link: `/lideres-de-opiniao/${
          opinionLeader && opinionLeader.name ? opinionLeader.name.replace(/\s+/g, '') : 'nome-indefinido'
        }/${encodeURIComponent(opinionLeader.id)}`,
      };
    } else {
      return {
        text: 'VOLTAR À LISTA',
        link: '/lideres-de-opiniao',
      };
    }
  };

  return (
    <Fragment>
      {isAppLoading ? (
        <Fragment />
      ) : (
        <Container className="py-5">
          <div className="d-inline-block mb-3">
            <Button
              text={handleNavigationButton().text}
              link={true}
              classes="p-0 m-0 d-flex align-items-center"
              icon={<ArrowLeftIcon classes="me-2" />}
              iconLeft={true}
              handleButtonPressed={() => navigate(handleNavigationButton().link)}
            />
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            {/* Opinion leader name */}
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>Nome do líder de opinião</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nome do líder de opinião"
                {...register('name')}
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
            </Form.Group>

            {/* Description */}
            <Form.Group className="mb-3" controlId="formDescription">
              <Form.Label>Descrição</Form.Label>
              <Form.Control as="textarea" rows={3} {...register('description')} />
            </Form.Group>

            {/* Observations */}
            <Form.Group className="mb-3" controlId="formObservations">
              <Form.Label>Observações</Form.Label>
              <Form.Control as="textarea" rows={3} {...register('observations')} />
            </Form.Group>

            {/* focusArea */}
            <Form.Group className="mb-3" controlId="formObservations">
              <Form.Label>Áreas de Foco</Form.Label>
              <Form.Control as="textarea" rows={3} {...register('focusArea')} />
            </Form.Group>

            {/* Linkedin */}
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>Linkedin</Form.Label>
              <Form.Control
                type="text"
                placeholder="Linkedin"
                {...register('linkedin')}
                isInvalid={!!errors.linkedin}
              />
              <Form.Control.Feedback type="invalid">{errors.linkedin?.message}</Form.Control.Feedback>
            </Form.Group>

            {/* Twitter */}
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>Twitter</Form.Label>
              <Form.Control type="text" placeholder="Twitter" {...register('twitter')} isInvalid={!!errors.twitter} />
              <Form.Control.Feedback type="invalid">{errors.twitter?.message}</Form.Control.Feedback>
            </Form.Group>

            {/* Facebook */}
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>Facebook</Form.Label>
              <Form.Control
                type="text"
                placeholder="Facebook"
                {...register('facebook')}
                isInvalid={!!errors.facebook}
              />
              <Form.Control.Feedback type="invalid">{errors.facebook?.message}</Form.Control.Feedback>
            </Form.Group>

            {/* Academic background */}
            <Accordion className="mb-3" defaultActiveKey={isAcademicBackgroundAccordionOpen() ? '0' : '-1'}>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Background Académico</Accordion.Header>
                <Accordion.Body>
                  <Table bordered={false}>
                    <tbody>
                      {Object.values(VOLUNTEERING_PROFILE).map((background) => {
                        return (
                          <tr key={background}>
                            <td>{cleanString(background)}</td>
                            <td>
                              <Form.Group controlId={`formAcademicBackground-${background}`}>
                                <Form.Check
                                  className="float-end"
                                  aria-label={background}
                                  {...register('academicBackground')}
                                  value={background}
                                />
                              </Form.Group>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            {/* Problems */}
            <Accordion className="mb-3" defaultActiveKey={isProblemsAccordionOpen() ? '0' : '-1'}>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Problemas Sociais</Accordion.Header>
                <Accordion.Body>
                  <Accordion defaultActiveKey={getProblemsAccordionItemKeys()} alwaysOpen>
                    {Object.values(SOCIAL_PROBLEMS).map((socialGroup) => {
                      return (
                        <Accordion.Item eventKey={socialGroup.id.toString()} key={socialGroup.group}>
                          <Accordion.Header>{cleanString(socialGroup.group)}</Accordion.Header>
                          <Accordion.Body>
                            <Table bordered={false}>
                              <tbody>
                                {socialGroup.problems.map((problem) => {
                                  return (
                                    <tr key={`${socialGroup.id}#${problem}`}>
                                      <td>{problem}</td>
                                      <td>
                                        <Form.Group controlId={`formProblems-${socialGroup.id}#${problem}`}>
                                          <Form.Check
                                            className="float-end"
                                            aria-label={problem}
                                            value={`${socialGroup.group}#${problem}`}
                                            {...register('problems')}
                                          />
                                        </Form.Group>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })}
                  </Accordion>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            {/* Useful websites */}
            <Form.Group className="mb-3" controlId="formInspiringLinks">
              <Form.Label>SITE(S) ÚTEIS</Form.Label>
              <div className="d-flex">
                <Form.Control
                  type="text"
                  placeholder="Novo site"
                  value={newSite}
                  onChange={(event) => handleNewSiteOnChange(event.target.value)}
                  isInvalid={isNewSiteInvalid}
                />
                <BsButton
                  variant="success"
                  size="sm"
                  type="button"
                  className="ms-3 py-0"
                  disabled={!newSite}
                  onClick={handleNewSiteOnClick}
                >
                  <AddIcon outerClasses="ms-auto me-auto" innerClasses={styles['c-icon']} />
                </BsButton>
              </div>
              {isNewSiteInvalid && <div className="d-block invalid-feedback">Tem que adicionar um URL válido</div>}
            </Form.Group>

            {newSites.length > 0 && (
              <Table bordered={false}>
                <tbody>
                  {newSites.map((site, index) => (
                    <tr key={`${site}-${index}`}>
                      <td>
                        <a href={site} target="_blank" rel="noreferrer">
                          {site}
                        </a>
                      </td>
                      <td className="pe-0">
                        <BsButton
                          variant="danger"
                          size="sm"
                          type="button"
                          className="ms-3 float-end"
                          onClick={() => handleRemoveNewSiteOnClick(site)}
                        >
                          <CloseIcon outerClasses="ms-auto me-auto" innerClasses={styles['c-icon']} />
                        </BsButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            <div className="d-flex justify-content-center">
              <BsButton variant="primary" size="lg" type="submit" className="text-white">
                {opinionLeader ? 'Submeter Feedback' : 'Submeter'}
              </BsButton>
            </div>
          </Form>
        </Container>
      )}
    </Fragment>
  );
}

export default OpinionLeaderSubmission;
