import { UseFormRegister } from 'react-hook-form';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';

// Components
import { FilteredFormValues } from '../../interfaces/filtered-form-values.interface';
import { ODS, REGIONS, SERVICES, TARGET_AUDIENCE, VOLUNTEERING_PROFILE } from '../../model/options';
import { cleanString } from '../../utils/helpers.util';
import AccordionToggle from '../accordion-toggle/accordion-toggle.component';

type AccordionArrayProps = {
  title: string;
  formRegister: UseFormRegister<FilteredFormValues>;
  formRegisterValue: keyof FilteredFormValues;
  eventKey: string;
  arr: typeof VOLUNTEERING_PROFILE | typeof TARGET_AUDIENCE | typeof REGIONS | typeof SERVICES | typeof ODS;
};

function AccordionArray({ title, formRegister, formRegisterValue, eventKey, arr }: AccordionArrayProps) {
  return (
    <div className="mt-3">
      <AccordionToggle eventKey={eventKey}>{title}</AccordionToggle>
      <Accordion.Collapse eventKey={eventKey}>
        <Table borderless={true} size="sm" className="mt-2">
          <tbody>
            {Object.values(arr).map((value, index) => (
              <tr key={`${value}-${index}`}>
                <td>
                  <p className="mb-0 ms-3">{cleanString(value)}</p>
                </td>
                <td className="align-middle">
                  <Form.Group controlId={`formAcademicBackground-${value}-${index}`} className="ms-3">
                    <Form.Check
                      className="float-end"
                      aria-label={value}
                      value={value}
                      {...formRegister(formRegisterValue)}
                    />
                  </Form.Group>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Accordion.Collapse>
    </div>
  );
}

export default AccordionArray;
