import { ReactNode } from 'react';

// Styles
import styles from './button.component.module.scss';

type ButtonProps = {
  text: string;
  outline?: boolean;
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  handleButtonPressed?: () => void;
  type?: 'button' | 'submit' | 'reset';
  classes?: string;
  link?: boolean;
  icon?: ReactNode;
  iconLeft?: boolean;
};

// normal button props:
//  - text
//  - optional: size, disabled, handleButtonPressed, type, classes

// outline button:
//  - text
//  - outline: true
//  - optional: size, disabled, handleButtonPressed, type, classes

// link button:
//  - text
//  - link: true
//  - optional: size, disabled, handleButtonPressed, type, classes, outline

// icon button:
//  - text
//  - icon
//  - iconLeft
//  - optional: size, disabled, handleButtonPressed, type, classes, outline, link
function Button({
  text,
  outline = false,
  size = 'medium',
  disabled = false,
  handleButtonPressed,
  type = 'button',
  classes,
  link = false,
  icon,
  iconLeft,
}: ButtonProps) {
  const options = {
    className: `${link ? styles['c-btn-link'] : outline ? styles['c-btn-outline'] : styles['c-btn']} ${
      styles['c-btn-' + size]
    } ${classes}`,
    type,
    disabled,
    onClick: handleButtonPressed,
  };

  return (
    <button {...options}>
      <>
        {typeof iconLeft !== 'undefined' && iconLeft && icon}
        {text}
        {typeof iconLeft !== 'undefined' && !iconLeft && icon}
      </>
    </button>
  );
}
export default Button;
