import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FirebaseError } from 'firebase/app';
import { Timestamp, serverTimestamp } from 'firebase/firestore';
import { date, object, string } from 'yup';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

// Components
import { AppContext } from '../../state/app-state-context';
import { AuthService, UserService } from '../../services';
import { DISTRICTS } from '../../model/options';
import { Datepicker } from '../../components';
import { FirebaseAuthErrors, UnknownError } from '../../constants/errors.constants';
import { Role } from '../../enums/role.enum';
import { User } from '../../interfaces/user.interface';
import { openToastAction } from '../../state/actions';

// Styles
import styles from './signup-confirmation.component.module.scss';

type FormValues = {
  name: string;
  surname: string;
  birthDate: Date;
  city: string;
  district: string;
};

const validationSchema = object().shape({
  name: string().required('Nome é um campo obrigatório'),
  surname: string().required('Apelido é um campo obrigatório'),
  birthDate: date()
    .required('Data de nascimento é um campo obrigatório')
    .transform((curr, orig) => (orig === '' ? null : curr))
    .nullable()
    .default(undefined),
  city: string().required('Cidade é um campo obrigatório'),
  district: string().oneOf(DISTRICTS, 'Tem que selecionar um distrito'),
});

function SignUpConfirmation() {
  const {
    dispatch,
    state: { currentAuthUser },
  } = useContext(AppContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: currentAuthUser?.displayName?.split(' ')[0] ?? '',
      surname: currentAuthUser?.displayName?.split(' ')[1] ?? '',
    },
  });
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<FormValues> = async (formValues) => {
    if (currentAuthUser && currentAuthUser.email) {
      const user: User = {
        id: currentAuthUser.uid,
        name: formValues.name.toLowerCase(),
        surname: formValues.surname.toLowerCase(),
        email: currentAuthUser.email,
        birthDate: Timestamp.fromDate(formValues.birthDate),
        city: formValues.city.toLowerCase(),
        district: formValues.district,
        created: serverTimestamp(),
        role: Role.Normal,
        migrated: false,
      };

      try {
        await UserService.creaseUserInDatabase(user);
        await AuthService.signOut();
        dispatch(openToastAction('Registo efetuado com sucesso, por favor verifique o seu email!', 'success'));
        navigate('/login');
      } catch (error) {
        if (error instanceof FirebaseError) {
          const errorMessage = FirebaseAuthErrors[error.code] ?? UnknownError;
          dispatch(openToastAction(errorMessage, 'danger'));
        }
      }
    }
  };

  return (
    <div className={styles['sign-up-confirmation-container']}>
      <div className={`ms-auto me-auto pb-5 ${styles['content']}`}>
        <div className={styles['content-title']}>
          <h4>Plataforma U.DREAM</h4>
          <h1>Continuar com o registo</h1>
        </div>
        <Form onSubmit={handleSubmit(onSubmit)} className={styles['content-inputs']} autoComplete="off">
          <Form.Group className="custom-bootstrap-form-group" controlId="formName">
            <Form.Label>Nome</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nome"
              {...register('name')}
              isInvalid={!!errors.name}
              className="custom-input"
            />
            <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="custom-bootstrap-form-group mt-3" controlId="formSurname">
            <Form.Label>Apelido</Form.Label>
            <Form.Control
              type="text"
              placeholder="Apelido"
              {...register('surname')}
              isInvalid={!!errors.surname}
              className="custom-input"
            />
            <Form.Control.Feedback type="invalid">{errors.surname?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="custom-bootstrap-form-group mt-3" controlId="formBirthDate">
            <Form.Label>Data de nascimento</Form.Label>
            <Controller
              control={control}
              name="birthDate"
              render={({ field }) => (
                <Datepicker isInvalid={!!errors.birthDate} field={field.value} onChange={field.onChange} />
              )}
            />

            <div className={`invalid-feedback ${errors.birthDate && 'd-block'}`}>{errors.birthDate?.message}</div>
          </Form.Group>
          <Form.Group className="custom-bootstrap-form-group mt-3" controlId="formCity">
            <Form.Label>Cidade</Form.Label>
            <Form.Control
              type="text"
              placeholder="Cidade"
              {...register('city')}
              isInvalid={!!errors.city}
              className="custom-input"
            />
            <Form.Control.Feedback type="invalid">{errors.city?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="custom-bootstrap-form-group mt-3" controlId="formDistrict">
            <Form.Label>Distrito</Form.Label>
            <Form.Select
              {...register('district')}
              isInvalid={!!errors.district}
              defaultValue="selectDistrictDefaultValue"
              className="custom-input-select"
            >
              <option disabled value="selectDistrictDefaultValue">
                Selecionar distrito
              </option>
              {DISTRICTS.map((d) => (
                <option key={d} value={d}>
                  {d}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">{errors.district?.message}</Form.Control.Feedback>
          </Form.Group>
          <div className={styles['content-button']}>
            <Button type="submit" className="w-100 custom-bootstrap-button">
              Registar
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default SignUpConfirmation;
