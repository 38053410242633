import { doc, getDoc, setDoc } from 'firebase/firestore';

// Components
import { Options } from '../interfaces/options.interface';
import { OptionsCollectionPath } from '../interfaces/options.interface';
import { db } from '../firebase-app';

const OPTIONS_DOCUMENT_NAME = 'options';

const OptionsService = {
  get: async () => {
    const docRef = doc(db, OptionsCollectionPath, OPTIONS_DOCUMENT_NAME);
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? (docSnap.data() as Options) : null;
  },
  update: async (options: Options) => {
    const docRef = doc(db, OptionsCollectionPath, OPTIONS_DOCUMENT_NAME);
    await setDoc(docRef, options);
  },
};

export default OptionsService;
