import { Dispatch, useState } from 'react';
import { SOCIAL_PROBLEMS } from '../../model/options';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';

type ProblemProps = {
  complexForm: any;
  setComplexForm: Dispatch<any>;
};

function getProblems(socialProblemChoice: string): string[] {
  let problems: string[] = [];
  Object.values(SOCIAL_PROBLEMS).forEach((entries) => {
    if (entries.group === socialProblemChoice) {
      if (entries.problems) {
        problems = entries.problems;
      }
    }
  });
  return problems;
}

function ProblemsForm({ complexForm, setComplexForm }: ProblemProps) {
  const [socialProblemChoice, setSocialProblemChoice] = useState<string>();

  return (
    <Form.Group className="mb-3" controlId="problems">
      <Table className={'table table-hover table-responsive table-striped table-bordered"'}>
        <tbody>
          {Object.entries(complexForm.problems).map(([problemGroup, problems], index) => {
            return (
              <tr key={index}>
                <td className="fw-bold">
                  {problemGroup}
                  <Button
                    variant="warning"
                    size="sm"
                    onClick={() => {
                      delete complexForm.problems[problemGroup];
                      setComplexForm({ ...complexForm, problems: complexForm.problems });
                    }}
                  >
                    🗑️
                  </Button>
                </td>
                {(problems as string[]).map((problem, i) => {
                  return (
                    <td key={i}>
                      {problem}
                      <Button
                        variant="warning"
                        size="sm"
                        onClick={() => {
                          complexForm.problems[problemGroup].splice(i, 1);
                          setComplexForm({ ...complexForm, problems: complexForm.problems });
                        }}
                      >
                        {'   '}🗑️
                      </Button>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Form.Select
        aria-label="social-problems-group"
        onChange={(e) => {
          setSocialProblemChoice(e.target.value);
        }}
      >
        {Object.values(SOCIAL_PROBLEMS).map((socialGroup, index) => {
          return (
            <option key={index} value={socialGroup?.group}>
              {socialGroup?.group}
            </option>
          );
        })}
      </Form.Select>
      <Form.Select
        aria-label="social-problems"
        onChange={(e) => {
          const aux = new Map(Object.entries(complexForm.problems)) as Map<string, string[]>;
          if (socialProblemChoice && aux) {
            if (aux.get(socialProblemChoice)) {
              aux.get(socialProblemChoice)?.push(e.target.value);
            } else {
              aux.set(socialProblemChoice, [e.target.value]);
            }
            setComplexForm({ ...complexForm, problems: Object.fromEntries(aux) });
          }
        }}
      >
        {socialProblemChoice &&
          getProblems(socialProblemChoice).map((problem, index) => {
            return (
              <option key={index} value={problem}>
                {problem}
              </option>
            );
          })}
      </Form.Select>
    </Form.Group>
  );
}

export default ProblemsForm;
