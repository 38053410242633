import * as Yup from 'yup';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { FirebaseError } from 'firebase/app';
import { Fragment, useContext, useEffect, useState } from 'react';
import { IconBaseProps } from 'react-icons';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

// Components
import { AppContext } from '../../state/app-state-context';
import { AuthService, EmailService } from '../../services';
import { Message } from '../../components';
import { openToastAction } from '../../state/actions';

// Styles
import styles from './actions.component.module.scss';

const iconProps: IconBaseProps = {
  color: 'rgba(23, 72, 74, 0.5)',
  size: '16',
};

type FormValues = {
  password: string;
  confirmPassword: string;
};

const schemaValidation = Yup.object().shape({
  password: Yup.string()
    .required('Palavra-passe é um campo obrigatório')
    .min(6, 'Palavra-passe tem que ter pelo o menos 6 caracteres')
    .max(40, 'Palavra-passe não pode exceder os 40 caracteres'),
  confirmPassword: Yup.string()
    .required('Confirmar palavra-passe é um campo obrigatório')
    .oneOf([Yup.ref('password')], 'As palavras-passes não correspondem'),
});

function Actions() {
  const { dispatch } = useContext(AppContext);
  const [error, setError] = useState<FirebaseError>();
  const [emailFromPasswordReset, setEmailFromPasswordReset] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const mode = searchParams.get('mode');
  const actionCode = searchParams.get('oobCode');
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(schemaValidation),
  });

  useEffect(() => {
    const setPurpose = async () => {
      if (mode && actionCode) {
        switch (mode) {
          case 'verifyEmail':
            handleVerifyEmail(actionCode);
            break;
          case 'resetPassword': {
            handleResetPassword(actionCode);
            break;
          }
          default:
            navigate('/');
        }
      }
    };

    setPurpose();
  }, []);

  const handleVerifyEmail = async (actionCode: string) => {
    try {
      await AuthService.validateEmailVerification(actionCode);
      await AuthService.signOut();
      dispatch(openToastAction('Email verificado com sucesso, por favor faça login', 'info'));
      navigate('/login');
    } catch (error) {
      if (error instanceof FirebaseError) {
        setError(error);
      }
    }
  };

  const handleResetPassword = async (actionCode: string) => {
    const userEmail = await AuthService.verifyPasswordResetCode(actionCode);
    setEmailFromPasswordReset(userEmail);
  };

  const onSubmitPasswordReset: SubmitHandler<FormValues> = async (data) => {
    if (!actionCode || !mode || mode !== 'resetPassword' || !emailFromPasswordReset) {
      return;
    }

    try {
      await AuthService.confirmPasswordReset(actionCode, data.password);
      await AuthService.signOut();
      EmailService.resetUserPasswordConfirmation(emailFromPasswordReset);
      dispatch(openToastAction('Palavra-passe alterada com sucesso, por favor faça login', 'success'));
      navigate('/login');
    } catch (error) {
      if (error instanceof FirebaseError) {
        setError(error);
      }
    }
  };

  const handleShowPassword = () => setPasswordShown(!passwordShown);
  const handleShowConfirmPassword = () => setConfirmPasswordShown(!confirmPasswordShown);

  return (
    <div className={styles['actions-container']}>
      <div className={`ms-auto me-auto pb-5 ${styles['content']}`}>
        {error && <Message message={error.message} />}
        {mode === 'resetPassword' && emailFromPasswordReset && !error && (
          <Fragment>
            <div className={styles['content-title']}>
              <h4>Plataforma U.DREAM</h4>
              <h1>Introduza a sua nova palavra-passe</h1>
            </div>
            <Form
              onSubmit={handleSubmit(onSubmitPasswordReset)}
              className={styles['content-inputs']}
              autoComplete="off"
            >
              <Form.Group className="custom-bootstrap-form-group mt-3" controlId="formPassword">
                <Form.Label>Palavra-passe</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={passwordShown ? 'text' : 'password'}
                    placeholder="Palavra-passe"
                    {...register('password')}
                    isInvalid={!!errors.password}
                    className="custom-input-password"
                  />
                  <InputGroup.Text onClick={handleShowPassword} role="button">
                    {passwordShown ? <AiOutlineEyeInvisible {...iconProps} /> : <AiOutlineEye {...iconProps} />}
                  </InputGroup.Text>
                </InputGroup>
                <div className={`invalid-feedback ${errors.password && 'd-block'}`}>{errors.password?.message}</div>
              </Form.Group>
              <Form.Group className="custom-bootstrap-form-group mt-3" controlId="formConfirmPassword">
                <Form.Label>Confirme a palavra-passe</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={confirmPasswordShown ? 'text' : 'password'}
                    placeholder="Confirme a palavra-passe"
                    {...register('confirmPassword')}
                    isInvalid={!!errors.confirmPassword}
                    className="custom-input-password"
                  />
                  <InputGroup.Text onClick={handleShowConfirmPassword} role="button">
                    {confirmPasswordShown ? <AiOutlineEyeInvisible {...iconProps} /> : <AiOutlineEye {...iconProps} />}
                  </InputGroup.Text>
                </InputGroup>
                <div className={`invalid-feedback ${errors.confirmPassword && 'd-block'}`}>
                  {errors.confirmPassword?.message}
                </div>
              </Form.Group>
              <div className={styles['content-button']}>
                <Button type="submit" className="w-100 custom-bootstrap-button">
                  Registar nova palavra-passe
                </Button>
              </div>
            </Form>
          </Fragment>
        )}
      </div>
    </div>
  );
}
export default Actions;
