type ColorsType = {
  [key: string]: string;
};

export const Colors: ColorsType = {
  white: '#FFFFFF',
  gray100: '#FCFCFC',
  gray200: '#F8F8F8',
  gray300: '#F0F0F0',
  gray400: '#DBDBDB',
  gray500: '#C9C9C9',
  gray600: '#AFAFAF',
  gray700: '#6D6D6D',
  gray800: '#282828',
  black: '#000000',
  blue00: '#007480',
  blue01: '#0099A8',
  blue02: '#6AC3CC',
  blue03: '#B6E2E6',
  blue04: '#E7F5F7',
  yellow00: '#FFD96F',
  yellow01: '#FFEEC0',
  color01: '#CCEBEE',
  color02: '#F7E6D6',
  color03: '#F0C8C8',
  color04: '#F7F2C3',
  color05: '#E4F7C3',
  color06: '#C3F7D5',
  color07: '#C3E2F7',
  color08: '#C3CCF7',
  color09: '#EAC3F7',
  color10: '#F7C3D9',
  color11: '#C3F7F7',
  color12: '#DCAED5',
  color13: '#CEC8DE',
};
