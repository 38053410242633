import { AppStore } from './types';
import { User as AuthUser, onIdTokenChanged } from 'firebase/auth';
import { User as FirestoreUser } from '../interfaces/user.interface';
import { LearnWorldsService, UserService } from '../services';
import { ReactNode, createContext, useEffect } from 'react';
import { auth } from '../firebase-app';
import {
  createAuthUserStateChangedAction,
  createFirestoreUserStateChangedAction,
  finishAppLoadingAction,
  loadLearnWorldsAction,
} from './actions';
import { initialState, reducer } from './reducer';
import { useReducerWithMiddleware } from './use-reducer-with-middleware';

const AppContext = createContext<AppStore>({
  state: initialState,
  dispatch: () => undefined,
});

function AppStateProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducerWithMiddleware(reducer, initialState);
  const providerState = {
    state,
    dispatch,
  };

  const setCurrentAuthUser = (authUser: AuthUser | null) => dispatch(createAuthUserStateChangedAction(authUser));
  const setCurrentFirestoreUser = (firestoreUser: FirestoreUser | null) =>
    dispatch(createFirestoreUserStateChangedAction(firestoreUser));
  const setFinishAppLoadingAction = () => dispatch(finishAppLoadingAction());
  const setLoadLearnWorldsAction = (url: string) => dispatch(loadLearnWorldsAction(url));

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(auth, async (authUser) => {
      let firestoreUser: FirestoreUser | null = null;

      if (authUser) {
        firestoreUser = await UserService.getUser(authUser.uid);
      }

      setCurrentFirestoreUser(firestoreUser);
      setCurrentAuthUser(authUser);

      if (firestoreUser) {
        const { success, url } = await LearnWorldsService.loginUser(firestoreUser);
        if (success) {
          setLoadLearnWorldsAction(url);
        }
      } else {
        setLoadLearnWorldsAction('');
      }

      setFinishAppLoadingAction();
    });

    return unsubscribe;
  }, []);

  return <AppContext.Provider value={providerState}>{children}</AppContext.Provider>;
}

export { AppContext, AppStateProvider };
