import Container from 'react-bootstrap/Container';

import { ListElement, PageHeader } from '../../components';
import STATIC_DATA from '../home/data';
import headerImg from '../../images/acoes-de-impacto/header.png';

import './list-volunteering-actions.component.scss';

function ListVolunteeringActions() {
  return (
    <Container className="py-5 list-volunteering-actions-page">
      <PageHeader
        title="As próximas ações de impacto social U.DREAM"
        description="Aqui poderás encontrar um conjunto de ações de impacto que estão de portas abertas para qualquer pessoa que sonhe em fazer a diferença. Estes eventos são a oportunidade certa para iniciares o teu caminho de Liderança Social. Pautados pela informalidade e alegria que nos caracteriza, estes momentos prometem colocar a paixão de cuidar da comunidade na rota da tua vida. Vamos a isso?"
        buttons={[]}
        img={<img src={headerImg} style={{ width: '394px', height: '445px' }}></img>}
      />
      <div className="results-list">
        {STATIC_DATA.IMPACT_ACTIONS.map((impactAction, index) => (
          <ListElement
            key={`impact-action-${index}`}
            title={impactAction.title}
            description={impactAction.description}
            tags={[]}
            link="https://forms.gle/N3BZKzc8bYox2GJP9"
            imageSrc={impactAction.image}
          />
        ))}
      </div>
    </Container>
  );
}

export default ListVolunteeringActions;
