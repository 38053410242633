import { Fragment, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Variant } from 'react-bootstrap/esm/types';
import BsButton from 'react-bootstrap/Button';
import Container from 'react-bootstrap/container';
import Form from 'react-bootstrap/form';

// Components
import { Options } from '../../interfaces/options.interface';
import { Toaster } from '../../components';
import { isValidUrl } from '../../utils/helpers.util';
import OptionsService from '../../services/options.service';

type ManageOptionsFormValues = {
  actionsFormUrl: string;
};

function ManageOptions() {
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState('');
  const [bgToast, setBgToast] = useState<Variant>('success');
  const [isLoading, setIsLoading] = useState(true);
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm<ManageOptionsFormValues>({
    mode: 'onSubmit',
  });

  useEffect(() => {
    getOptionsFromDb();
  }, []);

  const getOptionsFromDb = async () => {
    try {
      const optionsFromDb = await OptionsService.get();
      if (optionsFromDb) {
        setDefaultFormValues(optionsFromDb);
      }
    } catch {
      setBgToast('warning');
      setTextToast('Não foi possível carregar o url do formulário');
      setShowToast(true);
    } finally {
      setIsLoading(false);
    }
  };

  const setDefaultFormValues = (optionsFromDb: Options) => {
    if (optionsFromDb.actionsFormUrl) {
      setValue('actionsFormUrl', optionsFromDb.actionsFormUrl);
    }
  };

  const validateFormErrors = (data: ManageOptionsFormValues) => {
    let numberOfErrors = 0;

    if (data.actionsFormUrl.length > 0 && !isValidUrl(data.actionsFormUrl)) {
      setError('actionsFormUrl', { message: 'URL não é válido (https://...)' });
      numberOfErrors++;
    }

    return numberOfErrors > 0;
  };

  const onSubmit: SubmitHandler<ManageOptionsFormValues> = async (data) => {
    if (validateFormErrors(data)) {
      return;
    }

    try {
      await OptionsService.update({ ...data });
      setBgToast('success');
      setTextToast('Opções guardadas com sucesso');
    } catch {
      setBgToast('danger');
      setTextToast('Erro ao guardar opções');
    } finally {
      setShowToast(true);
    }
  };

  return (
    <Fragment>
      {isLoading ? (
        <Fragment />
      ) : (
        <Container className="py-5">
          <Form onSubmit={handleSubmit(onSubmit)}>
            {/* Form URL */}
            <Form.Group className="mb-3" controlId="formActionsFormUrl">
              <Form.Label>URL do Form das ações de impacto</Form.Label>
              <Form.Control
                type="text"
                placeholder="https://"
                {...register('actionsFormUrl')}
                isInvalid={!!errors.actionsFormUrl}
              />
              <Form.Control.Feedback type="invalid">{errors.actionsFormUrl?.message}</Form.Control.Feedback>
            </Form.Group>
            <div className="d-flex justify-content-center">
              <BsButton variant="primary" size="lg" type="submit" className="text-white">
                Submeter
              </BsButton>
            </div>
          </Form>
        </Container>
      )}

      <Toaster toastText={textToast} backgroundColor={bgToast} setShowToast={setShowToast} showToast={showToast} />
    </Fragment>
  );
}

export default ManageOptions;
