import axios from 'axios';
import humps from 'humps';

// Components
import { AuthenticationRequest } from '../interfaces/learnworlds/requests/authentication-request.interface';
import { AuthenticationResponse } from '../interfaces/learnworlds/responses/authentication-response.interface';
import { AuthenticationTokenData } from '../interfaces/learnworlds/authentication-token-data.interface';
import { Course } from '../interfaces/learnworlds/course.interface';
import { GetACourseResponse } from '../interfaces/learnworlds/responses/get-a-course-response.interface';
import { GetCoursesResponse } from '../interfaces/learnworlds/responses/get-courses-response.interface';
import { GetUserCoursesResponse } from '../interfaces/learnworlds/responses/get-user-courses-response.interface';
import { GetUserProgressResponse } from '../interfaces/learnworlds/responses/get-user-progress-response.interface';
import { SsoRequest } from '../interfaces/learnworlds/requests/sso-request.interface';
import { SsoResponse } from '../interfaces/learnworlds/responses/sso-response.interface';
import { User } from '../interfaces/user.interface';

const learnworldsApi = axios.create({
  baseURL: process.env.REACT_APP_LEARN_WORLDS_API_URL,
});

learnworldsApi.interceptors.request.use(
  async (config) => {
    const accessToken = await getAccessToken();
    config.headers = {
      'Lw-Client': process.env.REACT_APP_LEARN_WORLDS_CLIENT_ID ?? '',
      Authorization: `Bearer ${accessToken}`,
    };

    return config;
  },
  (error) => Promise.reject(error)
);

type GetCoursesParams = { page?: number; categories?: string[] };

const LearnWorldsService = {
  loginUser: async (user: User) => {
    return await loginUser(user);
  },
  getUserCourses: async (userEmail: string) => {
    try {
      const userProgress = await getUserProgress(userEmail);

      const userCourses: Course[] = [];

      for (const progressWithCourse of userProgress) {
        const course = await getCourse(progressWithCourse.courseId);
        userCourses.push(course);
      }

      return userCourses;
    } catch {
      return [] as Course[];
    }
  },
  getUserProgress: async (userEmail: string) => getUserProgress(userEmail),
  getCourseInformation: async (courseId: string) => getCourse(courseId),
  getAllCourses: async (params?: GetCoursesParams) => getAllCourses(params),
};

async function getUserProgress(userEmail: string) {
  const response = await learnworldsApi.get(`/v2/users/${userEmail}/progress`);
  const { data: userProgress } = humps.camelizeKeys<GetUserProgressResponse>(response.data);
  return userProgress;
}

async function getCourse(courseId: string) {
  const response = await learnworldsApi.get(`/v2/courses/${courseId}`);
  const course = humps.camelizeKeys<GetACourseResponse>(response.data);
  return course;
}

async function getUserCourses(userEmail: string) {
  const response = await learnworldsApi.get(`/v2/users/${userEmail}/courses`);
  const { data } = humps.camelizeKeys<GetUserCoursesResponse>(response.data);
  const userCourses = data.map((d) => d.course);
  return userCourses;
}

async function getAllCourses(params?: GetCoursesParams) {
  const courseParams = new URLSearchParams();

  if (params) {
    const { page, categories } = params;

    if (page) {
      courseParams.append('page', page.toString());
    }

    if (categories && categories.length > 0) {
      courseParams.append('categories', categories.join(','));
    }
  }

  const response = await learnworldsApi.get(`/v2/courses`, { params: courseParams });
  return humps.camelizeKeys<GetCoursesResponse>(response.data);
}

async function loginUser(user: User) {
  const request: SsoRequest = {
    email: user.email,
    username: user.name + ' ' + user.surname,
    avatar: process.env.REACT_APP_LEARN_WORLDS_USER_AVATAR ?? '',
    redirectUrl: process.env.REACT_APP_LEARN_WORLDS_REDIRECT_URL ?? '',
  };

  const response = await learnworldsApi.post('/sso', request);
  return humps.camelizeKeys<SsoResponse>(response.data);
}

async function getAccessTokenFromApi() {
  const request: AuthenticationRequest = {
    clientId: process.env.REACT_APP_LEARN_WORLDS_CLIENT_ID ?? '',
    clientSecret: process.env.REACT_APP_LEARN_WORLDS_CLIENT_SECRET ?? '',
    grantType: process.env.REACT_APP_LEARN_WORLDS_GRANT_TYPE ?? '',
  };

  const url = `${process.env.REACT_APP_LEARN_WORLDS_API_URL}/oauth2/access_token`;
  const headers = { 'Lw-Client': process.env.REACT_APP_LEARN_WORLDS_CLIENT_ID ?? '' };

  const response = await axios.post(url, humps.decamelizeKeys(request), { headers });
  const data = humps.camelizeKeys<AuthenticationResponse>(response.data);
  const tokenData: AuthenticationTokenData = {
    accessToken: data.tokenData.accessToken,
    tokenExpiryDate: Date.now() + data.tokenData.expiresIn * 1000,
  };

  localStorage.setItem(process.env.REACT_APP_LEARN_WORLDS_LOCALSTORAGE_KEY ?? '', JSON.stringify(tokenData));

  return tokenData.accessToken;
}

async function getAccessToken() {
  const learnWorldsLocalStorageData = localStorage.getItem(process.env.REACT_APP_LEARN_WORLDS_LOCALSTORAGE_KEY ?? '');

  if (learnWorldsLocalStorageData) {
    const tokenData = JSON.parse(learnWorldsLocalStorageData) as AuthenticationTokenData;
    const isTokenValid = tokenData && Date.now() < tokenData.tokenExpiryDate;

    if (isTokenValid) {
      return Promise.resolve(tokenData.accessToken);
    }
  }

  return getAccessTokenFromApi();
}

export default LearnWorldsService;
