import * as Yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Variant } from 'react-bootstrap/types';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import Accordion from 'react-bootstrap/Accordion';
import BsButton from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';

// Components
import { Action, actionConverter } from '../../model/actions/action';
import { AddIcon, ArrowLeftIcon, Button, CloseIcon, Toaster } from '../../components';
import { DatabaseService } from '../../services/database.service';
import { IMPACT_ACTIONS_DURATIONS, SOCIAL_PROBLEMS, TARGET_AUDIENCE } from '../../model/options';
import { arrayToObject, cleanString, isValidUrl } from '../../utils/helpers.util';

// Styles
import styles from './new-impact-action.component.module.scss';

type NewImpactActionFormValues = {
  actionDescription: string;
  actionHowTo: string;
  durationDescription?: string;
  problems?: string[];
  targetAudience?: string[];
};

const newImpactActionSchema = Yup.object().shape({
  actionDescription: Yup.string().required('Descrição da ação é um campo obrigatório'),
  actionHowTo: Yup.string().required('Como executar ação é um campo obrigatório'),
  durationDescription: Yup.string(),
  problems: Yup.array().of(Yup.string()).nullable(),
  targetAudience: Yup.array().of(Yup.string()).nullable(),
});

function NewImpactAction() {
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState('');
  const [bgToast, setBgToast] = useState<Variant>('success');
  const [newInspirationLink, setNewInspirationLink] = useState('');
  const [isNewInspirationLinkInvalid, setIsNewInspirationLinkInvalid] = useState(false);
  const [newInspirationLinks, setNewInspirationLinks] = useState<string[]>([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<NewImpactActionFormValues>({
    mode: 'all',
    resolver: yupResolver(newImpactActionSchema),
  });
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<NewImpactActionFormValues> = async (data) => {
    try {
      const newAction = await createNewImpactAction(data);
      navigate(`/acoes-de-impacto/${encodeURIComponent(newAction.id)}`);
    } catch {
      setTextToast('Não foi possível criar ação de impacto.');
      setBgToast('danger');
      setShowToast(true);
    }
  };

  const handleNewInspirationLinkOnChange = (url: string) => {
    setIsNewInspirationLinkInvalid(false);
    setNewInspirationLink(url);
  };

  const handleNewInspirationLinkOnClick = () => {
    if (newInspirationLink) {
      if (isValidUrl(newInspirationLink)) {
        setNewInspirationLinks([...newInspirationLinks, newInspirationLink]);
        setNewInspirationLink('');
      } else {
        setIsNewInspirationLinkInvalid(true);
      }
    }
  };

  const handleRemoveNewInspirationLinkOnClick = (url: string) => {
    setNewInspirationLinks(newInspirationLinks.filter((link) => link !== url));
  };

  const createNewImpactAction = async (data: NewImpactActionFormValues) => {
    const newAction = Action.newUnapprovedAction(data.actionDescription, data.actionHowTo);
    newAction.durationDescription = data.durationDescription;
    newAction.problems = data.problems && Object.keys(data.problems).length > 0 ? arrayToObject(data.problems) : {};
    newAction.targetAudience = data.targetAudience ?? [];
    newAction.inspirationLinks = newInspirationLinks.length > 0 ? newInspirationLinks.join(' ') : '';

    return await DatabaseService.addEntry<Action>(Action.PATH, newAction, actionConverter);
  };

  return (
    <>
      <Container className="py-5">
        <div className="d-inline-block mb-3">
          <Button
            text="VOLTAR À LISTA"
            link={true}
            classes="p-0 m-0 d-flex align-items-center"
            icon={<ArrowLeftIcon classes="me-2" />}
            iconLeft={true}
            handleButtonPressed={() => navigate('/acoes-de-impacto')}
          />
        </div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {/* Impact action description */}
          <Form.Group className="mb-3" controlId="formDescription">
            <Form.Label>Objetivo da ação</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              {...register('actionDescription')}
              isInvalid={!!errors.actionDescription}
            />
            <Form.Control.Feedback type="invalid">{errors.actionDescription?.message}</Form.Control.Feedback>
          </Form.Group>

          {/* Impact action how to */}
          <Form.Group className="mb-3" controlId="formHowTo">
            <Form.Label>Como será a ação</Form.Label>
            <Form.Control as="textarea" rows={3} {...register('actionHowTo')} />
          </Form.Group>

          {/* Duration description */}
          <Form.Group className="mb-3" controlId="formDurationDescription">
            <Form.Label>Quanto tempo necessito dedicar para concluir a ação?</Form.Label>
            <Form.Select {...register('durationDescription')}>
              <option value="">Selecionar duração</option>
              {IMPACT_ACTIONS_DURATIONS.map((durationDescription: string, index) => {
                return (
                  <option key={`${durationDescription}-${index}`} value={durationDescription}>
                    {durationDescription}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>

          {/* Problems */}
          <Accordion className="mb-3">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Problemas Sociais</Accordion.Header>
              <Accordion.Body>
                {Object.values(SOCIAL_PROBLEMS).map((socialGroup, index) => {
                  return (
                    <Accordion
                      key={`${socialGroup.group}-${index}`}
                      className={`${SOCIAL_PROBLEMS.length - 1 !== index && 'mb-3'}`}
                    >
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>{cleanString(socialGroup.group)}</Accordion.Header>
                        <Accordion.Body>
                          <Table bordered={false}>
                            <tbody>
                              {socialGroup.problems.map((problem, index) => {
                                return (
                                  <tr key={`${problem}-${index}`}>
                                    <td>{problem}</td>
                                    <td>
                                      <Form.Group controlId={`formProblems-${problem}-${index}`}>
                                        <Form.Check
                                          className="float-end"
                                          aria-label={problem}
                                          value={`${socialGroup.group}#${problem}`}
                                          {...register('problems')}
                                        />
                                      </Form.Group>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  );
                })}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          {/* Target audience */}
          <Accordion className="mb-3">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Público Alvo</Accordion.Header>
              <Accordion.Body>
                <Table bordered={false}>
                  <tbody>
                    {Object.values(TARGET_AUDIENCE).map((target, index) => {
                      return (
                        <tr key={`${target}-${index}`}>
                          <td>{cleanString(target)}</td>
                          <td>
                            <Form.Group controlId={`formTargetAudience-${target}-${index}`}>
                              <Form.Check
                                className="float-end"
                                aria-label={target}
                                {...register('targetAudience')}
                                value={target}
                              />
                            </Form.Group>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          {/* Links de inspiração */}
          <Form.Group className="mb-3" controlId="formInspiringLinks">
            <Form.Label>Links de inspiração</Form.Label>
            <div className="d-flex">
              <Form.Control
                type="text"
                placeholder="Novo link de inspiração"
                value={newInspirationLink}
                onChange={(event) => handleNewInspirationLinkOnChange(event.target.value)}
                isInvalid={isNewInspirationLinkInvalid}
              />
              <BsButton
                variant="success"
                size="sm"
                type="button"
                className="ms-3 py-0"
                disabled={!newInspirationLink}
                onClick={handleNewInspirationLinkOnClick}
              >
                <AddIcon outerClasses="ms-auto me-auto" innerClasses={styles['c-icon']} />
              </BsButton>
            </div>
            {isNewInspirationLinkInvalid && (
              <div className="d-block invalid-feedback">Tem que adicionar um URL válido</div>
            )}
          </Form.Group>

          {newInspirationLinks.length > 0 && (
            <Table bordered={false}>
              <tbody>
                {newInspirationLinks.map((link, index) => (
                  <tr key={`${link}-${index}`}>
                    <td>
                      <a href={link} target="_blank" rel="noreferrer">
                        {link}
                      </a>
                    </td>
                    <td className="pe-0">
                      <BsButton
                        variant="danger"
                        size="sm"
                        type="button"
                        className="ms-3 float-end"
                        onClick={() => handleRemoveNewInspirationLinkOnClick(link)}
                      >
                        <CloseIcon outerClasses="ms-auto me-auto" innerClasses={styles['c-icon']} />
                      </BsButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}

          <div className="d-flex justify-content-center">
            <BsButton variant="primary" size="lg" type="submit" className="text-white">
              Submeter
            </BsButton>
          </div>
        </Form>
      </Container>
      <Toaster toastText={textToast} showToast={showToast} setShowToast={setShowToast} backgroundColor={bgToast} />
    </>
  );
}

export default NewImpactAction;
