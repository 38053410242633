import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import Container from 'react-bootstrap/Container';

// Components
import { Action, actionConverter } from '../../model/actions/action';
import {
  ArrowLeftIcon,
  DetailsPageHeader,
  DetailsPageSection,
  DetailsPageTag,
  DetailsPageText,
} from '../../components';
import { DatabaseService } from '../../services/database.service';
import { SOCIAL_PROBLEMS } from '../../model/options';
import { ScreenBreakpoint } from '../../enums/screen-breakpoint.enum';
import { cleanString } from '../../utils/helpers.util';
import { fetchImage } from '../../utils';

// Styles
import './impact-action-detail.component.scss';

function ImpactActionDetail() {
  const { width: windowWidth } = useWindowSize();
  const { actionId } = useParams();
  const [action, setAction] = useState<Action>();
  const [actionImageSrc, setActionImageSrc] = useState('');

  useEffect(() => {
    if (actionId) {
      getOpinionLeader(actionId);
    }
  }, []);

  const getOpinionLeader = async (actionId: string) => {
    const actionFromDb = await DatabaseService.getById<Action>(Action.PATH, actionId, actionConverter);

    if (actionFromDb) {
      setAction(actionFromDb);
      fetchActionImage(actionFromDb.problems ?? {});
    }
  };

  const fetchActionImage = async (socialProblems: { [key: string]: string[] }) => {
    const placeholderSrcImg = (await fetchImage('placeholder.png')).image;

    if (Object.keys(socialProblems).length === 0) {
      setActionImageSrc(placeholderSrcImg);
    } else {
      const socialProblem = Object.keys(socialProblems)[0];
      const socialProblemImage = SOCIAL_PROBLEMS.find((sP) => sP.group === socialProblem)?.image_social_problems;

      if (!socialProblemImage) {
        setActionImageSrc(placeholderSrcImg);
      } else {
        const { image, error } = await fetchImage(`acoes-de-impacto/${socialProblemImage}.jpg`);

        if (error) {
          setActionImageSrc(placeholderSrcImg);
        } else {
          setActionImageSrc(image);
        }
      }
    }
  };

  return (
    <Container className="py-5">
      <a className="go-back" href="/acoes-de-impacto">
        <ArrowLeftIcon />
        <span>VOLTAR À LISTA</span>
      </a>
      <div className="details-page-content">
        <div className="details-page-content-left">
          {windowWidth < ScreenBreakpoint.Large ? <img src={actionImageSrc} alt={action?.actionDescripton} /> : <></>}
          <DetailsPageHeader
            sectionTitle="Ações simples de Impacto"
            title={action?.actionDescripton}
          ></DetailsPageHeader>
          {action?.actionHowTo ? (
            <DetailsPageSection title="Como será a ação">
              <DetailsPageText labels={[action?.actionHowTo]}></DetailsPageText>
            </DetailsPageSection>
          ) : (
            <></>
          )}
          {Object.entries(action?.problems || {}).length ? (
            <DetailsPageSection title="Problemáticas">
              {Object.entries(action?.problems || {}).map(([problematic, problems], problemIndex) => {
                return (
                  <DetailsPageText
                    key={`problem-${problemIndex}`}
                    title={cleanString(problematic)}
                    labels={problems.map((problem) => `· ${problem}`)}
                  ></DetailsPageText>
                );
              })}
            </DetailsPageSection>
          ) : (
            <></>
          )}
        </div>
        <div className="details-page-content-right">
          {windowWidth < ScreenBreakpoint.Large ? <></> : <img src={actionImageSrc} alt={action?.actionDescripton} />}
          {action?.durationDescription ? (
            <DetailsPageSection title="Duração">
              <div className="details-page-tags">
                <DetailsPageTag>{action?.durationDescription}</DetailsPageTag>
              </div>
            </DetailsPageSection>
          ) : (
            <></>
          )}
          {action?.targetAudience ? (
            <DetailsPageSection title="Público-Alvo">
              <div className="details-page-tags">
                {(action?.targetAudience || []).map((targetAudienceType, index) => {
                  return (
                    <DetailsPageTag key={`target-audience-${index}`}>{cleanString(targetAudienceType)}</DetailsPageTag>
                  );
                })}
              </div>
            </DetailsPageSection>
          ) : (
            <></>
          )}
        </div>
      </div>
    </Container>
  );
}

export default ImpactActionDetail;
