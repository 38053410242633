import { AiOutlineClose } from 'react-icons/ai';
import { Dispatch, Fragment, SetStateAction, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import BootstrapNav from 'react-bootstrap/Nav';
import BootstrapNavbar from 'react-bootstrap/Navbar';
import BootstrapOffcanvas from 'react-bootstrap/Offcanvas';

import { AppContext } from '../../../../state/app-state-context';
import { Role } from '../../../../enums/role.enum';
import { ScreenBreakpoint } from '../../../../enums/screen-breakpoint.enum';
import Dropdown from '../../../dropdown/dropdown.component';
import NavbarDropdownProfile from '../navbar-dropdown-profile/navbar-dropdown-profile.component';

import blueLogo from '../../../../images/blue-logo.png';

import './navbar-offcanvas.styles.scss';

const IMPACT_ACTIONS_DROPDOWN_PROPS = {
  list: [
    { title: 'Organizações e projetos', path: '/projetos' },
    { title: 'Ações simples', path: '/acoes-de-impacto' },
  ],
  title: 'Ações de impacto',
};

type NavbarOffcanvasProps = {
  ariaControlsId: string;
  showOffcanvas: boolean;
  setShowOffcanvas: Dispatch<SetStateAction<boolean>>;
};

function NavbarOffcanvas({ ariaControlsId, showOffcanvas, setShowOffcanvas }: NavbarOffcanvasProps) {
  const {
    state: { currentAuthUser, currentFirestoreUser, learnWorlds },
  } = useContext(AppContext);
  const { width: windowWidth } = useWindowSize();

  useEffect(() => {
    if (windowWidth >= ScreenBreakpoint.Large && showOffcanvas) {
      setShowOffcanvas(false);
    }
  }, [windowWidth]);

  const isAdmin = currentAuthUser?.emailVerified && currentFirestoreUser?.role === Role.Admin;
  const learnWorldsUrl = learnWorlds.url || 'https://udream.learnworlds.com/courses';

  const navbarOffcanvasProps = {
    id: ariaControlsId,
    'aria-labelledby': 'offcanvasNavbarLabel-expand-lg',
    placement: 'end',
    className: 'u-navbar-offcanvas',
    keyboard: true,
    show: showOffcanvas,
  };

  const handleCloseButton = () => {
    setShowOffcanvas(false);
  };

  return (
    <BootstrapNavbar.Offcanvas {...navbarOffcanvasProps}>
      <BootstrapOffcanvas.Header className="u-navbar-offcanvas-header">
        <BootstrapOffcanvas.Title
          as={Link}
          to="/"
          id="offcanvasNavbarLabel-expand-lg"
          className="u-navbar-offcanvas-header-title"
        >
          <img src={blueLogo} className="u-navbar-offcanvas-header-logo" alt="U.DREAM logo" />
        </BootstrapOffcanvas.Title>
        <button onClick={handleCloseButton} className="u-navbar-offcanvas-header-close-button">
          <AiOutlineClose size="16" />
        </button>
      </BootstrapOffcanvas.Header>
      {windowWidth < ScreenBreakpoint.Large ? (
        <BootstrapOffcanvas.Body className="u-navbar-offcanvas-body-expanded">
          <BootstrapNav className="u-navbar-offcanvas-body-expanded-navigation">
            {/*
            <BootstrapNav.Item className="u-nav-item">{IMPACT_ACTIONS_DROPDOWN_PROPS.title}</BootstrapNav.Item>
            {IMPACT_ACTIONS_DROPDOWN_PROPS.list.map((l) => (
              <BootstrapNav.Link key={l.title} as={Link} to={l.path} className="u-nav-link">
                {l.title}
              </BootstrapNav.Link>
            ))}
            <hr className="u-nav-item-divider" />
            <BootstrapNav.Link as={Link} to="/lideres-de-opiniao" className="u-nav-link">
              Líderes sociais
            </BootstrapNav.Link>
            */}
            <BootstrapNav.Link as={Link} to="/voluntariado" className="u-nav-link">
              Ações de voluntariado
            </BootstrapNav.Link>
            <hr className="u-nav-item-divider" />
            <BootstrapNav.Link href={learnWorldsUrl} className="u-nav-link">
              Cursos e formações
            </BootstrapNav.Link>
            {currentAuthUser ? (
              <>
                <hr className="u-nav-item-divider" />
                <BootstrapNav.Link as={Link} to="/perfil" className="u-nav-link">
                  Dados pessoais
                </BootstrapNav.Link>
                {/*
                <BootstrapNav.Link as={Link} to="/meus-cursos" className="u-nav-link">
                  Os meus cursos
                </BootstrapNav.Link>{' '}
                */}
              </>
            ) : (
              <></>
            )}
            {isAdmin ? (
              <Fragment>
                <hr className="u-nav-item-divider" />
                <BootstrapNav.Link as={Link} to="/data-center" className="u-nav-link">
                  Gerir base de dados
                </BootstrapNav.Link>
                <BootstrapNav.Link as={Link} to="/gerir-utilizadores" className="u-nav-link">
                  Gerir utilizadores
                </BootstrapNav.Link>
                <BootstrapNav.Link as={Link} to="/gerir-opcoes" className="u-nav-link">
                  Gerir opções
                </BootstrapNav.Link>
              </Fragment>
            ) : (
              <Fragment />
            )}
            {currentAuthUser ? (
              <>
                <hr className="u-nav-item-divider" />
                <BootstrapNav.Link as={Link} to="/terminar-sessao" className="u-nav-link">
                  Terminar sessão
                </BootstrapNav.Link>{' '}
              </>
            ) : (
              <></>
            )}
          </BootstrapNav>
        </BootstrapOffcanvas.Body>
      ) : (
        <BootstrapOffcanvas.Body className="u-navbar-offcanvas-body">
          <div />
          <div className="u-navbar-offcanvas-body-nav">
            {/*
            <Dropdown {...IMPACT_ACTIONS_DROPDOWN_PROPS} expandNavbar={setShowOffcanvas} />
            <BootstrapNav.Link as={Link} to="/lideres-de-opiniao">
              Líderes sociais
            </BootstrapNav.Link>
                        */}
            <BootstrapNav.Link href="/voluntariado">Ações de voluntariado</BootstrapNav.Link>
            <BootstrapNav.Link href={learnWorldsUrl}>Cursos e formações</BootstrapNav.Link>
          </div>
          <div className="u-navbar-offcanvas-body-nav">
            <NavbarDropdownProfile />
          </div>
        </BootstrapOffcanvas.Body>
      )}
    </BootstrapNavbar.Offcanvas>
  );
}

export default NavbarOffcanvas;
