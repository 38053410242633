import * as Yup from 'yup';
import { Dispatch, SetStateAction } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import BsButton from 'react-bootstrap/Button';
import BsModal from 'react-bootstrap/Modal';
import InputText from '../../input-text/input-text.component';

type newActionFormValues = {
  actionDescription: string;
};

const newActionValidationSchema = Yup.object().shape({
  actionDescription: Yup.string().required('Descrição da ação é um campo obrigatório'),
});

type SubmitActionProps = {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  handleSubmitForm: (value: string) => void;
};

function SubmitActionModalBody({ setShowModal, handleSubmitForm }: SubmitActionProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<newActionFormValues>({ mode: 'onSubmit', resolver: yupResolver(newActionValidationSchema) });

  const onSubmit: SubmitHandler<newActionFormValues> = (data) => {
    if (Object.keys(errors).length === 0 && handleSubmitForm) {
      handleSubmitForm(data.actionDescription);
      setShowModal(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BsModal.Body>
        <InputText<newActionFormValues>
          id="formOrgName"
          name="actionDescription"
          label="Objetivo da ação: (o quê?) - sensibilzar as pessoas"
          showLabel={false}
          register={register}
          errorMessage={errors.actionDescription?.message}
        />
      </BsModal.Body>
      <BsModal.Footer>
        <BsButton variant="secondary" onClick={() => setShowModal(false)}>
          Fechar
        </BsButton>
        <BsButton variant="primary" type="submit" className="text-white">
          Submeter
        </BsButton>
      </BsModal.Footer>
    </form>
  );
}

export default SubmitActionModalBody;
