import { Fragment, useContext } from 'react';
import useEffectOnce from 'react-use/lib/useEffectOnce';
import useList from 'react-use/lib/useList';
import useToggle from 'react-use/lib/useToggle';

import { AppContext } from '../../state/app-state-context';
import { Card, NewButton } from '../../components';
import { LearnWorldsService } from '../../services';
import { UserCourse } from '../../interfaces/learnworlds/user-courses';
import { goTo } from '../../utils/helpers.util';

import './user-courses.styles.scss';

function UserCourses() {
  const {
    state: { currentFirestoreUser, learnWorlds },
  } = useContext(AppContext);
  const learnWorldsUrl = learnWorlds.url || 'https://udream.learnworlds.com/courses';
  const [userCourses, { set: setUserCourses }] = useList<UserCourse>([]);
  const [appIsLoading, toggleAppIsLoading] = useToggle(true);

  useEffectOnce(() => {
    if (currentFirestoreUser?.email) {
      getUserCourses(currentFirestoreUser.email);
    }
  });

  async function getUserCourses(userEmail: string) {
    try {
      const userProgress = await LearnWorldsService.getUserProgress(userEmail);

      const userCoursesWithProgress: UserCourse[] = [];

      for (const courseProgress of userProgress) {
        const courseInformation = await LearnWorldsService.getCourseInformation(courseProgress.courseId);
        userCoursesWithProgress.push({ ...courseProgress, ...courseInformation });
      }

      setUserCourses(userCoursesWithProgress);
    } catch {
      setUserCourses([]);
    } finally {
      toggleAppIsLoading(false);
    }
  }

  function goToCourse(course: UserCourse) {
    goTo(`https://udream.learnworlds.com/course/${course.id}`, '_blank');
  }

  return (
    <div>
      {appIsLoading ? (
        <Fragment />
      ) : (
        <div className="user-courses-container">
          <h2>Os meus cursos</h2>
          {userCourses.length > 0 ? (
            <div className="user-courses-container-list">
              {userCourses.map((course) => (
                <Card
                  key={course.id}
                  progressPercentage={course.progressRate}
                  imageURL={course.courseImage || ''}
                  isLocalImage={false}
                  title={course.title}
                  description={course.description || ''}
                  buttonChildren="Continuar curso"
                  buttonTheme="teal"
                  buttonOutlined={false}
                  handleButtonClick={() => goToCourse(course)}
                />
              ))}
            </div>
          ) : (
            <div className="user-courses-container-no-courses">
              <p>Não está inscrito em nenhum curso.</p>
            </div>
          )}
          <NewButton
            theme="beige"
            accent="green"
            outlined={true}
            size="lg"
            onClick={() => goTo(learnWorldsUrl, '_blank')}
          >
            Ver todos os cursos
          </NewButton>
        </div>
      )}
    </div>
  );
}

export default UserCourses;
