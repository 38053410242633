import './details-page-tag.component.scss';
import { ReactNode } from 'react';

export default function DetailsPageTag({ children }: { children: ReactNode }) {
  return (
    <div className="details-page-tag">
      <span>{children}</span>
    </div>
  );
}
