import { ChangeEvent, Dispatch, Fragment, SetStateAction, useState } from 'react';
import { Role } from '../../../enums/role.enum';
import { UserWithTagsAndCourses } from '../../../interfaces/user.interface';
import BsButton from 'react-bootstrap/Button';
import BsModal from 'react-bootstrap/Modal';
import FormSelect from 'react-bootstrap/FormSelect';

type ChangeUserRoleModalProps = {
  user: UserWithTagsAndCourses;
  handleRoleChange: (userId: string, role: Role) => void;
  setShowChangeUserRoleModal: Dispatch<SetStateAction<boolean>>;
};

function ChangeUserRoleModal({ user, handleRoleChange, setShowChangeUserRoleModal }: ChangeUserRoleModalProps) {
  const [userRole, setUserRole] = useState(user.role);

  const handleOnChangeRole = (event: ChangeEvent<HTMLSelectElement>) => {
    setUserRole(event.target.value as Role);
  };

  const handleOnClickCloseButton = () => {
    setShowChangeUserRoleModal(false);
  };

  const handleOnClick = () => {
    handleRoleChange(user.id, userRole);
    setShowChangeUserRoleModal(false);
  };

  return (
    <Fragment>
      <BsModal.Body>
        <FormSelect
          defaultValue={userRole}
          onChange={handleOnChangeRole}
          className="text-capitalize"
          size="sm"
          aria-label="Mudar função do utilizador"
          id="changeUserRole"
        >
          {Object.values(Role).map((role) => (
            <option key={`${user.id}-role-${role}`}>{role}</option>
          ))}
        </FormSelect>
      </BsModal.Body>
      <BsModal.Footer>
        <BsButton variant="secondary" onClick={handleOnClickCloseButton}>
          Fechar
        </BsButton>
        <BsButton variant="primary" className="text-white" onClick={handleOnClick} disabled={userRole === user.role}>
          Submeter
        </BsButton>
      </BsModal.Footer>
    </Fragment>
  );
}

export default ChangeUserRoleModal;
