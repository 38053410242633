import BootstrapProgressBar from 'react-bootstrap/ProgressBar';

import './progress-bar.styles.scss';

type ProgressBarProps = {
  percentage: number;
};

function ProgressBar({ percentage }: ProgressBarProps) {
  return (
    <div className="u-progress">
      <BootstrapProgressBar now={100} className="u-progress-bar" style={{ width: `${percentage}%` }} />
      <span className="u-progress-percentage">{percentage}%</span>
    </div>
  );
}

export default ProgressBar;
