type CloseIconProps = { outerClasses?: string; innerClasses?: string };

function CloseIcon({ outerClasses = '', innerClasses = '' }: CloseIconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={outerClasses}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.05703 1.05721C1.57773 0.536512 2.42195 0.536512 2.94265 1.05721L9.99984 8.1144L17.057 1.05721C17.5777 0.536512 18.4219 0.536512 18.9426 1.05721C19.4633 1.57791 19.4633 2.42213 18.9426 2.94283L11.8855 10L18.9426 17.0572C19.4633 17.5779 19.4633 18.4221 18.9426 18.9428C18.4219 19.4635 17.5777 19.4635 17.057 18.9428L9.99984 11.8856L2.94265 18.9428C2.42195 19.4635 1.57773 19.4635 1.05703 18.9428C0.536329 18.4221 0.536329 17.5779 1.05703 17.0572L8.11422 10L1.05703 2.94283C0.536329 2.42213 0.536329 1.57791 1.05703 1.05721Z"
        className={innerClasses}
      />
    </svg>
  );
}

export default CloseIcon;
