import { Dispatch, SetStateAction } from 'react';
import { Variant } from 'react-bootstrap/esm/types';
import Toast from 'react-bootstrap/Toast';
import ToastContainer, { ToastPosition } from 'react-bootstrap/ToastContainer';

type ToasterProps = {
  position?: ToastPosition;
  toastText: string;
  setShowToast: Dispatch<SetStateAction<boolean>>;
  showToast: boolean;
  backgroundColor: Variant;
};

function Toaster({ position = 'top-end', toastText, setShowToast, showToast, backgroundColor }: ToasterProps) {
  return (
    <ToastContainer position={position}>
      <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide bg={backgroundColor}>
        <Toast.Body className="text-white lead">{toastText}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
}

export default Toaster;
