import { useEffect } from 'react';
import useToggle from 'react-use/lib/useToggle';

const useIsOverflowHorizontally = (node: HTMLDivElement | null) => {
  const [overflows, setOverflows] = useToggle(false);

  useEffect(() => {
    setOverflows(!!node && !!node.scrollWidth && !!node.clientWidth && node.scrollWidth > node.clientWidth);
  }, [node]);

  return overflows;
};

export { useIsOverflowHorizontally };
