import { ComplexFormProps } from './complex-form.interface';
import { TARGET_AUDIENCE } from '../../model/options';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';

function TargetAudienceForm({ complexForm, setComplexForm }: ComplexFormProps) {
  return (
    <Form.Group className="mb-3" controlId="target-audience">
      <Form.Label>Público Alvo</Form.Label>
      <ListGroup>
        {complexForm.targetAudience.map((target: string, index: number) => {
          return (
            <ListGroup.Item key={index}>
              {target}
              <Button
                size={'sm'}
                onClick={() => {
                  complexForm.targetAudience.splice(index, 1);
                  setComplexForm({ ...complexForm, targetAudience: complexForm.targetAudience });
                }}
              >
                Remover
              </Button>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
      <Form.Select
        aria-label="Público Alvo"
        onChange={(e) => {
          if (!complexForm.targetAudience.includes(e.target.value)) {
            complexForm.targetAudience.push(e.target.value);
            setComplexForm({ ...complexForm, targetAudience: complexForm.targetAudience });
          }
        }}
      >
        {Object.values(TARGET_AUDIENCE).map((target, index) => {
          return (
            <option key={index} value={target}>
              {target}
            </option>
          );
        })}
      </Form.Select>
    </Form.Group>
  );
}

export default TargetAudienceForm;
