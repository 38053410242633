import { Navigate, Outlet } from 'react-router-dom';
import { useContext } from 'react';

import { AppContext } from '../../state/app-state-context';

const AuthenticatedRoutes = () => {
  const {
    state: { currentAuthUser, currentFirestoreUser },
  } = useContext(AppContext);

  const isAllowed = !!currentAuthUser && currentAuthUser?.emailVerified && !!currentFirestoreUser;

  return isAllowed ? <Outlet /> : <Navigate to="/login" />;
};

export default AuthenticatedRoutes;
