import { FaFacebookF, FaGoogle, FaTwitter } from 'react-icons/fa';
import { FacebookAuthProvider, GoogleAuthProvider, TwitterAuthProvider } from 'firebase/auth';
import { FcGoogle } from 'react-icons/fc';
import { FirebaseError } from 'firebase/app';
import { Fragment, MouseEvent, useContext, useEffect, useState } from 'react';
import { GrFacebook } from 'react-icons/gr';
import { IconBaseProps } from 'react-icons';
import { SubmitHandler, useForm } from 'react-hook-form';
import { bool, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Nav from 'react-bootstrap/Nav';

// Components
import { AppContext } from '../../state/app-state-context';
import { FirebaseAuthErrors, UnknownError } from '../../constants/errors.constants';
import { openToastAction } from '../../state/actions';
import AuthService from '../../services/auth.service';

// Styles
import styles from './social-login.component.module.scss';

type FormValues = {
  termsAndConditions: boolean;
};

const validationSchema = object().shape({
  termsAndConditions: bool()
    .oneOf([true], 'Necessita de aceitar os termos e condições para prosseguir com o registo.')
    .default(false),
});

const iconProps: IconBaseProps = {
  color: '#F5F4F2',
  size: '24',
};

const modalIconProps: IconBaseProps = {
  size: '40',
};

const googleModalIconProps: IconBaseProps = {
  ...modalIconProps,
};

const facebookModalIconProps: IconBaseProps = {
  ...modalIconProps,
  color: '#3b5998',
};

const twitterModalIconProps: IconBaseProps = {
  ...modalIconProps,
  color: '#00acee',
};

interface SsoModal {
  type: 'Google' | 'Facebook' | 'Twitter' | undefined;
  show: boolean;
}

const initialSsoModalValues: SsoModal = {
  type: undefined,
  show: false,
};

type SocialLoginProps = {
  registerUser?: boolean;
};

function SocialLogin({ registerUser = false }: SocialLoginProps) {
  const [modalOptions, setModalOptions] = useState<SsoModal>(initialSsoModalValues);
  const { dispatch } = useContext(AppContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (modalOptions.show) {
      reset({ termsAndConditions: false });
    }
  }, [modalOptions]);

  const handleSsoLogin = async (type: 'Google' | 'Facebook' | 'Twitter', event?: MouseEvent<HTMLButtonElement>) => {
    if (event) {
      event.preventDefault();
    }

    try {
      if (type === 'Google') {
        const provider = new GoogleAuthProvider();
        provider.setCustomParameters({ prompt: 'select_account' });
        await AuthService.signInWithProvider(provider);
      } else if (type === 'Facebook') {
        const provider = new FacebookAuthProvider();
        provider.setCustomParameters({ display: 'popup' });
        await AuthService.signInWithProvider(provider);
      } else {
        const provider = new TwitterAuthProvider();
        await AuthService.signInWithProvider(provider);
      }
    } catch (error) {
      if (error instanceof FirebaseError && error.code !== 'auth/popup-closed-by-user') {
        const errorMessage = FirebaseAuthErrors[error.code] ?? UnknownError;
        dispatch(openToastAction(errorMessage, 'danger'));
      }
    }
  };

  const onSubmit: SubmitHandler<FormValues> = async (formValues) => {
    if (formValues.termsAndConditions && modalOptions.type) {
      const ssoType = modalOptions.type;
      setModalOptions(initialSsoModalValues);
      handleSsoLogin(ssoType);
    }
  };

  return (
    <Fragment>
      <div className={styles['sso-outer-container']}>
        <div className={styles['sso-inner-container']}>
          <Button
            className="custom-bootstrap-button"
            type="button"
            onClick={(e) =>
              registerUser ? setModalOptions({ type: 'Google', show: true }) : handleSsoLogin('Google', e)
            }
          >
            <FaGoogle {...iconProps} />
          </Button>
          <Button
            className="custom-bootstrap-button"
            type="button"
            onClick={(e) =>
              registerUser ? setModalOptions({ type: 'Facebook', show: true }) : handleSsoLogin('Facebook', e)
            }
          >
            <FaFacebookF {...iconProps} />
          </Button>
          <Button
            className="custom-bootstrap-button"
            type="button"
            onClick={(e) =>
              registerUser ? setModalOptions({ type: 'Twitter', show: true }) : handleSsoLogin('Twitter', e)
            }
          >
            <FaTwitter {...iconProps} />
          </Button>
        </div>
      </div>
      <Modal show={modalOptions.show} onHide={() => setModalOptions(initialSsoModalValues)}>
        <Modal.Header closeButton>
          <Modal.Title className="text-primary">Registar com {modalOptions.type}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="d-flex flex-column align-items-center">
              {modalOptions.type === 'Google' && <FcGoogle {...googleModalIconProps} />}
              {modalOptions.type === 'Facebook' && <GrFacebook {...facebookModalIconProps} />}
              {modalOptions.type === 'Twitter' && <FaTwitter {...twitterModalIconProps} />}

              <Form.Group className="custom-bootstrap-form-group mt-3" controlId="formTermsAndConditions">
                <Form.Check
                  type="checkbox"
                  {...register('termsAndConditions')}
                  label={
                    <Nav.Link
                      href="https://www.udream.pt/termos-e-condicoes"
                      target="_blank"
                      rel="noreferrer"
                      className="text-decoration-underline annotation"
                    >
                      Aceitação dos termos e condições
                    </Nav.Link>
                  }
                />
              </Form.Group>
              <div className={`invalid-feedback ${errors.termsAndConditions && 'd-block'}`}>
                {errors.termsAndConditions?.message}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              className="custom-bootstrap-button"
              size="sm"
              onClick={() => setModalOptions(initialSsoModalValues)}
              type="button"
            >
              Fechar
            </Button>
            <Button className="custom-bootstrap-button" size="sm" type="submit">
              Continuar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Fragment>
  );
}

export default SocialLogin;
