import { ComplexFormProps } from './complex-form.interface';
import { ODS } from '../../model/options';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';

function OdsForm({ complexForm, setComplexForm }: ComplexFormProps) {
  return (
    <Form.Group className="mb-3" controlId="ods">
      <Form.Label>Objetivos de desenvolvimento sustentável</Form.Label>
      <ListGroup>
        {complexForm.sustainabilityObjectives.map((ods: string, index: number) => {
          return (
            <ListGroup.Item key={index}>
              {ods}
              <Button
                size={'sm'}
                onClick={() => {
                  complexForm.sustainabilityObjectives.splice(index, 1);
                  setComplexForm({
                    ...complexForm,
                    sustainabilityObjectives: complexForm.sustainabilityObjectives,
                  });
                }}
              >
                Remover
              </Button>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
      <Form.Select
        aria-label="ODS"
        onChange={(e) => {
          if (!complexForm.sustainabilityObjectives.includes(e.target.value)) {
            complexForm.sustainabilityObjectives.push(e.target.value);
            setComplexForm({
              ...complexForm,
              sustainabilityObjectives: complexForm.sustainabilityObjectives,
            });
          }
        }}
      >
        {Object.values(ODS).map((ods, index) => {
          return (
            <option key={index} value={ods}>
              {ods}
            </option>
          );
        })}
      </Form.Select>
    </Form.Group>
  );
}

export default OdsForm;
