import './page-header.component.scss';

export default function PageHeader({
  title,
  description,
  buttons,
  img,
}: {
  title: string;
  description: string;
  buttons: Array<JSX.Element>;
  img: JSX.Element;
}) {
  return (
    <div className="page-header">
      <div className="page-header-left-side">
        <div className="page-title">{title}</div>
        <div className="page-description">{description}</div>
        <div className="buttons">{buttons}</div>
      </div>
      <div className="page-header-right-side">{img}</div>
    </div>
  );
}
