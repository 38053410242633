export const REGIONS = {
  AVEIRO: '1. Distrito de Aveiro',
  BEJA: '2. Distrito de Beja',
  BRAGA: '3. Distrito de Braga',
  BRAGANCA: '4. Distrito de Bragança',
  CASTELO_BRANCO: '5. Distrito de Castelo Branco',
  COIMBRA: '6. Distrito de Coimbra',
  EVORA: '7. Distrito de Évora',
  FARO: '8. Distrito de Faro',
  GUARDA: '9. Distrito da Guarda',
  LEIRIA: '10. Distrito de Leiria',
  LISBOA: '11. Distrito de Lisboa',
  PORTALEGRE: '12. Distrito de Portalegre',
  PORTO: '13. Distrito do Porto',
  SANTAREM: '14. Distrito de Santarém',
  SETUBAL: '15. Distrito de Setúbal',
  VIANA_DO_CASTELO: '16. Distrito de Viana do Castelo',
  VILA_REAL: '17. Distrito de Vila Real',
  VISEU: '18. Distrito de Viseu',
  ACORES: '19. RA Açores',
  MADEIRA: '20. RA Madeira',
  PORTUGAL_CONTINENTAL: '21. Portugal Continental',
  TODO_O_PAIS: '22. Todo o país',
  INTERNACIONAL: '23. Internacional',
};

export const STATUS = {
  enviado: 'Enviado',
  pseudo: 'Pseudo',
  falta_completar: 'Falta completar',
  completo: 'Completo',
  rever: 'REVER ',
};

export const TRUE_FALSE = {
  true: 'Sim',
  false: 'Não',
};

export const SOCIAL_PROBLEMS = [
  {
    id: 1,
    group: '1. Sustentabilidade Ambiental: carbono, água, lixo/desperdício, ecossistemas vivos, espaço',
    equivalence: 'GP.1',
    problems: [
      'Fauna',
      'Todos',
      'Flora',
      'Água potável e saneamento',
      'Energias renováveis e acessíveis',
      'Poluição',
      'Alterações climáticas',
      'Consumo e produção sustentáveis',
      'Exploração espacial',
    ],
    image_social_problems: 'sustentabilidade-ambiental',
    image_social_leader: 'personagem-a1',
  },
  {
    id: 2,
    group: '2. Deficiência, Incapacidade, Diversidade funcional',
    equivalence: 'GP.2',
    problems: [
      'Apoio à vida independente',
      'Todos',
      'Acesso à saúde',
      'Recursos multifuncionais / produtos de apoio / ajudas técnicas',
      'Educação inclusiva e equitativa',
      '(Re)inserção socioprofissional',
      'Mobilidade e acessibilidades',
    ],
    image_social_problems: 'deficiencia-Incapacidade-diversidade-funcional',
    image_social_leader: 'personagem-a2',
  },
  {
    id: 3,
    group: '3. Demografia e Envelhecimento',
    equivalence: 'GP.3',
    problems: [
      'Envelhecimento demográfico, renovação de gerações e crescimento populacional',
      'Todos',
      'Necessidades básicas da pessoa idosa',
      'Envelhecimento ativo e saudável',
      'Aprendizagem intergeracional',
      'Violência contra a pessoa idosa',
    ],
    image_social_problems: 'demografia-envelhecimento',
    image_social_leader: 'personagem-a3',
  },
  {
    id: 4,
    group: '4. Economia, Emprego, Pobreza',
    equivalence: 'GP.4',
    problems: [
      'Economia social e solidária',
      'Todos',
      'Economia informal',
      'Legislação laboral',
      'Desemprego',
      'Criação de emprego',
      '(Re)inserção no mercado de trabalho',
      'Fome e/ou desnutrição',
      'Carência socioeconómica',
      'Carência habitacional',
      'Isolamento social e/ou reinserção social',
    ],
    image_social_problems: 'economia-emprego-pobreza',
    image_social_leader: 'personagem-a4',
  },
  {
    id: 5,
    group: '5. Educação, Cultura e Literacia',
    equivalence: 'GP.5',
    problems: [
      'Desenvolvimento pessoal',
      'Todos	',
      'STEAM, currículo, programa educativo',
      'Necessidades Educativas Especiais (NEE)',
      'Inovação pedagógica e mudança educativa',
      'Artes, entretenimento e espetáculo',
    ],
    image_social_problems: 'educação-cultura-literacia',
    image_social_leader: 'personagem-a5',
  },
  {
    id: 6,
    group: '6. LGBTQIA+',
    equivalence: 'GP.6',
    problems: [
      'Atitudes e Discriminação',
      'Todos',
      'Equidade no acesso a direitos, bens e serviços',
      'Violência contra LGBTQIA+',
    ],
    image_social_problems: 'lgbtqia-plus',
    image_social_leader: 'personagem-a6',
  },
  {
    id: 7,
    group: '7. Minorias étnicas, Migrantes, Refugiados, Ex-colonos',
    equivalence: 'GP.7',
    problems: [
      'Atitudes e Discriminação',
      'Todos',
      'Equidade no acesso a direitos, bens e serviços',
      'Integração de minorias sociais no mercado de trabalho',
      'Liberdade e diversidade religiosa',
      'Violência contra grupos minoritários',
    ],
    image_social_problems: 'minorias-etnicas-migrantes-refugiados-ex-colonos',
    image_social_leader: 'personagem-a7',
  },
  {
    id: 8,
    group: '8. Paz, Terrorismo e Segurança',
    equivalence: 'GP.8',
    problems: [
      'Justiça e Direito Internacional',
      'Todos',
      'Segurnça',
      'Criminalidade',
      'Guerra',
      'Terrorismo',
      'Genocidio',
    ],
    image_social_problems: 'paz-terrorismo-segurança',
    image_social_leader: 'personagem-a8',
  },
  {
    id: 9,
    group: '9. Saúde Biológica ',
    equivalence: 'GP.9',
    problems: [
      'Equidade do acesso aos cuidados de saúde',
      'Todos',
      'Dignidade humana nos cuidados de saúde',
      'Promoção da saúde e da sociedade do bem-estar',
      'Prevenção da doença',
      'Remediação e intervenção da doença',
    ],
    image_social_problems: 'saude-biologica',
    image_social_leader: 'personagem-a9',
  },
  {
    id: 10,
    group: '10. Saúde Mental, Comportamento desviante e Desenvolvimento humano',
    equivalence: 'GP.10',
    problems: [
      'Equidade e acesso adequado aos cuidados de saúde',
      'Todos',
      'Promoção da saúde mental',
      'Prevenção em saúde mental',
      'Remediação e intervenção em saúde mental',
      'Delinquência juvenil',
      'Comportamentos aditivos e dependências (CAD)',
    ],
    image_social_problems: 'saude-mental-comportamento-desviante-desenvolvimento-humano',
    image_social_leader: 'personagem-a10',
  },
  {
    id: 11,
    group: '11. Universo Digital e Tecnologia',
    equivalence: 'GP.11',
    problems: [
      'Literacia e inclusão digital: Acesso e Qualidade',
      'Todos	',
      'Vigilância, segurança e privacidade digital',
      'Cidades Digitais	',
      'Inteligência artificial e robótica',
      'Ciberbullying',
      'Redes sociais',
      'Ciberterrorismo',
      'Tecnologia de apoio e/ou adaptativa',
      'Tecnologia aumentativa e cyborgs',
      'Data knowledge',
      'RGPD',
    ],
    image_social_problems: 'universo-digital-tecnologia',
    image_social_leader: 'personagem-a11',
  },
  {
    id: 12,
    group: '12. Violência (todas as formas)',
    equivalence: 'GP.12',
    problems: [
      'Violência psicológica/verbal/moral e/ou violência simbólica',
      'Todos',
      'Violência física',
      'Violência no namoro',
      'Violência sexual',
      'Violência Doméstica e Familiar',
      'Negligência e Abandono',
      'Violência financeira/económica',
      'Violência no trabalho e/ou assédio profissional',
      'Bullying',
      'Suicídio e/ou autoflagelação',
      'Violência comunitária e/ou coletiva',
    ],
    image_social_problems: 'violencia',
    image_social_leader: 'personagem-a12',
  },
  {
    id: 13,
    group: '13. Todos',
    equivalence: 'GP.13',
    problems: ['Todos'],
  },
];

export const TARGET_AUDIENCE = {
  BEBE: '1. Bebé',
  CRIANCA: '2. Criança',
  JOVEM: '3. Jovem',
  JOVEM_ADULTO: '4. Jovem Adulto',
  ADULTO: '5. Adulto',
  IDOSO: '6. Idoso',
  COMUNIDADE_GERAL: '7.  Comunidade Geral',
  ANIMAIS: '8.  Animais',
  MEIO_AMBIENTE: '9. Meio Ambiente',
  TODOS: '10. Todos',
};

export const PROFESSIONAL_CATEGORIES = [
  {
    id: 1,
    area: '1. Representantes Do Poder Legislativo E De Órgãos Executivos, Dirigentes, Directores E Gestores Executivos',
    equivalence: 'AP.1',
    professions: [
      '11 Representantes do poder legislativo e de órgãos executivos, dirigentes superiores da Administração Pública, de organizações especializadas, directores e gestores de empresas',
      '12 Directores de serviços administrativos e comerciais',
      '13 Directores de produção e de serviços especializados',
      '14 Directores de hotelaria, restauração, comércio e de outros serviços',
    ],
  },
  {
    id: 2,
    area: '2. Especialistas Das Actividades Intelectuais E Científicas',
    equivalence: 'AP.2',
    professions: [
      '21 Especialistas das ciências físicas, matemáticas, engenharias e técnicas afins',
      '22 Profissionais de saúde',
      '23 Professores',
      '24 Especialistas em finanças, contabilidade, organização administrativa, relações públicas e comerciais',
      '25 Especialistas em tecnologias de informação e comunicação (TIC)',
      '26 Especialistas em assuntos jurídicos, sociais, artísticos e culturais',
    ],
  },
  {
    id: 3,
    area: '3. Técnicos E Profissões De Nível Intermédio',
    equivalence: 'AP.3',
    professions: [
      '31 Técnicos e profissões das ciências e engenharia, de nível intermédio',
      '32 Técnicos e profissionais, de nível intermédio da saúde',
      '33 Técnicos de nível intermédio, das áreas financeira, administrativa e dos negócios',
      '34 Técnicos de nível intermédio dos serviços jurídicos, sociais, desportivos, culturais e similares',
      '35 Técnicos das tecnologias de informação e comunicação',
    ],
  },
  {
    id: 4,
    area: '4. Pessoal Administrativo',
    equivalence: 'AP.4',
    professions: [
      '41 Empregados de escritório, secretários em geral e operadores de processamento de dados',
      '42 Pessoal de apoio directo a clientes',
      '43 Operadores de dados, de contabilidade, estatística, de serviços financeiros e relacionados com o registo',
      '44 Outro pessoal de apoio de tipo administrativo',
    ],
  },
  {
    id: 5,
    area: '5. Trabalhadores Dos Serviços Pessoais, De Protecção E Segurança E Vendedores',
    equivalence: 'AP.5',
    professions: [
      '51 Trabalhadores dos serviços pessoais',
      '52 Vendedores',
      '53 Trabalhadores dos cuidados pessoais e similares',
      '54 Pessoal dos serviços de protecção e segurança',
    ],
  },
  {
    id: 6,
    area: '6. Agricultores E Trabalhadores Qualificados Da Agricultura, Da Pesca E Da Floresta',
    equivalence: 'AP.6',
    professions: [
      '61 Agricultores e trabalhadores qualificados da agricultura e produção animal, orientados para o mercado',
      '62 Trabalhadores qualificados da floresta, pesca e caça, orientados para o mercado',
      '63 Agricultores, criadores de animais, pescadores, caçadores e colectores, de subsistência',
    ],
  },
  {
    id: 7,
    area: '7. Trabalhadores Qualificados Da Indústria, Construção E Artífices',
    equivalence: 'AP.7',
    professions: [
      '71 Trabalhadores qualificados da construção e similares, excepto electricista',
      '72 Trabalhadores qualificados da metalurgia, metalomecânica e similares',
      '73 Trabalhadores qualificados da impressão, do fabrico de instrumentos de precisão, joalheiros,artesãos e similares',
      '74 Trabalhadores qualificados em electricidade e em electrónica',
      '75 Trabalhadores da transformação de alimentos, da madeira, do vestuário e outras indústrias e artesanato',
    ],
  },
  {
    id: 8,
    area: '8. Operadores De Instalações E Máquinas E Trabalhadores Da Montagem',
    equivalence: 'AP.8',
    professions: [
      '81 Operadores de instalações fixas e máquinas',
      '82 Trabalhadores da montagem',
      '83 Condutores de veículos e operadores de equipamentos móveis',
    ],
  },
  {
    id: 9,
    area: '9. Trabalhadores Não Qualificados',
    equivalence: 'AP.9',
    professions: [
      '91 Trabalhadores de limpeza',
      '92 Trabalhadores não qualificados da agricultura, produção animal, pesca e floresta',
      '93 Trabalhadores não qualificados da indústria extractiva, construção, indústria transformadora e transportes',
      '94 Assistentes na preparação de refeições',
      '95 Vendedores ambulantes (excepto de alimentos) e prestadores de serviços na rua',
      '96 Trabalhadores dos resíduos e de outros serviços elementares',
    ],
  },
  {
    id: 10,
    area: '10. Indefinido',
    equivalence: 'AP.10',
    professions: ['indefinido'],
  },
];

export const SERVICES = {
  ADVOCACY: '1. Advocacy, Direitos Humanos, Democracia',
  ALOJAMENTO: '2. Alojamento e limpeza, Alimentação, Higiene pessoal e Saúde',
  AMBIENTE: '3. Ambiente, Cuidado Animal, Agricultura',
  APOIO_EMOCIONAL: '4. Apoio Emocional, Psicológico e Psicossocial',
  CULTURA: '5. Cultura, Artes, Desporto, Entretenimento',
  EDUCACAO: '6. Educação (formal e/ou não-formal), Literacia e Investigação',
  INFORMATICA: '7. Informática, Design, Fotografia',
  FINANCAS: '8. Finanças e Gestão',
  MOBILIDADE: '9. Mobilidade e Transporte',
  REPARACAO: '10. Reparação, Manutenção, Confeção',
};

export const VOLUNTEERING_PROFILE = {
  AGRICULTURA: '1. Agricultura, Silvicultura e Pescas',
  ARQUITETURA: '2. Arquitetura e Construção',
  ARTES: '3. Artes',
  CIENCIAS_EDUCACAO: '4. Ciências da Educação',
  CIENCIAS_VIDA: '5. Ciências da Vida',
  CIENCIAS_EMPRESARIAIS: '6. Ciências Empresariais',
  CIENCIAS_FISICAS: '7. Ciências Físicas',
  CIENCIAS_SOCIAIS: '8. Ciências Sociais e do Comportamento',
  CIENCIAS_VETERINARIAS: '9. Ciências Veterinárias',
  DIREITO: '10. Direito',
  ENGENHARIA: '11. Engenharia e Técnicas Afins',
  HUMANIDADES: '12. Humanidades',
  INDUSTRIAS: '13. Indústrias Transformadoras',
  INFORMACAO: '14. Informação e Jornalismo',
  INFORMATICA: '15. Informática',
  MATEMATICA: '16. Matemática e Estatística',
  PROTECAO_AMBIENTE: '17. Proteção do Ambiente',
  SAUDE: '18. Saúde',
  SERVICOS_TRANSPORTE: '19. Serviços de Transporte',
  SERVICOS_PESSOAIS: '20. Serviços Pessoais',
  SERVICOS_SOCIAIS: '21. Serviços Sociais',
  TODOS: '22. Todos/ Indiferente',
};

export const ODS = {
  POBREZA: '1. Erradicar a pobreza',
  FOME: '2. Erradicar a fome',
  SAUDE: '3. Saúde de qualidade',
  EDUCACAO: '4. Educação de qualidade',
  IGUALDADE: '5. Igualdade de género',
  AGUA: '6. Água Potável e saneamento',
  ENERGIAS: '7. Energias Renováveis e Acessíveis',
  TRABALHO: '8. Trabalho digno e crescimento económico',
  INDUSTRIA: '9. Indústria, Inovação e Infraestruturas',
  REDUZIR: '10. Reduzir as desigualdades',
  CIDADES: '11. Cidades e comunidades Sustentáveis',
  PRODUCAO: '12. Produção e consumo sustentáveis',
  ACAO_CLIMATICA: '13. Ação climática',
  PROTEGER_MARINHA: '14. Proteger a vida marinha',
  PROTEGER_TERRESTRE: '15. Proteger a vida terrestre',
  PAZ: '16. Paz, justiça e instituições eficazes',
  PARCERIAS: '17. Parcerias para a implementação dos objetivos',
};

export const IMPACT_ACTIONS_DURATIONS = [
  'Menos de 15 minutos',
  'Algumas horas',
  'Alguns dias (porque requer aguardar respostas de terceiros, por exemplo)',
];

export const DISTRICTS = [
  'Aveiro',
  'Beja',
  'Braga',
  'Bragança',
  'Castelo Branco',
  'Coimbra',
  'Évora',
  'Faro',
  'Guarda',
  'Leiria',
  'Lisboa',
  'Portalegre',
  'Porto',
  'Região Autónoma da Madeira',
  'Região Autónoma dos Açores',
  'Santarém',
  'Setúbal',
  'Viana do Castelo',
  'Vila Real',
  'Viseu',
];

export {};
