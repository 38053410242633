import { ChangeEvent, Dispatch, Fragment, SetStateAction } from 'react';
import BsButton from 'react-bootstrap/Button';
import BsModal from 'react-bootstrap/Modal';
import FormControl from 'react-bootstrap/FormControl';

type InviteUsersModalProps = {
  emails: string;
  setEmails: Dispatch<SetStateAction<string>>;
  setShowInviteUsersModal: Dispatch<SetStateAction<boolean>>;
  handleInviteUsers: () => void;
};

const InviteUsersModal = ({ emails, setEmails, setShowInviteUsersModal, handleInviteUsers }: InviteUsersModalProps) => {
  const handleOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setEmails(event.target.value);
  };

  const handleOnClickCloseButton = () => {
    setShowInviteUsersModal(false);
  };

  const handleOnClickInviteButton = () => {
    handleInviteUsers();
  };

  return (
    <Fragment>
      <BsModal.Body>
        <p>Email por linha</p>
        <FormControl as="textarea" rows={15} value={emails} onChange={handleOnChange} />
      </BsModal.Body>
      <BsModal.Footer>
        <BsButton variant="secondary" onClick={handleOnClickCloseButton}>
          Fechar
        </BsButton>
        <BsButton variant="primary" className="text-white" onClick={handleOnClickInviteButton} disabled={!emails}>
          Convidar
        </BsButton>
      </BsModal.Footer>
    </Fragment>
  );
};

export default InviteUsersModal;
