import { Navigate, Outlet } from 'react-router-dom';
import { useContext } from 'react';

import { AppContext } from '../../state/app-state-context';
import { Role } from '../../enums/role.enum';

function AdminRoutes() {
  const {
    state: { currentFirestoreUser },
  } = useContext(AppContext);

  const isAdmin = currentFirestoreUser?.role === Role.Admin ?? false;

  return isAdmin ? <Outlet /> : <Navigate to="/forbidden" />;
}

export default AdminRoutes;
