import './banner.styles.scss';
import { ReactNode } from 'react';
import { Theme } from '../../enums/theme.enum';

type BannerProps = {
  theme: Theme;
  children: ReactNode;
};

function Banner({ theme, children }: BannerProps) {
  return (
    <div className={`u-banner-outer`}>
      <div className={`u-banner-inner u-banner-inner-${theme}`}>{children}</div>
    </div>
  );
}

export default Banner;
