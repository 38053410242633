export const ELASTIC_ACTIONS_PATH = 'actions';
export const ELASTIC_LEADERS_PATH = 'leaders';
export const ELASTIC_PROJECTS_PATH = 'projects';
export const ELASTIC_ORGS_PATH = 'orgs';
//export const ELASTIC_USERS_PATH = 'users';
/*
    Official Docs for API: https://www.elastic.co/guide/en/app-search/8.5/search.html
   */
export interface SearchApiOptions {
  /*
    Strings to match. The value '' (empty string) matches all documents. (Note that API limits for documents returned applies. See Response body for more details.)
     */
  query?: string[];
  /*
  Engine to search in. Defaults to the current engine.
   */
  engine: string;
  /*
  size: Number of results per page. Must be greater than or equal to 1 and less than or equal to 1000. Defaults to 10.
  current: Page number of results to return. Must be greater than or equal to 1 and less than or equal to 100. Defaults to 1.
   */
  pagination?: { size: number; current: number };
  filterList?: SearchApiFilter[];
  sort?: { [key: string]: 'asc' | 'desc' } | { [key: string]: 'asc' | 'desc' }[];
  searchFields?: { [key: string]: { weight?: number } };
}

/*
  https://www.elastic.co/guide/en/app-search/8.5/filters.html

  logic
        any - At least one of the filters must match. This functions as an OR condition.
        all - All the filters must match. This functions as an AND condition.
        none - All the filters must not match. This functions as a NOT condition.
 */
export interface SearchApiFilter {
  filter: { key: string; values: string[] };
  logic: 'any' | 'all' | 'none';
}

export interface SearchApiResponse {
  results: unknown[];
  meta: SearchApiMeta;
}

export interface SearchApiMeta {
  request_id: string;
  warnings: unknown[];
  alerts: unknown[];
  page: PaginationSettings;
}

export interface SearchBoxMeta {
  engine: string;
  id: string;
  score: number;
}

export interface PaginationSettings {
  current: number;
  size: number;
  total_pages: number;
  total_results: number;
}
